import React from 'react';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import Store from '../../../store';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import DataSetMetadata from '../../../models/audience/DataSetMetadata';
import checkPermissions from '../CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import { LinkContainer } from 'react-router-bootstrap';
import Spinner from '../Spinner';
import { LtbDatasetVariables } from '../../../models/audience/ltb/LtbDataset';
import {
  IS_LLTB,
  MAX_NUMBER_OF_LOADING_DATASETS,
} from '../../../utils/constants';
import { DataSetQuality } from '../../../models/data/types';
import DataSetOverviews from '../../../models/data/DataSetOverviews';

interface LoadedDatasetOverviewProps {
  store?: Store;
  tenant: string;
  overviews: DataSetOverviews;
  dataSetMetadata: DataSetMetadata[];
  modelId: string;
  cleanCache: () => void;
  cleaningCache: boolean;
}

type LoadedDatasetOverviewState = {
  isLoading: boolean;
  showRefreshDatasetModal: boolean;
  currentLoadingModels: number[];
};

const LastUpdatedOn = ({ date, email }: { date: string; email: string }) => (
  <span>
    Last updated on <b>{moment(date).format('MMM DD, YYYY h:mma')}</b> by{' '}
    <b>{email}</b>
  </span>
);

export default inject('store')(
  observer(
    class LoadedDatasetOverview extends React.Component<
      LoadedDatasetOverviewProps,
      LoadedDatasetOverviewState
    > {
      constructor(props: LoadedDatasetOverviewProps) {
        super(props);

        this.state = {
          isLoading: true,
          showRefreshDatasetModal: false,
          currentLoadingModels: [],
        };

        this.toggleShowRefreshDatasetModal =
          this.toggleShowRefreshDatasetModal.bind(this);
        this.reloadDataset = this.reloadDataset.bind(this);
      }

      async componentDidMount() {
        const { tenant, activeModel } = this.props.store;
        const [currentLoadingModels] = await Promise.all([
          this.props.store.mkAudiencePage.getCurrentLoadingModels(tenant),
          activeModel.type === 'customer_fit'
            ? this.props.store.getDefaultDatasetVariables(activeModel.buildType)
            : this.props.store.getDefaultDatasetLtbVariables(),
          this.props.store.getTenantAudiences(),
          this.props.store.getTenantConversionNames(),
        ]);

        this.setState({ isLoading: false, currentLoadingModels });
      }

      toggleShowRefreshDatasetModal() {
        this.setState({
          showRefreshDatasetModal: !this.state.showRefreshDatasetModal,
        });
      }

      async reloadDataset() {
        await this.props.store.recompute();
      }

      render() {
        const { tenant, modelId, overviews, dataSetMetadata, cleaningCache } =
          this.props;

        const {
          activeModel,
          csvPage,
          redshiftPage,
          mkAudiencePage,
          pqlPage,
          mqaPage,
          isAllowedToEdit,
        } = this.props.store;
        const training = overviews.get('training');
        const validation = overviews.get('validation');
        const { isLoading, currentLoadingModels } = this.state;

        const isMqa = activeModel.type === 'mqa';

        let ltbVariables: LtbDatasetVariables;
        if (IS_LLTB(activeModel.type)) {
          ltbVariables = pqlPage.variables;
        } else if (isMqa) {
          ltbVariables = mqaPage.variables;
        }
        const validationDatasetMetadata = dataSetMetadata?.find(
          (dataset) => dataset?.datasetType === 'validation'
        );
        const trainingDatasetMetadata = dataSetMetadata?.find(
          (dataset) => dataset?.datasetType === 'training'
        );

        const cantLoad =
          currentLoadingModels.length >= MAX_NUMBER_OF_LOADING_DATASETS;

        if (isLoading) {
          return <Spinner />;
        }

        const dataQualityVariantes: Record<DataSetQuality, string> = {
          average: 'warning',
          bad: 'danger',
          good: 'success',
        };

        return (
          <>
            <Card border="light" className="h-100">
              <Card.Body>
                <Card.Title className="d-flex">
                  <h4 className="d-inline-block mb-2 fw-bold">
                    Dataset Summary
                  </h4>
                  {checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <ButtonToolbar className="d-inline-block ms-auto">
                      {isAllowedToEdit && (
                        <OverlayTrigger
                          show={cantLoad}
                          overlay={
                            <Tooltip>
                              {`Model ${currentLoadingModels[0]} and ${currentLoadingModels[1]} are currently loading. Please wait until they both load to begin loading another model`}
                            </Tooltip>
                          }
                        >
                          <span>
                            <Button
                              variant="outline-secondary"
                              disabled={cleaningCache || cantLoad}
                              className="me-2"
                              onClick={this.toggleShowRefreshDatasetModal}
                            >
                              {activeModel.type === 'customer_fit'
                                ? 'Reload computations'
                                : 'Reload aggregations'}
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}

                      <LinkContainer
                        to={
                          activeModel.type === 'customer_fit'
                            ? `/tenant/${tenant}/models/${modelId}/import_data`
                            : `/tenant/${tenant}/models/${modelId}/import_data/${activeModel.type}`
                        }
                      >
                        <Button variant="outline-secondary">
                          {activeModel.live ? (
                            'View dataset parameters'
                          ) : (
                            <>
                              {activeModel.type === 'customer_fit'
                                ? 'Change datasets source'
                                : 'Change datasets'}
                            </>
                          )}
                        </Button>
                      </LinkContainer>
                    </ButtonToolbar>
                  )}
                </Card.Title>
                <Row>
                  <Col sm={6}>
                    <h5>Training dataset</h5>
                    {activeModel.buildType === 'mk_audience' && (
                      <>
                        {mkAudiencePage.variables.trainingDataset.audience && (
                          <Row className="mb-1">
                            <Col sm={4}>Audience</Col>
                            <Col>
                              {
                                mkAudiencePage.variables.trainingDataset
                                  .audience
                              }
                            </Col>
                          </Row>
                        )}

                        <Row className="mb-1">
                          <Col sm={4}>Conversion type</Col>
                          <Col>
                            {
                              mkAudiencePage.variables.trainingDataset
                                .conversionModel
                            }
                          </Col>
                        </Row>
                      </>
                    )}
                    {activeModel.buildType === 'ltb' && (
                      <>
                        {ltbVariables.trainingAudience && (
                          <Row className="mb-1">
                            <Col sm={4}>Audience</Col>
                            <Col>{ltbVariables.trainingAudience}</Col>
                          </Row>
                        )}
                        <Row className="mb-1">
                          <Col sm={4}>Conversion type</Col>
                          <Col>{ltbVariables.trainingConversionModel}</Col>
                        </Row>
                      </>
                    )}
                    {activeModel.buildType === 'csv_file' && (
                      <Row className="mb-1">
                        <Col sm={4}>File name</Col>
                        <Col>{csvPage.variables.trainingFileName}</Col>
                      </Row>
                    )}
                    {activeModel.buildType === 'from_redshift_tables' && (
                      <Row className="mb-1">
                        <Col sm={4}>From Redshift table</Col>
                        <Col>{redshiftPage.variables.trainingTableName}</Col>
                      </Row>
                    )}

                    <Row className="mb-1">
                      <Col sm={4}>
                        {`Number of ${isMqa ? 'accounts' : 'records'}`}
                      </Col>
                      <Col sm={3}>
                        {training.dataSetQuality !== 'good' ? (
                          <OverlayTrigger
                            rootClose={true}
                            placement="right"
                            overlay={
                              <Popover id="popover-training">
                                <Popover.Body>
                                  <span
                                    className={`text-${
                                      dataQualityVariantes[
                                        training.dataSetQuality
                                      ]
                                    }`}
                                  >
                                    {training.dataSetQuality === 'bad'
                                      ? 'Dataset is too small!'
                                      : 'Dataset is of medium size.'}
                                  </span>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div className="d-flex flex-row">
                              {isMqa
                                ? training.domainRecords
                                : training.records}
                              <i
                                aria-hidden
                                className={`fas fa-exclamation-circle fa-lg ms-1 text-${
                                  dataQualityVariantes[training.dataSetQuality]
                                }`}
                              />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <>
                            {isMqa ? training.domainRecords : training.records}
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col sm={4}>{`Number of ${
                        isMqa ? 'converted accounts' : 'conversions'
                      }`}</Col>
                      <Col>
                        {isMqa
                          ? training.domainConversions
                          : training.conversions}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col sm={4}>
                        Conversion rate{' '}
                        <OverlayTrigger
                          rootClose={true}
                          placement="top"
                          overlay={
                            <Popover id="popover">
                              <Popover.Body>
                                <b>
                                  Why is the conversion rate of the training
                                  dataset much higher than of the validation
                                  dataset?
                                </b>{' '}
                                The training dataset is built to have a minimum
                                conversion rate by rebalancing the number of
                                non-converters compared to the number of
                                converters to reduce the risk associated to
                                class imbalance.{' '}
                                <a
                                  href="https://support.madkudu.com/hc/en-us/articles/4422952901389-Why-is-the-Training-dataset-conversion-rate-higher-than-you-expect-"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Learn more.
                                </a>
                              </Popover.Body>
                            </Popover>
                          }
                          trigger="click"
                        >
                          <i
                            aria-hidden
                            className="fas fa-question-circle fa-lg ms-1 text-info"
                          />
                        </OverlayTrigger>
                      </Col>
                      <Col>
                        {numeral(
                          isMqa ? training.domainRate : training.rate
                        ).format('0.0')}
                        %
                      </Col>
                    </Row>
                    {trainingDatasetMetadata && (
                      <LastUpdatedOn
                        date={trainingDatasetMetadata.loadedAt}
                        email={trainingDatasetMetadata.email}
                      />
                    )}
                  </Col>
                  <Col sm={6}>
                    <h5>Validation dataset</h5>
                    {activeModel.buildType === 'mk_audience' && (
                      <>
                        {mkAudiencePage.variables.validationDataset
                          .audience && (
                          <Row className="mb-1">
                            <Col sm={4}>Audience</Col>
                            <Col>
                              {
                                mkAudiencePage.variables.validationDataset
                                  .audience
                              }
                            </Col>
                          </Row>
                        )}

                        <Row className="mb-1">
                          <Col sm={4}>Conversion type</Col>
                          <Col>
                            {
                              mkAudiencePage.variables.validationDataset
                                .conversionModel
                            }
                          </Col>
                        </Row>
                      </>
                    )}
                    {activeModel.buildType === 'ltb' && (
                      <>
                        {ltbVariables.validationAudience && (
                          <Row className="mb-1">
                            <Col sm={4}>Audience</Col>
                            <Col>{ltbVariables.validationAudience}</Col>
                          </Row>
                        )}

                        <Row className="mb-1">
                          <Col sm={4}>Conversion type</Col>
                          <Col>{ltbVariables.validationConversionModel}</Col>
                        </Row>
                      </>
                    )}
                    {activeModel.buildType === 'csv_file' && (
                      <Row className="mb-1">
                        <Col sm={4}>File name</Col>
                        <Col>{csvPage.variables.validationFileName}</Col>
                      </Row>
                    )}
                    {activeModel.buildType === 'from_redshift_tables' && (
                      <Row className="mb-1">
                        <Col sm={4}>From Redshift table</Col>
                        <Col>{redshiftPage.variables.validationTableName}</Col>
                      </Row>
                    )}

                    <Row className="mb-1">
                      <Col sm={4}>{`Number of ${
                        isMqa ? 'accounts' : 'records'
                      }`}</Col>
                      <Col sm={3}>
                        {validation.dataSetQuality !== 'good' ? (
                          <OverlayTrigger
                            rootClose={true}
                            placement="right"
                            overlay={
                              <Popover id="popover-validaiton">
                                <Popover.Body>
                                  <span
                                    className={`text-${
                                      dataQualityVariantes[
                                        validation.dataSetQuality
                                      ]
                                    }`}
                                  >
                                    {validation.dataSetQuality === 'bad'
                                      ? 'Dataset is too small!'
                                      : 'Dataset is of medium size.'}
                                  </span>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div className="d-flex flex-row">
                              {isMqa
                                ? validation.domainRecords
                                : validation.records}
                              <i
                                aria-hidden
                                className={`fas fa-exclamation-circle fa-lg ms-1 text-${
                                  dataQualityVariantes[
                                    validation.dataSetQuality
                                  ]
                                }`}
                              />
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <>
                            {isMqa
                              ? validation.domainRecords
                              : validation.records}
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col sm={4}>{`Number of ${
                        isMqa ? 'converted accounts' : 'conversions'
                      }`}</Col>
                      <Col>
                        {isMqa
                          ? validation.domainConversions
                          : validation.conversions}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col sm={4}>Conversion rate</Col>
                      <Col>
                        {numeral(
                          isMqa ? validation.domainRate : validation.rate
                        ).format('0.0')}
                        %
                      </Col>
                    </Row>
                    <LastUpdatedOn
                      date={validationDatasetMetadata?.loadedAt}
                      email={validationDatasetMetadata?.email}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Modal
              show={this.state.showRefreshDatasetModal}
              onHide={this.toggleShowRefreshDatasetModal}
            >
              <Modal.Header>
                <Modal.Title>
                  {activeModel.type === 'customer_fit'
                    ? 'Reload computations?'
                    : 'Reload aggregations?'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {activeModel.type === 'customer_fit' ? (
                  <>
                    <p>
                      This will start enriching again the training and
                      validation datasets to include any new computations or any
                      update made to existing computations.
                    </p>
                    <p>
                      Updated enrichment can potentially change this model if it
                      includes computations that are not static.{' '}
                      <a
                        href="https://support.madkudu.com/hc/en-us/articles/4418653484941"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none text-primary"
                      >
                        Learn more.
                      </a>
                    </p>
                  </>
                ) : (
                  <p>
                    This will start recomputing any new or updated aggregations
                    and aggregated events for the records in the training and
                    validation datasets to allow you to use them in this model.
                  </p>
                )}

                <p className="fw-bold">
                  It may take a few minutes to several hours where the model
                  will not be accessible for this duration. You&apos;ll receive
                  a confirmation email when finished.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={this.toggleShowRefreshDatasetModal}
                >
                  Cancel
                </Button>
                <Button onClick={this.reloadDataset}>Start reloading</Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);
