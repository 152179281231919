import { action, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { ModelType } from '../ModelItem';
import { Status } from '../../back/tenantmodels/model_duplicating_flags';

export default class RootPageModel {
  landingPageCurrentLoad: string;

  cleaningCache: boolean;

  createModelError: string;

  updateModelNameError: string;

  updatingModelName: boolean;

  isDataSetLoadingProgress: boolean;

  isDataSetLoadingError: boolean;

  duplicationStatus: Status;

  updatingModelNotes: boolean;

  updatingContext: boolean;

  updateModelArchiveStatusError: string;

  archivingModel: boolean;

  constructor(other?: RootPageModel) {
    if (other) {
      this.landingPageCurrentLoad = other.landingPageCurrentLoad;
      this.cleaningCache = other.cleaningCache;
      this.createModelError = other.createModelError;
      this.updateModelNameError = other.updateModelNameError;
      this.updatingModelName = other.updatingModelName;
      this.isDataSetLoadingProgress = other.isDataSetLoadingProgress;
      this.isDataSetLoadingError = other.isDataSetLoadingError;
      this.duplicationStatus = other.duplicationStatus;
      this.updatingModelNotes = other.updatingModelNotes;
      this.updatingContext = other.updatingContext;
      this.updateModelArchiveStatusError = other.updateModelArchiveStatusError;
      this.archivingModel = other.archivingModel;
    } else {
      this.landingPageCurrentLoad = null;
      this.cleaningCache = false;
      this.createModelError = null;
      this.updateModelNameError = null;
      this.updatingModelName = false;
      this.isDataSetLoadingProgress = false;
      this.isDataSetLoadingError = false;
      this.duplicationStatus = 'succeeded';
      this.updatingModelNotes = false;
      this.updatingContext = false;
      this.updateModelArchiveStatusError = null;
      this.archivingModel = false;
    }
    makeObservable(this, {
      landingPageCurrentLoad: observable,
      cleaningCache: observable,
      createModelError: observable,
      updateModelNameError: observable,
      updatingModelName: observable,
      isDataSetLoadingProgress: observable,
      isDataSetLoadingError: observable,
      duplicationStatus: observable,
      updatingModelNotes: observable,
      updatingContext: observable,
      updateModelArchiveStatusError: observable,
      archivingModel: observable,
      createModel: action,
      duplicateModel: action,
      getDataSetLoadingProgress: action,
    });
  }

  async createModel(
    tenant: number,
    modelOptions: { name: string; type: ModelType }
  ): Promise<number> {
    try {
      const { data } = await axios.post<{ modelId: number }>(
        `/api/tenant/${tenant}/model`,
        modelOptions
      );
      return data.modelId;
    } catch (err) {
      this.createModelError = err?.response?.body?.message ?? err?.message;
    }
  }

  async duplicateModel(
    tenant: number,
    modelOptions: { name: string; fromModelId: number }
  ): Promise<number> {
    const { fromModelId, name } = modelOptions;
    const {
      data: { modelId },
    } = await axios.post(
      `/api/tenant/${tenant}/model/${fromModelId}/duplicate`,
      null,
      {
        params: { name },
      }
    );

    return modelId;
  }

  async getDuplicationStatus({
    tenant,
    modelId,
  }: {
    tenant: number;
    modelId: number;
  }): Promise<void> {
    try {
      const {
        data: { status },
      } = await axios.get<{ status: Status }>(
        `/api/tenant/${tenant}/model/${modelId}/duplicate/status`
      );

      this.duplicationStatus = status;

      if (status === 'succeeded') {
        // dataSetMetadata is loaded on server side only for the moment. We need to reload the page
        window.location.reload();
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        this.duplicationStatus = 'succeeded';
      }
    }
  }

  async getDataSetLoadingProgress({
    tenant,
    modelId,
  }: {
    tenant: number;
    modelId: number;
  }) {
    const { data } = await axios.get(
      `/api/tenant/${tenant}/model/${modelId}/datasets/loaded`
    );
    this.isDataSetLoadingProgress = !data;

    if (!this.isDataSetLoadingProgress) {
      // dataSetMetadata is loaded on server side only for the moment. We need to reload the page
      window.location.reload();
    }
  }
}
