import { makeObservable, observable } from 'mobx';

export type FunnelBreakdown = {
  [customerFit: string]: {
    leads?: number;
    conversions?: number;
    revenue?: number;
  };
};

export type LeadToRevenue = {
  [customerFit: string]: {
    leads?: number;
    revenue?: number;
  };
};

export type PerformanceCheckDataValue = {
  [customerFit: string]: number;
};

interface PerformanceCheckDataModel {
  funnelBreakdown: FunnelBreakdown;
  leadToRevenue: LeadToRevenue;
  conversionRateLeadToRevenue: PerformanceCheckDataValue;
  averageDealSize: PerformanceCheckDataValue;
  predictedLeadValue: PerformanceCheckDataValue;
}

export class PerformanceCheckPageModel {
  performanceCheckData: PerformanceCheckDataModel;

  loadingPerformanceCheckData: boolean;

  performanceCheckError: string;

  constructor(other?: PerformanceCheckPageModel) {
    if (other) {
      this.performanceCheckData = other.performanceCheckData;
      this.loadingPerformanceCheckData = other.loadingPerformanceCheckData;
      this.performanceCheckError = other.performanceCheckError;
    } else {
      this.performanceCheckData = null;
      this.loadingPerformanceCheckData = false;
      this.performanceCheckError = null;
    }
    makeObservable(this, {
      performanceCheckData: observable,
      loadingPerformanceCheckData: observable,
      performanceCheckError: observable,
    });
  }
}
