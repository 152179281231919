import { makeObservable, observable } from 'mobx';

export default class TreeHistory {
  sha: string;

  commitMessage: string;

  author: string;

  date: string;

  active?: boolean;

  constructor(
    sha: string,
    commitMessage: string,
    author: string,
    date: string
  ) {
    this.sha = sha;
    this.commitMessage = commitMessage;
    this.author = author;
    this.date = date;
    this.active = false;
    makeObservable(this, {
      sha: observable,
      commitMessage: observable,
      author: observable,
      date: observable,
      active: observable,
    });
  }
}
