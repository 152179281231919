import React, { useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

import { Feature } from '../../../../models/feature_eval/FeatureEvaluation';

export default function EditableNumberCell({
  getValue,
  row: { index },
  column: { id },
  table,
}: CellContext<Feature, number>) {
  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      className="border rounded"
      type="number"
      step="0.1"
      value={value}
      onChange={(event) => {
        setValue(Number(event.target.value));
      }}
      onBlur={onBlur}
    />
  );
}
