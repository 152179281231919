import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DatasetType } from '../../../../../models/audience/mk_audience/MkAudienceDataset';
import DataSetType from '../../../../../models/audience/DataSetType';
import Store from '../../../../../store';
import { getDatasetParameter } from '../../../../../utils';
import Error from '../../../../components/error/Error';
import checkPermissions from '../../../../components/CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../../../permissions';
import { LinkContainer } from 'react-router-bootstrap';
import Spinner from '../../../../components/Spinner';
import HeaderCard from '../../../../components/generic_components/HeaderCard';
import DateRangePicker from '../../../../components/generic_components/DateRangePicker';
import { MAX_NUMBER_OF_LOADING_DATASETS } from '../../../../../utils/constants';

interface MkAudiencePageProps extends RouteComponentProps {
  store?: Store;
}

interface MkAudiencePageState {
  loading: boolean;
  shouldShowAdvancedTraining: boolean;
  shouldShowAdvancedValidation: boolean;
  currentLoadingModels: number[];
}

const MkAudiencePage = inject('store')(
  observer(
    class MkAudiencePage extends React.Component<
      MkAudiencePageProps,
      MkAudiencePageState
    > {
      errorMessageRef: React.RefObject<HTMLDivElement>;

      constructor(props: MkAudiencePageProps) {
        super(props);

        this.state = {
          loading: true,
          shouldShowAdvancedTraining: false,
          shouldShowAdvancedValidation: false,
          currentLoadingModels: [],
        };

        makeObservable(this, {
          buildDataset: action,
          changeStartDate: action,
          changeEndDate: action,
          changeConversionModel: action,
          changeAudience: action,
          changeRebalancingRatio: action,
          changeMaxLeadsPerDomain: action,
          handleOnCheckRemoveAnyPastAccount: action,
        });

        this.buildDataset = this.buildDataset.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
        this.changeEndDate = this.changeEndDate.bind(this);
        this.changeConversionModel = this.changeConversionModel.bind(this);
        this.changeAudience = this.changeAudience.bind(this);
        this.changeRebalancingRatio = this.changeRebalancingRatio.bind(this);
        this.changeMaxLeadsPerDomain = this.changeMaxLeadsPerDomain.bind(this);
        this.toggleShowAdvanced = this.toggleShowAdvanced.bind(this);
        this.handleOnCheckRemoveAnyPastAccount =
          this.handleOnCheckRemoveAnyPastAccount.bind(this);

        this.errorMessageRef = React.createRef();
      }

      async componentDidMount() {
        const { tenant } = this.props.store;
        const [currentLoadingModels] = await Promise.all([
          this.props.store.mkAudiencePage.getCurrentLoadingModels(tenant),
          this.props.store.getDefaultDatasetVariables('mk_audience'),
          this.props.store.getTenantAudiences(),
          this.props.store.getTenantConversionNames(),
        ]);

        this.setState({ loading: false, currentLoadingModels });
      }

      componentDidUpdate(): void {
        const {
          csvPage: { dataSets },
        } = this.props.store;

        if (dataSets.uploadDataSetError) {
          this.errorMessageRef.current.scrollIntoView();
        }
      }

      async buildDataset() {
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        const { tenant, modelId, csvPage } = this.props.store;
        await this.props.store.automateBuildModel('mk_audience', dataset);
        if (!csvPage.dataSets.uploadDataSetError) {
          this.props.history.push(`/tenant/${tenant}/models/${modelId}`);
        }
      }

      changeStartDate(type: DatasetType, value: string) {
        this.props.store.mkAudiencePage.variables[type].startDate = value;
      }

      changeEndDate(type: DatasetType, value: string) {
        this.props.store.mkAudiencePage.variables[type].endDate = value;
      }

      changeConversionModel(type: DatasetType, value: string) {
        this.props.store.mkAudiencePage.variables[type].conversionModel = value;
      }

      changeAudience(type: DatasetType, value: string) {
        this.props.store.mkAudiencePage.variables[type].audience = value;
      }

      changeRebalancingRatio(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.mkAudiencePage.variables.postProcessing.rebalancingRatio =
          Number(event.target.value);
      }

      changeMaxLeadsPerDomain(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.mkAudiencePage.variables.postProcessing.maxLeadsPerDomain =
          Number(event.target.value);
      }

      toggleShowAdvanced(showAdvanced: string) {
        if (showAdvanced === 'validation') {
          this.setState({
            shouldShowAdvancedValidation:
              !this.state.shouldShowAdvancedValidation,
          });
        }
        if (showAdvanced === 'training') {
          this.setState({
            shouldShowAdvancedTraining: !this.state.shouldShowAdvancedTraining,
          });
        }
      }

      handleOnCheckRemoveAnyPastAccount(type: DatasetType) {
        this.props.store.mkAudiencePage.variables[
          type
        ].remove_any_customer_email =
          !this.props.store.mkAudiencePage.variables[type]
            .remove_any_customer_email;
      }

      render() {
        const {
          csvPage,
          mkAudiencePage,
          isAllowedToEdit,
          tenant,
          modelId,
          rootPage,
        } = this.props.store;
        const {
          loading,
          shouldShowAdvancedTraining,
          shouldShowAdvancedValidation,
          currentLoadingModels,
        } = this.state;
        const { dataSets } = csvPage;
        const { isDataSetLoadingProgress } = rootPage;
        const { audiences, variables, conversionNames } = mkAudiencePage;
        const datasetParameterPresent: DataSetType = getDatasetParameter(
          this.props.location.search
        );

        const cantLoad =
          currentLoadingModels.length >= MAX_NUMBER_OF_LOADING_DATASETS;

        if (loading) {
          return <Spinner />;
        }

        return (
          <>
            <LinkContainer
              to={`/tenant/${tenant}/models/${modelId}/overview`}
              activeClassName=""
            >
              <Button variant="link" className="me-2">
                Go back to Overview
              </Button>
            </LinkContainer>
            <div className="w-75 mt-4 mx-auto d-grid gap-4">
              {dataSets.uploadDataSetError && (
                <div ref={this.errorMessageRef}>
                  <Error
                    message={dataSets.uploadDataSetError.message}
                    type={dataSets.uploadDataSetError.type}
                  />
                </div>
              )}
              <h4>Edit model datasets</h4>
              <HeaderCard
                supportLink="https://support.madkudu.com/hc/en-us/articles/4404633185549-Customer-Fit-Training-and-Validation-datasets"
                tenant={tenant}
              />
              {!datasetParameterPresent && (
                <Card className="mb-3" border="light">
                  <Card.Body>
                    <Card.Title>Training dataset options</Card.Title>
                    <p className="mb-4">
                      Create a training dataset from a cohort of contacts who
                      converted and did not convert, to build your segmentation.
                    </p>
                    <Row className="mb-3">
                      <Col md={4}>
                        Include all the <b>emails created</b> between
                        <OverlayTrigger
                          delay={{ show: 0, hide: 500 }}
                          overlay={(props: Record<string, unknown>) => (
                            <Tooltip id="training-dates-tooltip" {...props}>
                              It is recommended to use a minimum 6-month
                              training time frame from 12 months ago to 6 months
                              ago
                            </Tooltip>
                          )}
                        >
                          <i className="fas fa-info-circle ms-1"></i>
                        </OverlayTrigger>
                      </Col>
                      <Col>
                        <DateRangePicker
                          startDate={variables.trainingDataset.startDate}
                          endDate={variables.trainingDataset.endDate}
                          onDatesChanged={({ startDate, endDate }) => {
                            if (startDate) {
                              this.changeStartDate(
                                'trainingDataset',
                                startDate.format('YYYY-MM-DD')
                              );
                            }
                            if (endDate) {
                              this.changeEndDate(
                                'trainingDataset',
                                endDate.format('YYYY-MM-DD')
                              );
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        ...From the <b>audience</b>...
                        <OverlayTrigger
                          delay={{ show: 0, hide: 500 }}
                          overlay={(props: Record<string, unknown>) => (
                            <Tooltip id="audience-tooltip" {...props}>
                              See the definition of an audience or add more
                              audiences{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://app.madkudu.com/org/${tenant}/mapping/audiences`}
                              >
                                here
                              </a>
                            </Tooltip>
                          )}
                        >
                          <i className="fas fa-info-circle ms-1"></i>
                        </OverlayTrigger>
                      </Col>
                      <Col md={4}>
                        <Form.Select
                          disabled={!isAllowedToEdit}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            this.changeAudience(
                              'trainingDataset',
                              event.target.value
                            );
                          }}
                          value={variables.trainingDataset.audience}
                        >
                          <option>Select an audience</option>
                          {audiences.map((audience) => (
                            <option key={audience}>{audience}</option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={4}>
                        ...Who <b>converted</b> or not into...
                        <OverlayTrigger
                          delay={{ show: 0, hide: 500 }}
                          overlay={(props: Record<string, unknown>) => (
                            <Tooltip id="conversion-tooltip" {...props}>
                              See the definition of a conversion or add more
                              conversion definitions{' '}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://app.madkudu.com/org/${tenant}/mapping/conversion_mapping/overview`}
                              >
                                here
                              </a>
                            </Tooltip>
                          )}
                        >
                          <i className="fas fa-info-circle ms-1"></i>
                        </OverlayTrigger>
                      </Col>
                      <Col md={4}>
                        <Form.Select
                          disabled={!isAllowedToEdit}
                          onChange={(
                            event: React.ChangeEvent<HTMLSelectElement>
                          ) => {
                            this.changeConversionModel(
                              'trainingDataset',
                              event.target.value
                            );
                          }}
                          value={variables.trainingDataset.conversionModel}
                        >
                          <option>Select a conversion</option>
                          {conversionNames.map((conversionName) => (
                            <option key={conversionName}>
                              {conversionName}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>

                    <Button
                      variant="link"
                      onClick={() => this.toggleShowAdvanced('training')}
                    >
                      {'>'} Advanced options
                    </Button>

                    {shouldShowAdvancedTraining && (
                      <div className="mt-3">
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="training-dataset"
                            label={<b>Remove any past converted account</b>}
                            checked={
                              variables.trainingDataset
                                .remove_any_customer_email
                            }
                            disabled={!isAllowedToEdit}
                            onChange={() =>
                              this.handleOnCheckRemoveAnyPastAccount(
                                'trainingDataset'
                              )
                            }
                          />
                        </Form.Group>
                        <p className="mb-4">
                          This option removes from the training dataset any lead
                          associated to a company who already converted before
                          the simulated dates.{' '}
                          <b>
                            It is recommended to activate this option when
                            building a model to predict new business
                            opportunities{' '}
                          </b>
                          , and to disable this option when predicting upsell or
                          expansions.
                        </p>

                        <Form.Group className="mb-3">
                          <Row>
                            <Col md="auto">
                              <Form.Label className="fw-bold d-inline-block">
                                Rebalancing ratio
                              </Form.Label>
                            </Col>
                            <Col md="auto">
                              <Form.Control
                                type="number"
                                disabled={!isAllowedToEdit}
                                onChange={this.changeRebalancingRatio}
                                value={String(
                                  variables.postProcessing.rebalancingRatio
                                )}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <p className="mb-4">
                          To improve the quality of the model predictions, the
                          training dataset should have a minimum conversion rate
                          to reduce the class imbalance bias.{' '}
                          <b>
                            It is recommended to use a minimum of 20% conversion
                            rate
                          </b>{' '}
                          unless there aren’t enough conversions (less than
                          100).
                        </p>
                        <Form.Group className="mb-3">
                          <Row>
                            <Col md="auto">
                              <Form.Label className="fw-bold">
                                Max number of leads per domain
                              </Form.Label>
                            </Col>
                            <Col md="auto">
                              <Form.Control
                                type="number"
                                disabled={!isAllowedToEdit}
                                onChange={this.changeMaxLeadsPerDomain}
                                value={
                                  variables.postProcessing.maxLeadsPerDomain
                                }
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <p>
                          Only people with the Customer Fit segment checked will
                          be included in the training dataset.{' '}
                          <b>
                            It is recommended to limit to 5 leads per companies
                            to limit the bias towards companies with a lot of
                            leads
                          </b>
                          , while giving more weight to these companies.{' '}
                        </p>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              )}
              <Card border="light">
                <Card.Body>
                  <Card.Title>Validation dataset options</Card.Title>
                  <p className="mb-4">
                    Validate your segmentation on a validation dataset.
                  </p>
                  <Row className="mb-3">
                    <Col md={4}>
                      Include all the <b>emails created</b> between
                      <OverlayTrigger
                        delay={{ show: 0, hide: 500 }}
                        overlay={(props: Record<string, unknown>) => (
                          <Tooltip id="validation-date-tooltip" {...props}>
                            It is recommended to use a minimum 3-month
                            validation time frame from 6 months ago to 3 months
                            ago. The validation should not overlap with the
                            training time frame
                          </Tooltip>
                        )}
                      >
                        <i className="fas fa-info-circle ms-1"></i>
                      </OverlayTrigger>
                    </Col>
                    <Col>
                      <DateRangePicker
                        startDate={variables.validationDataset.startDate}
                        endDate={variables.validationDataset.endDate}
                        onDatesChanged={({ startDate, endDate }) => {
                          if (startDate) {
                            this.changeStartDate(
                              'validationDataset',
                              startDate.format('YYYY-MM-DD')
                            );
                          }
                          if (endDate) {
                            this.changeEndDate(
                              'validationDataset',
                              endDate.format('YYYY-MM-DD')
                            );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      ...From the <b>audience</b>...
                      <OverlayTrigger
                        delay={{ show: 0, hide: 500 }}
                        overlay={(props: Record<string, unknown>) => (
                          <Tooltip id="audience-tooltip" {...props}>
                            See the definition of an audience or add more
                            audiences{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://app.madkudu.com/org/${tenant}/mapping/audiences`}
                            >
                              here
                            </a>
                          </Tooltip>
                        )}
                      >
                        <i className="fas fa-info-circle ms-1"></i>
                      </OverlayTrigger>
                    </Col>
                    <Col md={4}>
                      <Form.Select
                        disabled={!isAllowedToEdit}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          this.changeAudience(
                            'validationDataset',
                            event.target.value
                          );
                        }}
                        value={variables.validationDataset.audience}
                      >
                        <option>Select an audience</option>
                        {audiences.map((audience) => (
                          <option key={audience}>{audience}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      ...Who <b>converted</b> or not into...
                      <OverlayTrigger
                        delay={{ show: 0, hide: 500 }}
                        overlay={(props: Record<string, unknown>) => (
                          <Tooltip id="conversion-tooltip" {...props}>
                            See the definition of a conversion or add more
                            conversion definitions{' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://app.madkudu.com/org/${tenant}/mapping/conversion_mapping/overview`}
                            >
                              here
                            </a>
                          </Tooltip>
                        )}
                      >
                        <i className="fas fa-info-circle ms-1"></i>
                      </OverlayTrigger>
                    </Col>
                    <Col md={4}>
                      <Form.Select
                        disabled={!isAllowedToEdit}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          this.changeConversionModel(
                            'validationDataset',
                            event.target.value
                          );
                        }}
                        value={variables.validationDataset.conversionModel}
                      >
                        <option>Select a conversion</option>
                        {conversionNames.map((conversionName) => (
                          <option key={conversionName}>{conversionName}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Button
                    variant="link"
                    onClick={() => this.toggleShowAdvanced('validation')}
                  >
                    {'>'} Advanced options
                  </Button>

                  {shouldShowAdvancedValidation && (
                    <Form.Group className="mb-3 mt-1">
                      <Form.Check
                        type="checkbox"
                        id="validation-dataset"
                        label="Remove any past converted account"
                        checked={
                          variables.validationDataset.remove_any_customer_email
                        }
                        disabled={!isAllowedToEdit}
                        onChange={() =>
                          this.handleOnCheckRemoveAnyPastAccount(
                            'validationDataset'
                          )
                        }
                      />
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
              <div className="mx-auto mb-4">
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/import_data`}
                  activeClassName=""
                >
                  <Button variant="outline-primary" className="me-2">
                    Cancel
                  </Button>
                </LinkContainer>
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,
                  <OverlayTrigger
                    show={cantLoad}
                    overlay={
                      <Tooltip>
                        {`Model ${currentLoadingModels[0]} and ${currentLoadingModels[1]} are currently loading. Please wait until they both load to begin loading another model`}
                      </Tooltip>
                    }
                  >
                    <span>
                      <Button
                        variant="primary"
                        disabled={
                          isDataSetLoadingProgress ||
                          !isAllowedToEdit ||
                          cantLoad
                        }
                        onClick={this.buildDataset}
                      >
                        {isDataSetLoadingProgress
                          ? 'Loading...'
                          : 'Build and load dataset'}
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(MkAudiencePage);
