import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';

import numeral from 'numeral';

import { Card, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  XAxisProps,
  Cell,
  TooltipProps,
} from 'recharts';

import Store from '../../../store';
import Error from '../../components/error/Error';
import Spinner from '../../components/Spinner';
import { computeDomainOfFloatArray, GRADIENT_COLORS } from '../../../utils';
import useGradient from '../../hooks/useGradient';

interface FeatureEvaluationPageProps {
  store?: Store;
}

const STROKE_COLOR = '#f5f5f5';
const { grey, lowestBlue, lowestRed, maxBlue, maxRed } = GRADIENT_COLORS;

const LiftTooltip = ({
  active,
  payload,
  label,
  getLiftColor,
}: TooltipProps<number, string> & {
  getLiftColor(lift: number): string;
}) => {
  if (!active && !payload?.length) return null;

  if (active && payload?.length) {
    const [{ value: lift }] = payload;
    return (
      <Card>
        <Card.Body>
          <Card.Text>{label}</Card.Text>
          <div className="d-flex align-items-center">
            <div
              className="me-2"
              style={{
                backgroundColor: getLiftColor(lift),
                width: 15,
                height: 15,
              }}
            />
            <Card.Text>{lift}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    );
  }
};

const FeatureEvaluationPage = ({ store }: FeatureEvaluationPageProps) => {
  const [search, setSearch] = useState('');

  // loading evalution one time at the component's mount
  useEffect(() => {
    store.loadFeatureEvaluation();
  }, []);

  const { featureEvaluation, loading, error } = store.featureEvaluationPage;

  const eventsData = useMemo(() => {
    const features = featureEvaluation?.features || [];

    return features.map((feature) => ({
      event: feature.metaEvent,
      lift: parseFloat(numeral(feature.lift).format('0.00')),
    }));
  }, [featureEvaluation]);

  const lifts = useMemo(() => eventsData.map(({ lift }) => lift), [eventsData]);

  const filteredEventsData = useMemo(
    () =>
      eventsData.filter(({ event }) =>
        event?.toLowerCase().includes(search.toLowerCase())
      ),
    [search, eventsData]
  );

  const xAxisProps: XAxisProps = useMemo(
    () => ({
      dataKey: 'lift',
      type: 'number',
      allowDecimals: false,
      domain: computeDomainOfFloatArray(lifts),
      axisLine: {
        stroke: STROKE_COLOR,
        strokeWidth: 2,
      },
      interval: 0,
      // FIXME: Recharts dosn't support well fixing domain and trickCount (=> need to put a big trickCount value to make him show all the ticks)
      tickCount: 1000,
      tickLine: { stroke: STROKE_COLOR, strokeWidth: 2 },
    }),
    [lifts]
  );

  const getLiftColor = useGradient(
    lifts,
    {
      min: lowestBlue,
      max: maxBlue,
    },
    {
      min: lowestRed,
      max: maxRed,
    },
    grey
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="mt-3 mb-5">
      <Card border="light" className="mb-4">
        <Card.Body>
          <Card.Text>
            The historical analysis of the behavior of your past converters and
            non-converters allows surfacing the most and least impactful events
            on conversion. <br />
            In short,
          </Card.Text>
          <ul>
            <li>
              when <b>lift &gt; 0</b>, a lead performing this event is more
              likely to convert
            </li>
            <li>
              when <b>lift {'<'} 0</b>, a lead performing this event is less
              likely to convert
            </li>
          </ul>
          <Card.Text>
            The events below are defined in the{' '}
            <a
              href={`https://app.madkudu.com/org/${store.tenant}/mapping/event_mapping`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Event mapping{' '}
              <i aria-hidden className="fas fa-external-link-alt"></i>
            </a>
          </Card.Text>
          <Card.Text>
            <a
              href="https://support.madkudu.com/hc/en-us/articles/4407270991245-Likelihood-to-Buy-Feature-Evaluation-"
              target="_blank"
              className="text-decoration-none"
              rel="noopener noreferrer"
            >
              <i aria-hidden className="fas fa-book-open"></i> Learn more about
              the Lift
            </a>
          </Card.Text>
        </Card.Body>
      </Card>

      <Error
        title="An error has occurred while loading insights"
        message={error}
      />

      <Card border="light">
        <Card.Body>
          {featureEvaluation && (
            <>
              <Row className="mb-2">
                <Col>
                  <h4 className="fw-bold">Impact of events on conversion</h4>
                </Col>
                <Col className="my-auto">
                  <p className="fst-italic my-auto float-end">
                    Data from the training dataset
                  </p>
                </Col>
              </Row>

              <InputGroup className="mb-3 w-25">
                <InputGroup.Text>
                  <i aria-hidden className="fas fa-search"></i>
                </InputGroup.Text>
                <FormControl
                  placeholder="Search for an event..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
              {filteredEventsData.length === 0 ? (
                <div>{`Sorry, no events found including ${search}`}</div>
              ) : (
                <>
                  <Row>
                    <Col md={{ span: 3, offset: 2 }} className="fw-bold">
                      Event
                    </Col>
                    <Col md={{ span: 3, offset: 2 }} className="fw-bold">
                      Lift
                    </Col>
                  </Row>
                  <BarChart
                    height={filteredEventsData.length * 50 + 75}
                    width={1200}
                    layout="vertical"
                    data={filteredEventsData}
                  >
                    <CartesianGrid stroke={STROKE_COLOR} />
                    <XAxis {...xAxisProps} orientation="top" xAxisId="top" />
                    <XAxis {...xAxisProps} />
                    <YAxis dataKey="event" type="category" width={300} />
                    <Tooltip
                      content={<LiftTooltip getLiftColor={getLiftColor} />}
                      cursor={{ fill: STROKE_COLOR }}
                    />
                    <Bar
                      dataKey="lift"
                      barSize={45}
                      isAnimationActive={false}
                      label={{ fill: 'white' }}
                    >
                      {filteredEventsData.map(({ lift }, index) => (
                        <Cell key={index} fill={getLiftColor(lift)} />
                      ))}
                    </Bar>
                  </BarChart>
                  <Row>
                    <Col md={{ span: 3, offset: 2 }} className="fw-bold">
                      Event
                    </Col>
                    <Col md={{ span: 3, offset: 2 }} className="fw-bold">
                      Lift
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default inject('store')(observer(FeatureEvaluationPage));
