import { makeObservable, observable } from 'mobx';
import ModelPerformances from '../ModelPerformances';

export type LeadGradeValue = 'A' | 'B' | 'C' | 'D' | 'E';
export type LeadGradeFormula = {
  fit: string;
  ltb: string;
};
export type LeadGradeMatrix = {
  [ltb: string]: { [fit: string]: LeadGradeValue };
};
export type LeadGradeConfiguration = {
  formula: LeadGradeFormula;
  matrix: LeadGradeMatrix;
};
export type LeadGradeHelpMatrix = { [ltb: string]: { [fit: string]: number } };
export type LeadGradeAllHelpMatrix = {
  averageConversionRate: LeadGradeHelpMatrix;
  conversionRate: LeadGradeHelpMatrix;
  sumPopulation: LeadGradeHelpMatrix;
};

export default class LeadGradePageModel {
  configuration: LeadGradeConfiguration;

  performances: ModelPerformances;

  helpMatrixes: LeadGradeAllHelpMatrix;

  loadingConf: boolean;

  loadingPerformance: boolean;

  error: string;

  constructor(other?: LeadGradePageModel) {
    if (other) {
      this.configuration = other.configuration;
      this.performances = other.performances;
      this.helpMatrixes = other.helpMatrixes;
      this.loadingConf = other.loadingConf;
      this.loadingPerformance = other.loadingPerformance;
      this.error = other.error;
    } else {
      this.configuration = null;
      this.performances = null;
      this.helpMatrixes = null;
      this.loadingConf = false;
      this.loadingPerformance = false;
      this.error = null;
    }
    makeObservable(this, {
      configuration: observable,
      performances: observable,
      helpMatrixes: observable,
      loadingConf: observable,
      loadingPerformance: observable,
      error: observable,
    });
  }
}
