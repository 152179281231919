import { action, makeObservable, observable } from 'mobx';
import axios from 'axios';

import Computation from './Computation';
import { MetadataField } from '../metadataFields';
import IHttpException from '../errors/IHttpException';

export default class ComputationsPageModel {
  computations: Computation[];

  computationsInProgress: boolean;

  loading: boolean;

  loadingError: string;

  saving: boolean;

  savingError: IHttpException;

  formError: IHttpException;

  activeComputation: Computation;

  selectedPosition: number;

  modalConfirmRemove: boolean;

  modalConfirmSave: {
    active: boolean;
    withRecompute: boolean;
  };

  modalConfirmCancelChanges: boolean;

  message: string;

  metadataFields: MetadataField[];

  isSaving: boolean;

  constructor(other?: ComputationsPageModel) {
    if (other) {
      this.computations = other.computations;
      this.computationsInProgress = other.computationsInProgress;
      this.loading = other.loading;
      this.loadingError = other.loadingError;
      this.saving = other.saving;
      this.savingError = other.savingError;
      this.formError = other.formError;

      this.activeComputation = other.activeComputation;
      this.selectedPosition = null;
      this.modalConfirmRemove = false;
      this.modalConfirmSave = {
        active: false,
        withRecompute: false,
      };
      this.modalConfirmCancelChanges = false;
      this.message = null;
      this.metadataFields = other.metadataFields;
      this.isSaving = false;
    } else {
      this.computations = [];
      this.computationsInProgress = false;
      this.loading = false;
      this.loadingError = null;
      this.saving = false;
      this.savingError = null;
      this.formError = null;

      this.activeComputation = null;
      this.selectedPosition = null;
      this.modalConfirmRemove = false;
      this.modalConfirmSave = {
        active: false,
        withRecompute: false,
      };
      this.modalConfirmCancelChanges = false;
      this.message = null;
      this.metadataFields = [];
      this.isSaving = false;
    }
    makeObservable(this, {
      computations: observable,
      computationsInProgress: observable,
      loading: observable,
      loadingError: observable,
      saving: observable,
      savingError: observable,
      formError: observable,
      activeComputation: observable,
      selectedPosition: observable,
      modalConfirmRemove: observable,
      modalConfirmSave: observable,
      modalConfirmCancelChanges: observable,
      message: observable,
      metadataFields: observable,
      isSaving: observable,
      saveComputationsCache: action,
    });
  }

  async saveComputationsCache(tenant: number): Promise<boolean> {
    this.savingError = null;
    this.isSaving = true;
    // @todo Delete this code in the future as it is a hack against possible issues with the cache
    // Ref: https://github.com/MadKudu/springbok/pull/1375
    this.computations.forEach((computation) => {
      if (['custom', 'available_in_realtime'].includes(computation.context)) {
        // eslint-disable-next-line no-param-reassign
        computation.context = 'configured';
      }
    });
    try {
      await axios.post(
        `/api/tenant/${tenant}/computations/save_cache`,
        this.computations
      );
      this.isSaving = false;
      this.savingError = null;
      this.computationsInProgress = true;
      return true;
    } catch (err) {
      this.isSaving = false;
      this.savingError = err?.response?.data ?? {
        message: 'Unknown error.',
        type: 'other',
        status: 500,
      };
      return false;
    }
  }
}
