import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  FormCheck,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import PointBasedConditionDataModel from '../../../models/pointBasedForm/PointBasedConditionDataModel';
import { Subject, getVerbsSettings, Verb } from '../../../models/sqlform';
import Store from '../../../store';

interface PointBasedRuleConditionsComponentProps {
  store?: Store;
  key: number;
  isDisabled: boolean;
  canBeRemoved: boolean;
  pointBasedCondition: PointBasedConditionDataModel;
  pointBasedConditionIndex: number;
  groupPointBasedRulesIndex: number;
  pointBasedRuleIndex: number;
  handleOnChangeTrait: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    value: string
  ) => void;
  handleOnChangeCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    verb: Verb
  ) => void;
  handleAddValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleRemoveValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number
  ) => void;
  handleOnChangeValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number,
    value: string
  ) => void;
  handleOnClickRemoveSubCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleOnClickCaseSensitive: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    isLower: boolean
  ) => void;
}

interface PointBasedRuleConditionsComponentState {
  falseNegativesFormCheck: boolean;
}

const verbsSettings = getVerbsSettings();

export default inject('store')(
  observer(
    class PointBasedRuleConditionsComponent extends React.Component<
      PointBasedRuleConditionsComponentProps,
      PointBasedRuleConditionsComponentState
    > {
      render() {
        const { formFields, activeModel } = this.props.store;
        const {
          handleOnChangeTrait,
          pointBasedRuleIndex,
          groupPointBasedRulesIndex,
          pointBasedConditionIndex,
          handleOnChangeCondition,
          handleAddValue,
          handleRemoveValue,
          handleOnChangeValue,
          isDisabled,
          pointBasedCondition,
          handleOnClickRemoveSubCondition,
          handleOnClickCaseSensitive,
          canBeRemoved,
        } = this.props;

        const { trait, verb, values, isLower } = pointBasedCondition;
        const modelType = activeModel.type;
        const conditionVerbSettings = verbsSettings.find(
          ({ value }) => value === verb
        );

        return (
          <Form>
            <FormGroup>
              <Row>
                <Col xs={1}>
                  {canBeRemoved ? <strong>AND</strong> : <strong>IF</strong>}
                </Col>
                <Col xs={2}>
                  <Form.Select
                    name="trait"
                    disabled={isDisabled}
                    value={trait.subject}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      handleOnChangeTrait(
                        groupPointBasedRulesIndex,
                        pointBasedRuleIndex,
                        pointBasedConditionIndex,
                        event.target.value
                      )
                    }
                  >
                    {formFields &&
                      formFields.map(
                        (subject: Subject, subjectIndex: number) => (
                          <option value={subject.subject} key={subjectIndex}>
                            {subject.subject}
                          </option>
                        )
                      )}
                  </Form.Select>
                </Col>
                <Col xs={2}>
                  <Form.Select
                    name="verb"
                    disabled={isDisabled}
                    value={verb}
                    onChange={(event) =>
                      handleOnChangeCondition(
                        groupPointBasedRulesIndex,
                        pointBasedRuleIndex,
                        pointBasedConditionIndex,
                        event.target.value as Verb
                      )
                    }
                  >
                    {verbsSettings
                      .filter(
                        ({ modelTypes, characteristics: { types } }) =>
                          modelTypes.includes(modelType) &&
                          types.includes(trait.type)
                      )
                      .map(({ value }) => (
                        <option key={value}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={3}>
                  {values.map((value, valueIndex) =>
                    values.length >
                    conditionVerbSettings.characteristics.min ? (
                      <InputGroup
                        style={{ marginBottom: '5px' }}
                        key={`value_${valueIndex}`}
                      >
                        <FormControl
                          key={`value_${valueIndex}`}
                          type={trait.type === 'string' ? 'text' : trait.type}
                          name="value"
                          disabled={isDisabled}
                          value={value || ''}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleOnChangeValue(
                              groupPointBasedRulesIndex,
                              pointBasedRuleIndex,
                              pointBasedConditionIndex,
                              valueIndex,
                              event.target.value
                            )
                          }
                        />
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id="right">Remove the value</Tooltip>
                          }
                        >
                          <Button
                            onClick={() =>
                              handleRemoveValue(
                                groupPointBasedRulesIndex,
                                pointBasedRuleIndex,
                                pointBasedConditionIndex,
                                valueIndex
                              )
                            }
                            disabled={isDisabled}
                            variant="danger"
                          >
                            <i aria-hidden className="fas fa-trash" />
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    ) : (
                      <FormControl
                        key={`value_${valueIndex}`}
                        type={trait.type === 'string' ? 'text' : trait.type}
                        value={value || ''}
                        disabled={isDisabled}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleOnChangeValue(
                            groupPointBasedRulesIndex,
                            pointBasedRuleIndex,
                            pointBasedConditionIndex,
                            valueIndex,
                            event.target.value
                          )
                        }
                        style={{ marginBottom: '5px' }}
                      />
                    )
                  )}
                  {conditionVerbSettings.characteristics.max > values.length ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip id="tooltip">Add new value</Tooltip>}
                    >
                      <Button
                        onClick={() =>
                          handleAddValue(
                            groupPointBasedRulesIndex,
                            pointBasedRuleIndex,
                            pointBasedConditionIndex
                          )
                        }
                        disabled={isDisabled}
                        className="pull-left"
                      >
                        <i aria-hidden className="fas fa-plus" />
                      </Button>
                    </OverlayTrigger>
                  ) : null}
                </Col>
                <Col xs={2}>
                  {trait.type === 'string' &&
                    conditionVerbSettings.characteristics.max > 0 && (
                      <FormCheck
                        type="checkbox"
                        checked={isLower}
                        disabled={isDisabled}
                        onClick={() =>
                          handleOnClickCaseSensitive(
                            groupPointBasedRulesIndex,
                            pointBasedRuleIndex,
                            pointBasedConditionIndex,
                            !isLower
                          )
                        }
                        label="Case-insensitive"
                      />
                    )}
                </Col>
                <Col xs={1}>
                  {canBeRemoved && (
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="tooltip">Remove the condition</Tooltip>
                      }
                    >
                      <Button
                        onClick={() =>
                          handleOnClickRemoveSubCondition(
                            groupPointBasedRulesIndex,
                            pointBasedRuleIndex,
                            pointBasedConditionIndex
                          )
                        }
                        disabled={isDisabled}
                        variant="danger"
                      >
                        <i aria-hidden className="fas fa-trash" />
                      </Button>
                    </OverlayTrigger>
                  )}
                </Col>
              </Row>
            </FormGroup>
          </Form>
        );
      }
    }
  )
);
