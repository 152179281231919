/* eslint-disable max-classes-per-file */
import { action, makeObservable, observable } from 'mobx';
import ModelPerformances from '../ModelPerformances';
import axios from 'axios';
import { ModelType } from '../ModelItem';

export class EnsembleWeight {
  [tree: string]: number;
}

export type EnsembleThreshold = Record<string, number>;

export class BehavioralThresholdsDecile {
  thresholdMin: number;

  thresholdMax: number;

  population: number;

  conversions: number;

  tile: string;
}

export class EnsemblePerformance {
  weights: EnsembleWeight;

  thresholds: EnsembleThreshold;

  performances: ModelPerformances;

  constructor(
    weights: EnsembleWeight,
    thresholds: EnsembleThreshold,
    performances: ModelPerformances
  ) {
    this.weights = weights;
    this.thresholds = thresholds;
    this.performances = performances;
    makeObservable(this, {
      weights: observable,
      thresholds: observable,
      performances: observable,
    });
  }
}

export class BehavioralEnsemblePerformance {
  behavioralThresholdsDeciles: BehavioralThresholdsDecile[];

  thresholds: EnsembleThreshold;

  performances: ModelPerformances;

  constructor(
    behavioralThresholdsDeciles: BehavioralThresholdsDecile[],
    performances: ModelPerformances,
    threshold: EnsembleThreshold
  ) {
    this.behavioralThresholdsDeciles = behavioralThresholdsDeciles;
    this.performances = performances;
    this.thresholds = threshold;
    makeObservable(this, {
      behavioralThresholdsDeciles: observable,
      performances: observable,
      thresholds: observable,
    });
  }
}

export class Ensemble {
  error: string;

  weights: EnsembleWeight;

  thresholds: EnsembleThreshold;

  performances: EnsemblePerformance;

  behavioralPerformances: BehavioralEnsemblePerformance;

  constructor(
    weights: EnsembleWeight = {},
    thresholds: EnsembleThreshold = { 'very good': 0, good: 0, medium: 0 },
    performances: EnsemblePerformance = null,
    behavioralPerformances: BehavioralEnsemblePerformance = null
  ) {
    this.error = null;
    this.weights = weights;
    this.thresholds = thresholds;
    this.performances = performances;
    this.behavioralPerformances = behavioralPerformances;
    makeObservable(this, {
      error: observable,
      weights: observable,
      thresholds: observable,
      performances: observable,
      behavioralPerformances: observable,
    });
  }
}

export class EnsemblingPageModel {
  ensemble: Ensemble;

  loadingEnsemblingPerformances: boolean;

  fetchingBestThresholds: boolean;

  ensemblingError: string;

  constructor(other?: EnsemblingPageModel) {
    if (other) {
      this.ensemble = other.ensemble;
      this.loadingEnsemblingPerformances = other.loadingEnsemblingPerformances;
      this.ensemblingError = other.ensemblingError;
      this.fetchingBestThresholds = other.fetchingBestThresholds;
    } else {
      this.ensemble = null;
      this.loadingEnsemblingPerformances = false;
      this.fetchingBestThresholds = false;
      this.ensemblingError = null;
    }
    makeObservable(this, {
      ensemble: observable,
      loadingEnsemblingPerformances: observable,
      ensemblingError: observable,
      fetchingBestThresholds: observable,
      loadConfiguration: action,
      loadPerformance: action,
      auto: action,
    });
  }

  async loadConfiguration(tenant: number, modelId: number) {
    const {
      data: [weights, thresholds],
    } = await axios.get(
      `/api/tenant/${tenant}/model/${modelId}/ensembling/configuration`
    );
    const ensemble = new Ensemble(weights, thresholds);
    this.ensemble = ensemble;
    this.ensemble = ensemble;
  }

  async loadPerformance(
    tenant: number,
    modelId: number,
    options: {
      isProcessing: boolean;
      modelType: ModelType;
    }
  ) {
    const { isProcessing, modelType } = options;
    this.loadingEnsemblingPerformances = true;
    this.ensemblingError = null;
    try {
      const { data: ensembling } = await axios.put(
        `/api/tenant/${tenant}/model/${modelId}/ensembling`,
        {
          weights: this.ensemble.weights,
          thresholds: this.ensemble.thresholds,
        },
        { params: { isProcessing } }
      );
      if (modelType === 'customer_fit') {
        this.ensemble.performances = new EnsemblePerformance(
          ensembling.weights,
          ensembling.thresholds,
          ensembling.performances
        );
      } else {
        this.ensemble.behavioralPerformances =
          new BehavioralEnsemblePerformance(
            ensembling.behavioralThresholdsDeciles,
            ensembling.performances,
            ensembling.thresholds
          );
      }
    } catch (err) {
      this.ensemblingError = err.response
        ? err.response?.data?.message
        : err?.message;
    }
    this.loadingEnsemblingPerformances = false;
  }

  async auto(
    tenant: number,
    modelId: number,
    options: { isProcessing: boolean; modelType: ModelType }
  ) {
    const { isProcessing, modelType } = options;
    this.fetchingBestThresholds = true;
    this.ensemblingError = null;
    try {
      const { data: ensembling } = await axios.put(
        `/api/tenant/${tenant}/model/${modelId}/ensembling/auto`,
        null,
        { params: { isProcessing } }
      );
      if (modelType === 'customer_fit') {
        this.ensemble = {
          weights: ensembling.weights,
          thresholds: ensembling.thresholds,
          performances: new EnsemblePerformance(
            ensembling.weights,
            ensembling.thresholds,
            ensembling.performances
          ),
          behavioralPerformances: null,
          error: null,
        };
      } else {
        this.ensemble = {
          behavioralPerformances: new BehavioralEnsemblePerformance(
            ensembling.behavioralThresholdsDeciles,
            ensembling.performances,
            ensembling.thresholds
          ),
          thresholds: ensembling.thresholds,
          weights: null,
          error: null,
          performances: null,
        };
      }
    } catch (err) {
      this.ensemblingError = err.response
        ? err.response?.data?.message
        : err?.message;
    }

    this.fetchingBestThresholds = false;
  }
}
