import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Alert,
  Button,
  ButtonToolbar,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Computation from '../../models/computations/Computation';
import { PROFILES_PERMISSIONS } from '../../permissions';
import Store from '../../store';
import { snakeCaseToHumanReadable } from '../../utils';
import checkPermissions from '../components/CheckPermissions';
import Error from '../components/error/Error';
import Spinner from '../components/Spinner';
import Status from '../components/computations/Status';
import PageHeader from '../components/PageHeader';
import Table from '../components/generic_components/Table';

type ComputationsPageProps = RouteComponentProps & {
  store?: Store;
};

const ComputationsPage = inject('store')(
  observer(
    class ComputationsPage extends React.Component<ComputationsPageProps> {
      constructor(props: ComputationsPageProps) {
        super(props);

        this.saveComputationsArgo = this.saveComputationsArgo.bind(this);
        this.confirmSaveComputationsArgo =
          this.confirmSaveComputationsArgo.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.confirmCancelChanges = this.confirmCancelChanges.bind(this);
        this.hideAllModals = this.hideAllModals.bind(this);
      }

      async componentDidMount() {
        this.props.store.computationsPage.loading = true;
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.message = null;
        await this.props.store.getComputations();
        await this.props.store.getMetadataFieldsForComputations();
        if (this.props.store.computationsPage.computations.length === 0) {
          await this.props.store.initComputations();
          await this.props.store.getComputations();
        }

        this.props.store.computationsPage.loading = false;
      }

      saveComputationsArgo(options: { withRecompute: boolean }) {
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.modalConfirmSave = {
          active: true,
          withRecompute: options.withRecompute,
        };
        this.props.store.computationsPage.message = null;
      }

      async confirmSaveComputationsArgo() {
        this.props.store.computationsPage.saving = true;
        try {
          await this.props.store.saveComputationsArgo();

          this.props.store.computationsPage.loading = true;
          await this.props.store.getComputations();
          this.props.store.computationsPage.loading = false;
          this.props.store.computationsPage.message =
            'Released! Computations will be available in the next dataset you will load.';
        } catch (e) {
          this.props.store.computationsPage.savingError =
            e.response.body.message;
          window.scrollTo(0, 0);
        }
        this.props.store.computationsPage.saving = false;
        this.hideAllModals();
      }

      cancelChanges() {
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.modalConfirmCancelChanges = true;
        this.props.store.computationsPage.message = null;
      }

      async confirmCancelChanges() {
        await this.props.store.deleteComputationsCache();
        this.hideAllModals();
        this.props.store.computationsPage.loading = true;
        await this.props.store.getComputations();
        this.props.store.computationsPage.loading = false;
        this.props.store.computationsPage.message = 'Changes cancelled!';
        window.scrollTo(0, 0);
      }

      hideAllModals() {
        this.props.store.computationsPage.modalConfirmSave = {
          active: false,
          withRecompute: false,
        };
        this.props.store.computationsPage.modalConfirmCancelChanges = false;
      }

      render() {
        const { store } = this.props;
        const { computationsPage, tenant } = store;
        const {
          computations,
          computationsInProgress,
          loading,
          loadingError,
          saving,
          savingError,
          modalConfirmSave,
          modalConfirmCancelChanges,
          message,
        } = computationsPage;

        const columnHelper = createColumnHelper<Computation>();

        const columns: ColumnDef<Computation, unknown>[] = [
          {
            accessorFn: ({ name }) => snakeCaseToHumanReadable(name),
            id: 'label',
            header: 'Label',
            cell: (info) => (
              <>
                {info.row.original.context === 'standard' && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="standard-tooltip">
                        Standard computation
                      </Tooltip>
                    }
                  >
                    <i aria-hidden className="fas fa-lock me-2" />
                  </OverlayTrigger>
                )}
                <a
                  href={`/tenant/${tenant}/computations/${info.row.original.name}`}
                >
                  {info.getValue()}
                </a>
              </>
            ),
            sortDescFirst: false,
          },
          {
            accessorFn: ({ usedInLiveModel, isSynced }): string => {
              const statuses = [];
              if (usedInLiveModel) statuses.push('live');
              if (isSynced) statuses.push('sync');

              return statuses.join(' and ') || '-';
            },
            id: 'status',
            header: 'Status',
            cell: (info) => {
              const value = info.getValue() as string;

              return (
                <>
                  {value.includes('live') && (
                    <Status
                      text="Live"
                      title="Used in a live customer fit model"
                      background="success"
                    />
                  )}
                  {value.includes('sync') && (
                    <Status
                      text="Sync"
                      title="Synced to your CRM directly"
                      background="primary"
                    />
                  )}
                </>
              );
            },
            sortingFn: (rowA, rowB) => {
              const getScore = ({
                usedInLiveModel,
                isSynced,
                isDraft,
              }: Computation) => {
                let score = 0;
                if (usedInLiveModel) score += 4;
                if (isSynced) score += 2;
                if (isDraft) score += 1;

                return score;
              };

              const scoreA = getScore(rowA.original);
              const scoreB = getScore(rowB.original);

              return scoreA - scoreB;
            },
            filterFn: (
              { original: { usedInLiveModel, isSynced } },
              _,
              filterValue
            ) => {
              if (filterValue === 'live') return usedInLiveModel;
              if (filterValue === 'sync') return isSynced;
              if (filterValue === 'live and sync')
                return usedInLiveModel && isSynced;

              return !usedInLiveModel && !isSynced;
            },
          },
          columnHelper.accessor('type', {
            cell: (info) => info.getValue(),
            header: 'Type',
          }),
          {
            accessorFn: ({ updatedAt, context }) =>
              context === 'standard' ? '-' : moment(updatedAt).format('ll'),
            id: 'updatedAt',
            header: 'Updated At',
            cell: (info) => info.getValue(),
            sortingFn: (rowA, rowB) => {
              if (
                rowA.original.context === 'standard' ||
                rowB.original.context === 'standard'
              ) {
                if (rowA.original.context === rowB.original.context) {
                  return 0;
                } else if (rowA.original.context === 'standard') {
                  return -1;
                } else {
                  return 1;
                }
              }

              return moment(rowA.original.updatedAt).diff(
                moment(rowB.original.updatedAt)
              );
            },
            sortDescFirst: true,
            enableColumnFilter: false,
          },
        ];

        return (
          <>
            <PageHeader>
              <Row>
                <Col>
                  <h2>Computations</h2>
                </Col>
                <Col className="text-end">
                  {checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <LinkContainer to={`/tenant/${tenant}/computations/new`}>
                      <Button className="ms-3">
                        <i className="fas fa-plus" /> Add computation
                      </Button>
                    </LinkContainer>
                  )}
                </Col>
              </Row>
            </PageHeader>

            <div className="mt-3 mb-5">
              <Card border="light">
                <Card.Body>
                  <p>
                    Computations store information about a Person, a Company or
                    the combination of both. Your MadKudu account includes
                    computations by default. Build new computations to enrich
                    your models or segment your leads and accounts directly in
                    your CRM.
                  </p>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4403777900301-What-are-computations-"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-primary"
                  >
                    <i aria-hidden className="fas fa-book-open"></i> How
                    computations work
                  </a>
                  &nbsp; &nbsp;
                  <span>
                    <a
                      href="https://support.madkudu.com/hc/en-us/articles/4406008619533-Definitions-of-standard-computations"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-primary"
                    >
                      <i aria-hidden className="fas fa-book-open"></i>{' '}
                      Definition of Standard Computations
                    </a>
                  </span>
                </Card.Body>
              </Card>
              {(loadingError || savingError) && (
                <Error
                  message={loadingError || savingError?.message}
                  type={savingError?.type}
                />
              )}
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {computationsInProgress && (
                    <Alert className="d-flex mt-3" variant="info">
                      You have new computations or changes not deployed yet.
                      {checkPermissions(
                        PROFILES_PERMISSIONS.ARCHITECT,
                        <ButtonToolbar className="ms-auto">
                          <Button
                            className="me-2"
                            disabled={
                              computations.filter(
                                ({ name }) => name.trim().length === 0
                              ).length > 0
                            }
                            onClick={() =>
                              this.saveComputationsArgo({
                                withRecompute: false,
                              })
                            }
                          >
                            Deploy
                          </Button>
                          <Button variant="danger" onClick={this.cancelChanges}>
                            Cancel changes
                          </Button>
                        </ButtonToolbar>
                      )}
                    </Alert>
                  )}
                  {message && (
                    <Alert className="mt-3" variant="success">
                      <div dangerouslySetInnerHTML={{ __html: message }} />
                    </Alert>
                  )}
                  <Card className="mt-3 h-75">
                    <Card.Body>
                      <Table
                        data={computations}
                        columns={columns}
                        defaultSorting={[{ id: 'updatedAt', desc: true }]}
                        defaultPageSize={50}
                      />
                    </Card.Body>
                  </Card>
                  <Modal
                    show={modalConfirmSave.active}
                    onHide={this.hideAllModals}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        Deploy computations in production{' '}
                        {modalConfirmSave.withRecompute &&
                          'and reload datasets'}
                        ?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {modalConfirmSave.withRecompute ? (
                        <p>
                          This will:
                          <ul>
                            <li>
                              deploy any new computation or updates made to
                              computations.
                            </li>
                            <li>
                              start reloading the training and validation
                              datasets with these updates.{' '}
                              <b>
                                It may take several hours where the model will
                                not be accessible for this duration.
                              </b>
                            </li>
                          </ul>
                          Updates made on computations marked <b>Live</b> may
                          impact the model(s) using them. The updates will be
                          available at the next batch update.
                        </p>
                      ) : (
                        <p>
                          This will deploy any new computation or updates made
                          to computations. Updates made on computations marked{' '}
                          <b>Live</b> may impact the model(s) using them. The
                          updates will be available at the{' '}
                          <b>next batch update</b>.
                        </p>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={this.confirmSaveComputationsArgo}
                      >
                        {saving ? 'Deploying...' : 'Deploy'}
                      </Button>
                      <Button
                        onClick={this.hideAllModals}
                        disabled={saving}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={modalConfirmCancelChanges}
                    onHide={this.hideAllModals}
                  >
                    <Modal.Header>
                      <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Are you sure you want to cancel the current changes? All
                        your changes not released will be lost.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={this.confirmCancelChanges}
                      >
                        Cancel changes
                      </Button>
                      <Button
                        onClick={this.hideAllModals}
                        variant="outline-primary"
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(ComputationsPage);
