import { inject, observer } from 'mobx-react';
import queryString from 'querystring';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Store from '../../../store';
import Error from '../../components/error/Error';
import NewUnivariateAnalysisCharts from '../../components/univariate/NewUnivariateAnalysisCharts';
import UnivariateAnalysisCharts from '../../components/univariate/UnivariateAnalysisCharts';

type MatchParams = {
  tenant: string;
  modelId: string;
  treeId: string;
  nodeId: string;
};
interface NodeUnivariatePageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

type NodeUnivariatePageState = {
  showUnknown: boolean;
  selectedVariable: string;
};

const NodeUnivariatePage = inject('store')(
  observer(
    class NodeUnivariatePage extends React.Component<
      NodeUnivariatePageProps,
      NodeUnivariatePageState
    > {
      constructor(props: NodeUnivariatePageProps) {
        super(props);

        this.state = {
          showUnknown: false,
          selectedVariable: '',
        };

        this.checkUnivariateAnalysisWithPersonal =
          this.checkUnivariateAnalysisWithPersonal.bind(this);
        this.toggleShowUnknown = this.toggleShowUnknown.bind(this);
        this.selectVariable = this.selectVariable.bind(this);
      }

      checkUnivariateAnalysisWithPersonal() {
        this.props.store.checkNodeUnivariateAnalysisWithPersonal();

        const { treeId, nodeId } = this.props.match.params;
        const { univariateAnalysisWithPersonal } = this.props.store.nodePage;

        const search = queryString.parse(
          this.props.location.search.split('?')[1]
        );
        const newQueryString = {
          ...search,
          includePersonal: univariateAnalysisWithPersonal,
        };
        const newUrl = `${this.props.location.pathname}?${queryString.stringify(
          newQueryString
        )}`;

        this.props.history.push(newUrl);

        if (this.state.selectedVariable) {
          this.props.store.getNodeUnivariateForVariable(
            Number(treeId),
            Number(nodeId),
            this.state.selectedVariable
          );
        }
        this.props.store.getNodeUnivariate(Number(treeId), Number(nodeId));
      }

      updateIncludePersonalFromQueryString(search: string) {
        const parsedSearch = queryString.parse(search.split('?')[1]);
        this.props.store.nodePage.univariateAnalysisWithPersonal =
          parsedSearch.includePersonal === 'true';
      }

      componentDidMount() {
        this.updateIncludePersonalFromQueryString(this.props.location.search);

        const { treeId, nodeId } = this.props.match.params;

        // Get computations to list them in the computations' list
        if (this.props.store.computationsPage.computations.length === 0) {
          this.props.store.getComputations();
        }
        this.props.store.getNodeUnivariate(Number(treeId), Number(nodeId));
      }

      componentDidUpdate(): void {
        const { variables = [] } =
          this.props.store.nodePage.univariateAnalysis ?? {};
        if (!!variables.length && !this.state.selectedVariable) {
          this.setState({ selectedVariable: variables[0].id });
        }
      }

      toggleShowUnknown() {
        this.setState({
          showUnknown: !this.state.showUnknown,
        });
      }

      selectVariable(id: string) {
        this.setState({
          selectedVariable: id,
        });

        if (!this.props.store.nodePage.univariateAnalysis) {
          const { treeId, nodeId } = this.props.match.params;

          this.props.store.getNodeUnivariateForVariable(
            Number(treeId),
            Number(nodeId),
            id
          );
        }
      }

      render() {
        const { tenant, modelId, treeId, nodeId } = this.props.match.params;
        const { nodePage } = this.props.store;
        const { showUnknown, selectedVariable } = this.state;
        const { univariateAnalysis, loadingUnivariateAnalysis } = nodePage;

        const sampleLinkTemplate = (
          variableTechnicalName: string,
          variableReadableName: string,
          bucketRawName: string,
          bucketName: string
        ) =>
          `/tenant/${tenant}/models/${modelId}/insights/${variableTechnicalName}/${variableReadableName}/${bucketRawName}/${bucketName}/trees/${treeId}/nodes/${nodeId}/leads?includePersonal=${nodePage.univariateAnalysisWithPersonal}`;

        return (
          <div className="mt-3 mb-5">
            {nodePage.loadLeadsError && (
              <Error message={nodePage.loadLeadsError} />
            )}
            <Link
              className="d-block mb-3"
              to={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}`}
            >
              Back to tree configuration
            </Link>
            <Card className="mb-3">
              <Card.Body>
                <h3>
                  Insights for tree {treeId} - node {nodeId}
                </h3>
              </Card.Body>
            </Card>
            <Row className="mb-3">
              <Col>
                <div className="float-end">
                  <Form.Switch
                    className="d-inline-block me-3"
                    type="checkbox"
                    id="show_unknown_checkbox"
                    label="Include unknown values"
                    checked={showUnknown}
                    onChange={this.toggleShowUnknown}
                  />
                  <Form.Switch
                    className="d-inline-block"
                    type="checkbox"
                    id="is_personal_checkbox"
                    label="Include personal email"
                    checked={nodePage.univariateAnalysisWithPersonal}
                    onChange={this.checkUnivariateAnalysisWithPersonal}
                  />
                </div>
              </Col>
            </Row>

            {!univariateAnalysis || loadingUnivariateAnalysis ? (
              <NewUnivariateAnalysisCharts
                univariateAnalysis={nodePage.univariateAnalysisVariable}
                loadingUnivariateAnalysis={
                  nodePage.loadingUnivariateAnalysisVariable
                }
                univariateAnalysisError={
                  nodePage.univariateAnalysisVariableError
                }
                selectVariable={this.selectVariable}
                selectedVariable={this.state.selectedVariable}
                sampleLinkTemplate={sampleLinkTemplate}
                showUnknown={showUnknown}
              />
            ) : (
              <UnivariateAnalysisCharts
                univariateAnalysis={univariateAnalysis}
                selectVariable={this.selectVariable}
                selectedVariable={selectedVariable}
                sampleLinkTemplate={sampleLinkTemplate}
                showUnknown={showUnknown}
              />
            )}
          </div>
        );
      }
    }
  )
);
export default withRouter(NodeUnivariatePage);
