import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Card, Modal, Alert, Row, Col } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { BehavioralAggregation } from '../../models/aggregations/behavioral_aggregations';
import { PROFILES_PERMISSIONS } from '../../permissions';
import Store from '../../store';
import checkPermissions from '../components/CheckPermissions';
import { stringifyAggregationName } from '../components/behavioral_aggregations/utils';
import DeleteAggregationModal from '../components/behavioral_aggregations/DeleteAggregationModal';
import Error from '../components/error/Error';
import PageHeader from '../components/PageHeader';
import Spinner from '../components/Spinner';
import Status from '../components/computations/Status';
import Table from '../components/generic_components/Table';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment-mini';
import { AggregatedEvent } from '../../models/aggregations/aggregated_events';
import capitalize from 'lodash/capitalize';

interface BehavioralAggregationsPageProps extends RouteComponentProps {
  store?: Store;
}

interface BehavioralAggregationsPageState {
  deleting: boolean;
  shouldShowEventDeletionModal: boolean;
  deletingAggregatedEventName: string;
  isLoading: boolean;
  showDeployModel: boolean;
  deploying: boolean;
}

const isBehavioralAggregation = (
  row: BehavioralAggregation | AggregatedEvent
): row is BehavioralAggregation => 'isSynced' in row;

const BehavioralAggregationsPage = inject('store')(
  observer(
    class BehavioralAggregationsPage extends React.Component<
      BehavioralAggregationsPageProps,
      BehavioralAggregationsPageState
    > {
      constructor(props: BehavioralAggregationsPageProps) {
        super(props);

        this.state = {
          deleting: false,
          shouldShowEventDeletionModal: false,
          deletingAggregatedEventName: '',
          isLoading: true,
          showDeployModel: false,
          deploying: false,
        };

        this.editViewAggregation = this.editViewAggregation.bind(this);
        this.deploy = this.deploy.bind(this);
        this.toggleEventDeletionModal =
          this.toggleEventDeletionModal.bind(this);
        this.deleteAggregatedEvent = this.deleteAggregatedEvent.bind(this);
        this.setAggregatedEventToDelete =
          this.setAggregatedEventToDelete.bind(this);
        this.getToDeploy = this.getToDeploy.bind(this);
        this.toggleShowDeployModal = this.toggleShowDeployModal.bind(this);

        makeObservable(this, {
          editViewAggregation: action,
          deploy: action,
        });
      }

      async componentDidMount() {
        const promises = [];
        const { tenant } = this.props.store;
        if (!this.props.store.behavioralAggregationsPage.aggregations.length) {
          promises.push(
            this.props.store.behavioralAggregationsPage.get(tenant)
          );
        }

        if (!this.props.store.aggregatedEventsPage.aggregatedEvents.length) {
          promises.push(this.props.store.getAggregatedEvents());
        }
        await Promise.all(promises);

        this.setState({ isLoading: false });
      }

      async deploy() {
        this.setState({ deploying: true });
        await this.props.store.releaseAggregations();
        this.setState({ deploying: false, showDeployModel: false });

        window.location.reload();
      }

      toggleShowDeployModal() {
        this.setState({ showDeployModel: !this.state.showDeployModel });
      }

      editViewAggregation(aggregation: BehavioralAggregation) {
        return () => {
          const { tenant } = this.props.store;
          // avoid modifying object in aggregations list before "save" is clicked
          this.props.store.behavioralAggregationsPage.activeAggregation =
            JSON.parse(JSON.stringify(aggregation));
          this.props.history.push(
            `/tenant/${tenant}/aggregations/${encodeURI(
              aggregation.name
              // Allows to encode slashes in aggregation names
            ).replace(/\//gi, '%2F')}`
          );
        };
      }

      toggleEventDeletionModal() {
        this.setState({
          shouldShowEventDeletionModal:
            !this.state.shouldShowEventDeletionModal,
        });
      }

      setAggregatedEventToDelete(id: string) {
        return () => {
          this.setState({ deletingAggregatedEventName: id });
          this.toggleEventDeletionModal();
        };
      }

      deleteAggregatedEvent(name: string) {
        return async () => {
          this.setState({ deleting: true });
          const { aggregatedEvents } = this.props.store.aggregatedEventsPage;
          const newAggregatedEvents = aggregatedEvents.filter(
            (agg) => agg.name !== name
          );
          this.props.store.aggregatedEventsPage.aggregatedEvents =
            newAggregatedEvents;

          await this.props.store.saveAggregatedEvents();
          this.setState({ deleting: false });
          this.toggleEventDeletionModal();
        };
      }

      getToDeploy(unreleased: boolean, unreleasedEvents: boolean) {
        if (unreleased && unreleasedEvents) {
          return 'aggregation and aggregated events';
        }

        if (unreleased) {
          return 'aggregations';
        }

        if (unreleasedEvents) {
          return 'aggregated events';
        }
      }

      render() {
        const {
          deleting,
          deletingAggregatedEventName,
          shouldShowEventDeletionModal,
          isLoading,
          showDeployModel,
          deploying,
        } = this.state;
        const {
          aggregatedEventsPage,
          behavioralAggregationsPage,
          isSuperKudu,
          isArchitect,
          tenant,
        } = this.props.store;
        const { aggregatedEvents } = aggregatedEventsPage;
        const { aggregations, error } = behavioralAggregationsPage;
        const unreleased = !!aggregations.find((agg) => !agg.released);
        const unreleasedEvents = !!aggregatedEvents.find(
          ({ released }) => !released
        );
        const toDeploy = this.getToDeploy(unreleased, unreleasedEvents);

        const fullAggregations = aggregations.map((aggregation) => ({
          ...aggregation,
          subRows: aggregatedEvents.filter(
            ({ aggregationName }) => aggregationName === aggregation.name
          ),
        }));

        const getOthers = (
          row: BehavioralAggregation | AggregatedEvent
        ): {
          parentRow?: BehavioralAggregation;
          subRows?: AggregatedEvent[];
        } => {
          return isBehavioralAggregation(row)
            ? { subRows: row.subRows }
            : {
                parentRow: aggregations.find(
                  ({ name }) => name === row.aggregationName
                ),
              };
        };

        const columnHelper = createColumnHelper<
          BehavioralAggregation | AggregatedEvent
        >();

        const columns: ColumnDef<
          BehavioralAggregation | AggregatedEvent,
          unknown
        >[] = [
          {
            accessorFn: (row) =>
              isBehavioralAggregation(row)
                ? stringifyAggregationName(row.name)
                : capitalize(row.name.replaceAll('_', ' ')),
            id: 'name',
            header: 'Name',
            cell: ({ row, getValue }) => (
              <div
                style={{
                  // Since rows are flattened by default,
                  // we can use the row.depth property
                  // and paddingLeft to visually indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                }}
              >
                <>
                  {row.getCanExpand() && (
                    <span
                      className="cursor-pointer me-1"
                      onClick={row.getToggleExpandedHandler()}
                    >
                      {row.getIsExpanded() ? (
                        <i className="fas fa-chevron-down" />
                      ) : (
                        <i className="fas fa-chevron-right" />
                      )}
                    </span>
                  )}
                  {getValue()}
                </>
              </div>
            ),
            filterFn: ({ original }, _, filterValue: string) => {
              const lowerCasedValue = filterValue.toLowerCase();
              const isBA = isBehavioralAggregation(original);
              const { subRows, parentRow } = getOthers(original);
              const getBehavioralName = (input: string) =>
                stringifyAggregationName(input).toLocaleLowerCase();
              const getEventName = (input: string) =>
                capitalize(input.replaceAll('_', ' ')).toLocaleLowerCase();

              return isBA
                ? getBehavioralName(original.name).includes(lowerCasedValue) ||
                    subRows.some(({ name }) =>
                      getEventName(name).includes(lowerCasedValue)
                    )
                : getEventName(original.name).includes(lowerCasedValue) ||
                    getBehavioralName(parentRow.name).includes(lowerCasedValue);
            },
          },
          columnHelper.accessor('type', {
            cell: (info) => info.getValue(),
            header: 'Type',
          }),
          {
            accessorFn: (row): string => {
              const statuses = [];
              if (row.usedInLiveModel) statuses.push('live');
              if (isBehavioralAggregation(row) && row.isSynced)
                statuses.push('sync');

              return statuses.join(' and ') || '-';
            },
            id: 'status',
            header: 'Status',
            cell: (info) => {
              const value = info.getValue() as string;

              return (
                <>
                  {value.includes('live') && (
                    <Status
                      text="Live"
                      title="Used in a live LTB model"
                      background="success"
                    />
                  )}
                  {value.includes('sync') && (
                    <Status
                      text="Sync"
                      title="Synced to your CRM directly in a field or in the Sales Intelligence module"
                      background="primary"
                    />
                  )}
                </>
              );
            },
            sortingFn: (rowA, rowB) => {
              const getScore = (
                row: BehavioralAggregation | AggregatedEvent
              ) => {
                let score = 0;
                if (row.usedInLiveModel) score += 2;
                if (isBehavioralAggregation(row) && row.isSynced) score += 1;

                return score;
              };

              const scoreA = getScore(rowA.original);
              const scoreB = getScore(rowB.original);

              return scoreA - scoreB;
            },
            filterFn: ({ original }, _, filterValue) => {
              const isBA = isBehavioralAggregation(original);
              const { subRows, parentRow } = getOthers(original);

              if (filterValue === 'live')
                return isBA
                  ? original.usedInLiveModel ||
                      subRows.some(({ usedInLiveModel }) => usedInLiveModel)
                  : parentRow.usedInLiveModel || original.usedInLiveModel;
              if (filterValue === 'sync')
                return isBA ? original.isSynced : parentRow.isSynced;
              if (filterValue === 'live and sync')
                return isBA
                  ? original.usedInLiveModel && original.isSynced
                  : parentRow.usedInLiveModel && parentRow.isSynced;

              return isBA
                ? (!original.usedInLiveModel && !original.isSynced) ||
                    (!!subRows.length &&
                      !subRows.some((subRow) => subRow.usedInLiveModel))
                : !original.usedInLiveModel || !parentRow.usedInLiveModel;
            },
          },
          columnHelper.accessor('updatedAt', {
            cell: (info) => {
              const value = info.getValue();
              if (!value) return null;

              return moment(value).format('ll');
            },
            header: 'Updated at',
            enableColumnFilter: false,
          }),
          {
            accessorFn: (row) => {
              if (isBehavioralAggregation(row))
                return (
                  <>
                    <LinkContainer
                      to={`/tenant/${tenant}/aggregations/${encodeURI(
                        row.name
                        // Allows to encode slashes in aggregation names
                      ).replace(/\//gi, '%2F')}/frequency_analysis`}
                    >
                      <Button className="me-1">Analyze</Button>
                    </LinkContainer>
                    <Button
                      variant="secondary"
                      onClick={this.editViewAggregation(row)}
                    >
                      {isSuperKudu || isArchitect ? 'Edit' : 'View'}
                    </Button>
                  </>
                );

              return checkPermissions(
                PROFILES_PERMISSIONS.ARCHITECT,
                <Button
                  size="sm"
                  disabled={row.usedInLiveModel}
                  onClick={this.setAggregatedEventToDelete(row.name)}
                  variant="outline-danger"
                >
                  <i aria-hidden className="fas fa-trash" />
                </Button>
              );
            },
            id: 'actions',
            enableSorting: false,
            header: 'Actions',
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
        ];

        return (
          <>
            <PageHeader>
              <Row>
                <Col>
                  <h2>Behavioral aggregations</h2>
                </Col>
                <Col className="text-end">
                  {checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <LinkContainer to={`/tenant/${tenant}/aggregations/new`}>
                      <Button>
                        <i className="fas fa-plus" /> Add aggregation
                      </Button>
                    </LinkContainer>
                  )}
                </Col>
              </Row>
            </PageHeader>
            <div className="mt-3 mb-5">
              <Card className="mb-3">
                <Card.Body className="w-75">
                  <p>
                    Aggregations store information about the activity of a
                    Person or a Company in the form of numerical statistics.
                    Build new aggregations to enrich your models, or segment
                    your leads and accounts directly in your CRM.
                  </p>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4406299126925-What-are-Aggregations-"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-primary"
                  >
                    <i aria-hidden className="fas fa-book-open"></i> How
                    behavioral aggregations work
                  </a>
                </Card.Body>
              </Card>
              {error && <Error message={error} />}
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <DeleteAggregationModal
                    display="aggregated_events"
                    deleting={deleting}
                    shouldShow={shouldShowEventDeletionModal}
                    toggleModal={this.toggleEventDeletionModal}
                    deleteAggregation={this.deleteAggregatedEvent(
                      deletingAggregatedEventName
                    )}
                  />
                  {(unreleased || unreleasedEvents) && (
                    <Alert className="mt-1">
                      You have {toDeploy} that are unreleased. Please reload
                      your dataset in order to use these aggregations in your
                      model.
                      <Button
                        className="ms-4"
                        onClick={this.toggleShowDeployModal}
                      >
                        Deploy
                      </Button>
                    </Alert>
                  )}
                  <Card>
                    <Card.Body>
                      <Table
                        data={fullAggregations}
                        columns={columns}
                        defaultPageSize={50}
                      />
                    </Card.Body>
                  </Card>
                </>
              )}
            </div>
            <Modal show={showDeployModel} onHide={this.toggleShowDeployModal}>
              <Modal.Header>
                <Modal.Title>Deploy {toDeploy} in production?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This will deploy any new {toDeploy} or updates made to{' '}
                {toDeploy}. Updates made on {toDeploy} marked <b>Live</b> may
                impact the model(s) using them. The updates will be available at
                the <b>next batch update</b>.
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={deploying} onClick={this.deploy}>
                  {deploying ? 'Deploying...' : 'Deploy'}
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={this.toggleShowDeployModal}
                  disabled={deploying}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);

export default withRouter(BehavioralAggregationsPage);
