import { inject, observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { Role } from '../../models/Permissions';
import Store from '../../store';

type CheckPermissionsProps = {
  store?: Store;
};

type CheckPermissionsState = {
  shouldShow: boolean;
};

export default function checkPermissions(
  allowedRoles: Role[],
  elementToRender: ReactElement
) {
  const CheckPermissions = inject('store')(
    observer(
      class CheckPermissions extends React.Component<
        CheckPermissionsProps,
        CheckPermissionsState
      > {
        constructor(props: CheckPermissionsProps) {
          super(props);
          this.state = {
            shouldShow: false,
          };
        }

        componentDidMount() {
          const { isSuperKudu, tenant, userPermissions } = this.props.store;
          if (isSuperKudu) return this.setState({ shouldShow: true });

          const shouldShow = !!userPermissions.find((permission: any) => {
            return (
              permission.tenant === tenant &&
              allowedRoles.includes(permission.role)
            );
          });

          return this.setState({ shouldShow });
        }

        render() {
          if (!this.state.shouldShow) {
            return null;
          }

          return elementToRender;
        }
      }
    )
  );

  return <CheckPermissions />;
}
