import min from 'lodash/min';
import max from 'lodash/max';

import { parse } from 'querystring';

import DataSetType from '../models/audience/DataSetType';
import { LeadGradeValue } from '../models/lead_grade/LeadGradePageModel';
import { Condition, Subject } from '../models/sqlform';
import TreeStructure from '../models/tree/TreeStructure';

/**
 * Pick keys in `T` where type is `V`.
 */
export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type ModelPerformanceGraphsContext =
  | 'customer_fit'
  | 'behavioral'
  | 'lead_grade';

type CustomerFit = 'low' | 'medium' | 'good' | 'very good';
export const fitToInt: Record<CustomerFit, number> = {
  low: 0,
  medium: 1,
  good: 2,
  'very good': 3,
};
export function compareCustomerFits(c1: CustomerFit, c2: CustomerFit) {
  return fitToInt[c1] - fitToInt[c2];
}

type Ltb = 'low' | 'medium' | 'high' | 'very high';
const ltbToInt: Record<Ltb, number> = {
  low: 0,
  medium: 1,
  high: 2,
  'very high': 3,
};

export function compareLtb(c1: Ltb, c2: Ltb) {
  return ltbToInt[c1] - ltbToInt[c2];
}

const lgToInt: Record<LeadGradeValue, number> = {
  E: 0,
  D: 1,
  C: 2,
  B: 3,
  A: 4,
};

export const WEIGHTS = {
  customer_fit: fitToInt,
  behavioral: ltbToInt,
  lead_grade: lgToInt,
};

export const CHART_CUSTOMER_COLORS: {
  [context: string]: {
    [segment: string]: { background: string; text: string };
  };
} = {
  customer_fit: {
    'very good': { background: '#1F77CC', text: '#fff' },
    good: { background: '#46C5B5', text: '#fff' },
    medium: { background: '#EEC240', text: '#fff' },
    low: { background: '#d11f4d', text: '#fff' },
  },
  behavioral: {
    'very high': { background: '#1F77CC', text: '#fff' },
    high: { background: '#46C5B5', text: '#fff' },
    medium: { background: '#EEC240', text: '#fff' },
    low: { background: '#d11f4d', text: '#fff' },
  },
  lead_grade: {
    A: { background: '#1F77CC', text: '#fff' },
    B: { background: '#4CCDBD', text: '#fff' },
    C: { background: '#EFC934', text: '#fff' },
    D: { background: '#F9E5D0', text: '#fff' },
    E: { background: '#D81E5C', text: '#fff' },
  },
};

export const CHART_COLORS: {
  [context: string]: {
    [segment: string]: { background: string; text: string };
  };
} = {
  customer_fit: {
    'very good': { background: '#1e77cc', text: '#fff' },
    good: { background: '#46C5B5', text: '#fff' },
    medium: { background: '#EEC240', text: '#fff' },
    low: { background: '#d11f4d', text: '#fff' },
  },
  behavioral: {
    'very high': { background: '#1e77cc', text: '#fff' },
    high: { background: '#46C5B5', text: '#fff' },
    medium: { background: '#EEC240', text: '#fff' },
    low: { background: '#d11f4d', text: '#fff' },
  },
  lead_grade: {
    A: { background: '#1e77cc', text: '#fff' },
    B: { background: '#96bbf7', text: '#11416f' },
    C: { background: '#abc6f4', text: '#11416f' },
    D: { background: '#c9daf8', text: '#11416f' },
    E: { background: '#d6d6d6', text: '#333' },
  },
};

export const GRADIENT_COLORS = {
  maxRed: '#d11f4d',
  lowestRed: '#f2c5d0',
  maxBlue: '#1e77cc',
  lowestBlue: '#b9d4ee',
  grey: '#d4d4d4',
};

export function getDefaultCondition(): Condition {
  return {
    subject: { subject: '', type: 'string', context: 'standard', tags: [] },
    verb: 'is',
    complements: [''],
    lower: true,
  };
}

function getDefaultValue(type: string): string | number | boolean {
  switch (type) {
    case 'string':
      return "'unknown'";
    case 'number':
      return "'-333'";
    case 'date':
      return "'1970-01-01'";
    case 'boolean':
      return false;
    default:
      return null;
  }
}

export function addCoalesceForField(condition: Condition): string {
  const coalesceValue = getDefaultValue(condition.subject.type);
  return coalesceValue !== null
    ? `COALESCE(${addLowerIfNeeded(
        condition.subject,
        condition.lower
      )}, ${coalesceValue})`
    : addLowerIfNeeded(condition.subject, condition.lower);
}

export function addNumericCastIfNeeded(
  subject: Subject,
  values: string[],
  sqlExpression: string
): string {
  if (subject.type === 'number' && !Number.isNaN(Number(values[0]))) {
    return `CAST(${sqlExpression} AS NUMERIC)`;
  }
  return sqlExpression;
}

export function addLowerIfNeeded(subject: Subject, lower: boolean): string {
  if (lower && subject.type === 'string') {
    return `LOWER(${subject.subject})`;
  }
  return subject.subject;
}

export function removeCoalescesFromStatement(statement: string): string {
  return statement.replace(/COALESCE\(([^,]+),[^)]*\)/g, '$1');
}

export function removeKnownsFromStatement(statement: string) {
  return statement.replace(
    /CAST\(.*?(.*) AS VARCHAR\) NOT IN \('unknown', '1970-01-01', '-333', 'false'\)/g,
    '$1 is known'
  );
}

export function removeUnknownsFromStatement(statement: string) {
  return statement.replace(
    /CAST\(.*?(.*) AS VARCHAR\) IN \('unknown', '1970-01-01', '-333', 'false'\)/g,
    '$1 is unknown'
  );
}

export function removeCastNumericFromStatement(statement: string) {
  return statement.replace(/CAST\('?(.*?)'? AS NUMERIC\)/g, '$1');
}

export function prettifyStatement(statement: string): string {
  if (statement) {
    const noCoalesce = removeCoalescesFromStatement(statement);
    const noUnknown = removeUnknownsFromStatement(noCoalesce);
    const noKnown = removeKnownsFromStatement(noUnknown);
    return removeCastNumericFromStatement(noKnown);
  }
  return '';
}

export function extractNodeFromTreeStructure(
  tree: TreeStructure,
  nodeId: number
): TreeStructure {
  if (Number(tree.id) === Number(nodeId)) {
    return tree;
  }

  let result = null;
  for (let i = 0; result == null && i < tree.children.length; i += 1) {
    result = extractNodeFromTreeStructure(tree.children[i], nodeId);
  }
  return result;
}

export function flattenTree(tree: TreeStructure): TreeStructure[] {
  return [tree].concat(
    tree.children.reduce((arr, child) => arr.concat(flattenTree(child)), [])
  );
}

export function snakeCaseToHumanReadable(field: string): string {
  return field
    .split('_')
    .filter((f) => f.trim().length > 0)
    .map((f) => f.trim().charAt(0).toUpperCase() + f.slice(1))
    .join(' ');
}

export function capitalize(word: string): string {
  return word[0]?.toUpperCase() + word?.slice(1) ?? '';
}

export function capitalizeEachWord(sentence: string) {
  return sentence
    .split(/ /g)
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');
}

/**
 * Return a gradient color from green to red
 * Value must be between 100 (green) and 0 (red)
 * HSL: red is 0, green is 140
 */
export function getColorForPercentage(value: number) {
  const hue = (value * 1.4).toString(10);
  const saturation = Math.min(100 - value / 2, 70);
  return `hsl(${hue}, ${saturation}%, ${saturation}%)`;
}

export function getDatasetParameter(search: string): DataSetType {
  const parsedStr = parse(search);

  return parsedStr.dataset as DataSetType;
}

export function computeDomainOfFloatArray(values: number[]): [number, number] {
  const maxValue = max(values);
  const minValue = min(values);

  const formatToSuppValue = (val: number) =>
    val > 0 ? Math.ceil(val) : Math.floor(val);

  return [formatToSuppValue(minValue), formatToSuppValue(maxValue)];
}
