import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  Route,
  withRouter,
  RouteComponentProps,
  Switch,
  Redirect,
} from 'react-router-dom';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../../components/CheckPermissions';
import Spinner from '../../components/Spinner';
import TreePage from './TreePage';

interface TreesPageProps extends RouteComponentProps<any> {
  store?: Store;
}

type TreesPageState = {
  isLoading: boolean;
};

const TreesPage = inject('store')(
  observer(
    class TreesPage extends React.Component<TreesPageProps, TreesPageState> {
      basePath: string;

      constructor(props: TreesPageProps) {
        super(props);

        this.state = {
          isLoading: true,
        };

        makeObservable(this, {
          toggleAutoSelectBestTrees: action,
          confirmAutoSelectBestTrees: action,
        });

        this.selectTree = this.selectTree.bind(this);
        this.toggleAutoSelectBestTrees =
          this.toggleAutoSelectBestTrees.bind(this);
        this.confirmAutoSelectBestTrees =
          this.confirmAutoSelectBestTrees.bind(this);

        const { tenant, modelId } = props.store;
        this.basePath = `/tenant/${tenant}/models/${modelId}/model/trees`;
      }

      async componentDidMount() {
        const promises = [];
        const { trees } = this.props.store.treesPage;
        promises.push(this.props.store.fetchFormFields());

        if (!trees?.length) {
          promises.push(this.props.store.loadAllTrees());
        }
        await Promise.all(promises);
        this.setState({ isLoading: false });
      }

      selectTree(treeId: number) {
        this.props.history.push(`${this.basePath}/${treeId}`);
      }

      toggleAutoSelectBestTrees() {
        this.props.store.treesPage.activeAutoSelectTrees =
          !this.props.store.treesPage.activeAutoSelectTrees;
      }

      async confirmAutoSelectBestTrees() {
        this.props.store.treesPage.autoSelectingTrees = true;
        await this.props.store.autoSelectBestTrees();
        this.props.store.treesPage.autoSelectingTrees = false;
        this.toggleAutoSelectBestTrees();
        this.selectTree(1);
      }

      render() {
        const { treesPage, isAllowedToEdit } = this.props.store;
        const { activeAutoSelectTrees, autoSelectingTrees, trees } = treesPage;

        const re = this.props.location.pathname.match(/trees\/([0-9]+)/);
        const treeId: number = re && re[1] && Number(re[1]);

        if (!trees || !trees.length) {
          return <Spinner />;
        }

        return (
          <div className="mt-3">
            <div className="mb-3">
              {trees.map(({ id, loading }) => (
                <Button
                  key={id}
                  variant={id === treeId ? 'primary' : 'light'}
                  active={id === treeId}
                  disabled={loading}
                  onClick={() => this.selectTree(id)}
                  className="me-1"
                >
                  Tree {id}
                </Button>
              ))}
              {checkPermissions(
                PROFILES_PERMISSIONS.ARCHITECT,
                <Button
                  variant="outline-primary"
                  className="float-end"
                  disabled={!isAllowedToEdit}
                  onClick={this.toggleAutoSelectBestTrees}
                >
                  Auto-select the best trees
                </Button>
              )}
            </div>
            <Switch>
              <Route path={`${this.basePath}/:treeId`}>
                <TreePage />
              </Route>

              <Route path={this.basePath}>
                <Redirect to={`${this.basePath}/1`} />
              </Route>
            </Switch>

            <Modal
              onHide={this.toggleAutoSelectBestTrees}
              show={activeAutoSelectTrees}
            >
              <Modal.Header>
                <Modal.Title>
                  Are you sure you want to replace the current trees with
                  auto-selected trees?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  There&apos;s no <code>undo</code> button
                </p>
                {autoSelectingTrees && (
                  <>
                    <Spinner />
                    <div className="text-center">
                      We&apos;re deep in the forest, researching the best trees
                      for you, just a moment...
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={this.toggleAutoSelectBestTrees}
                  disabled={autoSelectingTrees}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={this.confirmAutoSelectBestTrees}
                  disabled={autoSelectingTrees || !isAllowedToEdit}
                >
                  {autoSelectingTrees
                    ? 'Auto-selecting trees...'
                    : 'Auto-select best trees'}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      }
    }
  )
);

export default withRouter(TreesPage);
