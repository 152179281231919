import { inject } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { FrequencyAnalysis } from '../../../models/aggregations/aggregated_events';
import Store from '../../../store';
import Table from '../generic_components/Table';

interface CorrelationTableProps extends RouteComponentProps {
  store?: Store;
}

class CorrelationTable extends React.Component<CorrelationTableProps> {
  render() {
    const { frequencyAnalysis } = this.props.store.aggregatedEventsPage;
    const columnHelper = createColumnHelper<FrequencyAnalysis>();
    const columns: ColumnDef<FrequencyAnalysis, unknown>[] = [
      columnHelper.accessor('aggregated_event_display', {
        cell: (info) => info.getValue(),
        header: 'Aggregated event display',
      }),
      {
        accessorFn: ({ prob_conversion_if_not_this }) =>
          +Number(prob_conversion_if_not_this).toFixed(2),
        id: 'prob_conversion_if_not_this',
        header: 'Prob conversion if not this',
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: ({ prob_conversion_if_this }) =>
          +Number(prob_conversion_if_this).toFixed(2),
        id: 'prob_conversion_if_this',
        header: 'Prob conversion if this',
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: ({ phi }) => +Number(phi).toFixed(4),
        id: 'phi',
        header: 'Phi',
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: ({ percent_of_people_who_did_action }) =>
          +Number(percent_of_people_who_did_action).toFixed(2),
        id: 'percent_of_people_who_did_action',
        header: 'Percent of people who did action',
        cell: (info) => info.getValue(),
      },
      columnHelper.accessor('number_of_occurences_of_event_last_x_days', {
        cell: (info) => info.getValue(),
        header: 'Number of occurences of event last x days',
      }),
    ];

    return <Table data={frequencyAnalysis} columns={columns} />;
  }
}

export default inject('store')(withRouter(CorrelationTable));
