import React, { ReactElement } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

type ClickableCardProps = {
  icon: ReactElement;
  text: string;
  link: string;
};

const ClickableCard = ({ icon, text, link }: ClickableCardProps) => {
  return (
    <LinkContainer to={link}>
      <Card
        border="light"
        className="box-shadow-3d on-hover-selectable"
        role="button"
      >
        <Card.Body>
          <Row>
            <Col md={2} className="text-center">
              {icon}
            </Col>
            <Col>{text}</Col>
          </Row>
        </Card.Body>
      </Card>
    </LinkContainer>
  );
};

export default ClickableCard;
