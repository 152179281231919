import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
  Form,
} from 'react-bootstrap';
import {
  getVerbSettings,
  getVerbsSettings,
  Verb,
} from '../../../../../models/sqlform';
import Store from '../../../../../store';
import {
  DirectedComputationCondition,
  DirectedComputationConditionGroup,
} from '../../../../../models/computations/Computation';
import startCase from 'lodash/startCase';
import MadSelect from '../../../generic_components/select_component/MadSelect';

const verbsSettings = getVerbsSettings();

interface DirectedConditionProps {
  store?: Store;
  directedConditionGroup: DirectedComputationConditionGroup;
  directedCondition: DirectedComputationCondition;
  directedConditionIndex: number;
}

const DirectedCondition = inject('store')(
  observer(function DirectedCondition({
    store,
    directedConditionGroup,
    directedCondition,
    directedConditionIndex,
  }: DirectedConditionProps) {
    const handleOnChangeField = (value: string) => {
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.field = value;
    };

    const handleOnChangeVerb = (value: Verb) => {
      const verbSettings = getVerbSettings(value);
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.verb = value;
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.values = Array(verbSettings.characteristics.min).fill('');
    };

    const handleOnChangeValue = (valueIndex: number, newValue: string) => {
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.values[valueIndex] = newValue;
    };

    const handleRemoveValue = (valueIndex: number) => {
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.values.splice(valueIndex, 1);
    };

    const handleAddValue = () => {
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.values.push('');
    };

    const handleOnClickRemoveCondition = () => {
      directedConditionGroup.directedConditions.splice(
        directedConditionIndex,
        1
      );
    };

    const handleOnLowerChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      directedConditionGroup.directedConditions[
        directedConditionIndex
      ].condition.isLower = event.target.checked;
    };

    const { values, verb, field, isLower } = directedCondition.condition;

    const formFields = store.computationsPage.metadataFields;

    const fieldType = formFields.find(
      (formField) => formField.name === field
    )?.type;

    const filteredVerbs = verbsSettings.filter(
      ({ characteristics: { types } }) => types.includes(fieldType)
    );

    const conditionVerbSettings = getVerbSettings(verb);
    const isVerbUsageForString =
      conditionVerbSettings.characteristics.types.includes('string');

    const readonly =
      store.computationsPage.activeComputation.context === 'standard';

    const isLowerValue = isLower ?? true;

    return (
      <>
        <Row className="pt-1 pb-2 gx-1">
          {directedCondition.direction && (
            <Col xs={1}>
              <span className="d-inline-block fw-bold mt-1">
                {directedCondition.direction}
              </span>
            </Col>
          )}
          <Col xs={directedCondition.direction ? 4 : 5}>
            <MadSelect
              defaultValue={{ value: field, label: startCase(field) }}
              isSearchable
              isDisabled={readonly}
              options={formFields?.map(({ name: value }) => ({
                value,
                label: startCase(value),
              }))}
              onChange={(option) => handleOnChangeField(option.value)}
            />
          </Col>
          <Col xs={3}>
            <MadSelect
              isDisabled={readonly}
              defaultValue={{ value: verb, label: verb }}
              isSearchable
              options={(fieldType !== 'attribute'
                ? filteredVerbs
                : verbsSettings
              ).map(({ value }) => ({ value, label: value }))}
              onChange={(option) => handleOnChangeVerb(option.value)}
            />
            <Form.Check
              id="case-insensitive"
              label="Case-insensitive"
              checked={isVerbUsageForString ? isLowerValue : false}
              disabled={readonly || !isVerbUsageForString}
              onChange={handleOnLowerChange}
            />
          </Col>
          <Col xs={3}>
            {values.map((value, valueIndex) =>
              values.length > conditionVerbSettings.characteristics.min ? (
                <InputGroup key={`value_${valueIndex}`}>
                  <FormControl
                    key={`value_${valueIndex}`}
                    type="text"
                    disabled={readonly}
                    value={value ?? ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleOnChangeValue(valueIndex, event.target.value);
                    }}
                  />
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="right">Remove the value</Tooltip>}
                  >
                    <Button
                      onClick={() => {
                        handleRemoveValue(valueIndex);
                      }}
                      disabled={readonly}
                      variant="danger"
                    >
                      <i aria-hidden className="fas fa-trash" />
                    </Button>
                  </OverlayTrigger>
                </InputGroup>
              ) : (
                <FormControl
                  key={`value_${valueIndex}`}
                  type="text"
                  value={value ?? ''}
                  disabled={readonly}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnChangeValue(valueIndex, event.target.value)
                  }
                />
              )
            )}
            {conditionVerbSettings.characteristics.max > values.length ? (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Add new value</Tooltip>}
              >
                <Button
                  onClick={handleAddValue}
                  disabled={readonly}
                  className="pull-left"
                >
                  <i aria-hidden className="fas fa-plus" />
                </Button>
              </OverlayTrigger>
            ) : null}
          </Col>
          <Col xs={1}>
            {directedConditionIndex > 0 && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Remove the condition</Tooltip>}
              >
                <Button
                  onClick={handleOnClickRemoveCondition}
                  disabled={readonly}
                  variant="danger"
                >
                  <i aria-hidden className="fas fa-trash" />
                </Button>
              </OverlayTrigger>
            )}
          </Col>
        </Row>
      </>
    );
  })
);

export default DirectedCondition;
