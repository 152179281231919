import { makeObservable, observable } from 'mobx';

import { CustomerFitSampleLead } from './SampleLead';
import UnivariateAnalysis from './univariate/UnivariateAnalysis';
import UnivariateAnalysisVariable from './univariate/UnivariateAnalysisVariable';

export default class NodePageModel {
  leads: CustomerFitSampleLead[];

  loadLeadsError: string;

  loadingLeads: boolean;

  univariateAnalysis: UnivariateAnalysis;

  univariateAnalysisVariable: UnivariateAnalysisVariable;

  loadingUnivariateAnalysis: boolean;

  loadingUnivariateAnalysisVariable: boolean;

  univariateAnalysisError: string;

  univariateAnalysisVariableError: string;

  univariateAnalysisWithPersonal: boolean;

  constructor(other?: NodePageModel) {
    if (other) {
      this.leads = other.leads;
      this.loadLeadsError = other.loadLeadsError;
      this.loadingLeads = other.loadingLeads;
      this.univariateAnalysis = other.univariateAnalysis;
      this.loadingUnivariateAnalysis = other.loadingUnivariateAnalysis;
      this.loadingUnivariateAnalysisVariable =
        other.loadingUnivariateAnalysisVariable;
      this.univariateAnalysisVariable = other.univariateAnalysisVariable;
      this.univariateAnalysisError = other.univariateAnalysisError;
      this.univariateAnalysisVariableError =
        other.univariateAnalysisVariableError;
      this.univariateAnalysisWithPersonal =
        other.univariateAnalysisWithPersonal;
    } else {
      this.leads = [];
      this.loadLeadsError = null;
      this.loadingLeads = false;
      this.univariateAnalysis = null;
      this.univariateAnalysisVariable = null;
      this.loadingUnivariateAnalysis = false;
      this.loadingUnivariateAnalysisVariable = false;
      this.univariateAnalysisError = null;
      this.univariateAnalysisVariableError = null;
      this.univariateAnalysisWithPersonal = false;
    }
    makeObservable(this, {
      leads: observable,
      loadLeadsError: observable,
      loadingLeads: observable,
      univariateAnalysis: observable,
      univariateAnalysisVariable: observable,
      loadingUnivariateAnalysis: observable,
      loadingUnivariateAnalysisVariable: observable,
      univariateAnalysisError: observable,
      univariateAnalysisVariableError: observable,
      univariateAnalysisWithPersonal: observable,
    });
  }
}
