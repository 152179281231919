import { inject, observer } from 'mobx-react';
import queryString from 'querystring';
import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import Store from '../../../store';
import Error from '../../components/error/Error';
import Table from '../../components/generic_components/Table';
import Spinner from '../../components/Spinner';
import { CustomerFitSampleLead } from '../../../models/SampleLead';

const COLUMN_VISIBILITY_BAG = 'univariateLeadsColumnVisibility';
const DEFAULT_COLUMN_VISIBILITY = {
  employees: false,
  amountRaised: false,
  marketCap: false,
  isFortune500Company: false,
  country: false,
  predictiveTraffic: false,
  industry: false,
  hasEnterpriseTechnology: false,
};

type MatchParams = {
  variableId: string;
  variableName: string;
  readableValue: string;
  value: string;
  nodeId: string;
  treeId: string;
};

interface UnivariateLeadsPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface UnivariateLeadsPageState {
  columnVisibility: Partial<Record<keyof CustomerFitSampleLead, boolean>>;
}

const UnivariateLeadsPage = inject('store')(
  observer(
    class UnivariateLeadsPage extends React.Component<
      UnivariateLeadsPageProps,
      UnivariateLeadsPageState
    > {
      constructor(props: UnivariateLeadsPageProps) {
        super(props);
        this.reloadLeads = this.reloadLeads.bind(this);
        this.checkUnivariateAnalysisWithPersonal =
          this.checkUnivariateAnalysisWithPersonal.bind(this);

        this.state = {
          columnVisibility: DEFAULT_COLUMN_VISIBILITY,
        };
      }

      async componentDidMount() {
        const savedColumnVisibility =
          localStorage.getItem(COLUMN_VISIBILITY_BAG) ?? null;
        if (savedColumnVisibility) {
          this.setState({
            columnVisibility: JSON.parse(savedColumnVisibility),
          });
        }

        const { variableId, value, nodeId, treeId } = this.props.match.params;
        this.updateIncludePersonalFromQueryString(this.props.location.search);
        if (treeId && nodeId) {
          return this.props.store.getUnivariateAnalysisLeads(
            variableId,
            value,
            treeId,
            nodeId
          );
        }
        return this.reloadLeads();
      }

      async reloadLeads() {
        const { variableId, value, nodeId, treeId } = this.props.match.params;
        if (treeId && nodeId)
          return this.props.store.getUnivariateAnalysisLeads(
            variableId,
            value,
            treeId,
            nodeId
          );
        return this.props.store.getUnivariateAnalysisLeads(variableId, value);
      }

      updateIncludePersonalFromQueryString(search: string) {
        const parsedSearch = queryString.parse(search);
        this.props.store.univariateAnalysisPage.univariateAnalysisWithPersonal =
          parsedSearch.includePersonal === 'true';
      }

      async checkUnivariateAnalysisWithPersonal() {
        this.props.store.checkUnivariateAnalysisWithPersonal();

        const { univariateAnalysisWithPersonal } =
          this.props.store.univariateAnalysisPage;
        const search = queryString.parse(this.props.location.search);
        const newQueryString = {
          ...search,
          includePersonal: univariateAnalysisWithPersonal,
        };
        const newUrl = `${this.props.location.pathname}?${queryString.stringify(
          newQueryString
        )}`;

        this.props.history.push(newUrl);
        await this.reloadLeads();
      }

      render() {
        const { variableId, variableName, readableValue, nodeId, treeId } =
          this.props.match.params;
        const { tenant, modelId, univariateAnalysisPage } = this.props.store;

        const columnHelper = createColumnHelper<CustomerFitSampleLead>();

        const columns: ColumnDef<CustomerFitSampleLead, unknown>[] = [
          columnHelper.accessor('email', {
            cell: (info) => info.getValue(),
            header: 'Email',
          }),
          columnHelper.accessor('employees', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Employees',
          }),
          columnHelper.accessor('amountRaised', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Amount raised',
          }),
          columnHelper.accessor('marketCap', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Market cap',
          }),
          {
            accessorFn: ({ isFortune500Company }: CustomerFitSampleLead) =>
              isFortune500Company ? 'yes' : 'no',
            id: 'isFortune500Company',
            header: 'Fortune 500 company',
            cell: (info) => info.getValue(),
          },
          columnHelper.accessor('country', {
            cell: (info) => info.getValue(),
            header: 'Country',
          }),
          columnHelper.accessor('predictiveTraffic', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Predicted traffic',
          }),
          columnHelper.accessor('industry', {
            cell: (info) => info.getValue(),
            header: 'Industry',
          }),
          {
            accessorFn: ({ hasEnterpriseTechnology }: CustomerFitSampleLead) =>
              hasEnterpriseTechnology ? 'yes' : 'no',
            id: 'hasEnterpriseTechnology',
            header: 'Has entreprise technology',
            cell: (info) => info.getValue(),
          },
          {
            accessorFn: ({ hasConverted }) => (hasConverted ? 'yes' : 'no'),
            id: 'hasConverted',
            header: 'Has converted',
            cell: (info) => info.getValue(),
          },
        ];

        const columnVisibility = this.state.columnVisibility;

        return (
          <div>
            {univariateAnalysisPage.loadLeadsError ? (
              <Error message={univariateAnalysisPage.loadLeadsError} />
            ) : null}
            <Link
              to={
                treeId && nodeId
                  ? `/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}/univariate?variable=${variableId}&includePersonal=${univariateAnalysisPage.univariateAnalysisWithPersonal}`
                  : `/tenant/${tenant}/models/${modelId}/insights?variable=${variableId}&includePersonal=${univariateAnalysisPage.univariateAnalysisWithPersonal}`
              }
            >
              Back to Univariate analysis
              {treeId && nodeId && ` for treeId ${treeId} - node ${nodeId}`}
            </Link>
            <h2>
              Sample of leads for variable {variableName}, value {readableValue}
              {treeId && nodeId && ` on tree ${treeId} - node ${nodeId}`}
            </h2>
            {!univariateAnalysisPage.loadingLeads ? (
              <Card className="mb-3">
                <Card.Body>
                  <Form.Check
                    type="checkbox"
                    id="is_personal_checkbox"
                    label={'Include "is_personal" values'}
                    checked={
                      univariateAnalysisPage.univariateAnalysisWithPersonal
                    }
                    onChange={this.checkUnivariateAnalysisWithPersonal}
                  />
                  <Table
                    data={univariateAnalysisPage.leads}
                    columns={columns}
                    defaultColumnVisibility={columnVisibility}
                    columnVisibilityBag={COLUMN_VISIBILITY_BAG}
                    additionalButtons={
                      <Button onClick={this.reloadLeads}>Reload leads</Button>
                    }
                  />
                </Card.Body>
              </Card>
            ) : (
              <Spinner />
            )}
          </div>
        );
      }
    }
  )
);

export default withRouter(UnivariateLeadsPage);
