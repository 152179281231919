import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Accordion,
  Alert,
  Button,
  Modal,
  Card,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TreeStructure from '../../../models/tree/TreeStructure';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import { flattenTree } from '../../../utils';
import checkPermissions from '../CheckPermissions';
import SplitConditionForm from './SplitConditionForm';

interface TreeSideBarProps {
  store?: Store;
  treeId: number;
  treeName: string;
  nodeId: number;
}

export default inject('store')(
  observer(
    class TreeSideBar extends React.Component<
      TreeSideBarProps,
      Record<string, never>
    > {
      constructor(props: TreeSideBarProps) {
        super(props);

        makeObservable(this, {
          toggleInsertChildNode: action,
          toggleReplaceSplitCondition: action,
          toggleDeleteSplitCondition: action,
        });

        this.toggleInsertChildNode = this.toggleInsertChildNode.bind(this);
        this.toggleReplaceSplitCondition =
          this.toggleReplaceSplitCondition.bind(this);
        this.toggleDeleteSplitCondition =
          this.toggleDeleteSplitCondition.bind(this);
        this.deleteSplitCondition = this.deleteSplitCondition.bind(this);
      }

      componentDidMount() {
        const { treeId, nodeId } = this.props;

        const newTrees = this.props.store.treesPage.trees;
        const newTree = newTrees.find((tree) => tree.id === treeId);
        const stats = newTree.definition.stats.map((node: any) => {
          // eslint-disable-next-line no-param-reassign
          node.selected = node.node === nodeId;
          return node;
        });
        newTree.definition.stats = stats;
        newTrees[treeId - 1] = newTree;
        this.props.store.treesPage.trees = newTrees;
        this.props.store.resetSplitCondition();
      }

      toggleInsertChildNode() {
        this.props.store.analyticsTrackEvent('Added tree split condition');
        this.props.store.treesPage.activeInsertChildNode =
          !this.props.store.treesPage.activeInsertChildNode;
      }

      toggleReplaceSplitCondition() {
        this.props.store.analyticsTrackEvent('Replaced tree split condition');

        this.props.store.treesPage.activeReplaceCondition =
          !this.props.store.treesPage.activeReplaceCondition;
      }

      toggleDeleteSplitCondition() {
        this.props.store.analyticsTrackEvent('Deleted tree split condition');
        this.props.store.treesPage.activeDeleteCondition =
          !this.props.store.treesPage.activeDeleteCondition;
      }

      async deleteSplitCondition() {
        const { treeId, nodeId, treeName } = this.props;
        await this.props.store.deleteSplitCondition(treeId, treeName, nodeId);
      }

      render() {
        const { treesPage, tenant, modelId, isAllowedToEdit } =
          this.props.store;
        const {
          trees,
          activeInsertChildNode,
          activeReplaceCondition,
          activeDeleteCondition,
          deletingSplitCondition,
        } = treesPage;
        const { treeId, nodeId, treeName } = this.props;

        let allNodes: TreeStructure[] = [];
        const tree = trees[treeId - 1];
        if (tree && tree.definition && tree.definition.structure) {
          allNodes = flattenTree(tree.definition.structure);
        }

        const enrichedNode: TreeStructure =
          allNodes.length && allNodes[nodeId - 1];

        const nodeStats = (
          <>
            {allNodes.length && enrichedNode ? (
              <>
                <ul>
                  <li>Population: {enrichedNode.population}</li>
                  <li>Conversions: {enrichedNode.conversions}</li>
                  <li>
                    Conversion rate:{' '}
                    {Math.round(
                      (enrichedNode.conversions / enrichedNode.population) *
                        10000
                    ) / 100}
                    %
                  </li>
                  <li>
                    % population:{' '}
                    {Math.round(enrichedNode.percentPopulation * 1000) / 10}%
                  </li>
                  <li>
                    % conversions:{' '}
                    {Math.round(enrichedNode.percentConversions * 1000) / 10}%
                  </li>
                </ul>
              </>
            ) : (
              <Alert variant="info">Select a node first</Alert>
            )}
          </>
        );

        // Confirm delete dialog modal
        const confirmDeleteDialog = (
          <Modal
            onHide={this.toggleDeleteSplitCondition}
            show={activeDeleteCondition}
          >
            <Modal.Header>
              <Modal.Title>
                Are you sure you want to delete node {nodeId}&apos;s split
                condition?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                There&apos;s no <code>undo</code> button
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.toggleDeleteSplitCondition}
                disabled={deletingSplitCondition}
                variant="outline-primary"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.deleteSplitCondition}
                disabled={deletingSplitCondition}
              >
                {deletingSplitCondition
                  ? 'Deleting condition...'
                  : 'Delete split condition'}
              </Button>
            </Modal.Footer>
          </Modal>
        );

        const marginBottomStyle = { marginBottom: 5 };

        return (
          <>
            <SplitConditionForm
              treeId={treeId}
              treeName={treeName}
              nodeId={nodeId}
              active={activeInsertChildNode}
            />
            <SplitConditionForm
              treeId={treeId}
              treeName={treeName}
              nodeId={nodeId}
              active={activeReplaceCondition}
              replace
            />
            {confirmDeleteDialog}
            <Card border="light">
              <Card.Body>
                <Card.Title>Statistics Node {nodeId}</Card.Title>
                {nodeStats}
              </Card.Body>
            </Card>
            {enrichedNode?.sumConditions && (
              <Accordion className="mt-4 mb-4">
                <Accordion.Item
                  className="border-light"
                  eventKey={`definition_node_${nodeId}`}
                  key={`key_definition_node_${nodeId}`}
                >
                  <Accordion.Header>Definition Node {nodeId}</Accordion.Header>
                  <Accordion.Body>
                    <code>{enrichedNode.sumConditions}</code>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            {enrichedNode && (
              <>
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,

                  <>
                    {enrichedNode.splitConditions && (
                      <>
                        <Card border="light">
                          <Card.Body>
                            <Card.Title>
                              Split condition Node {nodeId}
                            </Card.Title>
                            <ul className="list-unstyled">
                              {enrichedNode.splitConditions
                                .replace(/(AND)/g, 'AND_SPLIT_')
                                .split('_SPLIT_')
                                .map((condition: string, id: number) => (
                                  <li key={id}>
                                    <code>{condition}</code>
                                  </li>
                                ))}
                            </ul>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="replace-split">
                                  Replace split condition
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="outline-primary"
                                onClick={this.toggleReplaceSplitCondition}
                                style={marginBottomStyle}
                                disabled={!nodeId || !isAllowedToEdit}
                                data-tip="Replace split condition"
                                className="me-2"
                              >
                                Replace
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="replace-split">
                                  Delete split condition
                                </Tooltip>
                              }
                            >
                              <Button
                                style={marginBottomStyle}
                                variant="danger"
                                onClick={this.toggleDeleteSplitCondition}
                                disabled={!isAllowedToEdit}
                                data-tip="Delete split condition"
                              >
                                Delete
                              </Button>
                            </OverlayTrigger>
                          </Card.Body>
                        </Card>
                      </>
                    )}
                  </>
                )}
                <Card border="light" className="mt-4 mb-4">
                  <Card.Body>
                    <Card.Title className="mb-4">
                      Insights Node {nodeId}
                    </Card.Title>
                    <Link
                      to={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}/insights`}
                    >
                      <Button>View insights for this node</Button>
                    </Link>
                    <br />
                    <Link
                      to={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}/leads/0?includePersonal=false`}
                    >
                      View sample of non converters in this node
                    </Link>
                    <br />
                    <Link
                      to={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}/leads/1?includePersonal=false`}
                    >
                      View sample of converters in this node
                    </Link>
                  </Card.Body>
                </Card>
                <Button
                  onClick={this.toggleInsertChildNode}
                  style={marginBottomStyle}
                  disabled={!nodeId || !isAllowedToEdit}
                >
                  Split this node
                </Button>
              </>
            )}
          </>
        );
      }
    }
  )
);
