import React, { ReactElement } from 'react';
import { PerformanceCheckDataValue } from '../../../models/performance_check';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CHART_CUSTOMER_COLORS } from '../../../utils';
import { Card } from 'react-bootstrap';

interface PredictedLeadValueProps {
  data: PerformanceCheckDataValue;
}

export default function PredictedLeadValue({
  data,
}: PredictedLeadValueProps): ReactElement {
  const formattedData = [
    { name: 'low', value: Number((data?.low ?? 0).toFixed(2)) },
    { name: 'medium', value: Number((data?.medium ?? 0).toFixed(2)) },
    { name: 'good', value: Number((data?.good ?? 0).toFixed(2)) },
    { name: 'very good', value: Number((data?.['very good'] ?? 0).toFixed(2)) },
  ];

  const rate = Math.round((data?.['very good'] ?? 0) / (data?.low ?? 0));

  return (
    <Card className="h-100">
      <Card.Body>
        <Card.Title>Predicted Lead Value</Card.Title>
        <Card.Text>
          Your &apos;Very Good&apos; leads are worth <b>${rate}x</b> more than
          your &apos;Low&apos; leads.
        </Card.Text>
        <div style={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={formattedData} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 'dataMax']} />
              <YAxis dataKey="name" type="category" />
              <Tooltip formatter={(value) => `$${value}`} />
              <Bar dataKey="value">
                {formattedData.map(({ name }) => (
                  <Cell
                    key={name}
                    fill={CHART_CUSTOMER_COLORS.customer_fit[name].background}
                  />
                ))}
                <LabelList
                  dataKey="value"
                  fill="white"
                  formatter={(value: number) => `$${value}`}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card.Body>
    </Card>
  );
}
