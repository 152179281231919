import { inject, observer } from 'mobx-react';
import React from 'react';
import DeployMode from '../../../models/deploy/DeployMode';
import { getModelFolders, ModelFolder } from '../../../models/ModelItem';
import Store from '../../../store';
import { IS_LLTB, MODEL_TYPES_NAMES } from '../../../utils/constants';
import DeployTabComponent from './DeployTabComponent';

interface BehavioralFitTabProps {
  store?: Store;
  openDeployPopup: (
    deployMode: DeployMode,
    folders: ModelFolder[]
  ) => Promise<void>;
  openImpactPopup: () => Promise<void>;
}

export default inject('store')(
  observer(
    class BehavioralTab extends React.Component<
      BehavioralFitTabProps,
      Record<string, never>
    > {
      render() {
        const { activeModel } = this.props.store;
        const folders: ModelFolder[] = getModelFolders(activeModel, {
          forDeployment: true,
        });
        const modelTypeName = MODEL_TYPES_NAMES[activeModel.type];

        return (
          <>
            {activeModel.type === 'mqa' && (
              <DeployTabComponent
                enabled
                deployMode="standard"
                folders={folders}
                deployButtonLabel={`Deploy ${modelTypeName}`}
                downloadButtonLabel={(context: string) =>
                  `Download primary ${context} config`
                }
                title={
                  <p>
                    <b>Deployment {modelTypeName} model</b>
                  </p>
                }
                openDeployPopup={this.props.openDeployPopup}
                openImpactPopup={this.props.openImpactPopup}
              >
                <div>
                  <h6 className="fw-bold">
                    Use case: Deploying for the first time an {modelTypeName}{' '}
                    model
                  </h6>
                  <ul>
                    <li>
                      Click on the button Deploy below to deploy this new model
                      to production. (This means the platform will start
                      calculating scores for all your records).
                    </li>
                    <li>
                      Then send a request to support@madkudu.com to configure
                      the push of this model to your Salesforce accounts.
                    </li>
                  </ul>
                  <p>Please make sure of your edits before deploying.</p>
                </div>
                <div className="mt-4">
                  <h6 className="fw-bold">
                    Use case: Replacing the current {modelTypeName}
                    model marked “Live”
                  </h6>
                  <ul>
                    <li>
                      Click on the button Deploy below to deploy this new
                      version to production.
                    </li>
                    <li>
                      If a push configuration is enabled between MadKudu and
                      Salesforce for an {modelTypeName} model, scores and
                      signals will update with the next batch sync process.
                    </li>
                    <p>Make sure of your edits before deploying.</p>
                  </ul>
                </div>
              </DeployTabComponent>
            )}
            {IS_LLTB(activeModel.type) && (
              <DeployTabComponent
                enabled
                deployMode="standard"
                folders={folders}
                deployButtonLabel={`Deploy ${modelTypeName} and Lead Grade models`}
                downloadButtonLabel={(context: string) =>
                  `Download primary ${context} config`
                }
                title={
                  <p>
                    <b>
                      Deployment {modelTypeName} model {'&'} Lead Grade
                    </b>
                  </p>
                }
                openDeployPopup={this.props.openDeployPopup}
                openImpactPopup={this.props.openImpactPopup}
              >
                <div>
                  <h6 className="fw-bold">
                    Use case: Deploying for the first time a {modelTypeName}{' '}
                    model
                  </h6>
                  <ul>
                    <li>
                      Click on the button Deploy below to deploy this new model
                      to production. (This means the platform will start
                      calculating scores for all your records).
                    </li>
                    <li>
                      Then configure the push of this model here to push the
                      scores to your integrations
                    </li>
                  </ul>
                </div>
                <div className="mt-4">
                  <h6 className="fw-bold">
                    Use case: Replacing the current {modelTypeName} model marked
                    “Live”
                  </h6>
                  <ul>
                    <li>
                      Click on the button Deploy below to deploy this new
                      version to production.
                    </li>
                    <li>
                      Where a push configuration is enabled between MadKudu and
                      your integration(s) for a {modelTypeName} model, scores
                      and signals will update with the{' '}
                      <a
                        href="https://support.madkudu.com/hc/en-us/articles/4407302132621-How-to-read-the-Processes-page"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        next batch sync process.
                      </a>
                    </li>
                    <p>Make sure of your edits before deploying.</p>
                  </ul>
                </div>
              </DeployTabComponent>
            )}
          </>
        );
      }
    }
  )
);
