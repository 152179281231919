import isEqual from 'lodash/isEqual';
import { inject, observer } from 'mobx-react';
import { utc } from 'moment-mini';
import React from 'react';
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Store from '../../store';
import StepFive from '../components/behavioral_aggregations/StepFive';
import StepFour from '../components/behavioral_aggregations/StepFour';
import StepOne from '../components/behavioral_aggregations/StepOne';
import StepThree from '../components/behavioral_aggregations/StepThree';
import StepTwo from '../components/behavioral_aggregations/StepTwo';
import Error from '../components/error/Error';
import {
  BehavioralAggregation,
  logicOptions as logicOptionsByType,
} from '../../models/aggregations/behavioral_aggregations';
import PageHeader from '../components/PageHeader';

type MatchParams = {
  tenant: string;
};

interface NewBehavioralAggregationPageProps
  extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface NewBehavioralAggregationPageState {
  isFormInvalid: boolean;
}

const EMPTY_AGGREGATION: BehavioralAggregation = {
  id: null,
  type: null,
  logic: null,
  lifetime: null,
  name: null,
  released: false,
  isProductEvent: false,
  conditions: { rules: [] },
  conditionLogic: '',
};

const NewBehavioralAggregationPage = inject('store')(
  observer(
    class NewBehavioralAggregationPage extends React.Component<
      NewBehavioralAggregationPageProps,
      NewBehavioralAggregationPageState
    > {
      constructor(props: NewBehavioralAggregationPageProps) {
        super(props);

        this.state = {
          isFormInvalid: false,
        };

        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
        this.getSaveButtonText = this.getSaveButtonText.bind(this);
        this.updateIsFormInvalid = this.updateIsFormInvalid.bind(this);
      }

      async componentDidMount() {
        const { tenant } = this.props.store;
        if (
          this.props.store.behavioralAggregationsPage.aggregations.length === 0
        ) {
          await this.props.store.behavioralAggregationsPage.get(tenant);
        }

        this.props.store.behavioralAggregationsPage.activeAggregation =
          EMPTY_AGGREGATION;
        this.props.store.behavioralAggregationsPage.formStep = 1;
      }

      componentWillUnmount() {
        this.props.store.behavioralAggregationsPage.activeAggregation =
          EMPTY_AGGREGATION;
      }

      updateIsFormInvalid(isFormInvalid: boolean) {
        this.setState({
          isFormInvalid,
        });
      }

      async save() {
        const { tenant, behavioralAggregationsPage } = this.props.store;
        const { formStep } = behavioralAggregationsPage;
        const { activeAggregation } = behavioralAggregationsPage;

        if (formStep === 3) {
          // skip to name step and avoid conditions form if not advanced logic
          const isAdvanced = activeAggregation?.logic?.includes('custom');
          if (!isAdvanced) {
            this.props.store.behavioralAggregationsPage.formStep = 5;
            return;
          }
        }

        if (formStep < 5) {
          this.props.store.behavioralAggregationsPage.formStep += 1;
          return;
        }

        activeAggregation.updatedAt = utc().format('YYYY-MM-DD');
        activeAggregation.id = uuid();

        this.props.store.behavioralAggregationsPage.aggregations.push(
          activeAggregation
        );

        await this.props.store.behavioralAggregationsPage.save(tenant);
        this.props.history.push({
          pathname: `/tenant/${tenant}/aggregations`,
        });
        this.props.store.behavioralAggregationsPage.activeAggregation =
          EMPTY_AGGREGATION;
      }

      back() {
        const { tenant } = this.props.store;
        this.props.history.push({
          pathname: `/tenant/${tenant}/aggregations`,
        });
      }

      getSaveButtonText() {
        const { formStep, saving } =
          this.props.store.behavioralAggregationsPage;

        if (formStep < 5) {
          return 'Next';
        }

        if (saving) {
          return 'Saving';
        }

        return 'Save';
      }

      render() {
        const { behavioralAggregationsPage } = this.props.store;
        const { isFormInvalid } = this.state;
        const {
          activeAggregation,
          aggregations,
          error,
          formStep,
          saving,
          stepConfiguration,
        } = behavioralAggregationsPage;
        const { logic, lifetime, name, type, conditions } =
          activeAggregation || {};
        const isAdvanced = logicOptionsByType[type]
          ?.find(({ value }) => value === logic)
          ?.display.includes('(for a specific event or group of events)');
        const logicOptions = logicOptionsByType[activeAggregation.type] || [];
        const isDuplicatedAggregation = !!aggregations.find(
          (aggregation: BehavioralAggregation) => {
            return (
              aggregation.lifetime === lifetime &&
              aggregation.logic === logic &&
              aggregation.name === name &&
              aggregation.type === type &&
              (!isAdvanced ||
                (isAdvanced && isEqual(aggregation.conditions, conditions)))
            );
          }
        );

        return (
          <>
            <PageHeader>
              <Breadcrumb className="fs-5">
                <Breadcrumb.Item
                  href={`/tenant/${this.props.match.params.tenant}/aggregations`}
                >
                  Behavioral aggregations
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="fw-bold">
                  Create new aggregation
                </Breadcrumb.Item>
              </Breadcrumb>
            </PageHeader>

            <div className="mt-3 mb-3">
              {error && <Error message={error} />}
              {isDuplicatedAggregation && !saving && (
                <Error message="This aggregation already exists!" />
              )}
              <Row>
                <Col xs={{ span: 6, offset: 3 }}>
                  <Card>
                    <Card.Body>
                      {formStep >= 1 && <StepOne />}
                      {formStep >= 2 && <StepTwo logicOptions={logicOptions} />}
                      {formStep >= 3 && <StepThree />}
                      {formStep >= 4 && isAdvanced && <StepFour />}
                      {formStep >= 5 && (
                        <StepFive
                          isAdvanced={isAdvanced}
                          updateIsFormInvalid={this.updateIsFormInvalid}
                          isFormInvalid={isFormInvalid}
                        />
                      )}
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={this.back}
                          variant="outline-primary"
                          className="me-3"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={
                            (!stepConfiguration[formStep].validated &&
                              formStep < 5) ||
                            saving ||
                            isDuplicatedAggregation ||
                            isFormInvalid
                          }
                          onClick={this.save}
                        >
                          {this.getSaveButtonText()}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(NewBehavioralAggregationPage);
