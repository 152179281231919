import { makeObservable, observable } from 'mobx';

import { LtbDatasetVariables } from '../audience/ltb/LtbDataset';
import GenericDatasetPageModel from './GenericDatasetPageModel';

export default class PqlPageModel extends GenericDatasetPageModel {
  variables: LtbDatasetVariables;

  audiences: string[];

  conversionNames: string[];

  constructor(other?: PqlPageModel) {
    super();
    if (other) {
      this.variables = other.variables;
      this.audiences = other.audiences;
      this.conversionNames = other.conversionNames;
    } else {
      this.variables = {
        trainingDates: '',
        evaluationDate: '',
        trainingConversionModel: '',
        validationConversionModel: '',
        trainingAudience: 'all',
        validationAudience: 'all',
        rebalancingRatio: 0,
        customerFitIncluded: '',
        metaEventExclusionList: '',
        validation_remove_any_customer_email: true,
        training_remove_any_customer_email: true,
      };
      this.audiences = ['all'];
      this.conversionNames = [];
    }
    makeObservable(this, {
      variables: observable,
      audiences: observable,
    });
  }
}
