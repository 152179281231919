import axios from 'axios';
import { makeObservable, observable } from 'mobx';
import DataSetOverviews from './DataSetOverviews';

/**
 * DataPageModel is the class that manages the data page model(contains observable variables)
 */
export class DataPageModel {
  overviews: DataSetOverviews;

  loadingOverviews: boolean;

  overviewsError: string;

  constructor(other?: DataPageModel) {
    if (other) {
      this.overviews = other.overviews;
      this.loadingOverviews = other.loadingOverviews;
      this.overviewsError = other.overviewsError;
    } else {
      this.overviews = null;
      this.loadingOverviews = false;
      this.overviewsError = null;
    }
    makeObservable(this, {
      overviews: observable,
      loadingOverviews: observable,
      overviewsError: observable,
    });
  }

  async get(tenant: number, modelId: number) {
    this.loadingOverviews = true;

    try {
      const { data } = await axios.get<DataSetOverviews>(
        `/api/tenant/${tenant}/model/${modelId}/data`
      );
      this.overviews = new DataSetOverviews(data.overviews);
    } catch (err) {
      this.overviewsError = err?.response?.data?.message;
    }
  }
}
