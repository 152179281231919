/* eslint-disable max-classes-per-file */
import { action, makeObservable, observable } from 'mobx';
import { Ensemble } from '../ensembling';
import { LeadBehavioralModelType, ModelType } from '../ModelItem';
import { Condition } from '../sqlform';
import axios from 'axios';
import IHttpException from '../errors/IHttpException';

// eslint-disable-next-line
export type Should = 'should at most be' | 'should at least be' | 'should be';

export type SegmentCustomerFit = 'low' | 'medium' | 'good' | 'very good';
export type SegmentMqa = 'low' | 'medium' | 'high' | 'very high';
export type OPTIONS_MAPPER_SUBJECT_VALUES =
  | 'meta_event'
  | 'is_positive_user_activity'
  | 'activity_type';
export type OPTIONS_MAPPER_SUBJECT_LABELS =
  | 'MK Event name'
  | 'Is positive user activity'
  | 'Activity type';
export const OPTIONS_MAPPER_SUBJECT_VALUES_TO_LABELS: Record<
  OPTIONS_MAPPER_SUBJECT_VALUES,
  OPTIONS_MAPPER_SUBJECT_LABELS
> = {
  meta_event: 'MK Event name',
  is_positive_user_activity: 'Is positive user activity',
  activity_type: 'Activity type',
};

export class Rule {
  // index is only used for diagnosis purpose
  // do not use elsewhere
  index?: number;

  ruleType: 'form' | 'advanced';

  sqlConditions?: string;

  formConditions?: Condition[];

  rule: Should;

  segment: SegmentCustomerFit | SegmentMqa;

  // rule can't be deleted
  permanent?: boolean;

  constructor() {
    makeObservable(this, {
      ruleType: observable,
      sqlConditions: observable,
      formConditions: observable,
      rule: observable,
      segment: observable,
    });
  }
}

// Extract enumerated literal values
export const shoulds: Should[] = [
  'should at most be',
  'should at least be',
  'should be',
];
export const segments: Record<
  Exclude<ModelType, LeadBehavioralModelType>,
  SegmentCustomerFit[] | SegmentMqa[]
> = {
  customer_fit: ['low', 'medium', 'good', 'very good'],
  mqa: ['low', 'medium', 'high', 'very high'],
};

export interface IRulePageModel {
  rules: Rule[];

  loading: boolean;

  loadingError: IHttpException;

  saved: boolean;

  saving: boolean;

  savingError: IHttpException;

  hasJustRearranged: boolean;

  loadingEnsemble: boolean;

  ensemble: Ensemble;
}

export class RulePageModel implements IRulePageModel {
  rules: Rule[];

  loading: boolean;

  loadingError: IHttpException;

  saved: boolean;

  saving: boolean;

  savingError: IHttpException;

  hasJustRearranged: boolean;

  loadingEnsemble: boolean;

  ensemble: Ensemble;

  constructor(other?: RulePageModel) {
    if (other) {
      this.ensemble = other.ensemble;
      this.rules = other.rules;
      this.saved = other.saved;
      this.loading = other.loading;
      this.loadingError = other.loadingError;
      this.saving = other.saving;
      this.savingError = other.savingError;
      this.loadingEnsemble = other.loadingEnsemble;
      this.hasJustRearranged = other.hasJustRearranged;
    } else {
      this.ensemble = new Ensemble(null, null);
      this.rules = [];
      this.saved = true;
      this.loading = false;
      this.loadingError = null;
      this.saving = false;
      this.savingError = null;
      this.loadingEnsemble = false;
      this.hasJustRearranged = true;
    }
    makeObservable(this, {
      rules: observable,
      loading: observable,
      loadingError: observable,
      saved: observable,
      saving: observable,
      savingError: observable,
      hasJustRearranged: observable,
      loadingEnsemble: observable,
      ensemble: observable,
      save: action,
      get: action,
    });
  }

  async get({ tenant, modelId }: { tenant: number; modelId: number }) {
    this.loading = true;
    this.loadingError = null;

    try {
      const { data } = await axios.get(
        `/api/tenant/${tenant}/model/${modelId}/overrides`
      );
      this.rules = data;
    } catch (err) {
      this.loadingError = err?.response?.data ?? {
        message: 'Unknown error.',
        type: 'other',
        status: 500,
      };
    }
    this.loading = false;
  }

  async save({ tenant, modelId }: { tenant: number; modelId: number }) {
    this.saving = true;
    this.savingError = null;
    let error = false;

    try {
      await axios.put(`/api/tenant/${tenant}/model/${modelId}/overrides`, {
        overrides: this.rules,
      });
    } catch (err) {
      this.savingError = err?.response?.data ?? {
        message: 'Unknown error.',
        type: 'other',
        status: 500,
      };
      error = true;
    }
    this.saving = false;
    this.saved = !error;
  }
}
