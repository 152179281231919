import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, Nav } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import ModelBaseNames from '../../../models/modelBase/ModelBaseNames';
import Store from '../../../store';
import SmoothingPage from './SmoothingPage';
import ScoringRulesPage from './ScoringRulesPage';
import ModelBaseChoicePage from '../ModelBaseChoicePage';
import EnsemblingPage from './EnsemblingPage';
import OverridesPage from './OverridesPage';
import SignalsPage from './SignalsPage';
import TreesPage from './TreesPage';
import NodeLeadsPage from './NodeLeadsPage';
import NodeUnivariatePage from './NodeUnivariatePage';
import BehavioralEnsemblingPage from './BehavioralEnsemblingPage';
import LtbSignalsPage from './LtbSignalsPage';
import LeadGradePage from './LeadGradePage';
import FeaturesPage from './FeaturesPage';

type MatchParams = {
  tenant: string;
  modelId: string;
};

interface ModelDetailsPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

type ModelDetailsPageState = {
  isAdvancedMode: boolean;
};

const ModelDetailsPage = inject('store')(
  observer(
    class ModelDetailsPage extends React.Component<
      ModelDetailsPageProps,
      ModelDetailsPageState
    > {
      constructor(props: ModelDetailsPageProps) {
        super(props);

        this.state = {
          isAdvancedMode: false,
        };
        this.toggleAdvancedMode = this.toggleAdvancedMode.bind(this);
      }

      toggleAdvancedMode() {
        this.setState({
          isAdvancedMode: !this.state.isAdvancedMode,
        });
      }

      render() {
        const { path, params } = this.props.match;
        const { tenant, modelId } = params;

        const basePath = `/tenant/${tenant}/models/${modelId}/model`;
        const { isAdvancedMode } = this.state;
        const { activeModel } = this.props.store;
        const { base: modelBase, type: modelType } = activeModel;

        const isCustomerFitModel = modelType === 'customer_fit';
        const isTreeBasedModel = modelBase === ModelBaseNames.trees;

        const getDefaultRoute = () => {
          if (!isCustomerFitModel) {
            return `${basePath}/event_weights`;
          }
          if (isAdvancedMode) {
            return `${basePath}/base`;
          }
          if (isTreeBasedModel) {
            return `${basePath}/trees`;
          }
          return `${basePath}/rules`;
        };

        return (
          <>
            <Nav className="third-level-nav model-details bg-white">
              {isCustomerFitModel ? (
                <>
                  {isAdvancedMode && (
                    <Nav.Link
                      className="text-dark"
                      as={NavLink}
                      to={`${basePath}/base`}
                    >
                      Model Base
                    </Nav.Link>
                  )}
                  {isTreeBasedModel && (
                    <Nav.Link
                      className="text-dark"
                      as={NavLink}
                      to={`${basePath}/trees`}
                    >
                      Trees
                    </Nav.Link>
                  )}
                  {(!isTreeBasedModel ||
                    (isAdvancedMode && isTreeBasedModel)) && (
                    <Nav.Link
                      className="text-dark"
                      as={NavLink}
                      to={`${basePath}/rules`}
                    >
                      Rules
                    </Nav.Link>
                  )}
                </>
              ) : (
                <Nav.Link
                  className="text-dark"
                  as={NavLink}
                  to={`${basePath}/event_weights`}
                >
                  Event Weights
                </Nav.Link>
              )}
              <Nav.Link
                className="text-dark"
                as={NavLink}
                to={`${basePath}/thresholds`}
              >
                Thresholds
              </Nav.Link>
              {isCustomerFitModel || modelType === 'mqa' ? (
                <Nav.Link
                  className="text-dark"
                  as={NavLink}
                  to={`${basePath}/overrides`}
                >
                  Overrides
                </Nav.Link>
              ) : (
                <Nav.Link
                  className="text-dark"
                  as={NavLink}
                  to={`${basePath}/lead_grade`}
                >
                  Lead grade
                </Nav.Link>
              )}
              <Nav.Link
                className="text-dark"
                as={NavLink}
                to={`${basePath}/signals`}
              >
                Signals
              </Nav.Link>
              {activeModel.type === 'customer_fit' && (
                <Form className="ms-auto me-4 my-auto">
                  <Form.Switch
                    onChange={this.toggleAdvancedMode}
                    id="advanced-switch"
                    label="Advanced"
                    checked={this.state.isAdvancedMode}
                  />
                </Form>
              )}
            </Nav>
            <Switch>
              <Route path={`${path}/base`}>
                <ModelBaseChoicePage />
              </Route>
              <Route path={`${path}/trees/:treeId/nodes/:nodeId/leads/:target`}>
                <NodeLeadsPage />
              </Route>
              <Route path={`${path}/trees/:treeId/nodes/:nodeId/insights`}>
                <NodeUnivariatePage />
              </Route>
              {isTreeBasedModel && (
                <Route path={`${path}/trees`}>
                  <TreesPage />
                </Route>
              )}
              <Route path={`${path}/rules`}>
                {isTreeBasedModel ? <SmoothingPage /> : <ScoringRulesPage />}
              </Route>
              <Route path={`${path}/event_weights`}>
                <FeaturesPage />
              </Route>
              <Route path={`${path}/thresholds`}>
                {isCustomerFitModel ? (
                  <EnsemblingPage />
                ) : (
                  <BehavioralEnsemblingPage />
                )}
              </Route>
              <Route path={`${path}/overrides`}>
                <OverridesPage />
              </Route>
              <Route path={`${path}/lead_grade`}>
                <LeadGradePage />
              </Route>
              <Route path={`${path}/signals`}>
                {isCustomerFitModel ? <SignalsPage /> : <LtbSignalsPage />}
              </Route>
              <Route path={path}>
                <Redirect to={getDefaultRoute()} />
              </Route>
            </Switch>
          </>
        );
      }
    }
  )
);

export default withRouter(ModelDetailsPage);
