import React from 'react';
import {
  Alert,
  Button,
  FormCheck,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  Condition,
  getVerbSettings,
  getVerbsSettings,
  SqlForm,
  Subject,
} from '../../models/sqlform';
import MadSelect from './generic_components/select_component/MadSelect';

interface ConditionsFormProps {
  sqlForm: SqlForm;
  setConditionLogic: (logic: string) => void;
  handleSubjectSelect: (subject: Subject) => void;
  handleSubjectUnselect: (condition: Condition) => void;
  handleVerbChange: (value: string, conditionIndex: number) => void;
  handleComplementChange: (
    value: string,
    conditionIndex: number,
    complementIndex: number,
    error: boolean
  ) => void;
  possibleSubjects: Subject[];
  handleAddComplement: (conditionIndex: number) => void;
  handleRemoveComplement: (
    conditionIndex: number,
    complementIndex: number
  ) => void;
  handleLower: (conditionIndex: number) => void;
}

interface ConditionsFormState {
  hasUpdatedLogic: boolean;
}

const verbsSettings = getVerbsSettings();

export default class ConditionsForm extends React.Component<
  ConditionsFormProps,
  ConditionsFormState
> {
  constructor(props: ConditionsFormProps) {
    super(props);
    this.handleSubjectSelect = this.handleSubjectSelect.bind(this);
    this.handleLogicChange = this.handleLogicChange.bind(this);
    this.handleComplementChange = this.handleComplementChange.bind(this);
    this.handleAddComplement = this.handleAddComplement.bind(this);
    this.handleRemoveComplement = this.handleRemoveComplement.bind(this);
    this.handleLower = this.handleLower.bind(this);
    this.state = { hasUpdatedLogic: false };
  }

  componentDidUpdate() {
    const { sqlForm, setConditionLogic } = this.props;
    const { hasUpdatedLogic } = this.state;
    let defaultConditionLogic = '';
    sqlForm.conditions.forEach((_condition, index) => {
      defaultConditionLogic +=
        index > 0 ? ` AND $${index + 1}` : `$${index + 1}`;
    });
    if (!hasUpdatedLogic) {
      setConditionLogic(defaultConditionLogic);
    }
  }

  handleSubjectSelect(value: string) {
    const {
      sqlForm: { conditions, conditionLogic },
      handleSubjectSelect,
      setConditionLogic,
      possibleSubjects,
    } = this.props;
    const subject = possibleSubjects.find(
      ({ subject: aSubject }) => aSubject === value
    );
    handleSubjectSelect(subject);
    const newConditionLogic = `${conditionLogic} AND $${conditions.length}`;
    setConditionLogic(newConditionLogic);
  }

  handleLogicChange(value: string) {
    this.setState({ hasUpdatedLogic: true });
    this.props.setConditionLogic(value.toUpperCase());
  }

  handleComplementChange(
    value: string,
    conditionIndex: number,
    complementIndex: number
  ) {
    const condition = this.props.sqlForm.conditions[conditionIndex];
    const error = condition.subject.type === 'number' && isNaN(Number(value));
    this.props.handleComplementChange(
      value,
      conditionIndex,
      complementIndex,
      error
    );
  }

  handleAddComplement(conditionIndex: number) {
    this.props.handleAddComplement(conditionIndex);
  }

  handleRemoveComplement(conditionIndex: number, complementIndex: number) {
    this.props.handleRemoveComplement(conditionIndex, complementIndex);
  }

  handleLower(conditionIndex: number) {
    this.props.handleLower(conditionIndex);
  }

  render() {
    const {
      handleVerbChange,
      handleSubjectUnselect,
      sqlForm,
      possibleSubjects,
    } = this.props;

    const conditionLogicTip = (
      <Tooltip id="conditionLogicTooltip">
        You can use parenthesis. <br /> eg: <code>$1 AND ($2 OR $3)</code>
      </Tooltip>
    );

    return (
      <>
        {sqlForm.conditions.length ? (
          <Form className="mb-4">
            <FormLabel>Edit condition logic</FormLabel>
            <OverlayTrigger placement="bottom" overlay={conditionLogicTip}>
              <FormControl
                type="text"
                value={sqlForm.conditionLogic}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleLogicChange(event.target.value)
                }
                placeholder="$1 AND ($2 OR $3)"
                name="conditionLogic"
                className="w-50"
              />
            </OverlayTrigger>
          </Form>
        ) : (
          <Alert variant="info">No conditions yet.</Alert>
        )}
        {sqlForm.conditions.map((condition, conditionIndex) => {
          const verbSettings = getVerbSettings(condition.verb);

          return (
            <FormGroup key={`form_group_${conditionIndex}`} className="mb-3">
              <Row>
                <Col xs={5}>
                  <span className="opacity-50 me-3">${conditionIndex + 1}</span>
                  <span>{condition.subject.subject}</span>
                  {condition.subject.type === 'string' && (
                    <FormCheck
                      type="checkbox"
                      checked={condition.lower}
                      onChange={() => this.handleLower(conditionIndex)}
                      label="Case-insensitive"
                    />
                  )}
                </Col>
                <Col xs={3}>
                  <Form.Select
                    name="verb"
                    as="select"
                    value={condition.verb}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      handleVerbChange(event.target.value, conditionIndex)
                    }
                  >
                    {verbsSettings
                      .filter(({ characteristics: { types } }) =>
                        types.includes(condition.subject.type)
                      )
                      .map(({ value }) => (
                        <option key={value}>{value}</option>
                      ))}
                  </Form.Select>
                </Col>
                <Col xs={3}>
                  {condition.complements.map((complement, complementIndex) =>
                    condition.complements.length >
                    verbSettings.characteristics.min ? (
                      <InputGroup
                        style={{ marginBottom: '5px' }}
                        key={`compl_${complementIndex}`}
                      >
                        <FormControl
                          type="text"
                          value={complement}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            this.handleComplementChange(
                              event.target.value,
                              conditionIndex,
                              complementIndex
                            )
                          }
                        />
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id="right">Remove the value</Tooltip>
                          }
                        >
                          <Button
                            onClick={() =>
                              this.handleRemoveComplement(
                                conditionIndex,
                                complementIndex
                              )
                            }
                            variant="danger"
                          >
                            <i aria-hidden className="fas fa-trash" />
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                    ) : (
                      <FormControl
                        style={{ marginBottom: '5px' }}
                        key={`compl_${complementIndex}`}
                        type="text"
                        value={complement}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          this.handleComplementChange(
                            event.target.value,
                            conditionIndex,
                            complementIndex
                          )
                        }
                      />
                    )
                  )}
                  {verbSettings.characteristics.max >
                  condition.complements.length ? (
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip id="tooltip">Add new value</Tooltip>}
                    >
                      <Button
                        onClick={() => this.handleAddComplement(conditionIndex)}
                        className="pull-right"
                      >
                        <i aria-hidden className="fas fa-plus" />
                      </Button>
                    </OverlayTrigger>
                  ) : null}
                </Col>
                <Col xs={1}>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip">Remove the condition</Tooltip>
                    }
                  >
                    <Button
                      onClick={() => handleSubjectUnselect(condition)}
                      className="pull-right"
                      variant="danger"
                    >
                      <i aria-hidden className="fas fa-trash" />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </FormGroup>
          );
        })}
        <MadSelect
          isSearchable
          value={null}
          options={possibleSubjects.map(({ subject }) => ({
            value: subject,
            label: subject,
          }))}
          onChange={({ value }) => {
            this.handleSubjectSelect(value);
          }}
          placeholder="Add a condition"
        />
      </>
    );
  }
}
