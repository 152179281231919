import { CsvFileTrainingVariables } from './CsvFileDataset';
import DataSetRecord from './DataSetRecord';
import DataSetType from './DataSetType';
import { FromRedshiftTablesTrainingVariables } from './FromRedshiftTables';
import { LtbDatasetVariables } from './ltb/LtbDataset';
import { MkAudienceTrainingVariables } from './mk_audience/MkAudienceDataset';

export type TrainingDatasetVariables =
  | LtbDatasetVariables
  | MkAudienceTrainingVariables
  | FromRedshiftTablesTrainingVariables
  | CsvFileTrainingVariables;

export type AllTrainingDatasetVariables = {
  ltb: LtbDatasetVariables;
};

type DataSetContent = {
  redshiftQuery?: string;
  postgresQuery?: string;
  records?: DataSetRecord[];
};
export default class DataSet {
  type: DataSetType;

  name: string;

  size: number;

  content: DataSetContent;

  constructor(
    type: DataSetType,
    name: string,
    size: number,
    content: DataSetContent
  ) {
    this.type = type;
    this.name = name;
    this.size = size;
    this.content = content;
  }
}
