import { makeObservable, observable } from 'mobx';
import PointBasedConditionDataModel from './PointBasedConditionDataModel';

export default class PointBasedRuleDataModel {
  conditions: PointBasedConditionDataModel[];

  points: number;

  constructor(pointBasedRule: PointBasedRuleDataModel) {
    if (pointBasedRule) {
      this.points = pointBasedRule.points;
      this.conditions = pointBasedRule.conditions;
    } else {
      this.conditions = [];
      this.points = 0;
    }
    makeObservable(this, {
      conditions: observable,
      points: observable,
    });
  }
}
