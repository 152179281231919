import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  Col,
  FormControl,
  Card,
  Row,
  Table,
} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import {
  LeadGradeHelpMatrix,
  LeadGradeValue,
} from '../../../models/lead_grade/LeadGradePageModel';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import {
  compareCustomerFits,
  compareLtb,
  getColorForPercentage,
} from '../../../utils';
import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import ModelPerformanceGraphs from '../../components/ModelPerformanceGraphs';
import Spinner from '../../components/Spinner';

type ReadonlyMatrixProps = {
  name: string;
  matrix: LeadGradeHelpMatrix;
  colored?: boolean;
};

class ReadonlyMatrix extends React.Component<
  ReadonlyMatrixProps,
  Record<string, never>
> {
  constructor(props: ReadonlyMatrixProps) {
    super(props);
    this.getMaxValueFromMatrix = this.getMaxValueFromMatrix.bind(this);
  }

  getMaxValueFromMatrix() {
    // The max value is then used to calculate the background color
    const { matrix } = this.props;
    // eslint-disable-next-line prefer-spread
    const allValues = [].concat
      .apply(
        [],
        Object.values(matrix).map((ltb) => Object.values(ltb))
      )
      .map((v: any) => Number(v));
    return Math.max(...allValues);
  }

  render() {
    const { matrix, name, colored } = this.props;
    const maxValue = colored && this.getMaxValueFromMatrix();
    return (
      <Table hover bordered>
        <thead>
          <tr>
            <th />
            <th>very good</th>
            <th>good</th>
            <th>medium</th>
            <th>low</th>
          </tr>
        </thead>
        <tbody></tbody>
        <tbody>
          {Object.keys(matrix)
            .sort(compareLtb)
            .reverse()
            .map((ltbSegment) => (
              <tr key={`row_${name}_${ltbSegment}`}>
                <td>
                  <b>{ltbSegment}</b>
                </td>
                {Object.keys(matrix[ltbSegment])
                  .sort(compareCustomerFits)
                  .reverse()
                  .map((fitSegment) => {
                    return (
                      <td
                        key={`col_${name}_${ltbSegment}_${fitSegment}`}
                        style={
                          colored && {
                            backgroundColor: getColorForPercentage(
                              (matrix[ltbSegment][fitSegment] * 100) / maxValue
                            ),
                          }
                        }
                      >
                        {matrix[ltbSegment][fitSegment]}
                      </td>
                    );
                  })}
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

interface LeadGradePageProps {
  store?: Store;
}

export default inject('store')(
  observer(
    class LeadGradePage extends React.Component<LeadGradePageProps> {
      detailsRef = React.createRef<HTMLDivElement>();

      constructor(props: LeadGradePageProps) {
        super(props);

        makeObservable(this, {
          handleFormulaFitChange: action,
          handleFormulaLtbChange: action,
          handleMatrixChange: action,
        });

        this.handleFormulaFitChange = this.handleFormulaFitChange.bind(this);
        this.handleFormulaLtbChange = this.handleFormulaLtbChange.bind(this);
        this.handleMatrixChange = this.handleMatrixChange.bind(this);
        this.calculateLeadGradePerformance =
          this.calculateLeadGradePerformance.bind(this);
      }

      async componentDidMount() {
        this.props.store.leadGradePage.loadingConf = true;
        await Promise.all([
          this.props.store.getLeadGradeConfiguration(),
          this.props.store.getLeadGradeHelpMatrixes(),
        ]);
        this.props.store.leadGradePage.loadingConf = false;
      }

      handleFormulaFitChange(e: any) {
        this.props.store.leadGradePage.configuration.formula.fit =
          e.target.value;
      }

      handleFormulaLtbChange(e: any) {
        this.props.store.leadGradePage.configuration.formula.ltb =
          e.target.value;
      }

      handleMatrixChange(
        ltbSegment: string,
        fitSegment: string,
        value: LeadGradeValue
      ) {
        this.props.store.leadGradePage.configuration.matrix[ltbSegment][
          fitSegment
        ] = value;
      }

      async calculateLeadGradePerformance() {
        await this.props.store.calculateLeadGradePerformance({
          isProcessing: false,
        });
      }

      scrollToDetails = () => {
        this.detailsRef.current?.scrollIntoView({ behavior: 'smooth' });
      };

      render() {
        const { leadGradePage, activeModel, isAllowedToEdit } =
          this.props.store;
        const {
          configuration,
          performances,
          helpMatrixes,
          loadingConf,
          loadingPerformance,
          error,
        } = leadGradePage;

        if (loadingConf || !configuration || !helpMatrixes) {
          return <Spinner />;
        }

        const { matrix } = configuration;
        const { type: modelType } = activeModel;

        if (modelType === 'mqa') {
          return (
            <Alert variant="info">
              Lead Grade is only available for Lead Likelihood to Buy models.
            </Alert>
          );
        }

        return (
          <div className="mx-auto d-grid gap-2 w-75 mt-4 mb-3">
            <Card border="light">
              <Card.Body>
                <Card.Text>
                  Configure the Lead Grade segments based on the combination of
                  the Customer Fit segments and the Likelihood to Buy segments.
                  <br />
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4403971397005-Lead-Grade"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none text-primary"
                  >
                    <i aria-hidden className="fas fa-book-open"></i> What is the
                    Lead Grade?
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card border="light">
              <Card.Body>
                <Card.Title>Lead Grade Matrix</Card.Title>
                <p>
                  Configure the Lead Grade segments based on the combination of
                  the Customer Fit segments and the Likelihood to Buy segments{' '}
                </p>
                <Row>
                  <Col xs={{ span: 3, offset: 5 }}>
                    <h4>Customer Fit </h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1}>
                    <Row>
                      <Col xs={12}>
                        <div>
                          <h4 className="vertical-text ms-3 mt-5">
                            Likelihood to Buy
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={11}>
                    <Row className="ms-4">
                      <Table className="w-75">
                        <thead>
                          <tr>
                            <th />
                            <th className="text-center">Very good</th>
                            <th className="text-center">Good</th>
                            <th className="text-center">Medium</th>
                            <th className="text-center">Low</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(matrix)
                            .sort(compareLtb)
                            .reverse()
                            .map((ltbSegment) => (
                              <tr key={`row_${ltbSegment}`}>
                                <td>
                                  <b>{ltbSegment}</b>
                                </td>
                                {Object.keys(matrix[ltbSegment])
                                  .sort(compareCustomerFits)
                                  .reverse()
                                  .map((fitSegment) => {
                                    return (
                                      <td
                                        key={`col_${ltbSegment}_${fitSegment}`}
                                      >
                                        <FormControl
                                          type="text"
                                          value={matrix[ltbSegment][fitSegment]}
                                          disabled={!isAllowedToEdit}
                                          pattern="[A-E]"
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            this.handleMatrixChange(
                                              ltbSegment,
                                              fitSegment,
                                              e.target.value as LeadGradeValue
                                            )
                                          }
                                        />
                                      </td>
                                    );
                                  })}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {checkPermissions(
              PROFILES_PERMISSIONS.ARCHITECT,
              <Button
                onClick={this.calculateLeadGradePerformance}
                variant="primary"
                className="mb-2 mt-2 w-25"
              >
                Save {'&'} Compute performance
              </Button>
            )}
            <>
              <Error message={error} />
              {loadingPerformance && <Spinner />}
              {performances && (
                <>
                  <ModelPerformanceGraphs
                    performances={performances}
                    context="lead_grade"
                    colWidth={6}
                  />
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>More details</Accordion.Header>
                      <Accordion.Body
                        onEntered={() => {
                          this.scrollToDetails();
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <Card border="light">
                              <Card.Body>
                                <Card.Title ref={this.detailsRef}>
                                  Conversion rate per segment
                                </Card.Title>
                                <Row className="mt-4">
                                  <Col xs={12}>
                                    <h5 className="text-center">
                                      Customer Fit{' '}
                                    </h5>
                                    <Row>
                                      <Col xs={2}>
                                        <div>
                                          <h5 className="vertical-text mt-4 ms-3">
                                            Likelihood to Buy
                                          </h5>
                                        </div>
                                      </Col>
                                      <Col xs={10}>
                                        <ReadonlyMatrix
                                          matrix={
                                            helpMatrixes.averageConversionRate
                                          }
                                          name="averageConversionRate"
                                          colored
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <p>
                                  Number of converted leads per segment divided
                                  by the total number of leads in the segment in
                                  the validation dataset
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xs={6}>
                            <Card border="light">
                              <Card.Body>
                                <Card.Title>
                                  Distribution of Leads per segment
                                </Card.Title>
                                <Row className="mt-4">
                                  <Col xs={12}>
                                    <h5 className="text-center">
                                      Customer Fit{' '}
                                    </h5>
                                    <Row>
                                      <Col xs={2}>
                                        <div>
                                          <h5 className="vertical-text mt-4 ms-3">
                                            Likelihood to Buy
                                          </h5>
                                        </div>
                                      </Col>
                                      <Col xs={10}>
                                        <ReadonlyMatrix
                                          matrix={helpMatrixes.sumPopulation}
                                          name="sumPopulation"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <p>
                                  Number of leads per segment divided by the
                                  total population in the validation dataset
                                </p>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}
            </>
          </div>
        );
      }
    }
  )
);
