import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { PROFILES_PERMISSIONS } from '../../permissions';
import Store from '../../store';
import checkPermissions from './CheckPermissions';
import Spinner from './Spinner';

interface ModelNotesComponentProps {
  store?: Store;
}

interface ModelNotesComponentState {
  modelNoteValue: string;
  isLoading: boolean;
}

export default inject('store')(
  observer(
    class ModelNotesComponent extends React.Component<
      ModelNotesComponentProps,
      ModelNotesComponentState
    > {
      constructor(props: ModelNotesComponentProps) {
        super(props);
        this.state = { modelNoteValue: '', isLoading: true };
        this.onNoteChange = this.onNoteChange.bind(this);
        this.onSubmitModelNote = this.onSubmitModelNote.bind(this);
      }

      async componentDidMount() {
        const { activeModel } = this.props.store;

        this.setState({
          modelNoteValue: (activeModel && activeModel.notes) || '',
          isLoading: !this.state.isLoading,
        });
      }

      onNoteChange(event: any) {
        this.setState({ modelNoteValue: event.target.value });
      }

      async onSubmitModelNote() {
        await this.props.store.saveModelNotes(this.state.modelNoteValue);
      }

      render() {
        const {
          tenantModels,
          isAllowedToEdit,
          rootPage: { updatingModelNotes },
        } = this.props.store;
        const { modelNoteValue } = this.state;
        if (!tenantModels || !tenantModels.length) return <div />;

        return (
          <>
            {this.state.isLoading ? (
              <>
                <Spinner />
                <div className="text-center">Loading model notes...</div>
              </>
            ) : (
              <>
                <Form>
                  <Form.Group className="mb-3" controlId="modelNotes">
                    <Form.Label className="fw-bold">Model notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      onChange={this.onNoteChange}
                      value={modelNoteValue}
                      disabled={updatingModelNotes || !isAllowedToEdit}
                    />
                  </Form.Group>
                </Form>
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,
                  <Button
                    onClick={this.onSubmitModelNote}
                    variant="primary"
                    disabled={updatingModelNotes || !isAllowedToEdit}
                  >
                    {updatingModelNotes ? 'Saving...' : 'Save'}
                  </Button>
                )}
              </>
            )}
          </>
        );
      }
    }
  )
);
