import React, { Fragment, useState, useRef } from 'react';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import Store from '../../../store';
import { Transition } from '@headlessui/react';
import { Models } from '../../../models/create/ModelsList';
import { Model, labelsColors } from '../../../models/create/ModelType';
import { ModelCard } from './ModelCard';
import PageHeader from '../../components/PageHeader';
import BackBtn from './../../assets/images/icons/back_btn.svg';
import LoadingIcon from './../../assets/images/icons/load.svg';
import ArrowRight from './../../assets/images/icons/arrow_right.svg';
import ArrowLeft from './../../assets/images/icons/arrow_left.svg';
import { IS_HYBRID } from '../../../utils/constants';

type MatchParams = {
  tenant: string;
};
interface NewModelPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

function NewModelPage({ store, match }: NewModelPageProps) {
  const [showPanel, setShowPanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [currentModel, setCurrentModel] = useState<Model>(Models[0] as Model);
  const [name, setName] = useState<string>('');
  const [formSubmittedOnce, setFormSubmittedOnce] = useState<boolean>(false);

  const nameRef = useRef<HTMLInputElement>(null);

  const { tenant } = store;

  const getIntercomIcon = () =>
    document.querySelector('.intercom-lightweight-app') as HTMLElement;

  const openPanel = (model: Model) => {
    const intercomIcon = getIntercomIcon();
    if (intercomIcon) intercomIcon.style.zIndex = '-1';
    setCurrentModel(model);
    setShowPanel(true);
    setFormSubmittedOnce(false);
    setStep(1);
  };

  const closePanel = () => {
    getIntercomIcon()?.removeAttribute('style');
    setShowPanel(false);
  };

  const createNewModelAndRedirect = async () => {
    setLoading(true);
    const newModelId: number = await store.rootPage.createModel(tenant, {
      name: name,
      type: currentModel.type,
    });

    window.location.href = `/tenant/${match.params.tenant}/models/${newModelId}`;
  };

  const goToPreviousStep = () => {
    if (step === 2) setStep(1);
    else if (step === 1) closePanel();
  };

  const goToNextStep = () => {
    if (step === 1) setStep(2);
    else if (step === 2) {
      setFormSubmittedOnce(true);
      if (name) {
        createNewModelAndRedirect();
      }
    }
  };

  const models = Models as Model[];

  return (
    <>
      <PageHeader>
        <div className="tw-flex">
          <div>
            <Nav.Link
              as={NavLink}
              to={`/tenant/${tenant}/models`}
              className="tw-mr-3 hover:tw-opacity-60"
            >
              <img
                src={BackBtn}
                width={36}
                height={36}
                alt="Back to models"
                title="Back to models"
              />
            </Nav.Link>
          </div>
          <div>
            <h2>Create new model</h2>
            <span className="tw-mb-2 tw-block tw-text-[1.1rem] tw-text-gray-400">
              Start creating a new model by selecting a model type below
            </span>
          </div>
        </div>
      </PageHeader>

      <div className="tw-my-16 tw-mx-auto tw-max-w-7xl">
        <div className="tw-grid tw-grid-cols-1 tw-gap-8 lg:tw-grid-cols-2 xl:tw-grid-cols-3">
          {models.map((model: Model, i: number) => (
            <ModelCard key={i} openPanel={openPanel} model={model} />
          ))}
        </div>

        <Transition
          show={showPanel}
          as={Fragment}
          enter="tw-transition tw-duration-400 tw-ease-in-out"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
        >
          <div
            className="tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-bg-gray-400 tw-bg-opacity-70 tw-shadow-md"
            onClick={() => closePanel()}
          ></div>
        </Transition>

        <Transition
          show={showPanel}
          as={Fragment}
          enter="tw-transition tw-duration-300 tw-ease-in-out"
          enterFrom="tw-translate-x-full tw-opacity-100"
          enterTo="tw-translate-x-0 tw-opacity-100"
        >
          <div className="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-flex tw-w-[490px] tw-translate-x-0 tw-flex-col tw-justify-between tw-overflow-hidden tw-bg-white tw-opacity-100 tw-shadow-md">
            <div className="tw-flex-grow tw-overflow-y-auto">
              <div className="tw-relative tw-flex tw-h-32 tw-w-full tw-justify-center tw-overflow-hidden tw-bg-gray-200">
                <span className="tw-translate-y-8 tw-scale-150 tw-opacity-30">
                  {currentModel.illus}
                </span>
              </div>
              <div className="tw-relative tw-p-7">
                <Transition
                  show={step === 1}
                  enter="tw-transition tw-duration-200 tw-ease-in-out"
                  enterFrom="-tw-translate-x-1/4 tw-opacity-0"
                  enterTo="tw-translate-x-0 tw-opacity-100"
                  leave="tw-transition tw-duration-200 tw-ease-in-out"
                  leaveFrom="tw-translate-x-0 tw-opacity-100"
                  leaveTo="-tw-translate-x-1/4 tw-opacity-0"
                >
                  <div className="tw-relative">
                    <div className="tw-absolute tw-w-full">
                      {IS_HYBRID(currentModel.type) && (
                        <div className="tw-mb-2">
                          <span className="tw-inline-block tw-rounded-full tw-bg-[#80E6DC] tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-text-green-900">
                            Beta
                          </span>
                          <span className="tw-ml-2 tw-inline-block tw-text-sm tw-text-gray-500">
                            This model is in beta
                          </span>
                        </div>
                      )}
                      <div className="tw-flex tw-items-center">
                        <h2 className="tw-text-[1.65rem] tw-font-semibold tw-text-gray-900 group-hover:tw-opacity-70">
                          {currentModel.name}
                        </h2>
                        <span
                          style={{
                            backgroundColor: labelsColors[currentModel.color],
                          }}
                          className="tw-ml-auto -tw-mt-2 tw-inline-flex tw-h-7 tw-items-center tw-rounded-md tw-px-3 tw-text-sm tw-font-semibold tw-tracking-wide tw-text-white"
                        >
                          {currentModel.model}
                        </span>
                      </div>

                      <p className="tw-text-sm tw-text-gray-500">
                        {currentModel.description}
                      </p>

                      <span className="tw-mt-10 tw-mb-4 tw-block tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200 tw-pb-1 tw-text-[1.3rem] tw-font-semibold tw-text-gray-900">
                        Use cases
                      </span>
                      {currentModel.useCases.map((useCase, index) => (
                        <div key={index} className="tw-mt-6 tw-flex">
                          <div className="tw-mr-4 tw-flex tw-h-12 tw-w-12 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-200 tw-text-gray-500">
                            <img src={useCase.icon} width={24} height={24} />
                          </div>
                          <div>
                            <h3 className="tw-mb-[0.1rem] tw-block tw-text-base tw-font-semibold">
                              {useCase.title}
                            </h3>
                            <p className="tw-text-sm tw-text-gray-500">
                              {useCase.text}
                            </p>
                          </div>
                        </div>
                      ))}
                      {currentModel.more_description && (
                        <>
                          <span className="tw-mt-10 tw-block tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200 tw-pb-1 tw-text-[1.3rem] tw-font-semibold tw-text-gray-900">
                            About this model
                          </span>
                          <p className="tw-mt-4 tw-text-sm tw-text-gray-500">
                            {currentModel.more_description}
                          </p>
                        </>
                      )}

                      {currentModel.output && (
                        <span className="tw-mt-10 tw-mb-4 tw-block tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200 tw-pb-1 tw-text-[1.3rem] tw-font-semibold tw-text-gray-900">
                          Output example
                        </span>
                      )}

                      {currentModel.output && (
                        <img
                          src={currentModel.output}
                          width="263"
                          height="248"
                        />
                      )}
                    </div>
                  </div>
                </Transition>
                <Transition
                  show={step === 2}
                  enter="tw-transition tw-duration-200 tw-ease-in-out"
                  enterFrom="tw-translate-x-1/4 tw-opacity-0"
                  enterTo="tw-translate-x-0 tw-opacity-100"
                  leave="tw-transition tw-duration-200 tw-ease-in-out"
                  leaveFrom="tw-translate-x-0 tw-opacity-100"
                  leaveTo="tw-translate-x-1/4 tw-opacity-0"
                  afterEnter={() => nameRef.current.focus()}
                >
                  <div className="tw-absolute tw-w-full">
                    <h2 className="tw-text-[1.65rem] tw-font-semibold tw-text-gray-900 group-hover:tw-opacity-70">
                      Create new model
                    </h2>

                    <div className="tw-mt-8 tw-flex tw-items-center">
                      <span className="tw-inline-block tw-w-28 tw-shrink-0 tw-whitespace-nowrap">
                        Type:
                      </span>
                      <span className="tw-font-semibold">
                        {currentModel.name}
                      </span>
                    </div>
                    <div className="tw-mt-4 tw-flex tw-items-center">
                      <span className="tw-inline-block tw-w-28 tw-shrink-0 tw-whitespace-nowrap">
                        Name:
                      </span>
                      <input
                        ref={nameRef}
                        className="border rounded tw-w-full tw-p-2 placeholder:tw-text-gray-300"
                        type="text"
                        placeholder="Enter a model name"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setName(event.target.value)}
                      />
                    </div>
                    {formSubmittedOnce && !name && (
                      <div className="tw-mt-3">
                        <span className="tw-inline-block tw-w-28"></span>
                        <span className="tw-bg-red-100 tw-px-4 tw-py-3 tw-text-xs tw-text-red-700">
                          Please fill the model name
                        </span>
                      </div>
                    )}
                  </div>
                </Transition>
              </div>
            </div>
            <div className="tw-flex tw-h-20 tw-w-full tw-flex-shrink-0 tw-items-center tw-justify-between tw-border-0 tw-border-t tw-border-solid tw-border-gray-300 tw-py-2 tw-px-7">
              <button
                onClick={() => goToPreviousStep()}
                className="tw-text-gray tw-relative tw-inline-flex tw-h-11 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-solid !tw-border-gray-200 tw-bg-white tw-px-5 tw-text-sm tw-font-medium tw-shadow hover:tw-bg-gray-50 focus:tw-outline-none"
              >
                {step === 1 && <span>Cancel</span>}
                {step === 2 && (
                  <span className="tw-inline-flex tw-items-center tw-gap-2">
                    <img src={ArrowLeft} width={12} height={12} /> Previous
                  </span>
                )}
              </button>
              <button
                disabled={loading ? true : false}
                style={{
                  pointerEvents: loading ? 'none' : 'auto',
                }}
                onClick={() => goToNextStep()}
                className="border-0 tw-relative tw-inline-flex tw-h-11 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-bg-[#1e77cc] tw-px-5 tw-text-sm tw-font-medium tw-text-white tw-shadow hover:tw-bg-[#1c71c3] focus:tw-outline-none"
              >
                {loading && (
                  <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                    <img src={LoadingIcon} width={20} height={20} />
                  </div>
                )}
                <div
                  className={`tw-flex tw-items-center tw-justify-center`}
                  style={{
                    opacity: loading ? '0' : '1',
                  }}
                >
                  {step === 1 && (
                    <span className="tw-inline-flex tw-items-center tw-gap-2">
                      Next
                      <img src={ArrowRight} width={12} height={12} />
                    </span>
                  )}
                  {step === 2 && <span>Create model</span>}
                </div>
              </button>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}

export default withRouter(inject('store')(observer(NewModelPage)));
