import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  Card,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import DataSetType from '../../../../models/audience/DataSetType';
import InputTableDataSet from '../../../../models/audience/InputTableDataSet';
import Store from '../../../../store';
import { getDatasetParameter } from '../../../../utils';
import { MAX_NUMBER_OF_LOADING_DATASETS } from '../../../../utils/constants';
import Spinner from '../../../components/Spinner';
import Error from '../../../components/error/Error';

interface DatabaseImportPageProps extends RouteComponentProps {
  store?: Store;
}

interface DatabaseImportPagePageState {
  loading: boolean;
  currentLoadingModels: number[];
}

const DatabaseImportPage = inject('store')(
  observer(
    class DatabaseImportPage extends React.Component<
      DatabaseImportPageProps,
      DatabaseImportPagePageState
    > {
      errorMessageRef: React.RefObject<HTMLDivElement>;

      constructor(props: DatabaseImportPageProps) {
        super(props);

        this.state = {
          loading: true,
          currentLoadingModels: [],
        };

        makeObservable(this, {
          handleDataSetChange: action,
        });

        this.handleDataSetChange = this.handleDataSetChange.bind(this);
        this.uploadDataSetsTables = this.uploadDataSetsTables.bind(this);

        this.errorMessageRef = React.createRef();
      }

      async componentDidMount() {
        const { tenant } = this.props.store;
        const currentLoadingModels =
          await this.props.store.mkAudiencePage.getCurrentLoadingModels(tenant);

        this.setState({ loading: false, currentLoadingModels });
      }

      componentDidUpdate(): void {
        const {
          csvPage: { dataSets },
        } = this.props.store;

        if (dataSets.uploadDataSetError) {
          this.errorMessageRef.current.scrollIntoView();
        }
      }

      async uploadDataSetsTables() {
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        await this.props.store.uploadDataSetsFromRedshift(dataset);
        const { tenant, modelId, csvPage } = this.props.store;
        if (!csvPage.dataSets.uploadDataSetError) {
          this.props.history.push(
            `/tenant/${tenant}/model/${modelId}/overview`
          );
        }
      }

      handleDataSetChange(value: string, type: string) {
        this.props.store.csvPage.dataSets.setTableValue(type, value);
      }

      render() {
        const { csvPage, isAllowedToEdit, tenant, modelId, rootPage } =
          this.props.store;
        const { loading, currentLoadingModels } = this.state;
        const { dataSets } = csvPage;
        const { isDataSetLoadingProgress } = rootPage;
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        const tableDataset = dataSets.inputTableDataSets.find(
          (t) => t.type === (dataset || 'training')
        ).table;
        const dataSetsValid = tableDataset && tableDataset.trim().length > 0;

        const getFieldsInput = (field: InputTableDataSet) => {
          return (
            <Row>
              <FormGroup controlId={`field_table_${field.type}`}>
                <Row>
                  <Col md="auto" className="my-auto">
                    <FormLabel className="fw-bold">
                      Redshift table name
                    </FormLabel>
                  </Col>
                  <Col md={3}>
                    <FormControl
                      type="text"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleDataSetChange(event.target.value, field.type)
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Row>
          );
        };

        const cantLoad =
          currentLoadingModels.length >= MAX_NUMBER_OF_LOADING_DATASETS;

        if (loading) {
          return <Spinner />;
        }

        return (
          <>
            <LinkContainer
              to={`/tenant/${tenant}/models/${modelId}/overview`}
              activeClassName=""
            >
              <Button variant="link" className="me-2">
                Go back to Overview
              </Button>
            </LinkContainer>
            <div className="w-75 mt-4 mx-auto d-grid gap-4">
              {dataSets.uploadDataSetError && (
                <div ref={this.errorMessageRef}>
                  <Error
                    message={dataSets.uploadDataSetError.message}
                    type={dataSets.uploadDataSetError.type}
                  />
                </div>
              )}
              <h4>Edit model datasets</h4>
              <Card border="light">
                <Card.Body>
                  <Card.Title>
                    {dataset
                      ? String(dataset).charAt(0).toUpperCase() +
                        dataset.slice(1)
                      : 'Training'}{' '}
                    dataset
                  </Card.Title>
                  <p>
                    The <b>training dataset</b> must contain the following
                    columns
                  </p>
                  <ul>
                    <li>
                      email: contains the email of the record. If using a list
                      of domains append “madkudu@” to the domains.
                    </li>
                    <li>
                      target: value of 1 if the record has converted, 0
                      otherwise
                    </li>
                  </ul>
                  {getFieldsInput(
                    dataSets.inputTableDataSets.find(
                      (t) => t.type === (dataset || 'training')
                    )
                  )}
                </Card.Body>
              </Card>

              {!dataset && (
                <Card>
                  <Card.Body>
                    <Card.Title>Validation dataset</Card.Title>
                    <p>
                      The <b>validation dataset</b> must contain the following
                      columns
                    </p>
                    <ul>
                      <li>
                        email: contains the email of the record. If using a list
                        of domains append “madkudu@” to the domains.
                      </li>
                      <li>
                        target: value of 1 if the record has converted, 0
                        otherwise
                      </li>
                      <li>
                        amount: if the record has converted, value of the
                        opportunity
                      </li>
                      <li>
                        target_closed_won: Values 1 or 0 to identify if the
                        records converted into a Closed Won opportunity
                      </li>
                      <li>
                        amount_closed_won: if converted into a Closed Won
                        opportunity, the amount value of the deal
                      </li>
                    </ul>
                    <p>
                      This will allow evaluating the impact of the model on the
                      revenue pipeline
                    </p>
                    {getFieldsInput(
                      dataSets.inputTableDataSets.find(
                        (t) => t.type === 'validation'
                      )
                    )}
                  </Card.Body>
                </Card>
              )}

              <div className="mx-auto mb-4">
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/import_data`}
                  activeClassName=""
                >
                  <Button variant="outline-primary" className="me-2">
                    Cancel
                  </Button>
                </LinkContainer>

                <OverlayTrigger
                  show={cantLoad}
                  overlay={
                    <Tooltip>
                      {`Model ${currentLoadingModels[0]} and ${currentLoadingModels[1]} are currently loading. Please wait until they both load to begin loading another model`}
                    </Tooltip>
                  }
                >
                  <span>
                    <Button
                      variant="primary"
                      disabled={
                        !dataSetsValid ||
                        isDataSetLoadingProgress ||
                        !isAllowedToEdit ||
                        cantLoad
                      }
                      onClick={this.uploadDataSetsTables}
                      className="pull-right"
                    >
                      {isDataSetLoadingProgress
                        ? `Uploading dataset${!dataset ? 's' : ''}..`
                        : `Upload dataset${!dataset ? 's' : ''}`}
                    </Button>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(DatabaseImportPage);
