import axios from 'axios';

export default class GenericDatasetPageModel {
  async getCurrentLoadingModels(tenant: number) {
    try {
      const { data } = await axios.get(
        `/api/tenant/${tenant}/datasets/currently_loading`
      );

      return data;
    } catch (err) {
      return [];
    }
  }
}
