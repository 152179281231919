import UnivariateAnalysisVariableBucket from './UnivariateAnalysisVariableBucket';

type UnivariateAnalysisVariableTag =
  | 'Most Frequently Used'
  | 'Customer Available'
  | string;

export default class UnivariateAnalysisVariable {
  id: string;

  variableName: string;

  buckets: UnivariateAnalysisVariableBucket[];

  tags: UnivariateAnalysisVariableTag[];

  constructor(
    id: string,
    variableName: string,
    buckets: UnivariateAnalysisVariableBucket[],
    tags: UnivariateAnalysisVariableTag[]
  ) {
    this.id = id;
    this.variableName = variableName;
    this.buckets = buckets;
    this.tags = tags;
  }
}
