import UnivariateAnalysisVariableBucketWithShare from '../models/univariate/UnivariateAnalysisVariableBucketWithShare';

export default function calculateRelevanceScore(
  bucketWithShare: UnivariateAnalysisVariableBucketWithShare,
  avgConversionRate: number
): number {
  if (bucketWithShare.population > 100) {
    return (
      Math.sqrt((bucketWithShare.conversionRate - avgConversionRate) ** 2) /
        avgConversionRate +
      100
    );
  }
  if (bucketWithShare.population > 50) {
    return (
      Math.sqrt((bucketWithShare.conversionRate - avgConversionRate) ** 2) /
        avgConversionRate +
      50
    );
  }
  if (bucketWithShare.population > 25) {
    return (
      Math.sqrt((bucketWithShare.conversionRate - avgConversionRate) ** 2) /
        avgConversionRate +
      25
    );
  }
  return (
    Math.sqrt((bucketWithShare.conversionRate - avgConversionRate) ** 2) /
    avgConversionRate
  );
}
