import React, { useState } from 'react';
import { MultiValue } from 'react-select';

import GenericSelect, { GenericSelectProps } from './GenericSelect';
import { MultiSelectOption } from './types';

/**
 * @name MadMultiSelect
 * MadMultiSelect is a mutliple choices select component based on GenericMadSelect features.
 * Specific features :
 *    - Handle fixed options
 */
function MadMultiSelect<
  MadOption extends MultiSelectOption,
  IsFree extends boolean = false
>(props: GenericSelectProps<MadOption, true, IsFree>) {
  const { value, onChange } = props;

  const [selectedOptions, setSelectedOptions] = useState<MultiValue<MadOption>>(
    value as MultiValue<MadOption>
  );

  return (
    <GenericSelect
      {...props}
      isMulti
      isClearable={selectedOptions.some(({ isFixed }) => !isFixed)}
      styles={{
        multiValue: (base, state) => ({
          ...base,
          backgroundColor: state.data.isFixed
            ? 'gray'
            : state.theme.colors.primary,
        }),
        multiValueLabel: (base, state) => ({
          ...base,
          color: state.data.isFixed ? 'white' : base.color,
          fontSize: 13,
        }),
        multiValueRemove: (base, state) =>
          state.data.isFixed ? { ...base, display: 'none' } : base,
        ...props.styles,
      }}
      onChange={(newValues, actionMeta) => {
        let values = newValues;
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (actionMeta.removedValue.isFixed) {
              return;
            }
            break;
          case 'clear':
            values = selectedOptions.filter(({ isFixed }) => isFixed);
            break;
        }

        values = [
          ...values.filter(({ isFixed }) => isFixed),
          ...values.filter(({ isFixed }) => !isFixed),
        ];

        setSelectedOptions(values);

        // forward onChange
        onChange(values, actionMeta);
      }}
      color="#4DCCBD"
    />
  );
}

export default MadMultiSelect;
