import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Alert, Button, FormControl, Card, Form } from 'react-bootstrap';
import { SmoothingConfigValue } from '../../../models/smoothing';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import Spinner from '../../components/Spinner';

interface SmoothingPageProps {
  store?: Store;
}

interface SmoothingPageState {
  activeTab: number;
}

export default inject('store')(
  observer(
    class SmoothingPage extends React.Component<
      SmoothingPageProps,
      SmoothingPageState
    > {
      constructor(props: SmoothingPageProps) {
        super(props);

        makeObservable(this, {
          handleSmoothingValueChange: action,
        });

        this.selectTab = this.selectTab.bind(this);
        this.handleSmoothingValueChange =
          this.handleSmoothingValueChange.bind(this);
        this.handleSmoothingCustomConfigChange =
          this.handleSmoothingCustomConfigChange.bind(this);
        this.saveSmoothingConfig = this.saveSmoothingConfig.bind(this);
        this.state = {
          activeTab: 1,
        };
      }

      async componentDidMount() {
        await this.props.store.loadSmoothingConfig();
      }

      async selectTab(tab: number) {
        const { loadAllTrees, tenant, modelId, ensemblingPage } =
          this.props.store;
        if (tab === 2) {
          await loadAllTrees();
          await ensemblingPage.loadConfiguration(tenant, modelId);
        }
        this.setState({
          activeTab: tab,
        });
      }

      handleSmoothingValueChange(value: SmoothingConfigValue) {
        this.props.store.smoothingPage.smoothing.type = value;
        if (
          value === 'custom' &&
          !this.props.store.smoothingPage.smoothing.config
        ) {
          this.props.store.smoothingPage.smoothing.config = '';
        }
      }

      handleSmoothingCustomConfigChange(
        event: React.ChangeEvent<HTMLTextAreaElement>
      ) {
        this.props.store.smoothingPage.smoothing.config = event.target.value;
      }

      async saveSmoothingConfig() {
        await this.props.store.saveSmoothingConfig();
        if (!this.props.store.smoothingPage.smoothingConfigError) {
          this.props.store.smoothingPage.smoothingConfigSaved = true;
        }
      }

      render() {
        const { store } = this.props;
        const { smoothingPage, isAllowedToEdit } = store;
        const {
          smoothing,
          loadingSmoothingConfig,
          smoothingConfigError,
          savingSmoothingConfig,
          smoothingConfigSaved,
        } = smoothingPage;

        return (
          <div className="mt-3">
            {loadingSmoothingConfig || !smoothing ? (
              <Spinner />
            ) : (
              <>
                <Card border="light" className="mb-4">
                  <Card.Body>
                    <Card.Text>
                      <p>
                        Configure bonus and malus points to differentiate
                        records falling in the same Tree nodes (therefore who
                        have the same segment) in order to distribute their
                        score within their segment.
                      </p>
                      <a
                        href="https://support.madkudu.com/hc/en-us/articles/4608543571597"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                      >
                        <i aria-hidden className="fas fa-book-open"></i> How to
                        configure the customer fit model advanced rules
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
                {smoothingConfigError && (
                  <Error message={smoothingConfigError} />
                )}
                {smoothingConfigSaved && (
                  <Alert variant="success">Saved !</Alert>
                )}
                <Card border="light">
                  <Card.Body>
                    <Form.Check
                      type="radio"
                      checked={smoothing.type === 'default_demo'}
                      disabled={!isAllowedToEdit}
                      onChange={() =>
                        this.handleSmoothingValueChange('default_demo')
                      }
                      label={
                        <p>
                          Apply default configuration{' '}
                          <a
                            href="https://support.madkudu.com/hc/en-us/articles/4608543571597"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none"
                          >
                            Learn more
                          </a>
                        </p>
                      }
                    />
                    <Form.Check
                      type="radio"
                      checked={smoothing.type === 'custom'}
                      disabled={!isAllowedToEdit}
                      onChange={() => this.handleSmoothingValueChange('custom')}
                      className="mb-3"
                      label="Apply custom configuration (in SQL)"
                    />
                    {smoothing.type === 'custom' && (
                      <FormControl
                        as="textarea"
                        placeholder="Input a SQL script to define custom micro_ml"
                        rows={15}
                        onChange={this.handleSmoothingCustomConfigChange}
                        autoFocus={true}
                        disabled={!isAllowedToEdit}
                        value={smoothing.config}
                      />
                    )}
                    {checkPermissions(
                      PROFILES_PERMISSIONS.ARCHITECT,
                      <Button
                        variant="primary"
                        className="pull-right"
                        onClick={this.saveSmoothingConfig}
                        style={{ marginTop: '15px' }}
                        disabled={
                          savingSmoothingConfig ||
                          (smoothing.type === 'custom' &&
                            (!smoothing.config ||
                              smoothing.config.trim().length === 0)) ||
                          !isAllowedToEdit
                        }
                      >
                        {savingSmoothingConfig ? 'Saving...' : 'Save'}
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        );
      }
    }
  )
);
