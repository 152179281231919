import { load } from 'js-yaml';

import {
  CustomerFitSignals,
  Signal,
  SignalCondition,
  SignalGroup,
  ConditionDirection,
} from '../../../../models/signals';
import { Subject, Verb } from '../../../../models/sqlform';

const LOWER_REGEX_EXP = /LOWER\('.+'\)/gi;
const UNWANTED_STRINGS_IN_FIELD_REGEX_EXP =
  /(LOWER\('?)|('?\))|(%)|(')|(COALESCE\()|(\|\|)|(CAST\()|(\s+AS VARCHAR\)?)|(, '?\w+'?\))/gi;
const UNWANTED_STRINGS_IN_VALUE_REGEX_EXP =
  /(LOWER\('?)|('?\))|(%)|(')|(COALESCE\()|(\|\|)|(CAST\()|(\s+AS VARCHAR\))/gi;
const VALUES_SEPARATOR_REGEX_EXP = /'?,\s'?/g;
const SEARCH_FOR_CONTAINS_VERB_FORMAT_REGEX_EXP =
  /('%[\w]+%')|(('%'\s\|\|).+(\|\|\s'%'))/gi;
const SEARCH_FOR_BEGINS_WITH_FORMAT_REGEX_EXP = /('[\w]+%')|(\|\|\s'%')/gi;

export function getVerbBoundaries(subject: string) {
  switch (subject) {
    case 'is between':
      return {
        min: 2,
        max: 2,
      };
    case 'is known':
      return {
        min: 0,
        max: 0,
      };
    case 'is unknown':
      return {
        min: 0,
        max: 0,
      };
    case 'is any of':
      return {
        min: 1,
        max: 50,
      };
    case 'is none of':
      return {
        min: 1,
        max: 50,
      };
    default:
      return {
        min: 1,
        max: 1,
      };
  }
}

export const signalGroupPlaceHolder = (formFields: Subject[]): SignalGroup => ({
  groupName: '',
  signals: [signalPlaceHolder(formFields)],
});

export const signalPlaceHolder = (formFields: Subject[]): Signal => ({
  conditions: [
    {
      field: formFields[0]?.subject,
      verb: 'is',
      values: [''],
      lower: true,
    },
  ],
  direction: '+',
  priority: 50,
  description: {
    field: '',
    value: 'null',
  },
});

export const signalConditionPlaceHolder = (
  formFields: Subject[],
  direction: ConditionDirection
): SignalCondition => ({
  field: formFields[0]?.subject,
  verb: 'is',
  values: [''],
  direction,
  lower: true,
});

export function parseYamlSignalsToUi(
  signals: CustomerFitSignals
): null | SignalGroup[] {
  if (!signals) return null;
  const config = signals.config || signals.defaultConfig;

  const loadedConfig: any = load(config);
  return loadedConfig.signals.map((signalGroup: any) => {
    const { id, rules } = signalGroup;
    return {
      groupName: id,
      signals: rules.map((rule: any) => {
        const {
          priority,
          condition,
          description,
          signal_direction: direction,
          signal_value: value,
        } = rule;
        const { splitConditions, direction: conditionDirection } =
          splitConditionsByDirection(condition);
        const conditions = splitConditions.map((c) =>
          unparseConditionToUi(c, conditionDirection)
        );
        return {
          priority,
          description: {
            field: description,
            value: value && removeCastFromSignalValue(value),
          },
          direction,
          conditions,
        };
      }),
    };
  });
}

function removeCastFromSignalValue(value: string) {
  if (value.includes('CAST(') && value.includes('AS ')) {
    return value.slice(value.indexOf('CAST(') + 5, value.indexOf(' AS '));
  }
  return value;
}

export function splitConditionsByDirection(conditions: string): {
  splitConditions: string[];
  direction: ConditionDirection;
} {
  const andConditions = conditions.split(' AND ');
  const orConditions = conditions.split(' OR ');

  // if conditions are separated by 'AND'
  if (andConditions.length > 1) {
    return { splitConditions: andConditions, direction: 'AND' };
  }
  // if conditions are separated by 'OR', or there's only one condition
  return { splitConditions: orConditions, direction: 'OR' };
}

export function unparseConditionToUi(
  condition: string,
  direction: ConditionDirection
): SignalCondition {
  const lower = condition.includes('LOWER(');
  // logical order based in repeated characters. do not change this.

  // 'is not'
  if (condition.includes('!=')) {
    const [field, value] = condition.split(' != ');
    // remove first and last values which are single quotes
    const values = [cleanUnwantedStringsInValue(stripLower(value))];
    return {
      field: cleanUnwantedStringsInValue(field),
      values,
      verb: 'is not',
      direction,
      lower,
    };
  }
  // 'is not' different syntax
  if (condition.includes('<>')) {
    const [field, value] = condition.split(' <> ');
    // remove first and last values which are single quotes
    const values = [cleanUnwantedStringsInValue(stripLower(value))];
    return {
      field: cleanUnwantedStringsInValue(field),
      values,
      verb: 'is not',
      direction,
      lower,
    };
  }

  // 'does not contain -> different extraction
  if (condition.includes('NOT LIKE')) {
    const [field, value] = condition.split(' NOT LIKE ');
    // extract field from COALESCE function
    const values = [cleanUnwantedStringsInValue(stripLower(value))];

    const onlyNotDefaultCoalesceFieldValue = cleanUnwantedStringsInValue(field)
      ?.split(',')[0]
      ?.trim();
    return {
      field: onlyNotDefaultCoalesceFieldValue,
      values,
      verb: 'does not contain',
      direction,
      lower,
    };
  }
  // 'less than', etc.. -> different extraction
  if (condition.includes('<=')) {
    return extractMathematicalFieldAndValue(
      ' <= ',
      condition,
      'is less than or equal to',
      direction,
      lower
    );
  }
  if (condition.includes('>=')) {
    return extractMathematicalFieldAndValue(
      ' >= ',
      condition,
      'is more than or equal to',
      direction,
      lower
    );
  }
  if (condition.includes('>')) {
    return extractMathematicalFieldAndValue(
      ' > ',
      condition,
      'is more than',
      direction,
      lower
    );
  }
  if (condition.includes('<')) {
    return extractMathematicalFieldAndValue(
      ' < ',
      condition,
      'is less than',
      direction,
      lower
    );
  }
  // 'is between'
  if (condition.includes('BETWEEN')) {
    return extractFieldAndValueFromBetweenVerb(condition, direction);
  }

  // 'is known'
  if (condition.includes('NOT NULL')) {
    const field = condition.slice(0, condition.indexOf(' IS NOT NULL'));
    return {
      field: cleanUnwantedStringsInValue(field),
      values: [''],
      verb: 'is known',
      direction,
      lower,
    };
  }
  // 'is unknown'
  if (condition.includes('NULL')) {
    const field = condition.slice(0, condition.indexOf(' IS NULL'));
    return {
      field: cleanUnwantedStringsInValue(field),
      values: [''],
      verb: 'is unknown',
      direction,
      lower,
    };
  }
  // 'is none of'
  if (condition.includes('NOT IN')) {
    return extractFieldAndMultipleValue(
      ' NOT IN(',
      condition,
      'is none of',
      ' NOT IN (',
      direction,
      lower
    );
  }
  // 'is any of'
  if (condition.includes('IN')) {
    return extractFieldAndMultipleValue(
      ' IN(',
      condition,
      'is any of',
      ' IN (',
      direction,
      lower
    );
  }
  // 'is' should be almost last
  if (condition.includes('=')) {
    const [field, value] = condition.split(' = ');
    let cleanValue = cleanUnwantedStringsInValue(stripLower(value));
    // convert 'TRUE'/'FALSE' to '1'/'0', for PG
    if (cleanValue.toLowerCase() === 'true') cleanValue = '1';
    if (cleanValue.toLowerCase() === 'false') cleanValue = '0';

    const values = [cleanValue];

    return {
      field: cleanUnwantedStringsInValue(field),
      values,
      verb: 'is',
      direction,
      lower,
    };
  }

  if (condition.includes('LIKE')) {
    // contains
    if (SEARCH_FOR_CONTAINS_VERB_FORMAT_REGEX_EXP.test(condition)) {
      // reset index position to start searching from beginning
      SEARCH_FOR_CONTAINS_VERB_FORMAT_REGEX_EXP.lastIndex = 0;
      const [field, value] = condition.split(' LIKE ');
      const values = [cleanUnwantedStringsInValue(stripLower(value))];
      return {
        field: cleanUnwantedStringsInValue(field),
        values,
        verb: 'contains',
        direction,
        lower,
      };
    }

    // begins with
    if (SEARCH_FOR_BEGINS_WITH_FORMAT_REGEX_EXP.test(condition)) {
      // reset index position to start searching from beginning
      SEARCH_FOR_BEGINS_WITH_FORMAT_REGEX_EXP.lastIndex = 0;
      const [field, value] = condition.split(' LIKE ');
      const values = [cleanUnwantedStringsInValue(stripLower(value))];
      return {
        field: cleanUnwantedStringsInValue(field),
        values,
        verb: 'begins with',
        direction,
        lower,
      };
    }

    // ends with
    const [field, value] = condition.split(' LIKE ');
    const values = [cleanUnwantedStringsInValue(stripLower(value))];

    return {
      field: cleanUnwantedStringsInValue(field),
      values,
      verb: 'ends with',
      direction,
      lower,
    };
  }

  return {
    field: '',
    verb: 'is',
    values: [''],
    direction,
    lower,
  };
}

function stripLower(valueOrField: string) {
  const regExpExecArray = LOWER_REGEX_EXP.exec(valueOrField);
  LOWER_REGEX_EXP.lastIndex = 0;
  return regExpExecArray !== null
    ? regExpExecArray[0]?.trim()
    : valueOrField?.trim();
}

function cleanUnwantedStringsInValue(value: string): string {
  UNWANTED_STRINGS_IN_VALUE_REGEX_EXP.lastIndex = 0;
  return value.replace(UNWANTED_STRINGS_IN_VALUE_REGEX_EXP, '')?.trim();
}

export function cleanUnwantedStringsInField(field: string): string {
  UNWANTED_STRINGS_IN_FIELD_REGEX_EXP.lastIndex = 0;
  return field.replace(UNWANTED_STRINGS_IN_FIELD_REGEX_EXP, '')?.trim();
}

function extractFieldAndMultipleValue(
  separator: string,
  condition: string,
  verb: Verb,
  fallbackSeparator: string,
  direction: ConditionDirection,
  lower: boolean
): SignalCondition {
  let [field, rawValues] = condition.split(separator);
  // handle non-standard `IN ()` instead of `IN()`
  if (!field || !rawValues) {
    [field, rawValues] = condition.split(fallbackSeparator);
  }
  const values = rawValues.split(VALUES_SEPARATOR_REGEX_EXP).map((rawValue) => {
    return cleanUnwantedStringsInValue(stripLower(rawValue));
  });

  return {
    field: cleanUnwantedStringsInField(field),
    values,
    verb,
    direction,
    lower,
  };
}

function extractFieldAndValueFromBetweenVerb(
  condition: string,
  direction: ConditionDirection
): SignalCondition {
  const [rawField, rawValue] = condition.split(' BETWEEN ');
  // extract field from COALESCE function
  const field = rawField.includes('CAST')
    ? rawField.slice(rawField.indexOf('CAST(') + 5, rawField.indexOf(' AS'))
    : rawField;
  const values = rawValue.split(' and ').map((v) => v?.trim());
  return {
    field: stripLower(field),
    verb: 'is between',
    values,
    direction,
    lower: false,
  };
}

function extractMathematicalFieldAndValue(
  separator: string,
  condition: string,
  verb: Verb,
  direction: ConditionDirection,
  lower: boolean
): SignalCondition {
  const [rawField, value] = condition.split(separator);
  // extract field from COALESCE function
  const field = rawField.includes('CAST')
    ? rawField.slice(rawField.indexOf('CAST(') + 5, rawField.indexOf(' AS'))
    : rawField;
  return {
    field: stripLower(field),
    verb,
    values: [stripLower(value)],
    direction,
    lower,
  };
}
