import React from 'react';
import Tree from 'react-d3-tree';
import Store from '../../../store';
import NodeLabel from './NodeLabel';

interface TreeVizProps {
  store: Store;
  treeId: number;
  nodeId: number;
  actualTransitionCoords: {
    x: number;
    y: number;
    zoom: number;
  };
  selectNode: (
    nodeId: number,
    actualTransitionCoords?: { x: number; y: number; zoom: number }
  ) => void;
  setActualTransitionCoords: (coords: {
    x: number;
    y: number;
    zoom: number;
  }) => void;
}

interface TreeVizState {
  dimensions: {
    x: number;
    y: number;
  };
}

export default class TreeViz extends React.Component<
  TreeVizProps,
  TreeVizState
> {
  constructor(props: TreeVizProps) {
    super(props);
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handleOnTreeUpdate = this.handleOnTreeUpdate.bind(this);
    this.state = {
      dimensions: { x: 700, y: 600 },
    };
  }

  private graphRef = React.createRef<HTMLDivElement>();

  private actualTransitionCoords = this.props.actualTransitionCoords;

  componentDidMount() {
    this.setState({
      dimensions: {
        x: this.graphRef.current.offsetWidth,
        y: this.graphRef.current.offsetHeight,
      },
    });
  }

  handleNodeClick(
    e: any,
    actualTransitionCoords: { x: number; y: number; zoom: number }
  ) {
    const nodeId = parseInt(e.name, 10);
    this.props.selectNode(nodeId, actualTransitionCoords);
  }

  handleOnTreeUpdate(reactD3TreeItem: any) {
    if (reactD3TreeItem && reactD3TreeItem.translate && reactD3TreeItem.zoom) {
      this.actualTransitionCoords = {
        x: reactD3TreeItem.translate.x,
        y: reactD3TreeItem.translate.y,
        zoom: reactD3TreeItem.zoom,
      };
    }
  }

  render() {
    const { treesPage } = this.props.store;
    const { trees } = treesPage;
    const treeId = Number(this.props.treeId);
    const { dimensions } = this.state;
    const tree = trees[treeId - 1];
    return (
      <div style={{ height: '700px' }} ref={this.graphRef}>
        {tree?.definition ? (
          <Tree
            initialDepth={1}
            nodeSize={{ x: 120, y: 120 }}
            data={[tree.definition.structure] as any}
            allowForeignObjects
            collapsible={false}
            zoom={this.actualTransitionCoords.zoom}
            translate={{
              x: this.actualTransitionCoords.x,
              y: this.actualTransitionCoords.y,
            }}
            onUpdate={this.handleOnTreeUpdate}
            shouldCollapseNeighborNodes={false}
            transitionDuration={0}
            nodeLabelComponent={{
              render: (
                <NodeLabel
                  nodeId={this.props.nodeId}
                  store={this.props.store}
                  graphDimensions={dimensions}
                />
              ),
              foreignObjectWrapper: {
                x: -60,
                y: -60,
                width: 120,
                height: 120,
              },
            }}
            orientation="vertical"
            separation={{ siblings: 1, nonSiblings: 2 }}
            onClick={(e: any) =>
              this.handleNodeClick(e, this.actualTransitionCoords)
            }
          />
        ) : (
          'No tree'
        )}
      </div>
    );
  }
}
