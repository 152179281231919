import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DirectedConditionGroup from './DirectedConditionGroup';
import Store from '../../../../../store';
import { Button, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

import {
  ComputationCondition,
  DirectedComputationCondition,
  DirectedComputationConditionGroup,
} from '../../../../../models/computations/Computation';

interface ConditionalFormProps {
  store?: Store;
}

const ConditionalForm = inject('store')(
  observer(function ConditionalForm({ store }: ConditionalFormProps) {
    const sensors = useSensors(
      useSensor(MouseSensor, {
        activationConstraint: {
          distance: 10,
        },
      })
    );

    const handleAddGroupDirectedCondition = () => {
      const newComputationCondition: ComputationCondition = {
        field: store.computationsPage.metadataFields[0]?.name || '',
        verb: 'is',
        values: [''],
      };
      // No direction for the first one
      const newDirectedComputationCondition: DirectedComputationCondition = {
        condition: newComputationCondition,
      };
      const newGroupDirectedCondition: DirectedComputationConditionGroup = {
        _id: uuid(),
        targetComputation: '',
        directedConditions: [newDirectedComputationCondition],
      };
      store.computationsPage.activeComputation.configuration.modeProperties.conditional.conditions.push(
        newGroupDirectedCondition
      );
    };

    const handleOnElseValueChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      activeComputation.configuration.modeProperties.conditional.elseValue =
        event.target.value;
    };

    const activeComputation = store.computationsPage.activeComputation;

    const conditions =
      activeComputation.configuration.modeProperties.conditional.conditions;

    conditions.forEach((condition) => {
      if (!condition._id) {
        condition._id = uuid();
      }
    });

    const readonly = activeComputation.context === 'standard';

    const defaultElseValue = activeComputation.type === 'number' ? '0' : 'NULL';
    const elseValue =
      activeComputation.configuration.modeProperties.conditional.elseValue ??
      defaultElseValue;

    return (
      <>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => {
            const { active, over } = event;

            if (active.id !== over.id) {
              const oldIndex = conditions.findIndex(
                ({ _id }) => _id === active.id
              );
              const newIndex = conditions.findIndex(
                ({ _id }) => _id === over.id
              );

              store.computationsPage.activeComputation.configuration.modeProperties.conditional.conditions =
                arrayMove(conditions, oldIndex, newIndex);
            }
          }}
        >
          <SortableContext
            items={conditions.map((condition) => ({
              ...condition,
              id: condition._id,
            }))}
            strategy={verticalListSortingStrategy}
          >
            {conditions.map((condition, index) => (
              <DirectedConditionGroup
                key={condition._id}
                directedConditionGroup={condition}
                directedConditionGroupIndex={index}
              />
            ))}
          </SortableContext>
        </DndContext>
        {!readonly && (
          <Button variant="primary" onClick={handleAddGroupDirectedCondition}>
            + IF
          </Button>
        )}
        <div className="mt-3">
          <span className="d-inline-block fw-bold mt-2 me-3">ELSE</span>
          <Form.Control
            type={activeComputation.type === 'number' ? 'number' : 'text'}
            value={elseValue}
            disabled={readonly}
            onChange={handleOnElseValueChange}
            className="d-inline-block w-auto"
          />
        </div>
        <span className="d-inline-block mt-3 fst-italic text-secondary">
          Note: Only the first matching condition would apply
        </span>
      </>
    );
  })
);

export default ConditionalForm;
