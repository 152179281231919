import { makeObservable, observable } from 'mobx';
import { Subject, Verb } from '../sqlform';

export default class PointBasedConditionDataModel {
  trait: Subject;

  verb: Verb;

  values: string[];

  isLower?: boolean;

  error?: boolean;

  constructor(
    trait: Subject,
    condition?: Verb,
    values?: string[],
    isLower?: boolean
  ) {
    if (trait && condition && values && isLower) {
      this.trait = trait;
      this.verb = condition;
      this.values = values;
      this.isLower = false;
      this.error = null;
    } else {
      this.trait = trait;
      this.verb = 'is';
      this.values = trait.type === 'number' ? ['0'] : ['Unknown'];
      this.error = false;
      this.isLower = false;
    }
    makeObservable(this, {
      trait: observable,
      verb: observable,
      values: observable,
      isLower: observable,
      error: observable,
    });
  }
}
