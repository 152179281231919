import { inject, observer } from 'mobx-react';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { DataSetQuality } from '../../../models/data/types';
import Store from '../../../store';

interface BadDatasetMessageProps {
  store?: Store;
}

/**
 * Component that returns the warning alert message in case a dataset is considered bad, else returns null
 */
function BadDatasetMessage({ store }: BadDatasetMessageProps) {
  const { activeModel, tenant, modelId, dataPage } = store;
  const { live, type } = activeModel;

  const badDataset = dataPage?.overviews?.getBadQuality();

  if (!badDataset) {
    return <></>;
  }

  const dataQualityVariantes: Record<DataSetQuality, string> = {
    average: 'warning',
    bad: 'danger',
    good: 'success',
  };

  if (!live) {
    const reloadDatasetUrl = `https://studio.madkudu.com/tenant/${tenant}/models/${modelId}/import_data/${
      type === 'customer_fit' ? 'mk_audience' : type
    }`;

    return (
      <Alert variant={dataQualityVariantes[badDataset.dataSetQuality]}>
        Oh snap! The{' '}
        {badDataset.type === 'smoothing' ? 'training' : badDataset.type} dataset
        is{' '}
        {badDataset.dataSetQuality === 'average'
          ? 'considered small'
          : 'too small'}{' '}
        to have a significant model.{' '}
        <a target="_blank" rel="noopener noreferrer" href={reloadDatasetUrl}>
          Please change your dataset parameters
        </a>{' '}
        to include at least{' '}
        {badDataset.dataSetQuality === 'average' ? '1,200' : '1,000'} records
        and reload.
      </Alert>
    );
  }
  return <></>;
}

export default inject('store')(observer(BadDatasetMessage));
