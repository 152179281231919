import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

export default function ScrollButton() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  if (!isVisible) {
    return null;
  }
  return (
    <Button
      onClick={scrollToTop}
      variant="outline-primary"
      className="float-end mb-4 scroll-to-top"
    >
      &uarr; Back to top
    </Button>
  );
}
