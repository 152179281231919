import { inject, observer } from 'mobx-react';
import React from 'react';
import { Card } from 'react-bootstrap';
import { FunnelBreakdown } from '../../../models/performance_check';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
  Legend,
} from 'recharts';
import { CHART_CUSTOMER_COLORS } from '../../../utils';

type FunnelBreakdownProps = {
  funnelBreakdown: FunnelBreakdown;
};

export default inject('store')(
  observer(
    class FunnelBreakdownComponent extends React.Component<
      FunnelBreakdownProps,
      Record<string, never>
    > {
      render() {
        const { funnelBreakdown } = this.props;
        const veryGood = funnelBreakdown['very good'] || {};
        const good = funnelBreakdown.good || {};
        const qualifiedLeads = (veryGood.leads ?? 0) + (good.leads ?? 0);
        const qualifiedSales =
          (veryGood.conversions ?? 0) + (good.conversions ?? 0);
        const qualifiedRevenue = (veryGood.revenue ?? 0) + (good.revenue ?? 0);

        const data = [
          {
            name: 'Leads',
            'very good': funnelBreakdown['very good']?.leads ?? 0,
            good: funnelBreakdown.good?.leads ?? 0,
            other:
              100 -
              ((funnelBreakdown['very good']?.leads ?? 0) +
                (funnelBreakdown.good?.leads ?? 0)),
          },
          {
            name: 'Conversions Closed Won',
            'very good': funnelBreakdown['very good']?.conversions ?? 0,
            good: funnelBreakdown.good?.conversions ?? 0,
            other:
              100 -
              ((funnelBreakdown['very good']?.conversions ?? 0) +
                (funnelBreakdown.good?.conversions ?? 0)),
          },
          {
            name: 'Revenue',
            'very good': funnelBreakdown['very good']?.revenue ?? 0,
            good: funnelBreakdown.good?.revenue ?? 0,
            other:
              100 -
              ((funnelBreakdown['very good']?.revenue ?? 0) +
                (funnelBreakdown.good?.revenue ?? 0)),
          },
        ];

        return (
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Lead to Closed Won revenue breakdown </Card.Title>
              <p>
                <b>{qualifiedLeads}%</b> of your leads are qualified and they
                account for <b>{qualifiedSales}%</b> of your sales qualified
                opps, and those account for <b>{qualifiedRevenue}%</b> of your
                revenue.
              </p>
              <div style={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={data} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 100]} />
                    <YAxis
                      dataKey="name"
                      type="category"
                      tickFormatter={(name: string) => {
                        const limit = 8;
                        if (name.length < limit) return name;
                        return `${name.substring(0, limit)}...`;
                      }}
                    />
                    <Tooltip
                      itemStyle={{ color: 'black' }}
                      formatter={(value) => `${value}%`}
                    />
                    <Legend
                      formatter={(value) => (
                        <span style={{ color: 'black' }}>{value}</span>
                      )}
                    />
                    {['very good', 'good', 'other'].map((key) => (
                      <Bar
                        key={key}
                        dataKey={key}
                        stackId="a"
                        fill={
                          CHART_CUSTOMER_COLORS.customer_fit[key]?.background ??
                          '#DDE0E5'
                        }
                      >
                        <LabelList
                          dataKey={key}
                          fill="white"
                          formatter={(value: number) => `${value}%`}
                        />
                      </Bar>
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card.Body>
          </Card>
        );
      }
    }
  )
);
