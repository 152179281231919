import React, { useEffect, useMemo, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';

import { Alert, Button, Card, Form } from 'react-bootstrap';

import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import MadMultiSelect from '../../components/generic_components/select_component/MadMultiSelect';
import { checkIsArchitect, PROFILES_PERMISSIONS } from '../../../permissions';
import Spinner from '../../components/Spinner';
import Store from '../../../store';
import {
  MultiSelectOption,
  Option,
} from '../../components/generic_components/select_component/types';
import { stringifyAggregationName } from '../../components/behavioral_aggregations/utils';
import { IS_LLTB } from '../../../utils/constants';
import { ModelType } from '../../../models/ModelItem';

type MatchParams = {
  tenant: string;
};
interface LtbSignalsPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

const transformActivityTypeToOption = (
  activityType: string,
  modelType: ModelType
): MultiSelectOption => ({
  value: activityType,
  label: activityType,
  isFixed:
    activityType === 'Non-user Activity' ||
    (modelType === 'mql' && activityType === 'App Usage'),
});

const transformAggregationToOption = (agg: string): Option => ({
  value: agg,
  label: stringifyAggregationName(agg),
});

const transformMetaEventDisplayToOption = (metaEvent: string): Option => ({
  value: metaEvent,
  label: metaEvent,
});

function LtbSignalsPage({ store, match }: LtbSignalsPageProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function asyncActions() {
      await Promise.all([
        store.loadValidationData({ isProcessing: true }),
        store.loadSignals(),
        store.loadEventMetadata(),
        store.behavioralAggregationsPage.get(store.tenant),
      ]);

      setIsLoading(false);
    }

    asyncActions();
  }, []);

  const {
    isAllowedToEdit,
    validationPage,
    signalsPage,
    eventMetadata,
    tenant,
    userPermissions,
    isSuperKudu,
    behavioralAggregationsPage,
    activeModel,
  } = store;
  const { signals, signalsSaved, signalsError, savingSignals } = signalsPage;
  const validationError = validationPage.validation.error;

  const hasArchitectPermissions = useMemo(
    () => checkIsArchitect(tenant, userPermissions) || isSuperKudu,
    [tenant, userPermissions]
  );

  // aggregations to include
  const aggregationOptions = useMemo(
    () =>
      behavioralAggregationsPage.aggregations
        .filter(
          ({ type }) =>
            // exclude aggregations with incorrect type compared to model
            (IS_LLTB(activeModel.type) && type === 'email') ||
            (activeModel.type === 'mqa' && type === 'account')
        )
        .filter(({ isProductEvent }) =>
          // exclude product aggregations for MQL model
          activeModel.type === 'mql' ? !isProductEvent : true
        )
        .map(({ name }) => transformAggregationToOption(name)),
    [behavioralAggregationsPage.aggregations, activeModel.type]
  );
  const aggregationValues = useMemo(
    () =>
      signals.ltbSignals?.aggregationsToInclude.map(
        transformAggregationToOption
      ),
    [signals.ltbSignals?.aggregationsToInclude]
  );

  // activity type to exclude
  const activityTypeOptions = useMemo(
    () =>
      eventMetadata.activityTypes.map((activityType) =>
        transformActivityTypeToOption(activityType, activeModel.type)
      ),
    [eventMetadata.activityTypes, activeModel.type]
  );
  const activityTypeValues = useMemo(() => {
    // TODO: Pls me, find something less ugly
    let values = signals.ltbSignals?.activityTypeExclusionList.split(', ');

    values = isEqual(values, ['']) ? [] : values;

    return values?.map((activityType) =>
      transformActivityTypeToOption(activityType, activeModel.type)
    );
  }, [signals.ltbSignals?.activityTypeExclusionList, activeModel.type]);

  // events to exclude
  const eventOptions = useMemo(
    () =>
      eventMetadata.metaEventsDisplay?.map((metaEvent) =>
        transformMetaEventDisplayToOption(metaEvent)
      ),
    [eventMetadata.metaEventsDisplay]
  );
  const eventValues = useMemo(
    () =>
      signals.ltbSignals?.eventExclusionListFromSignals.map(
        transformMetaEventDisplayToOption
      ),
    [signals.ltbSignals?.eventExclusionListFromSignals]
  );

  if (isLoading) return <Spinner />;

  return (
    <div className="mt-3 mb-5">
      <>
        {validationError && <Error message={validationError} />}
        {signalsError && (
          <Error message={signalsError.message} type={signalsError.type} />
        )}
        {signalsSaved && <Alert variant="success">Signals saved !</Alert>}
        <Card>
          <Card.Body>
            <p>
              Configure the list of recent activities your Sales team sees in
              your CRM next to the score to provide explainability and
              additional information on the record. Check out the result in the{' '}
              <a href="../review/sample">Sample</a> page!
            </p>
            <p>
              By default all events mapped in the{' '}
              <a
                href={`https://app.madkudu.com/org/${match.params.tenant}/mapping/event_mapping`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Event mapping
              </a>{' '}
              and performed by the lead would appear in the Likelihood to Buy
              signals and you may want to add some aggregations or exclude some
              events too polluting.
            </p>
            <span className="fs-5 fw-bold">
              Aggregations to <i>include</i> in the signals
            </span>
            <div className="mt-2 mb-3">
              <MadMultiSelect
                value={aggregationValues}
                options={aggregationOptions}
                onChange={action((values) => {
                  store.signalsPage.signals.ltbSignals.aggregationsToInclude =
                    values.map(({ value }) => value);
                })}
                isDisabled={
                  !isAllowedToEdit || savingSignals || !hasArchitectPermissions
                }
                placeholder="Select aggregations..."
              />
            </div>
            <span className="fs-5 fw-bold">
              Events and activity types to <i>exclude</i> from the signals
            </span>
            <Form.Group className="mt-2 mb-2" controlId="formActivityType">
              <Form.Label className="fw-bold">
                Activity types to exclude
              </Form.Label>
              <MadMultiSelect
                isFree
                value={activityTypeValues}
                options={activityTypeOptions}
                onChange={action((values) => {
                  store.signalsPage.signals.ltbSignals.activityTypeExclusionList =
                    values.map(({ value }) => value).join(', ');
                })}
                isDisabled={
                  !isAllowedToEdit || savingSignals || !hasArchitectPermissions
                }
                placeholder="Select activities..."
              />
            </Form.Group>
            <span className="d-block fw-bold">Events to exclude</span>
            <span className="text-secondary">
              The name needs to be the same name as defined in the event
              mapping, under “MK Event (signals)”
            </span>
            <div className="mt-2">
              <MadMultiSelect
                isFree
                options={eventOptions}
                value={eventValues}
                onChange={action((values) => {
                  signals.ltbSignals.eventExclusionListFromSignals = values.map(
                    ({ value }) => value
                  );
                })}
                isDisabled={
                  !isAllowedToEdit || savingSignals || !hasArchitectPermissions
                }
                placeholder="Select events..."
              />
            </div>
            {checkPermissions(
              PROFILES_PERMISSIONS.ARCHITECT,
              <Button
                variant="success"
                className="mt-3"
                disabled={!isAllowedToEdit || savingSignals}
                onClick={action(async () => {
                  store.analyticsTrackEvent('Updated signals');
                  store.signalsPage.savingSignals = true;
                  await store.saveSignals();
                  store.signalsPage.savingSignals = false;
                })}
              >
                {savingSignals ? 'Saving...' : 'Save'}
              </Button>
            )}
          </Card.Body>
        </Card>
      </>
    </div>
  );
}

export default withRouter(inject('store')(observer(LtbSignalsPage)));
