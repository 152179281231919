import React from 'react';
import { Nav } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useRouteMatch,
} from 'react-router-dom';
import PerformanceCheckPage from './PerformanceCheckPage';
import SpotCheckPage from './SpotCheckPage';

type MatchParams = {
  tenant: string;
  modelId: string;
};

const ReviewPage = () => {
  const match = useRouteMatch<MatchParams>();

  const { tenant, modelId } = match.params;

  const basePath = `/tenant/${tenant}/models/${modelId}/review`;

  return (
    <>
      <Nav className="third-level-nav review bg-white">
        <Nav.Link className="text-dark" as={NavLink} to={`${basePath}/sample`}>
          Sample
        </Nav.Link>
        <Nav.Link
          className="text-dark"
          as={NavLink}
          to={`${basePath}/performance`}
        >
          Performance
        </Nav.Link>
      </Nav>
      <Switch>
        <Route path={`${match.path}/sample`}>
          <SpotCheckPage />
        </Route>
        <Route path={`${match.path}/performance`}>
          <PerformanceCheckPage />
        </Route>
        <Redirect from={match.path} to={`${match.path}/sample`} />
      </Switch>
    </>
  );
};

export default ReviewPage;
