import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Store from '../../../store';
import { parseAggregationName } from './utils';

interface StepOneComponentProps extends RouteComponentProps<any> {
  store?: Store;
}

interface StepOneComponentState {
  showDetails: boolean;
}

const DISPLAY_TO_VALUE: { [key: string]: 'email' | 'account' } = {
  Email: 'email',
  Account: 'account',
};
const VALUE_TO_DISPLAY: { [key: string]: string } = {
  email: 'Email',
  account: 'Account',
};

class StepOneComponent extends React.Component<
  StepOneComponentProps,
  StepOneComponentState
> {
  constructor(props: StepOneComponentProps) {
    super(props);

    this.state = {
      showDetails: false,
    };

    this.handleAggregationLevelChange =
      this.handleAggregationLevelChange.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);

    makeObservable(this, {
      handleAggregationLevelChange: action,
    });
  }

  handleAggregationLevelChange(e: any) {
    const { value } = e.target;
    const { customName, lifetime, logic } =
      this.props.store.behavioralAggregationsPage.activeAggregation;
    const type = DISPLAY_TO_VALUE[value];

    if (lifetime && logic && value) {
      // modifying type/level automatically changes name -> '<type> - <logic_with_lifetime_insterted>'
      this.props.store.behavioralAggregationsPage.activeAggregation.name =
        parseAggregationName({
          lifetime,
          logic,
          custom: customName,
        });
    }
    this.props.store.behavioralAggregationsPage.activeAggregation.type = type;
    this.props.store.behavioralAggregationsPage.stepConfiguration[1].validated =
      true;
  }

  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  render() {
    const { showDetails } = this.state;
    const { behavioralAggregationsPage, isSuperKudu, isArchitect } =
      this.props.store;
    const { activeAggregation } = behavioralAggregationsPage;

    if (!activeAggregation) return null;

    return (
      <Form.Group className="mb-3" controlId="formCategory">
        <Form.Label className="fw-bold">Category</Form.Label>
        <Form.Select
          name="aggregationLevel"
          value={VALUE_TO_DISPLAY[activeAggregation.type]}
          onChange={this.handleAggregationLevelChange}
          disabled={
            !(isSuperKudu || isArchitect) ||
            activeAggregation.usedInLiveModel ||
            activeAggregation.isSynced
          }
        >
          {['-', ...Object.keys(DISPLAY_TO_VALUE)].map((type) => (
            <option key={`aggregation_type_${type}`}>{type}</option>
          ))}
        </Form.Select>
        <Form.Text className="text-muted">
          <a className="mt-2 mb-2" onClick={this.toggleDetails}>
            &gt; How to choose?
          </a>
          {showDetails && (
            <div className="panel panel-default">
              <div className="panel-body">
                <h3 className="text-primary">
                  Below are some examples to help you choose at what level to
                  create the aggregation
                </h3>
                <div className="mb-2 text-primary fs-5 text">
                  <p>
                    Select the email level if you want to count the number of
                    activities done by a single user. These aggregations will be
                    available for the PQL model only.
                  </p>
                  <p className="fst-italic mt-2">
                    Example: number of active days in the last 30 days for a
                    user
                  </p>
                  <p>
                    Select the MK account level if you want to count the number
                    of activities done by an account, an account being defined
                    by a same domain or account, depending on what has been
                    mapped in the MadKudu app. These aggregations will be
                    available for the Account Likelihood to Buy model only.
                  </p>
                  <p className="fst-italic mt-2">
                    Example: number of active contacts in the last 30 days
                  </p>
                </div>
              </div>
            </div>
          )}
        </Form.Text>
      </Form.Group>
    );
  }
}

export default inject('store')(withRouter(observer(StepOneComponent)));
