import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Store from '../../../store';
import Error from '../../components/error/Error';
import SignalsGroups from '../../components/rules/signals/SignalsGroups';
import ScrollButton from '../../components/generic_components/ScrollButton';
import Spinner from '../../components/Spinner';

type MatchParams = {
  tenant: string;
  modelId: string;
};
interface SignalsPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

const SignalsPage = inject('store')(
  observer(
    class SignalsPage extends React.Component<
      SignalsPageProps,
      Record<string, never>
    > {
      constructor(props: SignalsPageProps) {
        super(props);

        makeObservable(this, {
          saveSignals: action,
        });

        this.saveSignals = this.saveSignals.bind(this);
      }

      async componentDidMount() {
        await Promise.all([
          this.props.store.loadValidationData({ isProcessing: true }),
          this.props.store.loadSignals(),
        ]);
      }

      async saveSignals() {
        this.props.store.analyticsTrackEvent('Updated signals');
        this.props.store.signalsPage.savingSignals = true;
        await this.props.store.saveSignals();
        this.props.store.signalsPage.savingSignals = false;
      }

      render() {
        const { validationPage, signalsPage } = this.props.store;
        const { signalsError, signalsSaved, loadingSignals, signals } =
          signalsPage;
        const {
          validation: { error: validationError },
        } = validationPage;

        const { tenant, modelId } = this.props.match.params;

        return (
          <Card className="mt-3 me-5 ms-5 mb-5">
            <Card.Body>
              <div className="mb-3">
                <p>
                  Configure the intel your Sales team sees in your CRM next to
                  the score to provide explainability and additional information
                  on the record.
                  <br />
                  Check out the result in the{' '}
                  <a
                    className="fw-bold"
                    href={`/tenant/${tenant}/models/${modelId}/review/sample`}
                  >
                    Sample
                  </a>{' '}
                  page!
                </p>

                <a
                  href="https://support.madkudu.com/hc/en-us/articles/4404497474061-Customer-Fit-Signals"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How to
                  configure the Customer Fit Signals
                </a>
              </div>
              {loadingSignals || !signals ? (
                <Spinner />
              ) : (
                <>
                  {validationError && <Error message={validationError} />}
                  {signalsError && (
                    <Error
                      message={signalsError.message}
                      type={signalsError.type}
                    />
                  )}

                  <SignalsGroups saveSignals={this.saveSignals} />
                  {signalsSaved && (
                    <Alert variant="success" className="mt-4">
                      Signals saved !
                    </Alert>
                  )}
                  <ScrollButton />
                </>
              )}
            </Card.Body>
          </Card>
        );
      }
    }
  )
);

export default withRouter(SignalsPage);
