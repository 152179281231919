import { makeObservable, observable } from 'mobx';
import DataSetType from './DataSetType';

interface InputTableDataSetConstructorProps {
  name: string;
  type: DataSetType;
  table?: string;
  tenant: number;
  modelId: number;
}

export default class InputTableDataSet {
  tenant: number;

  modelId: number;

  name: string;

  type: DataSetType;

  table: string;

  constructor({
    table,
    name,
    type,
    tenant,
    modelId,
  }: InputTableDataSetConstructorProps) {
    this.tenant = tenant;
    this.modelId = modelId;
    this.name = name;
    this.type = type;
    this.table = table;

    makeObservable(this, {
      name: observable,
      type: observable,
      table: observable,
    });
  }
}
