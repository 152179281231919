import React from 'react';
import { Card } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type MatchParams = {
  tenant: string;
};

type HelpCenterProps = RouteComponentProps<MatchParams>;

type HelpCardProps = {
  title: string;
  linkButtons: React.ReactElement;
  text: string;
};

const ButtonLink = ({ text, link }: { text: string; link: string }) => {
  return (
    <a
      role="button"
      className="btn btn-primary"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

const HelpCard = ({ title, linkButtons, text }: HelpCardProps) => (
  <Card border="light">
    <Card.Body className="d-inline-block">
      <Card.Title>{title}</Card.Title>
      {text}
      <span className="float-end btn-group-vertical d-grid gap-2">
        {linkButtons}
      </span>
    </Card.Body>
  </Card>
);

class HelpCenter extends React.Component<
  HelpCenterProps,
  Record<string, never>
> {
  render() {
    const { tenant } = this.props.match.params;
    return (
      <div className="w-75 mx-auto my-auto mt-4 d-grid gap-4">
        <HelpCard
          title="Help Docs"
          text="We've set up a dedidacted QA support site with lots of frequently asked questions"
          linkButtons={
            <ButtonLink
              text="Learn more"
              link="https://support.madkudu.com/hc/en-us"
            />
          }
        />
        <HelpCard
          title="Get help"
          text="Can't find your answer? We're here to help. Get in touch and we'll get back to you shortly"
          linkButtons={
            <>
              <ButtonLink
                text="Chat with us"
                link={`https://app.madkudu.com/org/${tenant}/help`}
              />
              <ButtonLink
                text="Submit a ticket"
                link="https://support.madkudu.com/hc/en-us/requests/new"
              />
              <ButtonLink text="Email us" link="mailto:support@madkudu.com" />
            </>
          }
        />
        <HelpCard
          title="API Docs"
          text="Check out our API documentation to integrate MadKudu everywhere"
          linkButtons={
            <ButtonLink
              text="Learn more"
              link="https://developers.madkudu.com/"
            />
          }
        />
      </div>
    );
  }
}

export default withRouter(HelpCenter);
