import { makeObservable, observable } from 'mobx';
import PointBasedRuleDataModel from './PointBasedRuleDataModel';

export default class GroupPointBasedRulesDataModel {
  rules: PointBasedRuleDataModel[];

  number: number;

  constructor(number?: number, rules?: PointBasedRuleDataModel[]) {
    if (number && rules) {
      this.number = number;
      this.rules = rules;
    } else {
      this.number = 1;
      this.rules = [];
    }
    makeObservable(this, {
      rules: observable,
      number: observable,
    });
  }
}
