import { makeObservable, observable } from 'mobx';

import CustomerFitSignals from './CustomerFitSignals';
import LtbSignals from './LtbSignals';
import { SignalGroup } from './types';

interface SignalsConstructorProps {
  customerFitSignals?: CustomerFitSignals;
  ltbSignals?: LtbSignals;
  signalsV2?: SignalGroup[];
}

export default class Signals {
  customerFitSignals?: CustomerFitSignals;

  ltbSignals?: LtbSignals;

  signalsV2?: SignalGroup[];

  constructor({
    customerFitSignals,
    ltbSignals,
    signalsV2,
  }: SignalsConstructorProps) {
    this.customerFitSignals = customerFitSignals;
    this.ltbSignals = ltbSignals;
    this.signalsV2 = signalsV2;

    makeObservable(this, {
      customerFitSignals: observable,
      ltbSignals: observable,
      signalsV2: observable,
    });
  }
}
