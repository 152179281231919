import { ModelType } from '../ModelItem';

export type Subject = {
  subject: string;
  type: string;
  context: string;
  tags: string[];
};

export type Verb =
  | 'is'
  | 'is not'
  | 'contains'
  | 'does not contain'
  | 'is less than or equal to'
  | 'is more than or equal to'
  | 'begins with'
  | 'ends with'
  | 'is more than'
  | 'is less than'
  | 'is any of'
  | 'is none of'
  | 'is between'
  | 'is known'
  | 'is unknown';

type VerbSettings = {
  value: Verb;
  modelTypes: ModelType[];
  characteristics: {
    min: number;
    max: number;
    types: string[];
  };
};

const verbsSettings: VerbSettings[] = [
  {
    value: 'is',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: {
      min: 1,
      max: 1,
      types: ['string', 'number', 'date', 'boolean'],
    },
  },
  {
    value: 'is not',
    modelTypes: ['customer_fit'],
    characteristics: {
      min: 1,
      max: 1,
      types: ['string', 'number', 'date', 'boolean'],
    },
  },
  {
    value: 'contains',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 1, types: ['string'] },
  },
  {
    value: 'does not contain',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 1, types: ['string'] },
  },
  {
    value: 'is less than or equal to',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: { min: 1, max: 1, types: ['number', 'date'] },
  },
  {
    value: 'is more than or equal to',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: { min: 1, max: 1, types: ['number', 'date'] },
  },
  {
    value: 'begins with',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 1, types: ['string'] },
  },
  {
    value: 'ends with',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 1, types: ['string'] },
  },
  {
    value: 'is more than',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: { min: 1, max: 1, types: ['number', 'date'] },
  },
  {
    value: 'is less than',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: { min: 1, max: 1, types: ['number', 'date'] },
  },
  {
    value: 'is any of',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 99, types: ['string', 'number'] },
  },
  {
    value: 'is none of',
    modelTypes: ['customer_fit'],
    characteristics: { min: 1, max: 99, types: ['string', 'number'] },
  },
  {
    value: 'is between',
    modelTypes: ['customer_fit', 'mqa'],
    characteristics: { min: 2, max: 2, types: ['number', 'date'] },
  },
  {
    value: 'is known',
    modelTypes: ['customer_fit'],
    characteristics: { min: 0, max: 0, types: ['string', 'number', 'date'] },
  },
  {
    value: 'is unknown',
    modelTypes: ['customer_fit'],
    characteristics: { min: 0, max: 0, types: ['string', 'number', 'date'] },
  },
];

export type Condition = {
  subject: Subject;
  object?: string;
  verb: Verb;
  complements: string[];
  lower: boolean;
  error?: boolean;
};

export type SqlForm = {
  conditionLogic: string;
  conditions: Condition[];
};

export function getVerbsSettings(
  options: { signals: boolean } = { signals: false }
): VerbSettings[] {
  if (!options.signals) {
    return verbsSettings.filter(
      (verbSetting) => !['begins with', 'ends with'].includes(verbSetting.value)
    );
  }

  return verbsSettings;
}

export function getVerbSettings(verb: Verb): VerbSettings {
  return verbsSettings.find(({ value }) => value === verb);
}
