import React from 'react';
import Highlighter from 'react-highlight-words';
import ReactSelect, { CSSObjectWithLabel, GroupBase } from 'react-select';
import ReactCreatableSelect from 'react-select/creatable';

import { Option, ReactSelectProps } from './types';

export interface CustomGenericSelectProps {
  controlStyles?: CSSObjectWithLabel;
  highlightSearched?: boolean;
  color?: string;
}

export type GenericSelectProps<
  MadOption extends Option,
  IsMulti extends boolean,
  IsFree extends boolean
> = CustomGenericSelectProps &
  ReactSelectProps<MadOption, IsMulti, GroupBase<MadOption>, IsFree> & {
    isFree?: IsFree;
  };

/**
 * GenericSelect is a generic component that add ahead React-Select component :
 *    - simplified css styling
 *    - search highlight
 *    - abstract creatable select and select in a single comp
 *
 * It aim to be generic and be a base for studio selects component.
 *
 * @see MadSelect, MadMultipleSelect
 *
 */
function GenericSelect<
  MadOption extends Option,
  IsMulti extends boolean,
  IsFree extends boolean
>(props: GenericSelectProps<MadOption, IsMulti, IsFree>) {
  const {
    controlStyles,
    highlightSearched = true,
    isFree = false,
    color = '#1e77cc',
  } = props;

  const SelectComponent = isFree ? ReactCreatableSelect : ReactSelect;

  return (
    <SelectComponent
      {...props}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: `${color}30`,
          primary: color,
        },
      })}
      styles={{
        control: (base) => ({
          ...base,
          ...controlStyles,
        }),
        ...props.styles,
      }}
      formatOptionLabel={
        highlightSearched &&
        ((option, { inputValue, selectValue }) =>
          !selectValue.includes(option) ? (
            <Highlighter
              textToHighlight={option.label}
              searchWords={[inputValue]}
              highlightStyle={{
                padding: 0,
                backgroundColor: '#ffff99',
              }}
            />
          ) : (
            <span>{option.label}</span>
          ))
      }
    />
  );
}

export default GenericSelect;
