import { EventMetadata } from '../../../models/event_metadata';
import { Option } from '../generic_components/select_component/types';
import { OPTIONS_MAPPER_SUBJECT_VALUES } from '../../../models/rules';

/**
 * Generate Options from the given subject value & the eventMetadata for the CreatableSelect(MultiSelect) Component
 * @param subjectValue
 * @param eventMetadata
 */
export function aggregationOptionsMapper(
  subjectValue: OPTIONS_MAPPER_SUBJECT_VALUES,
  { metaEvents, activityTypes }: EventMetadata
): Option[] {
  switch (subjectValue) {
    case 'activity_type':
      return activityTypes?.map((activityType) => ({
        value: activityType,
        label: activityType,
      }));
    case 'meta_event':
      return metaEvents?.map((metaEvent) => ({
        value: metaEvent,
        label: metaEvent,
      }));
    default:
      return [
        { value: 'true', label: 'true' },
        { value: 'false', label: 'false' },
      ];
  }
}
