import { makeObservable, observable } from 'mobx';

import { FromRedshiftTablesTrainingVariables } from '../audience/FromRedshiftTables';
import GenericDatasetPageModel from './GenericDatasetPageModel';

export default class RedshiftPageModel extends GenericDatasetPageModel {
  variables: FromRedshiftTablesTrainingVariables;

  constructor(other?: RedshiftPageModel) {
    super();
    if (other) {
      this.variables = other.variables;
    } else {
      this.variables = {
        trainingTableName: '',
        validationTableName: '',
      };
    }
    makeObservable(this, {
      variables: observable,
    });
  }
}
