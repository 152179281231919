import { makeObservable, observable } from 'mobx';

export default class DeployPageModel {
  deployPopupIsShown: boolean;

  isDeploying: boolean;

  modelIsDeployed: boolean;

  deploymentError: string;

  isLoadingLastCommits: boolean;

  impactPopupIsShown: boolean;

  lastCommits: {
    [key: string]: { author: string; date: string; isDeployValid: boolean };
  };

  constructor(other?: DeployPageModel) {
    if (other) {
      this.deployPopupIsShown = other.deployPopupIsShown;
      this.isDeploying = other.isDeploying;
      this.modelIsDeployed = other.modelIsDeployed;
      this.deploymentError = other.deploymentError;
      this.isLoadingLastCommits = other.isLoadingLastCommits;
      this.impactPopupIsShown = other.impactPopupIsShown;
      this.lastCommits = other.lastCommits;
    } else {
      this.deployPopupIsShown = false;
      this.isDeploying = false;
      this.modelIsDeployed = false;
      this.deploymentError = null;
      this.isLoadingLastCommits = false;
      this.impactPopupIsShown = false;
      this.lastCommits = {};
    }
    makeObservable(this, {
      deployPopupIsShown: observable,
      isDeploying: observable,
      modelIsDeployed: observable,
      deploymentError: observable,
      isLoadingLastCommits: observable,
      impactPopupIsShown: observable,
      lastCommits: observable,
    });
  }
}
