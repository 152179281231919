import { inject, observer } from 'mobx-react';
import { utc } from 'moment-mini';
import React from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import Store from '../../store';
import ComputationForm from '../components/computations/ComputationForm';
import PageHeader from '../components/PageHeader';
import Spinner from '../components/Spinner';

interface NewComputationPageProps extends RouteComponentProps<any> {
  store?: Store;
}

const NewComputationPage = inject('store')(
  observer(
    class NewComputationPage extends React.Component<
      NewComputationPageProps,
      Record<string, never>
    > {
      constructor(props: NewComputationPageProps) {
        super(props);

        this.props.store.computationsPage.activeComputation = null;

        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
      }

      async componentDidMount() {
        this.props.store.computationsPage.loading = true;
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.message = null;
        await this.props.store.getComputations();
        await this.props.store.getMetadataFieldsForComputations();
        if (this.props.store.computationsPage.computations.length === 0) {
          await this.props.store.initComputations();
          await this.props.store.getComputations();
        }

        const maxIndex =
          Math.max.apply(
            null,
            this.props.store.computationsPage.computations.map((c) => c.index)
          ) + 1;
        const defaultFieldName: string =
          this.props.store.computationsPage.metadataFields[0]?.name || '';
        this.props.store.computationsPage.activeComputation = {
          index: maxIndex + 1,
          name: '',
          type: 'string',
          context: 'configured',
          configuration: {
            modeProperties: {
              conditional: {
                conditions: [
                  {
                    _id: uuid(),
                    targetComputation: '',
                    directedConditions: [
                      {
                        condition: {
                          field: defaultFieldName,
                          values: [''],
                          verb: 'is',
                        },
                      },
                    ],
                  },
                ],
                elseValue: 'NULL',
              },
              advanced: {
                query: '',
              },
              oneToOne: {
                field: defaultFieldName,
              },
            },
            mode: 'conditional',
          },
          tags: ['custom'],
          category: {
            type: 'static',
          },
        };
        this.props.store.computationsPage.message = null;
        this.props.store.computationsPage.formError = null;
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.loading = false;
      }

      async save() {
        const { computationsPage } = this.props.store;
        const { activeComputation } = computationsPage;
        activeComputation.updatedAt = utc().format('YYYY-MM-DD');
        activeComputation.isDraft = true;
        this.props.store.computationsPage.computations.push(activeComputation);
        // TODO: Rethink the save computations' workflow
        const saved =
          await this.props.store.computationsPage.saveComputationsCache(
            this.props.store.tenant
          );
        if (saved) {
          this.back();
        } else {
          this.props.store.computationsPage.computations.pop();
        }
      }

      back() {
        const { tenant } = this.props.store;
        this.props.history.push({
          pathname: `/tenant/${tenant}/computations`,
        });
      }

      render() {
        if (!this.props.store.computationsPage.activeComputation) {
          return <Spinner />;
        }

        return (
          <>
            <PageHeader>
              <Breadcrumb className="fs-5 mb-2">
                <LinkContainer
                  to={`/tenant/${this.props.match.params.tenant}/computations`}
                >
                  <Breadcrumb.Item>Computations</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active className="fw-bold">
                  New computation
                </Breadcrumb.Item>
              </Breadcrumb>
            </PageHeader>

            <div className="mt-3 mb-5">
              <Card className="w-75 mx-auto">
                <Card.Body>
                  <ComputationForm
                    back={this.back}
                    save={this.save}
                    isNewComputation={true}
                  />
                </Card.Body>
              </Card>
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(NewComputationPage);
