import { action, makeObservable, observable } from 'mobx';
import { SqlForm } from '../sqlform';
import TreeHistory from './TreeHistory';
import Tree from './TreeLite';
import axios from 'axios';
import TreeDefinition from './TreeDefinition';

export default class TreesPageModel {
  trees: Tree[] = [];

  treePosition: { x: number; y: number };

  treeError: string;

  activeInsertChildNode: boolean;

  activeReplaceCondition: boolean;

  activeDeleteCondition: boolean;

  currentSplitCondition: SqlForm;

  savingSplitCondition: boolean;

  savingSplitConditionError: string;

  deletingSplitCondition: boolean;

  deletingSplitConditionError: string;

  resetingTree: boolean;

  resetTreeIsShown: boolean;

  advancedModeIsShown: boolean;

  historyIsShown: boolean;

  treeHistory: TreeHistory[];

  commitContent: string;

  updatingTree: boolean;

  isCommitDisplayLoading: boolean;

  activeAutoSelectTrees: boolean;

  autoSelectingTrees: boolean;

  constructor(other?: TreesPageModel) {
    if (other) {
      this.trees = other.trees;
      this.treeError = other.treeError;
      this.treePosition = other.treePosition;
      this.activeInsertChildNode = other.activeInsertChildNode;
      this.activeReplaceCondition = other.activeReplaceCondition;
      this.activeDeleteCondition = other.activeDeleteCondition;
      this.currentSplitCondition = other.currentSplitCondition;
      this.resetingTree = other.resetingTree;
      this.resetTreeIsShown = other.resetTreeIsShown;
      this.advancedModeIsShown = other.advancedModeIsShown;
      this.historyIsShown = other.historyIsShown;
      this.treeHistory = other.treeHistory;
      this.commitContent = other.commitContent;
      this.updatingTree = other.updatingTree;
      this.isCommitDisplayLoading = other.isCommitDisplayLoading;
      this.savingSplitCondition = other.savingSplitCondition;
      this.savingSplitConditionError = other.savingSplitConditionError;
      this.deletingSplitCondition = other.deletingSplitCondition;
      this.deletingSplitConditionError = other.deletingSplitConditionError;
      this.activeAutoSelectTrees = other.activeAutoSelectTrees;
      this.autoSelectingTrees = other.autoSelectingTrees;
    } else {
      this.trees = [];
      this.treeError = null;
      this.treeHistory = [];
      this.commitContent = '';
      this.isCommitDisplayLoading = false;
      this.treePosition = { x: 300, y: 20 };
      this.activeInsertChildNode = false;
      this.activeReplaceCondition = false;
      this.activeDeleteCondition = false;
      this.currentSplitCondition = null;
      this.resetingTree = false;
      this.resetTreeIsShown = false;
      this.advancedModeIsShown = false;
      this.historyIsShown = false;
      this.updatingTree = false;
      this.savingSplitCondition = false;
      this.savingSplitConditionError = null;
      this.deletingSplitCondition = false;
      this.deletingSplitConditionError = null;
      this.activeAutoSelectTrees = false;
      this.autoSelectingTrees = false;
    }
    makeObservable(this, {
      trees: observable,
      treePosition: observable,
      treeError: observable,
      activeInsertChildNode: observable,
      activeReplaceCondition: observable,
      activeDeleteCondition: observable,
      currentSplitCondition: observable,
      savingSplitCondition: observable,
      savingSplitConditionError: observable,
      deletingSplitCondition: observable,
      deletingSplitConditionError: observable,
      resetingTree: observable,
      resetTreeIsShown: observable,
      advancedModeIsShown: observable,
      historyIsShown: observable,
      treeHistory: observable,
      commitContent: observable,
      updatingTree: observable,
      isCommitDisplayLoading: observable,
      activeAutoSelectTrees: observable,
      autoSelectingTrees: observable,
      loadBestTrees: action,
      resetTree: action,
      loadAll: action,
      loadOne: action,
    });
  }

  async loadBestTrees(tenant: number, modelId: number): Promise<void> {
    this.treeError = null;
    try {
      const { data } = await axios.get(
        `/api/tenant/${tenant}/model/${modelId}/autotrees`
      );
      this.trees = data;
    } catch (err) {
      this.treeError = err?.response?.body?.message ?? err?.message;
    }
  }

  async resetTree(
    tenant: number,
    modelId: number,
    treeId: number
  ): Promise<void> {
    this.treeError = null;

    try {
      await axios.put(
        `/api/tenant/${tenant}/model/${modelId}/trees/${treeId}/reset`
      );
    } catch (err) {
      this.treeError = err?.response?.body?.message ?? err?.message;
    }
  }

  async loadAll(tenant: number, modelId: number): Promise<void> {
    this.treeError = null;

    try {
      const { data: trees } = await axios.get(
        `/api/tenant/${tenant}/model/${modelId}/trees`
      );
      this.trees = trees;
    } catch (err) {
      this.treeError = err?.response?.body?.message ?? err?.message;
    }
  }

  async loadOne(
    tenant: number,
    modelId: number,
    treeId: number
  ): Promise<Tree> {
    const tree = this.trees.find(({ id }) => id === treeId);
    tree.error = null;
    tree.definition = null;
    tree.loading = true;
    try {
      const { data: treeDefinition } = await axios.get(
        `/api/tenant/${tenant}/model/${modelId}/trees/${treeId}`
      );
      tree.definition = new TreeDefinition(
        treeDefinition.yaml,
        treeDefinition.name,
        treeDefinition.stats,
        treeDefinition.structure,
        treeDefinition.performances,
        treeDefinition.precisions
      );
    } catch (err) {
      this.treeError = tree.error =
        err?.response?.body?.message ?? err?.message;
    }
    tree.loading = false;
    return tree;
  }
}
