import DataSetType from '../audience/DataSetType';
import DataSetOverview from './DataSetOverview';

/**
 * A DataSetOverviews is a class that have a list of DataSetOverview
 */
export default class DataSetOverviews {
  overviews: DataSetOverview[];

  constructor(overviews: DataSetOverview[]) {
    this.overviews = overviews;
  }

  /**
   * Get the datasetoverview based on the given type
   * @param {DataSetType} datasetType
   * @returns {DataSetOverview} the target DatasetOverview
   */
  get(datasetType: DataSetType): DataSetOverview {
    return this.overviews.find(({ type }) => type === datasetType);
  }

  /**
   * Get the first bad datasetoverview quality found
   * A bad quality dataset is a dataset with < 1200 records
   * @returns {DataSetOverview | undefined} returns the bad DataSetOverview if found, or undefined
   */
  getBadQuality(): DataSetOverview | undefined {
    return this.overviews.find(
      ({ dataSetQuality }) => dataSetQuality !== 'good'
    );
  }
}
