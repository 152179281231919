import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Col,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import PointBasedRuleDataModel from '../../../models/pointBasedForm/PointBasedRuleDataModel';
import { Verb } from '../../../models/sqlform';
import Store from '../../../store';
import PointBasedRuleConditionsComponent from './PointBasedRuleConditionsComponent';

interface PointBasedRuleComponentProps {
  store?: Store;
  key: number;
  isDisabled: boolean;
  pointBasedRule: PointBasedRuleDataModel;
  pointBasedRuleIndex: number;
  groupPointBasedRulesIndex: number;
  handleOnChangeTrait: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    value: string
  ) => void;
  handleOnChangeCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    verb: Verb
  ) => void;
  handleAddValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleRemoveValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number
  ) => void;
  handleOnChangeValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number,
    value: string
  ) => void;
  handleOnPointsChange: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    value: number
  ) => void;
  handleOnClickRemoveRule: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number
  ) => void;
  handleOnClickAddSubCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number
  ) => void;
  handleOnClickRemoveSubCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleOnClickCaseSensitive: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    isLower: boolean
  ) => void;
}

interface PointBasedRuleComponentState {
  falseNegativesFormCheck: boolean;
}

export default inject('store')(
  observer(
    class PointBasedRuleComponent extends React.Component<
      PointBasedRuleComponentProps,
      PointBasedRuleComponentState
    > {
      renderPointBasedConditions() {
        const {
          groupPointBasedRulesIndex,
          pointBasedRuleIndex,
          pointBasedRule,
          handleOnChangeTrait,
          handleOnChangeCondition,
          handleAddValue,
          handleRemoveValue,
          handleOnChangeValue,
          handleOnClickAddSubCondition,
          handleOnClickRemoveSubCondition,
          handleOnClickCaseSensitive,
          isDisabled,
        } = this.props;
        return (
          <Col xs={8}>
            {pointBasedRule &&
              pointBasedRule.conditions.map(
                (pointBasedCondition, pointBasedConditionIndex) => {
                  return (
                    <div
                      key={`point_based_rule_conditions_${pointBasedConditionIndex}`}
                    >
                      <PointBasedRuleConditionsComponent
                        key={pointBasedConditionIndex}
                        isDisabled={isDisabled}
                        pointBasedCondition={pointBasedCondition}
                        pointBasedConditionIndex={pointBasedConditionIndex}
                        groupPointBasedRulesIndex={groupPointBasedRulesIndex}
                        pointBasedRuleIndex={pointBasedRuleIndex}
                        handleOnChangeTrait={handleOnChangeTrait}
                        handleOnChangeCondition={handleOnChangeCondition}
                        handleAddValue={handleAddValue}
                        handleRemoveValue={handleRemoveValue}
                        handleOnChangeValue={handleOnChangeValue}
                        canBeRemoved={pointBasedConditionIndex !== 0}
                        handleOnClickRemoveSubCondition={
                          handleOnClickRemoveSubCondition
                        }
                        handleOnClickCaseSensitive={handleOnClickCaseSensitive}
                      />
                      {pointBasedConditionIndex === 0 && (
                        <Button
                          variant="link"
                          key={`add_sub_condition_btn_${pointBasedConditionIndex}`}
                          disabled={isDisabled}
                          onClick={() =>
                            handleOnClickAddSubCondition(
                              groupPointBasedRulesIndex,
                              pointBasedRuleIndex
                            )
                          }
                        >
                          + AND
                        </Button>
                      )}
                    </div>
                  );
                }
              )}
          </Col>
        );
      }

      render() {
        const {
          handleOnClickRemoveRule,
          groupPointBasedRulesIndex,
          pointBasedRuleIndex,
          pointBasedRule,
          handleOnPointsChange,
          isDisabled,
        } = this.props;
        const { points } = pointBasedRule;

        return (
          <>
            <Row>
              {this.renderPointBasedConditions()}
              <Col xs={1}>
                <strong>THEN</strong>
              </Col>
              <Col xs={2}>
                <FormControl
                  type="number"
                  name="points"
                  pattern="\d*"
                  disabled={isDisabled}
                  value={points}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnPointsChange(
                      groupPointBasedRulesIndex,
                      pointBasedRuleIndex,
                      Number(event.target.value)
                    )
                  }
                />
              </Col>
              <Col xs={1}>
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">Remove the rule</Tooltip>}
                >
                  <Button
                    onClick={() =>
                      handleOnClickRemoveRule(
                        groupPointBasedRulesIndex,
                        pointBasedRuleIndex
                      )
                    }
                    disabled={isDisabled}
                    variant="danger"
                  >
                    <i aria-hidden className="fas fa-trash" />
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
            <hr />
          </>
        );
      }
    }
  )
);
