import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface UnreleasedAggregationsComponentProps {
  deleting: boolean;
  display: 'aggregations' | 'aggregated_events';
  shouldShow: boolean;
  deleteAggregation: () => Promise<void>;
  toggleModal: () => void;
}

export default class UnreleasedAggregations extends React.Component<
  UnreleasedAggregationsComponentProps,
  Record<string, never>
> {
  getEntity() {
    const { display } = this.props;

    if (display === 'aggregations') return 'aggregation';
    if (display === 'aggregated_events') return 'aggregated event';
  }

  render() {
    const { deleting, display, shouldShow, deleteAggregation, toggleModal } =
      this.props;

    if (!display) return null;

    return (
      <div>
        <Modal show={shouldShow} onHide={toggleModal} size="lg">
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The {this.getEntity()} will be permanently removed from this tenant.
            This may break your model if it actively uses this{' '}
            {this.getEntity()}.
            {display === 'aggregations' && (
              <>
                <br />
                <strong>
                  Deleting the aggregation will also delete the associated
                  aggregated events. Are you sure you want to proceed?
                </strong>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={toggleModal}
              disabled={deleting}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={deleteAggregation}
              disabled={deleting}
            >
              {deleting ? 'Deleting...' : 'Delete'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
