import { inject, observer } from 'mobx-react';
import React from 'react';
import { Accordion, Alert, Button, ButtonToolbar, Card } from 'react-bootstrap';
import GroupPointBasedRulesDataModel from '../../../models/pointBasedForm/GroupPointBasedRulesDataModel';
import PointBasedConditionDataModel from '../../../models/pointBasedForm/PointBasedConditionDataModel';
import PointBasedRuleDataModel from '../../../models/pointBasedForm/PointBasedRuleDataModel';
import { getVerbSettings, Subject, Verb } from '../../../models/sqlform';
import Store from '../../../store';
import ScrollButton from '../../components/generic_components/ScrollButton';
import PointBasedRuleGroupComponent from '../../components/rules/PointBasedRuleGroupComponent';
import Spinner from '../../components/Spinner';

interface ScoringRulesPageProps {
  store?: Store;
}

interface ScoringRulesPageState {
  falseNegativesFormCheck: boolean;
}

export default inject('store')(
  observer(
    class ScoringRulesPage extends React.Component<
      ScoringRulesPageProps,
      ScoringRulesPageState
    > {
      constructor(props: ScoringRulesPageProps) {
        super(props);

        // For children
        this.handleOnChangeTrait = this.handleOnChangeTrait.bind(this);
        this.handleOnChangeCondition = this.handleOnChangeCondition.bind(this);
        this.handleAddValue = this.handleAddValue.bind(this);
        this.handleRemoveValue = this.handleRemoveValue.bind(this);
        this.handleOnChangeValue = this.handleOnChangeValue.bind(this);
        this.handleOnPointsChange = this.handleOnPointsChange.bind(this);
        this.handleOnClickAddNewRule = this.handleOnClickAddNewRule.bind(this);
        this.handleOnClickRemoveRule = this.handleOnClickRemoveRule.bind(this);
        this.handleOnClickAddSubCondition =
          this.handleOnClickAddSubCondition.bind(this);
        this.handleOnClickRemoveSubCondition =
          this.handleOnClickRemoveSubCondition.bind(this);
        this.handleOnClickCaseSensitive =
          this.handleOnClickCaseSensitive.bind(this);
        // This page functions
        this.handleOnClickAddNewGroupRule =
          this.handleOnClickAddNewGroupRule.bind(this);
        this.handleOnClickSaveChanges =
          this.handleOnClickSaveChanges.bind(this);
      }

      async componentDidMount() {
        await this.props.store.getFormFieldsAndPointBasedRules();
      }

      createDefaultCondition() {
        const { formFields } = this.props.store;
        const firstStandardField = formFields.filter((field) => {
          return field.context === 'standard';
        })[0];
        return new PointBasedConditionDataModel(firstStandardField);
      }

      handleOnClickAddSubCondition(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions.push(this.createDefaultCondition());
      }

      handleOnClickRemoveSubCondition(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions.splice(pointBasedConditionIndex, 1);
      }

      createDefaultRule() {
        const defaultCondition: PointBasedConditionDataModel =
          this.createDefaultCondition();
        const pointBasedRule: PointBasedRuleDataModel = {
          conditions: [defaultCondition],
          points: 0,
        };
        return new PointBasedRuleDataModel(pointBasedRule);
      }

      handleOnClickAddNewRule(groupPointBasedRulesIndex: number) {
        const { groupsPointBasedRules } = this.props.store;
        const defaultRule: PointBasedRuleDataModel = this.createDefaultRule();
        groupsPointBasedRules[groupPointBasedRulesIndex].rules.push(
          defaultRule
        );
      }

      handleOnClickRemoveRule(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules.splice(
          pointBasedRuleIndex,
          1
        );
        this.checkRulesLengthAndRemoveGroup(groupPointBasedRulesIndex);
      }

      checkRulesLengthAndRemoveGroup(groupPointBasedRulesIndex: number) {
        const { groupsPointBasedRules } = this.props.store;
        const isGroupEmptyFromRules: boolean =
          groupsPointBasedRules[groupPointBasedRulesIndex].rules.length === 0;
        if (isGroupEmptyFromRules) {
          this.removeGroup(groupPointBasedRulesIndex);
        }
      }

      removeGroup(groupPointBasedRulesIndex: number) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules.splice(groupPointBasedRulesIndex, 1);
      }

      handleOnClickAddNewGroupRule() {
        const { groupsPointBasedRules } = this.props.store;
        const newGroupPointBasedRules: GroupPointBasedRulesDataModel =
          new GroupPointBasedRulesDataModel(groupsPointBasedRules.length + 1, [
            this.createDefaultRule(),
          ]);
        groupsPointBasedRules.push(newGroupPointBasedRules);
      }

      async handleOnClickSaveChanges() {
        await this.props.store.saveGroupsPointBasedRules();
      }

      handleOnChangeTrait(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number,
        value: string
      ) {
        const { formFields, groupsPointBasedRules } = this.props.store;

        const newTrait: Subject = formFields.find(
          (field) => field.subject === value
        );
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].trait = newTrait;
      }

      handleOnChangeCondition(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number,
        verb: Verb
      ) {
        const { groupsPointBasedRules } = this.props.store;
        const verbSettings = getVerbSettings(verb);
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].verb = verb;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].values = Array(
          verbSettings.characteristics.min
        ).fill('');
      }

      handleAddValue(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].values.push('');
      }

      handleRemoveValue(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number,
        valueIndex: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].values.splice(valueIndex, 1);
      }

      handleOnChangeValue(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number,
        valueIndex: number,
        value: string
      ) {
        const { groupsPointBasedRules } = this.props.store;
        const { trait } =
          groupsPointBasedRules[groupPointBasedRulesIndex].rules[
            pointBasedRuleIndex
          ].conditions[pointBasedConditionIndex];
        const error = trait.type === 'number' && isNaN(Number(value));
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].values[valueIndex] = value;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].error = error;
      }

      handleOnPointsChange(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        value: number
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].points = value;
      }

      handleOnClickCaseSensitive(
        groupPointBasedRulesIndex: number,
        pointBasedRuleIndex: number,
        pointBasedConditionIndex: number,
        isLower: boolean
      ) {
        const { groupsPointBasedRules } = this.props.store;
        groupsPointBasedRules[groupPointBasedRulesIndex].rules[
          pointBasedRuleIndex
        ].conditions[pointBasedConditionIndex].isLower = isLower;
      }

      renderPointBasedGroups() {
        const { groupsPointBasedRules, isAllowedToEdit } = this.props.store;
        const { isSaving } = this.props.store.pointBasedScoringRulePage;

        return (
          <Accordion className="mb-3 mt-4">
            {groupsPointBasedRules &&
              groupsPointBasedRules.map(
                (groupPointBasedRules, groupPointBasedRulesIndex) => {
                  return (
                    <PointBasedRuleGroupComponent
                      key={groupPointBasedRulesIndex}
                      isDisabled={isSaving || !isAllowedToEdit}
                      groupPointBasedRules={groupPointBasedRules}
                      groupPointBasedRulesIndex={groupPointBasedRulesIndex}
                      handleOnChangeTrait={this.handleOnChangeTrait}
                      handleOnChangeCondition={this.handleOnChangeCondition}
                      handleAddValue={this.handleAddValue}
                      handleRemoveValue={this.handleRemoveValue}
                      handleOnChangeValue={this.handleOnChangeValue}
                      handleOnPointsChange={this.handleOnPointsChange}
                      handleOnClickAddNewRule={this.handleOnClickAddNewRule}
                      handleOnClickRemoveRule={this.handleOnClickRemoveRule}
                      handleOnClickAddSubCondition={
                        this.handleOnClickAddSubCondition
                      }
                      handleOnClickRemoveSubCondition={
                        this.handleOnClickRemoveSubCondition
                      }
                      handleOnClickCaseSensitive={
                        this.handleOnClickCaseSensitive
                      }
                    />
                  );
                }
              )}
          </Accordion>
        );
      }

      render() {
        const { isAllowedToEdit, pointBasedScoringRulePage } = this.props.store;
        const { isLoading, isSaving, isError, errorMessage, isSuccess } =
          pointBasedScoringRulePage;

        return (
          <div className="mt-4 d-grid gap-2 me-2 ms-2">
            <Card border="light">
              <Card.Body>
                <p>
                  Create here your scoring rules. The total points associated to
                  a record is the sum of the points accumulated in each group. A
                  record gets points from only the first rule verified in each
                  group.
                </p>
                <a
                  href="https://support.madkudu.com/hc/en-us/articles/4404613282317-Customer-Fit-Point-based-model"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How to build
                  a point based model
                </a>
              </Card.Body>
            </Card>
            {isError && (
              <Alert variant="danger">
                Something went wrong: {errorMessage}, the actual configuration
                is not saved. However, this won&apos;t affect your model, we
                keep the oldest valid configuration saved!
              </Alert>
            )}
            {isSuccess && (
              <Alert variant="success">Configuration saved correctly!</Alert>
            )}
            <>{isLoading ? <Spinner /> : this.renderPointBasedGroups()}</>
            {!isLoading && (
              <>
                <ButtonToolbar>
                  <Button
                    variant="primary"
                    disabled={isSaving || !isAllowedToEdit}
                    onClick={() => this.handleOnClickAddNewGroupRule()}
                    className="me-2"
                  >
                    Add group
                  </Button>
                  <Button
                    variant="success"
                    disabled={isSaving || !isAllowedToEdit}
                    onClick={() => this.handleOnClickSaveChanges()}
                  >
                    {isSaving ? 'Saving...' : 'Save changes'}
                  </Button>
                </ButtonToolbar>
                <ScrollButton />
              </>
            )}
          </div>
        );
      }
    }
  )
);
