import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  Condition,
  getVerbSettings,
  Subject,
  Verb,
} from '../../../models/sqlform';
import Store from '../../../store';
import { getDefaultCondition } from '../../../utils';
import ConditionsForm from '../ConditionsForm';

interface SplitConditionFormProps {
  store?: Store;
  active: boolean;
  treeId: number;
  treeName: string;
  nodeId: number;
  replace?: boolean;
}

export default inject('store')(
  observer(
    class SplitConditionForm extends React.Component<
      SplitConditionFormProps,
      Record<string, never>
    > {
      constructor(props: SplitConditionFormProps) {
        super(props);

        makeObservable(this, {
          closePopup: action,
        });

        this.closePopup = this.closePopup.bind(this);
        this.handleSubjectSelect = this.handleSubjectSelect.bind(this);
        this.handleSubjectUnselect = this.handleSubjectUnselect.bind(this);
        this.setConditionLogic = this.setConditionLogic.bind(this);
        this.handleVerbChange = this.handleVerbChange.bind(this);
        this.handleComplementChange = this.handleComplementChange.bind(this);
        this.saveSplitCondition = this.saveSplitCondition.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.handleAddComplement = this.handleAddComplement.bind(this);
        this.handleRemoveComplement = this.handleRemoveComplement.bind(this);
        this.handleLower = this.handleLower.bind(this);
      }

      closePopup() {
        this.props.store.treesPage.activeInsertChildNode = false;
        this.props.store.treesPage.activeReplaceCondition = false;
        this.props.store.resetSplitCondition();
      }

      async saveSplitCondition() {
        const { treeId, nodeId, replace, treeName } = this.props;
        if (replace) {
          await this.props.store.replaceSplitCondition(
            treeId,
            treeName,
            nodeId
          );
        } else {
          await this.props.store.insertChildNode(treeId, treeName, nodeId);
        }
      }

      setConditionLogic(logic: string) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditionLogic = logic;
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleSubjectSelect(subject: Subject) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        const newCondition = getDefaultCondition();
        newCondition.subject = subject;
        newSplitCondition.conditions.push(newCondition);
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleSubjectUnselect(condition: Condition) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        const index = newSplitCondition.conditions.indexOf(condition);
        if (index > -1) {
          newSplitCondition.conditions.splice(index, 1);
          this.props.store.updateSplitCondition(newSplitCondition);
        }
      }

      handleVerbChange(verb: Verb, conditionIndex: number) {
        const verbSettings = getVerbSettings(verb);
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions[conditionIndex].verb = verb;
        newSplitCondition.conditions[conditionIndex].complements = Array(
          verbSettings.characteristics.min
        ).fill('');
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleComplementChange(
        value: string,
        conditionIndex: number,
        complementIndex: number,
        error: boolean
      ) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions[conditionIndex].complements[
          complementIndex
        ] = value;
        newSplitCondition.conditions[conditionIndex].error = error;
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      deleteCondition(conditionIndex: number) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions.splice(conditionIndex, 1);
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleAddComplement(conditionIndex: number) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions[conditionIndex].complements.push('');
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleRemoveComplement(conditionIndex: number, complementIndex: number) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions[conditionIndex].complements.splice(
          complementIndex,
          1
        );
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      handleLower(conditionIndex: number) {
        const newSplitCondition =
          this.props.store.treesPage.currentSplitCondition;
        newSplitCondition.conditions[conditionIndex].lower =
          !newSplitCondition.conditions[conditionIndex].lower;
        this.props.store.updateSplitCondition(newSplitCondition);
      }

      render() {
        const { treesPage, formFields } = this.props.store;
        const { currentSplitCondition, savingSplitCondition } = treesPage;
        const { active, replace } = this.props;

        const conditionsError = currentSplitCondition
          ? currentSplitCondition.conditions.filter(
              (c) =>
                c.error ||
                c.complements.some((co) => !co || co.trim().length === 0)
            ).length > 0
          : false;

        return (
          <Modal onHide={this.closePopup} show={active} size="xl">
            <Modal.Header>
              <Modal.Title>
                {replace ? 'Replace condition' : 'Set new condition'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {formFields && (
                <ConditionsForm
                  sqlForm={currentSplitCondition}
                  possibleSubjects={formFields}
                  setConditionLogic={this.setConditionLogic}
                  handleSubjectSelect={this.handleSubjectSelect}
                  handleSubjectUnselect={this.handleSubjectUnselect}
                  handleVerbChange={this.handleVerbChange}
                  handleComplementChange={this.handleComplementChange}
                  handleAddComplement={this.handleAddComplement}
                  handleRemoveComplement={this.handleRemoveComplement}
                  handleLower={this.handleLower}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.closePopup}
                disabled={savingSplitCondition}
                variant="outline-primary"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.saveSplitCondition}
                disabled={savingSplitCondition || conditionsError}
              >
                {savingSplitCondition
                  ? 'Saving conditions...'
                  : 'Save conditions'}
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }
    }
  )
);
