import { makeObservable, observable } from 'mobx';

export default class ModelBasePageDataModel {
  isSaving: boolean;

  isLoading: boolean;

  constructor(other?: ModelBasePageDataModel) {
    if (other) {
      this.isSaving = other.isSaving;
      this.isLoading = other.isLoading;
    } else {
      this.isSaving = false;
      this.isLoading = false;
    }
    makeObservable(this, {
      isSaving: observable,
      isLoading: observable,
    });
  }
}
