import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { EnsembleThreshold, EnsembleWeight } from '../../models/ensembling';

interface SummaryParametersProps {
  weights: EnsembleWeight;
  thresholds: EnsembleThreshold;
  isTreeBased: boolean;
  behavioral?: boolean;
}

export default class SummaryParameters extends React.Component<
  SummaryParametersProps,
  Record<string, never>
> {
  render() {
    const { weights, thresholds, behavioral, isTreeBased } = this.props;
    const performancesWeights =
      !behavioral &&
      Object.keys(weights).map((weight) => {
        return [
          <dt className="col-sm-4" key={`perf_weight_tree_${weight}`}>
            Tree {weight}
          </dt>,
          <dd className="col-sm-8" key={`perf_weight_tree_${weight}_val`}>
            {weights[weight]}
          </dd>,
        ];
      });
    const performancesThresholds = (
      behavioral
        ? ['very high', 'high', 'medium']
        : ['very good', 'good', 'medium']
    ).map((threshold) => {
      return [
        <dt className="col-sm-4" key={`perf_weight_tree_${threshold}`}>
          {threshold}
        </dt>,
        <dd className="col-sm-8" key={`perf_weight_tree_${threshold}_val`}>
          {thresholds[threshold]}
        </dd>,
      ];
    });
    return (
      <Card bg="light mb-3">
        <Card.Body>
          <Card.Title className="text-center">With parameters</Card.Title>
          <Row>
            {!behavioral && isTreeBased && (
              <dl className="col-sm-6 row">{performancesWeights}</dl>
            )}
            <dl className="col-sm-6 row">{performancesThresholds}</dl>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
