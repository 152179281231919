import React, { useState, useEffect } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import UnivariateAnalysisVariableBucketWithShare from '../../../models/univariate/UnivariateAnalysisVariableBucketWithShare';
import { formatNumber } from '../../../utils/formatters';
import SortForm from './SortForm';

type Sort = 'population' | 'name' | 'lift';
type Order = 'asc' | 'desc';

type LiftChartProps = {
  bucketsWithShares: UnivariateAnalysisVariableBucketWithShare[];
  defaultSort: Sort;
};

function CustomizedAxisTick({ x, y, payload: { value } }: any): JSX.Element {
  const MAX_TICK_LENGTH = 25;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        width="20"
      >
        {value.length > MAX_TICK_LENGTH
          ? value.substr(0, MAX_TICK_LENGTH - 3).concat('...')
          : value}
      </text>
      <title>{value}</title>
    </g>
  );
}

const sortOptions = [
  { key: 'population', value: 'Population' },
  { key: 'name', value: 'Name' },
  { key: 'lift', value: 'Lift' },
];

function LiftChart({
  bucketsWithShares,
  defaultSort,
}: LiftChartProps): JSX.Element {
  const [order, setOrder] = useState<Order>('asc');
  const [sort, setSort] = useState<Sort>('population');

  useEffect(() => {
    setSort(defaultSort);
  }, [defaultSort]);

  const handleSortChange = (selectedSort: Sort) => {
    setSort(selectedSort);
  };

  const handleOrderChange = (selectedOrder: Order) => {
    setOrder(selectedOrder);
  };

  const sortElements = (
    a: UnivariateAnalysisVariableBucketWithShare,
    b: UnivariateAnalysisVariableBucketWithShare
  ): number => {
    switch (sort) {
      case 'lift':
        return order === 'asc' ? a.lift - b.lift : b.lift - a.lift;
      case 'population':
        return order === 'asc'
          ? b.population - a.population
          : a.population - b.population;
      case 'name':
      default:
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
    }
  };

  const data = bucketsWithShares
    .slice(0, 30)
    .sort(sortElements)
    .map(({ name, lift }) => ({ name, lift }));

  return (
    <>
      <div style={{ height: 360 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="name"
              minTickGap={0}
              height={140}
              tick={<CustomizedAxisTick />}
              interval={0}
            />
            <YAxis />
            <Tooltip formatter={formatNumber} />
            <Legend layout="horizontal" verticalAlign="top" align="center" />
            <ReferenceLine y={0} stroke="#000" />
            <Bar name="Lift factor" dataKey="lift" fill="#1E77CC" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <SortForm
        sort={sort}
        order={order}
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        onOrderChange={handleOrderChange}
      />
    </>
  );
}

export default LiftChart;
