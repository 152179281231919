import React, { useState } from 'react';
import { DateRangePicker as ADateRangePicker } from 'react-dates';
import moment from 'moment';

interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  onDatesChanged: ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => void;
}

const DateRangePicker = ({
  startDate,
  endDate,
  onDatesChanged,
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<
    null | 'startDate' | 'endDate'
  >(null);
  return (
    <ADateRangePicker
      startDate={moment(startDate)}
      startDateId="startDate"
      endDate={moment(endDate)}
      endDateId="endDate"
      onDatesChange={onDatesChanged}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => {
        setFocusedInput(focusedInput);
      }}
      displayFormat="LL"
      isOutsideRange={() => false}
      numberOfMonths={3}
      minimumNights={30}
      small
    />
  );
};

export default DateRangePicker;
