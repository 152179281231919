// The order in which these array elements are added is important.
// We need to start from the deepest URLs and most specific ones.
// /computations/new before /computations
// /aggregations/new before /aggregations/(.+)
const PATHNAME_PAGE_INFO_MAP = [
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/deployment$/,
    pageName: 'Model - Deployment',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/review\/performance$/,
    pageName: 'Model - Performance',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/review\/sample$/,
    pageName: 'Model - Sample',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/signals$/,
    pageName: 'Model - Signals',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/overrides$/,
    pageName: 'Model - Overrides',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/thresholds$/,
    pageName: 'Model - Thresholds',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/lead_grade$/,
    pageName: 'Model - Lead grade',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/event_weights$/,
    pageName: 'Model - Event weights',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex:
      /tenant\/(\d+)\/models\/(\d+)\/model\/trees\/(\d+)\/nodes\/(\d+)\/leads\/O$/,
    pageName: 'Model - Tree - Node - Sample of non converters',
    pageParams: ['tenant', 'model_id', 'tree_id', 'node_id'],
  },
  {
    pathRegex:
      /tenant\/(\d+)\/models\/(\d+)\/model\/trees\/(\d+)\/nodes\/(\d+)\/leads\/1$/,
    pageName: 'Model - Tree - Node - Sample of converters',
    pageParams: ['tenant', 'model_id', 'tree_id', 'node_id'],
  },
  {
    pathRegex:
      /tenant\/(\d+)\/models\/(\d+)\/model\/trees\/(\d+)\/nodes\/(\d+)\/insights$/,
    pageName: 'Model - Tree - Node - Insights',
    pageParams: ['tenant', 'model_id', 'tree_id', 'node_id'],
  },
  {
    pathRegex:
      /tenant\/(\d+)\/models\/(\d+)\/model\/trees\/(\d+)\/nodes\/(\d+)$/,
    pageName: 'Model - Tree - Node',
    pageParams: ['tenant', 'model_id', 'tree_id', 'node_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/trees\/(\d+)$/,
    pageName: 'Model - Tree',
    pageParams: ['tenant', 'model_id', 'tree_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/trees$/,
    pageName: 'Model - Trees',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/rules$/,
    pageName: 'Model - Rules',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/model\/base$/,
    pageName: 'Model - Base',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/insights$/,
    pageName: 'Model - Insights',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/overview$/,
    pageName: 'Model - Overview',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/(\d+)\/import_data\/.+$/,
    pageName: 'Model - Dataset parameters',
    pageParams: ['tenant', 'model_id'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models\/new$/,
    pageName: 'Create new model',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/models$/,
    pageName: 'Models list',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/computations\/new$/,
    pageName: 'New computation',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/computations\/(.+)$/,
    pageName: 'Computation',
    pageParams: ['tenant', 'computation'],
  },
  {
    pathRegex: /tenant\/(\d+)\/computations$/,
    pageName: 'Computations',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/aggregations\/(.+)\/frequency_analysis$/,
    pageName: 'Aggregation - Frequency analysis',
    pageParams: ['tenant', 'aggregation'],
  },
  {
    pathRegex: /tenant\/(\d+)\/aggregations\/new$/,
    pageName: 'New aggregation',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/aggregations\/(.+)$/,
    pageName: 'Aggregation',
    pageParams: ['tenant', 'aggregation'],
  },
  {
    pathRegex: /tenant\/(\d+)\/aggregations$/,
    pageName: 'Aggregations',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/helpCenter$/,
    pageName: 'Help center',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)\/404$/,
    pageName: 'Not found',
    pageParams: ['tenant'],
  },
  {
    pathRegex: /tenant\/(\d+)$/,
    pageName: 'Tenant',
    pageParams: ['tenant'],
  },
];

export type PageParams = {
  tenant: string;
  model_id?: string;
  tree_id?: string;
  node_id?: string;
  computation?: string;
  aggregation?: string;
};

type PageInfo = {
  pageName: string;
  params: PageParams;
};

export default function getPageInfo(pathname: string): PageInfo | null {
  const pageInfo = PATHNAME_PAGE_INFO_MAP.find(({ pathRegex }) =>
    pathname.match(pathRegex)
  );

  if (!pageInfo) {
    return null;
  }

  if (pageInfo) {
    const match = pathname.match(pageInfo.pathRegex);
    match.shift();

    const params: PageParams = { tenant: '' };
    match.forEach((param, index) => {
      params[pageInfo.pageParams[index] as keyof PageParams] = param;
    });

    return { pageName: pageInfo.pageName, params };
  }
}
