import upperFirst from 'lodash/upperFirst';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeployMode from '../../../models/deploy/DeployMode';
import {
  getModelFilePath,
  ModelFolder,
  transformModelName,
} from '../../../models/ModelItem';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import { IS_LLTB, MODEL_TYPES_NAMES } from '../../../utils/constants';
import checkPermissions from '../../components/CheckPermissions';
import BehavioralTab from '../../components/deploy/BehavioralTab';
import MultiFitTab from '../../components/deploy/MultiFitTab';
import StandardFitTab from '../../components/deploy/StandardFitTab';
import Error from '../../components/error/Error';
import Spinner from '../../components/Spinner';
import launchAirflowDag from '../../../utils/launch_airflow_dag';

interface DeployPageProps {
  store?: Store;
}

type DeployPageState = {
  selectedDeployMode: DeployMode;
  selectedFolders: ModelFolder[];
  loading: boolean;
};

export default inject('store')(
  observer(
    class DeployPage extends React.Component<DeployPageProps, DeployPageState> {
      interval: number;

      constructor(props: DeployPageProps) {
        super(props);

        this.state = {
          selectedDeployMode: 'standard',
          selectedFolders: [],
          loading: true,
        };

        this.handleDeployModel = this.handleDeployModel.bind(this);
        this.openDeployPopup = this.openDeployPopup.bind(this);
        this.toggleShowDeployModal = this.toggleShowDeployModal.bind(this);
        this.openImpactPopup = this.openImpactPopup.bind(this);
        this.toggleShowImpactModal = this.toggleShowImpactModal.bind(this);
      }

      async componentDidMount() {
        await this.props.store.loadValidationData({ isProcessing: true });

        // Lead grade performances are not computed yet
        if (
          IS_LLTB(this.props.store.activeModel.type) &&
          !this.props.store.leadGradePage.performances
        ) {
          await this.props.store.getLeadGradeConfiguration();
          await this.props.store.calculateLeadGradePerformance({
            isProcessing: true,
          });
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.props.store.updateDeployStatus().then(() => {
          if (this.props.store.deployPage.isDeploying) {
            this.interval = window.setInterval(async () => {
              await this.props.store.updateDeployStatus();
              if (!this.props.store.deployPage.isDeploying) {
                clearInterval(this.interval);
              }
            }, 2000);
          }
        });
        this.setState({ loading: false });
      }

      UNSAFE_componentWillUnmount() {
        clearInterval(this.interval);
      }

      async handleDeployModel() {
        const { selectedFolders, selectedDeployMode } = this.state;
        await this.props.store.deployModel(selectedFolders, selectedDeployMode);
      }

      async openDeployPopup(deployMode: DeployMode, folders: ModelFolder[]) {
        this.setState({
          selectedDeployMode: deployMode,
          selectedFolders: folders,
        });
        this.props.store.deployPage.isLoadingLastCommits = true;

        await Promise.all(
          folders.map((folder) =>
            this.props.store.getLastCommit(folder, deployMode)
          )
        );

        this.props.store.deployPage.isLoadingLastCommits = false;
        this.toggleShowDeployModal();
      }

      toggleShowDeployModal() {
        this.props.store.deployPage.deployPopupIsShown =
          !this.props.store.deployPage.deployPopupIsShown;
      }

      async openImpactPopup() {
        this.toggleShowImpactModal();
      }

      toggleShowImpactModal() {
        this.props.store.deployPage.impactPopupIsShown =
          !this.props.store.deployPage.impactPopupIsShown;
      }

      render() {
        const { activeModel, validationPage, deployPage, isSuperKudu, tenant } =
          this.props.store;
        const {
          deploymentError,
          deployPopupIsShown,
          lastCommits,
          isDeploying,
          impactPopupIsShown,
        } = deployPage;
        const {
          validation: { error: validationError },
        } = validationPage;
        const { selectedDeployMode, selectedFolders } = this.state;

        const transformedModelName = transformModelName(activeModel.name);

        const unconventionalCommits = Object.entries(lastCommits)
          .filter((c) => selectedFolders.includes(c[0] as ModelFolder))
          .filter((c) => (c[1] ? !c[1].isDeployValid : null));

        if (this.state.loading) {
          return <Spinner />;
        }

        return (
          <>
            <div className="w-75 mx-auto mt-4">
              {activeModel.live && activeModel.lastDeployedAt && (
                <Alert variant="info">
                  This model was last deployed on{' '}
                  {`${moment(activeModel.lastDeployedAt).format(
                    'MMM DD, YYYY h:mma'
                  )}`}
                  {activeModel.lastDeployedBy &&
                    ` by ${activeModel.lastDeployedBy}`}
                </Alert>
              )}
              {(deploymentError || validationError) && (
                <Error message={validationError ?? deploymentError} />
              )}
              {activeModel.type === 'customer_fit' ? (
                <>
                  <StandardFitTab
                    openDeployPopup={this.openDeployPopup}
                    openImpactPopup={this.openImpactPopup}
                  />
                  <MultiFitTab
                    openDeployPopup={this.openDeployPopup}
                    openImpactPopup={this.openImpactPopup}
                  />
                </>
              ) : (
                <BehavioralTab
                  openDeployPopup={this.openDeployPopup}
                  openImpactPopup={this.openImpactPopup}
                />
              )}
            </div>
            <Modal
              show={deployPopupIsShown}
              onHide={this.toggleShowDeployModal}
            >
              <Modal.Header>
                <Modal.Title>
                  Deploy{' '}
                  {activeModel.type === 'customer_fit' &&
                    upperFirst(selectedDeployMode)}{' '}
                  {MODEL_TYPES_NAMES[activeModel.type]} model?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,
                  <>
                    {unconventionalCommits.map(([context, commit]) => (
                      <Alert variant="warning" key={`warning_${context}`}>
                        <strong>WARNING:</strong> The last modification to this
                        model{' '}
                        {isSuperKudu && (
                          <>
                            in <b>{context}</b>
                          </>
                        )}{' '}
                        was made outside of the Data Studio.{' '}
                        {isSuperKudu && (
                          <>
                            Changes made by {commit.author} at {commit.date}.
                          </>
                        )}
                        <br />
                        {isSuperKudu ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://github.com/MadKudu/tenant-config/tree/master/${getModelFilePath(
                              {
                                tenant,
                                modelType: activeModel.type,
                                modelId: activeModel.modelId,
                                folder: context as ModelFolder,
                                mode: selectedDeployMode,
                              }
                            )}`}
                          >
                            View the Github file
                          </a>
                        ) : (
                          <>
                            Please do not deploy and contact support@madkudu.com
                            to proceed to the deployment
                          </>
                        )}
                      </Alert>
                    ))}
                    {selectedDeployMode !== 'standard' && isSuperKudu && (
                      <Alert variant="warning">
                        The model name will be transformed into{' '}
                        <b>{transformedModelName}</b>.
                        <ul>
                          <li>
                            - Redshift Tables will be suffixed with &apos;_
                            {transformedModelName}&apos;
                          </li>
                          <li>
                            - Realtime API will return a &apos;customer_fit_
                            {transformedModelName}&apos; object
                          </li>
                        </ul>
                        If you want to change it, go to the{' '}
                        <Link to={`/tenant/${tenant}`}>tenant page</Link> and
                        click on the &quot;Edit&quot; button next to the model.
                      </Alert>
                    )}
                  </>
                )}
                <p>
                  {selectedDeployMode === 'standard' &&
                    'Any update made to this model will be deployed in production, and update scores within the next 8 hours in the integrations where a push configuration is enabled.'}
                  {selectedDeployMode === 'multi' &&
                    'This model will be deployed in parallel of any other Live Customer Fit model. To replace an existing Multi Fit model, make sure the model names are the same, then deploy this model and undeploy the other model. Scores will update within the next 8 hours in the integrations where a push configuration is enabled. If you’d like to push a new model to new fields in your integrations please send a request to support@madkudu.com'}
                </p>
                {isDeploying && (
                  <>
                    <Spinner />
                    <div className="text-center">
                      Deploying your model, just a moment...
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={this.toggleShowDeployModal}
                  disabled={isDeploying}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => this.handleDeployModel()}
                  disabled={
                    isDeploying ||
                    (!!unconventionalCommits.length && !isSuperKudu)
                  }
                >
                  {isDeploying ? 'Deploying...' : 'Deploy'}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={impactPopupIsShown}
              onHide={this.toggleShowImpactModal}
            >
              <Modal.Header>
                <Modal.Title>Initiate Model Analysis</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  By proceeding, you&apos;ll request a comparative analysis for
                  your model. Here&apos;s what to expect: A comprehensive report
                  for Model ID: {activeModel.modelId} will be compiled. The
                  results will be emailed to {this.props.store.email}, typically
                  within 3 hours. The parameters passed will be:
                </p>
                <ul>
                  <li>Model ID: {activeModel.modelId}</li>
                  <li>Model Type: {activeModel.type}</li>
                  <li>Tenant: {tenant}</li>
                  <li>Email: {this.props.store.email}</li>
                </ul>
                <p>This will not affect your current live model.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={this.toggleShowImpactModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => {
                    await launchAirflowDag({
                      tenant,
                      dagName: 'deployment_impact_analysis',
                      dagConf: {
                        model_id: activeModel.modelId,
                        model_type: activeModel.type,
                        TENANT: tenant,
                        email: this.props.store.email,
                      },
                    });
                    this.toggleShowImpactModal();
                  }}
                >
                  Start Analysis
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);
