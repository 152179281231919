/* eslint-disable max-classes-per-file */
import { makeObservable, observable } from 'mobx';
import ModelPerformances from '../ModelPerformances';
import { SmoothingResults } from '../smoothing';

export default class Validation {
  error: string;

  performances: ModelPerformances;

  smoothingResults: SmoothingResults;

  constructor(
    performances: ModelPerformances,
    error: string,
    smoothingResults: SmoothingResults
  ) {
    this.performances = performances;
    this.error = error;
    this.smoothingResults = smoothingResults;
    makeObservable(this, {
      error: observable,
      performances: observable,
      smoothingResults: observable,
    });
  }

  setPerformances(performances: ModelPerformances) {
    this.performances = performances;
  }
}

export class ValidationPageModel {
  validation: Validation;

  constructor(other?: ValidationPageModel) {
    if (other) {
      this.validation = other.validation;
    } else {
      this.validation = new Validation(null, null, null);
    }
    makeObservable(this, {
      validation: observable,
    });
  }
}
