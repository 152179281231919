import axios from 'axios';

type launchAirflowDagOptions = {
  tenant: number;
  dagName: string;
  dagConf?: Record<string, any>;
};

export default async function launchAirflowDag({
  tenant,
  dagName,
  dagConf,
}: launchAirflowDagOptions) {
  await axios.post(
    `/api/tenant/${tenant}/airflowdag/${dagName}`,
    dagConf ?? {}
  );
}
