import { makeObservable, observable } from 'mobx';

import { MkAudienceTrainingVariables } from '../audience/mk_audience/MkAudienceDataset';
import GenericDatasetPageModel from './GenericDatasetPageModel';

export default class MkAudiencePageModel extends GenericDatasetPageModel {
  error: string;

  variables: MkAudienceTrainingVariables;

  audiences: string[];

  conversionNames: string[];

  constructor(other?: MkAudiencePageModel) {
    super();
    if (other) {
      this.variables = other.variables;
      this.audiences = other.audiences;
      this.conversionNames = other.conversionNames;
      this.error = other.error;
    } else {
      this.error = null;
      this.variables = {
        validationDataset: {
          startDate: '',
          endDate: '',
          conversionModel: '',
          audience: 'all',
          remove_any_customer_email: true,
        },
        trainingDataset: {
          startDate: '',
          endDate: '',
          conversionModel: '',
          audience: 'all',
          remove_any_customer_email: true,
        },
        postProcessing: {
          rebalancingRatio: 0,
          maxLeadsPerDomain: 0,
        },
      };
      this.audiences = ['all'];
      this.conversionNames = [];
    }
    makeObservable(this, {
      error: observable,
      variables: observable,
      audiences: observable,
    });
  }
}
