import UnivariateAnalysisVariable from './UnivariateAnalysisVariable';

export type VariableSort =
  | 'alphabetical'
  | 'relevance'
  | 'most_frequently_used';
export type VariableOrder = 'asc' | 'desc';
export default class UnivariateAnalysis {
  variables: UnivariateAnalysisVariable[];

  constructor(variables: UnivariateAnalysisVariable[]) {
    this.variables = variables;
  }
}
