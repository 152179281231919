import React from 'react';

export default class PageHeader extends React.Component<
  { children: React.ReactNode },
  Record<string, never>
> {
  render() {
    return (
      <div className="page-header p-3 pb-1 bg-white">{this.props.children}</div>
    );
  }
}
