import DataSet from './DataSet';
import InputDatasetEmptyError from '../errors/InputDatasetEmptyError';
import InputTableDataSet from './InputTableDataSet';
import { ModelType } from '../ModelItem';

export default abstract class InputDataSet extends InputTableDataSet {
  abstract countRecordsInDataSet(
    modelId: number,
    modelType: ModelType
  ): Promise<number>;

  async isValid(modelId: number, modelType: ModelType) {
    const recordsSize = await this.countRecordsInDataSet(modelId, modelType);

    if (recordsSize === 0) {
      throw new InputDatasetEmptyError(this.type);
    }
  }

  abstract convertDataSet(
    tenant: number,
    modelId: number,
    modelType: ModelType,
    email: string
  ): Promise<DataSet>;
}
