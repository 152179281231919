export default class DataSetRecord {
  email?: string;

  target?: number;

  [key: string]: string | number;

  constructor(recordData: { [key: string]: any }) {
    Object.keys(recordData).forEach((k: string) => {
      this[k] = recordData[k];
    });
  }
}
