import { inject, observer } from 'mobx-react';
import React from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import GroupPointBasedRulesDataModel from '../../../models/pointBasedForm/GroupPointBasedRulesDataModel';
import { Verb } from '../../../models/sqlform';
import Store from '../../../store';
import PointBasedRuleComponent from './PointBasedRuleComponent';

interface PointBasedRuleGroupComponentProps {
  store?: Store;
  key: number;
  isDisabled: boolean;
  groupPointBasedRules: GroupPointBasedRulesDataModel;
  groupPointBasedRulesIndex: number;
  handleOnChangeTrait: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    value: string
  ) => void;
  handleOnChangeCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    verb: Verb
  ) => void;
  handleAddValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleRemoveValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number
  ) => void;
  handleOnChangeValue: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    valueIndex: number,
    value: string
  ) => void;
  handleOnPointsChange: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    value: number
  ) => void;
  handleOnClickAddNewRule: (groupPointBasedRulesIndex: number) => void;
  handleOnClickRemoveRule: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number
  ) => void;
  handleOnClickAddSubCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number
  ) => void;
  handleOnClickRemoveSubCondition: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number
  ) => void;
  handleOnClickCaseSensitive: (
    groupPointBasedRulesIndex: number,
    pointBasedRuleIndex: number,
    pointBasedConditionIndex: number,
    isLower: boolean
  ) => void;
}

interface PointBasedRuleGroupComponentState {
  falseNegativesFormCheck: boolean;
}

export default inject('store')(
  observer(
    class PointBasedRuleGroupComponent extends React.Component<
      PointBasedRuleGroupComponentProps,
      PointBasedRuleGroupComponentState
    > {
      renderPointBasedRules() {
        const {
          groupPointBasedRules,
          groupPointBasedRulesIndex,
          handleOnChangeTrait,
          handleOnChangeCondition,
          handleAddValue,
          handleRemoveValue,
          handleOnChangeValue,
          handleOnPointsChange,
          handleOnClickRemoveRule,
          handleOnClickAddSubCondition,
          handleOnClickRemoveSubCondition,
          handleOnClickCaseSensitive,
          isDisabled,
        } = this.props;
        return (
          <div>
            {groupPointBasedRules &&
              groupPointBasedRules.rules.map(
                (pointBasedRule, pointBasedRuleIndex) => {
                  return (
                    <PointBasedRuleComponent
                      key={pointBasedRuleIndex}
                      isDisabled={isDisabled}
                      pointBasedRule={pointBasedRule}
                      pointBasedRuleIndex={pointBasedRuleIndex}
                      groupPointBasedRulesIndex={groupPointBasedRulesIndex}
                      handleOnChangeTrait={handleOnChangeTrait}
                      handleOnChangeCondition={handleOnChangeCondition}
                      handleAddValue={handleAddValue}
                      handleRemoveValue={handleRemoveValue}
                      handleOnChangeValue={handleOnChangeValue}
                      handleOnPointsChange={handleOnPointsChange}
                      handleOnClickRemoveRule={handleOnClickRemoveRule}
                      handleOnClickAddSubCondition={
                        handleOnClickAddSubCondition
                      }
                      handleOnClickRemoveSubCondition={
                        handleOnClickRemoveSubCondition
                      }
                      handleOnClickCaseSensitive={handleOnClickCaseSensitive}
                    />
                  );
                }
              )}
            <hr />
          </div>
        );
      }

      render() {
        const {
          groupPointBasedRules,
          groupPointBasedRulesIndex,
          handleOnClickAddNewRule,
          isDisabled,
        } = this.props;
        return (
          <Accordion.Item
            eventKey={`signal_group_${groupPointBasedRulesIndex}`}
            key={`signal_group_${groupPointBasedRulesIndex}`}
          >
            <Accordion.Header>
              Group {groupPointBasedRules.number}
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={9}>
                  <Row>
                    <Col xs={1}></Col>
                    <Col xs={2}>
                      <strong>Trait</strong>
                    </Col>
                    <Col xs={2}>
                      <strong>Condition</strong>
                    </Col>
                    <Col xs={3}>
                      <strong>Value</strong>
                    </Col>
                    <Col xs={1}></Col>
                  </Row>
                </Col>
                <Col xs={2}>
                  <strong>Points</strong>
                </Col>
                <Col xs={1}></Col>
              </Row>
              <div>{this.renderPointBasedRules()}</div>
              <div className="mt-4 mb-2">
                <Button
                  variant="primary"
                  disabled={isDisabled}
                  onClick={() =>
                    handleOnClickAddNewRule(groupPointBasedRulesIndex)
                  }
                >
                  Add rule
                </Button>
                <span className="fst-italic ms-4">
                  The order of definitions is important as only the record would
                  get the points associated to the first rule it verifies within
                  a group
                </span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      }
    }
  )
);
