import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormCheck, Card, Row, Col } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AggregatedEvent } from '../../../models/aggregations/aggregated_events';
import Store from '../../../store';
import { getHighestPhiAgg } from './utils';
import { BehavioralAggregation } from '../../../models/aggregations/behavioral_aggregations';
import Spinner from '../Spinner';
import ModelItem from '../../../models/ModelItem';
import Error from '../error/Error';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GRADIENT_COLORS } from '../../../utils';

type MatchParams = {
  tenant: string;
  aggregationId: string;
};

interface CorrelationGraphProps extends RouteComponentProps<MatchParams> {
  store?: Store;
  aggregation: BehavioralAggregation;
  model: ModelItem;
}

const CorrelationGraph = inject('store')(
  observer(
    class CorrelationGraph extends React.Component<
      CorrelationGraphProps,
      Record<string, never>
    > {
      constructor(props: CorrelationGraphProps) {
        super(props);

        this.toggleChosenAggregatedEvent =
          this.toggleChosenAggregatedEvent.bind(this);
      }

      async componentDidMount() {
        await this.props.store.createFrequencyAnalysis(
          this.props.aggregation,
          this.props.model
        );
      }

      async componentDidUpdate(previousProps: CorrelationGraphProps) {
        if (this.props.model.modelId !== previousProps.model.modelId) {
          await this.props.store.createFrequencyAnalysis(
            this.props.aggregation,
            this.props.model
          );
        }
      }

      toggleChosenAggregatedEvent(name: string) {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
          const { frequencyAnalysis, chosenAggregatedEvents } =
            this.props.store.aggregatedEventsPage;
          const { checked } = e.target;
          const occurrences = frequencyAnalysis.find(
            ({ aggregated_event_display }) => {
              return name === aggregated_event_display;
            }
          )?.number_of_occurences_of_event_last_x_days;

          if (checked) {
            this.props.store.aggregatedEventsPage.chosenAggregatedEvents =
              chosenAggregatedEvents.concat({
                aggregationName: this.props.aggregation.name,
                name,
                occurrences,
                type: this.props.aggregation.type,
                released: false,
                isProductEvent: false,
              });
          } else {
            this.props.store.aggregatedEventsPage.chosenAggregatedEvents =
              chosenAggregatedEvents.filter((event) => event.name !== name);
          }

          this.forceUpdate();
        };
      }

      getAggregatedEventColor(phi: number) {
        return phi < 0 ? GRADIENT_COLORS.maxRed : GRADIENT_COLORS.maxBlue;
      }

      renderAggregatedEventSelector() {
        const { frequencyAnalysis, aggregatedEvents, chosenAggregatedEvents } =
          this.props.store.aggregatedEventsPage;

        return (
          <>
            <h4>Select the aggregated events that you want to create</h4>
            {frequencyAnalysis
              .map(
                ({
                  number_of_occurences_of_event_last_x_days,
                  aggregated_event_display,
                }) => {
                  const findEvent = (event: AggregatedEvent) =>
                    event.occurrences ===
                      number_of_occurences_of_event_last_x_days &&
                    event.aggregationName === this.props.aggregation.name &&
                    event.type === this.props.aggregation.type;
                  // aggregated event exists in temporary list or in db
                  const checked = !!chosenAggregatedEvents.find(findEvent);
                  const alreadyExists = !!aggregatedEvents.find(findEvent);
                  return (
                    <FormCheck
                      key={aggregated_event_display}
                      type="checkbox"
                      checked={checked}
                      disabled={alreadyExists}
                      onChange={this.toggleChosenAggregatedEvent(
                        aggregated_event_display
                      )}
                      label={aggregated_event_display}
                    />
                  );
                }
              )
              .reverse()}
          </>
        );
      }

      render() {
        if (this.props.store.aggregatedEventsPage.error) {
          return (
            <Error message={this.props.store.aggregatedEventsPage.error} />
          );
        }

        if (
          !this.props.store.aggregatedEventsPage.frequencyAnalysis.length ||
          this.props.store.aggregatedEventsPage.loading
        ) {
          return <Spinner />;
        }

        const { frequencyAnalysis } = this.props.store.aggregatedEventsPage;
        const data = frequencyAnalysis.map((fa) => ({
          ...fa,
          phi: Number(Number(fa.phi).toFixed(2)),
        }));
        // get value of x for element that has largest x value
        const faSortedByPhiDesc = [...data].sort(
          (a: any, b: any) => b.phi - a.phi
        );
        // const faSortedByPhiAsc = [...faSortedByPhiDesc].reverse();

        const highestPhiAgg = getHighestPhiAgg(
          faSortedByPhiDesc[0],
          faSortedByPhiDesc[1]
        );
        // const lowestPhiAgg = getHighestPhiAgg(
        //   faSortedByPhiAsc[0],
        //   faSortedByPhiAsc[1]
        // );
        // const xAxisLimitHighest = Number(
        //   Math.abs(highestPhiAgg.phi).toFixed(2)
        // );
        // const xAxisLimitLowest = Number(Math.abs(lowestPhiAgg.phi).toFixed(2));
        // const xAxisLimit = Math.max(xAxisLimitHighest, xAxisLimitLowest);

        return (
          <>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>
                  <i aria-hidden className="far fa-lightbulb"></i> Analysis
                </Card.Title>
                <p>
                  &quot;{highestPhiAgg.aggregated_event_display}&quot; offers
                  the strongest association to conversion
                </p>
                <a
                  href="https://support.madkudu.com/hc/en-us/articles/4410714328717"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How to read
                  the frequency analysis
                </a>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Frequency analysis</Card.Title>
                <p>
                  Which frequency has the strongest association to conversion?
                </p>
                <div style={{ height: 400 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={data} layout="vertical">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis
                        dataKey="aggregated_event_display"
                        type="category"
                        width={200}
                        tickFormatter={(aggregatedEventDisplay: string) => {
                          const limit = 23;
                          if (aggregatedEventDisplay.length < limit)
                            return aggregatedEventDisplay;
                          return `${aggregatedEventDisplay.substring(
                            0,
                            limit
                          )}...`;
                        }}
                      />
                      <Tooltip />
                      <Bar dataKey="phi">
                        {data.map(({ aggregated_event_display, phi }) => (
                          <Cell
                            key={aggregated_event_display}
                            fill={this.getAggregatedEventColor(phi)}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>

            <Row>
              <Col xs={5}>
                <Card className="mb-3">
                  <Card.Body>{this.renderAggregatedEventSelector()}</Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        );
      }
    }
  )
);

export default withRouter(CorrelationGraph);
