import axios from 'axios';
import { makeObservable, observable } from 'mobx';
import { FeatureEvaluation } from './FeatureEvaluation';

export default class FeatureEvaluationPageModel {
  featureEvaluation: FeatureEvaluation;

  loading: boolean;

  saving: boolean;

  error: string;

  constructor(other?: FeatureEvaluationPageModel) {
    if (other) {
      this.featureEvaluation = other.featureEvaluation;
      this.loading = other.loading;
      this.saving = other.saving;
      this.error = other.error;
    } else {
      this.featureEvaluation = null;
      this.loading = false;
      this.saving = false;
      this.error = null;
    }
    makeObservable(this, {
      featureEvaluation: observable,
      loading: observable,
      saving: observable,
      error: observable,
    });
  }

  async load(tenant: string | number, modelId: string | number) {
    this.loading = true;
    this.error = null;
    try {
      const { data: featureEvaluation } = await axios.get<FeatureEvaluation>(
        `/api/tenant/${tenant}/model/${modelId}/feature_evaluation`
      );
      this.featureEvaluation = featureEvaluation;
    } catch (error) {
      this.error = error.message;
    }
    this.loading = false;
  }

  async save(tenant: string | number, modelId: string | number) {
    this.saving = true;
    this.error = null;

    const { featureEvaluation } = this;
    try {
      await axios.put(
        `/api/tenant/${tenant}/model/${modelId}/feature_evaluation`,
        featureEvaluation
      );
    } catch (error) {
      this.error = error.message;
    }
    this.saving = false;
  }

  async reset(tenant: string | number, modelId: string | number) {
    this.loading = true;
    this.error = null;
    try {
      const { data: featureEvaluation } = await axios.put<FeatureEvaluation>(
        `/api/tenant/${tenant}/model/${modelId}/feature_evaluation/reset`
      );
      this.featureEvaluation = featureEvaluation;
    } catch (error) {
      this.error = error.message;
    }

    this.loading = false;
  }
}
