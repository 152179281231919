import { inject, observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import {
  Button,
  Col,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { LeadBehavioralModelType, ModelType } from '../../../models/ModelItem';
import {
  OPTIONS_MAPPER_SUBJECT_VALUES,
  Rule,
  SegmentCustomerFit,
  SegmentMqa,
  segments,
  Should,
  shoulds,
} from '../../../models/rules';
import {
  Condition,
  getVerbSettings,
  Subject,
  Verb,
} from '../../../models/sqlform';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../CheckPermissions';
import RuleConditions from './RuleConditions';
import { aggregationOptionsMapper } from './utils';
import MadSelect from '../generic_components/select_component/MadSelect';

interface RuleComponentProps {
  store?: Store;
  rule: Rule;
  index: number;
  rules: Rule[];
  updateRules: (rules: Rule[]) => void;
  formFields: Subject[];
  eventActivityFields: Subject[];
  withRuleAndSegment: boolean;
  withSubConditions: boolean;
  isAggregationsForm: boolean;
  isAllowedToEdit: boolean;
}

interface RuleComponentState {
  modelType: ModelType;
}

const DeleteRule = ({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
}): ReactElement => (
  <OverlayTrigger overlay={<Tooltip id="tooltip">Delete override</Tooltip>}>
    <Button
      className="ms-3"
      variant="danger"
      onClick={onClick}
      disabled={disabled}
    >
      <i aria-hidden className="fas fa-trash" />
    </Button>
  </OverlayTrigger>
);

export default inject('store')(
  observer(
    class RuleComponent extends React.Component<
      RuleComponentProps,
      RuleComponentState
    > {
      constructor(props: RuleComponentProps) {
        super(props);

        const {
          isAggregationsForm,
          store: { activeModel },
        } = props;
        // override modeltype in case of form for behavioral aggregations, because we
        // want to have access to all verbs in VERBS_CHARACTERISTICS
        this.state = {
          modelType: isAggregationsForm ? 'customer_fit' : activeModel.type,
        };

        this.addCondition = this.addCondition.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.handleComplementChange = this.handleComplementChange.bind(this);
        this.handleVerbChange = this.handleVerbChange.bind(this);
        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleRuleChange = this.handleRuleChange.bind(this);
        this.handleSegmentChange = this.handleSegmentChange.bind(this);
        this.handleAddComplement = this.handleAddComplement.bind(this);
        this.handleRemoveComplement = this.handleRemoveComplement.bind(this);
        this.handleSqlConditionChange =
          this.handleSqlConditionChange.bind(this);
        this.handleLower = this.handleLower.bind(this);
      }

      addCondition() {
        const {
          index,
          rules,
          formFields,
          isAggregationsForm,
          eventActivityFields,
          store,
        } = this.props;
        const { eventMetadata } = store;
        const newRules: Rule[] = rules;
        const defaultComplement: string = isAggregationsForm
          ? aggregationOptionsMapper(
              eventActivityFields[0].subject as OPTIONS_MAPPER_SUBJECT_VALUES,
              eventMetadata
            )[0]?.value
          : '';
        const defaultCondition: Condition = {
          subject: isAggregationsForm ? eventActivityFields[0] : formFields[0],
          verb: 'is',
          complements: [defaultComplement],
          lower: true,
        };

        newRules[index].formConditions.push(defaultCondition);
        this.props.updateRules(newRules);
      }

      deleteCondition(conditionIndex: number, ruleIndex: number) {
        const newRules: Rule[] = this.props.rules;
        newRules[ruleIndex].formConditions.splice(conditionIndex, 1);
        this.props.updateRules(newRules);
      }

      deleteRule() {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules.splice(index, 1);
        this.props.updateRules(newRules);
      }

      handleComplementChange(
        value: string,
        conditionIndex: number,
        complementIndex: number
      ) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        const condition = newRules[index].formConditions[conditionIndex];
        const error =
          condition.subject.type === 'number' && isNaN(Number(value));
        newRules[index].formConditions[conditionIndex].complements[
          complementIndex
        ] = value;
        newRules[index].formConditions[conditionIndex].error = error;
        this.props.updateRules(newRules);
      }

      handleVerbChange(verb: Verb, conditionIndex: number) {
        const { index, rules, isAggregationsForm, store } = this.props;
        const { eventMetadata } = store;
        const verbSettings = getVerbSettings(verb);
        const newRules: Rule[] = rules;
        const subject =
          newRules[index].formConditions[conditionIndex].subject.subject;
        newRules[index].formConditions[conditionIndex].verb = verb;
        let defaultComplementValue = '';
        if (isAggregationsForm) {
          defaultComplementValue = aggregationOptionsMapper(
            subject as OPTIONS_MAPPER_SUBJECT_VALUES,
            eventMetadata
          )[0]?.value;
        }
        newRules[index].formConditions[conditionIndex].complements = Array(
          verbSettings.characteristics.min
        ).fill(defaultComplementValue);
        this.props.updateRules(newRules);
      }

      handleSubjectChange(value: string, conditionIndex: number) {
        const {
          index,
          rules,
          formFields,
          eventActivityFields,
          isAggregationsForm,
          store,
        } = this.props;
        const { eventMetadata } = store;
        const newRules: Rule[] = rules;

        const fields = eventActivityFields || formFields;
        const subject = fields.find((s) => s.subject === value);

        newRules[index].formConditions[conditionIndex].subject = subject;
        if (isAggregationsForm) {
          const newComplementValue: string = aggregationOptionsMapper(
            value as OPTIONS_MAPPER_SUBJECT_VALUES,
            eventMetadata
          )[0]?.value;
          this.handleComplementChange(newComplementValue, conditionIndex, 0);
        } else {
          newRules[index].formConditions[conditionIndex].complements = Array(
            newRules[index].formConditions[conditionIndex].complements.length
          ).fill('');
        }
        newRules[index].formConditions[conditionIndex].error = false;
        this.props.updateRules(newRules);
      }

      handleRuleChange(value: Should) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules[index].rule = value;
        this.props.updateRules(newRules);
      }

      handleSegmentChange(value: SegmentCustomerFit | SegmentMqa) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules[index].segment = value;
        this.props.updateRules(newRules);
      }

      handleAddComplement(conditionIndex: number) {
        const { index, rules, isAggregationsForm, store } = this.props;
        const { eventMetadata } = store;
        const newRules: Rule[] = rules;
        let defaultComplementValue = '';
        if (isAggregationsForm) {
          const subject =
            newRules[index].formConditions[conditionIndex].subject.subject;
          defaultComplementValue = aggregationOptionsMapper(
            subject as OPTIONS_MAPPER_SUBJECT_VALUES,
            eventMetadata
          )[0]?.value;
        }
        newRules[index].formConditions[conditionIndex].complements.push(
          defaultComplementValue
        );
        this.props.updateRules(newRules);
      }

      handleRemoveComplement(conditionIndex: number, complementIndex: number) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules[index].formConditions[conditionIndex].complements.splice(
          complementIndex,
          1
        );
        this.props.updateRules(newRules);
      }

      handleLower(conditionIndex: number) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules[index].formConditions[conditionIndex].lower =
          !newRules[index].formConditions[conditionIndex].lower;
        this.props.updateRules(newRules);
      }

      handleSqlConditionChange(e: any) {
        const { index, rules } = this.props;
        const newRules: Rule[] = rules;
        newRules[index].sqlConditions = e.target.value;
        this.props.updateRules(newRules);
      }

      render() {
        const {
          rule,
          index,
          formFields,
          eventActivityFields,
          withRuleAndSegment,
          withSubConditions,
          isAggregationsForm,
          isAllowedToEdit,
          store,
        } = this.props;
        const { modelType } = this.state;
        const { eventMetadata } = store;

        return (
          <div className="mt-2 border-bottom">
            <Row className="gx-1">
              <Col className="conditions">
                <div className="d-flex align-items-start">
                  <div className="flex-fill">
                    {rule.ruleType === 'form' ? (
                      <>
                        <RuleConditions
                          conditions={rule.formConditions}
                          formFields={formFields}
                          eventActivityFields={eventActivityFields}
                          ruleIndex={index}
                          handleSubjectChange={this.handleSubjectChange}
                          handleVerbChange={this.handleVerbChange}
                          handleComplementChange={this.handleComplementChange}
                          deleteCondition={this.deleteCondition}
                          handleAddComplement={this.handleAddComplement}
                          handleRemoveComplement={this.handleRemoveComplement}
                          handleLower={this.handleLower}
                          isAggregationsForm={isAggregationsForm}
                          modelType={modelType}
                          withRuleAndSegment={withRuleAndSegment}
                          isAllowedToEdit={isAllowedToEdit && !rule.permanent}
                          eventMetadata={eventMetadata}
                        />
                        {withSubConditions &&
                          checkPermissions(
                            PROFILES_PERMISSIONS.ARCHITECT,
                            <a
                              className="ms-2"
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                this.addCondition();
                              }}
                            >
                              + AND
                            </a>
                          )}
                      </>
                    ) : (
                      <FormControl
                        as="textarea"
                        className="mb-1"
                        placeholder="employees > 1000 AND domain = 'madkudu.com'"
                        rows={3}
                        disabled={!isAllowedToEdit}
                        onChange={this.handleSqlConditionChange}
                        autoFocus={true}
                        value={rule.sqlConditions}
                      />
                    )}
                  </div>
                  {withRuleAndSegment ||
                    checkPermissions(
                      PROFILES_PERMISSIONS.ARCHITECT,
                      <DeleteRule
                        disabled={rule.permanent || !isAllowedToEdit}
                        onClick={this.deleteRule}
                      />
                    )}
                </div>
              </Col>
              {withRuleAndSegment && (
                <>
                  <Col className="rule" sm={2}>
                    <MadSelect
                      isSearchable
                      value={{
                        label: rule.rule,
                        value: rule.rule,
                      }}
                      options={shoulds.map((should) => ({
                        label: should,
                        value: should,
                      }))}
                      onChange={({ value }) => {
                        this.handleRuleChange(value);
                      }}
                    />
                  </Col>
                  <Col className="segment" sm={2}>
                    <div className="d-flex align-items-center">
                      <MadSelect
                        isSearchable
                        isDisabled={!isAllowedToEdit}
                        value={{
                          label: rule.segment,
                          value: rule.segment,
                        }}
                        options={segments[
                          modelType as Exclude<
                            ModelType,
                            LeadBehavioralModelType
                          >
                        ].map((segment) => ({
                          label: segment,
                          value: segment,
                        }))}
                        onChange={({ value }) => {
                          this.handleSegmentChange(value);
                        }}
                      />
                      {checkPermissions(
                        PROFILES_PERMISSIONS.ARCHITECT,
                        <DeleteRule
                          disabled={rule.permanent || !isAllowedToEdit}
                          onClick={this.deleteRule}
                        />
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        );
      }
    }
  )
);
