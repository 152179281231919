import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import DeployMode from '../../../models/deploy/DeployMode';
import { ModelFolder } from '../../../models/ModelItem';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../CheckPermissions';

type DeployTabComponentProps = {
  store?: Store;
  enabled: boolean;
  deployMode: DeployMode;
  folders: ModelFolder[];
  deployButtonLabel: string;
  downloadButtonLabel: (context: string) => string;
  disabledMessage?: string;
  title: React.ReactNode;
  openDeployPopup: (
    deployMode: DeployMode,
    folders: ModelFolder[]
  ) => Promise<void>;
  openImpactPopup: () => Promise<void>;
  children: React.ReactNode;
};

export default inject('store')(
  observer(
    class DeployTabComponent extends React.Component<
      DeployTabComponentProps,
      Record<string, never>
    > {
      constructor(props: DeployTabComponentProps) {
        super(props);

        this.generateCode = this.generateCode.bind(this);
        this.generateObservationsCode =
          this.generateObservationsCode.bind(this);
      }

      generateCode(folder: ModelFolder) {
        const { deployMode, store } = this.props;
        const { tenant, modelId } = store;
        const encodedFolder = encodeURIComponent(folder);
        const fileName = modelId
          ? `${tenant}_${modelId}.yaml`
          : `${tenant}.yaml`;
        const url = `/api/tenant/${tenant}/model/${modelId}/config/${encodedFolder}/${deployMode}/${encodedFolder}_${fileName}`;
        window.location.href = url;
      }

      generateObservationsCode(folder: ModelFolder) {
        const { store } = this.props;
        const { tenant, modelId } = store;
        const encodedFolder = encodeURIComponent(folder);
        const url = `/api/tenant/${tenant}/model/${modelId}/config/${encodedFolder}/observations/observations_${tenant}.yaml`;
        window.location.href = url;
      }

      render() {
        const {
          children,
          enabled,
          folders,
          deployButtonLabel,
          downloadButtonLabel,
          disabledMessage,
          store,
          title,
          deployMode,
          openDeployPopup,
          openImpactPopup,
        } = this.props;
        const { tenant, deployPage, isSuperKudu, activeModel } = store;
        const { isLoadingLastCommits } = deployPage;

        return (
          <Card border="light" className="mt-2 mb-4">
            <Card.Body>
              <Card.Title>{title}</Card.Title>
              <div>
                {folders.length ? (
                  <div>
                    {checkPermissions(
                      PROFILES_PERMISSIONS.ARCHITECT,
                      <div>
                        {children}
                        {enabled && (
                          <Row>
                            <Col>
                              <Button
                                onClick={async () => {
                                  await openDeployPopup(deployMode, folders);
                                }}
                                disabled={isLoadingLastCommits}
                                className="me-3"
                              >
                                {deployButtonLabel}
                                {isLoadingLastCommits && (
                                  <i
                                    aria-hidden
                                    className="fas fa-spinner fa-spin ms-1"
                                  />
                                )}
                              </Button>
                              {isSuperKudu && (
                                <Button
                                  className="me-3"
                                  variant="secondary"
                                  onClick={async () => {
                                    await openImpactPopup();
                                  }}
                                >
                                  Estimate Deployment Impact
                                </Button>
                              )}
                            </Col>
                            {enabled && isSuperKudu && (
                              <Col className="text-end">
                                <Button
                                  variant="info"
                                  href={`https://app.madkudu.com/org/${tenant}/processes`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="me-2"
                                >
                                  Go to Processes
                                </Button>
                                <DropdownButton
                                  title="Download code"
                                  id="download-files"
                                  className="d-inline-block"
                                >
                                  {folders.map((context) => (
                                    <Dropdown.Item
                                      key={`btn_${context}`}
                                      onClick={() => this.generateCode(context)}
                                    >
                                      {downloadButtonLabel(context)}
                                    </Dropdown.Item>
                                  ))}
                                  {activeModel.type === 'customer_fit' && (
                                    <>
                                      <Dropdown.Divider />
                                      {folders.map((context) => (
                                        <Dropdown.Item
                                          key={`btn_observations_${context}`}
                                          onClick={() =>
                                            this.generateObservationsCode(
                                              context
                                            )
                                          }
                                        >
                                          Download observations {context} config
                                        </Dropdown.Item>
                                      ))}
                                    </>
                                  )}
                                </DropdownButton>
                              </Col>
                            )}
                          </Row>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    No context is selected. You must at least check
                    &quot;Batch&quot; or &quot;Realtime&quot;.
                  </div>
                )}
              </div>

              {!enabled && <Alert variant="warning">{disabledMessage}</Alert>}
            </Card.Body>
          </Card>
        );
      }
    }
  )
);
