import { inject, observer } from 'mobx-react';
import React from 'react';
import Computation from '../../../../models/computations/Computation';
import Store from '../../../../store';
import { MetadataField } from '../../../../models/metadataFields';
import startCase from 'lodash/startCase';
import MadSelect from '../../generic_components/select_component/MadSelect';

interface OneToOneFormComponentProps {
  store?: Store;
  computation: Computation;
  handleOneToOneFieldChange: (field: string) => void;
  formFields: MetadataField[];
  readonly: boolean;
}

export default inject('store')(
  observer(
    class OneToOneFormComponent extends React.Component<
      OneToOneFormComponentProps,
      Record<string, never>
    > {
      render() {
        const { computation, readonly, handleOneToOneFieldChange, formFields } =
          this.props;

        return (
          <MadSelect
            defaultValue={{
              value: computation.configuration.modeProperties.oneToOne.field,
              label: startCase(
                computation.configuration.modeProperties.oneToOne.field
              ),
            }}
            isDisabled={readonly}
            isSearchable
            options={formFields?.map(({ name: value }) => ({
              value,
              label: startCase(value),
            }))}
            onChange={(option) => handleOneToOneFieldChange(option.value)}
          />
        );
      }
    }
  )
);
