import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';

import DataSetType from '../../../../../models/audience/DataSetType';
import Store from '../../../../../store';
import { getDatasetParameter } from '../../../../../utils';
import Error from '../../../../components/error/Error';
import checkPermissions from '../../../../components/CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../../../permissions';
import Spinner from '../../../../components/Spinner';
import { LinkContainer } from 'react-router-bootstrap';
import HeaderCard from '../../../../components/generic_components/HeaderCard';
import SingleDatePicker from '../../../../components/generic_components/SingleDatePicker';
import { MAX_NUMBER_OF_LOADING_DATASETS } from '../../../../../utils/constants';

interface PqlPageProps extends RouteComponentProps {
  store?: Store;
}

interface PqlPageState {
  loading: boolean;
  shouldShowAdvanced: boolean;
  currentLoadingModels: number[];
}

const PqlPage = inject('store')(
  observer(
    class PqlPage extends React.Component<PqlPageProps, PqlPageState> {
      errorMessageRef: React.RefObject<HTMLDivElement>;

      constructor(props: PqlPageProps) {
        super(props);

        this.state = {
          loading: true,
          shouldShowAdvanced: false,
          currentLoadingModels: [],
        };

        makeObservable(this, {
          buildDataset: action,
          changeTrainingConversionModel: action,
          changeValidationConversionModel: action,
          changeTrainingAudience: action,
          changeValidationAudience: action,
          changeRebalancingRatio: action,
          changeCustomerFitIncluded: action,
          changeMetaEventExclusionList: action,
          handleCheckRemoveAnyPastAccount: action,
        });

        this.buildDataset = this.buildDataset.bind(this);
        this.changeTrainingConversionModel =
          this.changeTrainingConversionModel.bind(this);
        this.changeValidationConversionModel =
          this.changeValidationConversionModel.bind(this);
        this.changeTrainingAudience = this.changeTrainingAudience.bind(this);
        this.changeValidationAudience =
          this.changeValidationAudience.bind(this);
        this.changeRebalancingRatio = this.changeRebalancingRatio.bind(this);
        this.changeCustomerFitIncluded =
          this.changeCustomerFitIncluded.bind(this);
        this.changeMetaEventExclusionList =
          this.changeMetaEventExclusionList.bind(this);
        this.toggleShowAdvanced = this.toggleShowAdvanced.bind(this);
        this.handleCheckRemoveAnyPastAccount =
          this.handleCheckRemoveAnyPastAccount.bind(this);

        this.errorMessageRef = React.createRef();
      }

      async componentDidMount() {
        const { tenant } = this.props.store;
        const [currentLoadingModels] = await Promise.all([
          this.props.store.mkAudiencePage.getCurrentLoadingModels(tenant),
          this.props.store.getDefaultDatasetLtbVariables(),
          this.props.store.getTenantAudiences(),
          this.props.store.getTenantConversionNames(),
        ]);

        this.setState({ loading: false, currentLoadingModels });
      }

      componentDidUpdate(): void {
        const {
          csvPage: { dataSets },
        } = this.props.store;

        if (dataSets.uploadDataSetError) {
          this.errorMessageRef.current.scrollIntoView();
        }
      }

      async buildDataset() {
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        const { tenant, modelId, csvPage, pqlPage } = this.props.store;
        await this.props.store.uploadLtbDataset(dataset, pqlPage.variables);
        if (!csvPage.dataSets.uploadDataSetError) {
          this.props.history.push(`/tenant/${tenant}/models/${modelId}`);
        }
      }

      changeTrainingConversionModel(
        event: React.ChangeEvent<HTMLSelectElement>
      ) {
        this.props.store.pqlPage.variables.trainingConversionModel =
          event.target.value;
      }

      changeValidationConversionModel(
        event: React.ChangeEvent<HTMLSelectElement>
      ) {
        this.props.store.pqlPage.variables.validationConversionModel =
          event.target.value;
      }

      changeTrainingAudience(event: React.ChangeEvent<HTMLSelectElement>) {
        this.props.store.pqlPage.variables.trainingAudience =
          event.target.value;
      }

      changeValidationAudience(event: React.ChangeEvent<HTMLSelectElement>) {
        this.props.store.pqlPage.variables.validationAudience =
          event.target.value;
      }

      changeRebalancingRatio(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.pqlPage.variables.rebalancingRatio = Number(
          event.target.value
        );
      }

      changeCustomerFitIncluded(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.pqlPage.variables.customerFitIncluded =
          event.target.value;
      }

      changeMetaEventExclusionList(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store.pqlPage.variables.metaEventExclusionList =
          event.target.value;
      }

      toggleShowAdvanced() {
        this.setState({ shouldShowAdvanced: !this.state.shouldShowAdvanced });
      }

      handleCheckRemoveAnyPastAccount(dataSetType: string) {
        if (dataSetType === 'training') {
          this.props.store.pqlPage.variables.training_remove_any_customer_email =
            !this.props.store.pqlPage.variables
              .training_remove_any_customer_email;
        } else {
          this.props.store.pqlPage.variables.validation_remove_any_customer_email =
            !this.props.store.pqlPage.variables
              .validation_remove_any_customer_email;
        }
      }

      render() {
        const { csvPage, pqlPage, isAllowedToEdit, tenant, modelId, rootPage } =
          this.props.store;
        const { shouldShowAdvanced, loading, currentLoadingModels } =
          this.state;
        const { isDataSetLoadingProgress } = rootPage;
        const { dataSets } = csvPage;
        const { audiences, variables, conversionNames } = pqlPage;
        const datasetParameterPresent: DataSetType = getDatasetParameter(
          this.props.location.search
        );

        const trainingDates =
          variables.trainingDates === ''
            ? []
            : variables.trainingDates.split(', ');

        const minDate = moment().subtract(9, 'months').format('YYYY-MM-DD');
        const maxDate = moment().subtract(1, 'month').format('YYYY-MM-DD');

        const cantLoad =
          currentLoadingModels.length >= MAX_NUMBER_OF_LOADING_DATASETS;

        if (loading) {
          return <Spinner />;
        }

        return (
          <>
            <LinkContainer
              to={`/tenant/${tenant}/models/${modelId}/overview`}
              activeClassName=""
            >
              <Button variant="link" className="me-2">
                Go back to Overview
              </Button>
            </LinkContainer>
            <div className="w-75 mt-4 mx-auto d-grid gap-4">
              {dataSets.uploadDataSetError && (
                <div ref={this.errorMessageRef}>
                  <Error
                    message={dataSets.uploadDataSetError.message}
                    type={dataSets.uploadDataSetError.type}
                  />
                </div>
              )}
              <h4>Edit model datasets</h4>
              <HeaderCard
                supportLink="https://support.madkudu.com/hc/en-us/articles/4417182544653-Likelihood-to-Buy-Training-and-Validation-datasets"
                tenant={tenant}
              />
              {!datasetParameterPresent && (
                <>
                  <Card>
                    <Card.Body>
                      <Card.Title>Training dataset parameters</Card.Title>
                      <p className="mb-4">
                        Create a training dataset from a cohort of contacts who
                        converted and did not convert, to build your
                        segmentation.
                      </p>
                      <Row className="mb-3">
                        <Col md={4}>
                          Include all the emails <b>active</b> on...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="training-dates-tooltip" {...props}>
                                Select one or more dates at least 1 month apart,
                                between 9 months ago and 1 month ago (since
                                MadKudu only stored 9 months of behavioral
                                data).{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://support.madkudu.com/hc/en-us/articles/4417182544653-Likelihood-to-Buy-Training-and-Validation-datasets"
                                >
                                  Learn more
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>

                        <Col>
                          {trainingDates.map((trainingDate, index) => (
                            <span
                              className="me-1"
                              key={`${trainingDate}-${index}`}
                            >
                              <SingleDatePicker
                                date={trainingDate}
                                onDateChange={(date) => {
                                  if (!date) return;

                                  trainingDates[index] =
                                    date.format('YYYY-MM-DD');
                                  trainingDates.sort();
                                  this.props.store.pqlPage.variables.trainingDates =
                                    trainingDates.join(', ');
                                }}
                                minDate={minDate}
                                maxDate={maxDate}
                                onDelete={() => {
                                  trainingDates.splice(index, 1);
                                  this.props.store.pqlPage.variables.trainingDates =
                                    trainingDates.join(', ');
                                }}
                              />
                            </span>
                          ))}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">Add new date</Tooltip>
                            }
                          >
                            <Button
                              className="me-1"
                              onClick={() => {
                                trainingDates.push(
                                  moment()
                                    .subtract(3, 'months')
                                    .format('YYYY-MM-DD')
                                );
                                trainingDates.sort();
                                this.props.store.pqlPage.variables.trainingDates =
                                  trainingDates.join(', ');
                              }}
                            >
                              <i aria-hidden className="fas fa-plus" />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={4}>
                          ...From the <b>audience</b>...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="audience-tooltip" {...props}>
                                See the definition of an audience or add more
                                audiences{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://app.madkudu.com/org/${tenant}/mapping/audiences`}
                                >
                                  here
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>
                        <Col md={4}>
                          <Form.Select
                            disabled={!isAllowedToEdit}
                            onChange={this.changeTrainingAudience}
                            value={variables.trainingAudience}
                          >
                            {audiences.map((audience) => (
                              <option key={audience}>{audience}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={4}>
                          ...Who <b>converted</b> into...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="conversion-tooltip" {...props}>
                                See the definition of a conversion or add more
                                conversion definitions{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://app.madkudu.com/org/${tenant}/mapping/conversion_mapping/overview`}
                                >
                                  here
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>
                        <Col md={4}>
                          <Form.Select
                            disabled={!isAllowedToEdit}
                            onChange={this.changeTrainingConversionModel}
                            value={variables.trainingConversionModel}
                          >
                            {conversionNames.map((conversionName) => (
                              <option key={conversionName}>
                                {conversionName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>

                      <Button variant="link" onClick={this.toggleShowAdvanced}>
                        {'>'} Advanced options
                      </Button>
                      {shouldShowAdvanced && (
                        <>
                          <Form.Group className="mb-3 mt-4">
                            <Form.Check
                              type="checkbox"
                              checked={
                                variables.training_remove_any_customer_email
                              }
                              disabled={!isAllowedToEdit}
                              onChange={() =>
                                this.handleCheckRemoveAnyPastAccount('training')
                              }
                              label={<b>Remove any past converted account</b>}
                            />
                          </Form.Group>
                          <p>
                            This option removes from the training dataset any
                            lead associated to a company who already converted
                            before the simulated dates.{' '}
                            <b>
                              It is recommended to activate this option when
                              building a model to predict new business
                              opportunities
                            </b>
                            , and to disable this option when predicting upsell
                            or expansions.
                          </p>
                          <Form.Group className="mb-3 mt-4">
                            <Row>
                              <Col md="auto">
                                <Form.Label className="fw-bold">
                                  Rebalancing ratio
                                </Form.Label>
                              </Col>
                              <Col md="auto">
                                <Form.Control
                                  type="number"
                                  disabled={!isAllowedToEdit}
                                  onChange={this.changeRebalancingRatio}
                                  value={String(variables.rebalancingRatio)}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                          <p className="mb-4">
                            To improve the quality of the model predictions, the
                            training dataset should have a minimum conversion
                            rate to reduce the class imbalance bias.{' '}
                            <b>
                              It is recommended to use a minimum of 20%
                              conversion rate
                            </b>{' '}
                            unless there aren’t enough conversions (less than
                            100).
                          </p>
                          <Row>
                            <Form.Group className="mb-3">
                              <Row>
                                <Col md="auto">
                                  <Form.Label className="fw-bold">
                                    Customer Fit Included
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    type="text"
                                    disabled={!isAllowedToEdit}
                                    onChange={this.changeCustomerFitIncluded}
                                    value={variables.customerFitIncluded}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Row>
                          <p className="mb-4">
                            Only people with the Customer Fit segment input will
                            be included in the training dataset.{' '}
                            <b>
                              It is recommended to exclude people with a low
                              Customer Fit segment
                            </b>{' '}
                            (people with no fit for your product) to focus the
                            historical analysis on the behavior of the most
                            relevant people.
                          </p>
                          <Row>
                            <Form.Group className="mb-3">
                              <Row>
                                <Col md="auto">
                                  <Form.Label className="fw-bold">
                                    Events excluded from the active definition
                                    (Optional)
                                  </Form.Label>
                                </Col>
                                <Col md={4}>
                                  <Form.Control
                                    type="text"
                                    disabled={!isAllowedToEdit}
                                    onChange={this.changeMetaEventExclusionList}
                                    value={variables.metaEventExclusionList}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Row>
                          <p>
                            People who have <b>only</b> performed these events
                            will not be included in the training dataset.{' '}
                            <b>
                              It is recommended to exclude events with little
                              relevance and a high frequently
                            </b>{' '}
                            performed like Opened email, Clicked email....
                          </p>
                        </>
                      )}
                    </Card.Body>
                  </Card>

                  <Card border="light">
                    <Card.Body>
                      <Card.Title>Validation dataset options</Card.Title>
                      <p className="mb-4">
                        Validate your segmentation on a validation dataset.
                      </p>
                      <Row className="mb-3">
                        <Col md={4}>
                          Include all the emails <b>active</b> on...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="training-dates-tooltip" {...props}>
                                Select one date between 9 months ago and 1 month
                                ago (since MadKudu only stored 9 months of
                                behavioral data).{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://support.madkudu.com/hc/en-us/articles/4417182544653-Likelihood-to-Buy-Training-and-Validation-datasets"
                                >
                                  Learn more
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>

                        <Col>
                          <SingleDatePicker
                            date={variables.evaluationDate}
                            onDateChange={(date) => {
                              if (!date) return;

                              this.props.store.pqlPage.variables.evaluationDate =
                                date.format('YYYY-MM-DD');
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={4}>
                          ...From the <b>audience</b>...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="audience-tooltip" {...props}>
                                See the definition of an audience or add more
                                audiences{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://app.madkudu.com/org/${tenant}/mapping/audiences`}
                                >
                                  here
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>
                        <Col md={4}>
                          <Form.Select
                            disabled={!isAllowedToEdit}
                            onChange={this.changeValidationAudience}
                            value={variables.validationAudience}
                          >
                            <option>Select an audience</option>
                            {audiences.map((audience) => (
                              <option key={audience}>{audience}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          ...Who <b>converted</b> or not into...
                          <OverlayTrigger
                            delay={{ show: 0, hide: 500 }}
                            overlay={(props: Record<string, unknown>) => (
                              <Tooltip id="conversion-tooltip" {...props}>
                                See the definition of a conversion or add more
                                conversion definitions{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://app.madkudu.com/org/${tenant}/mapping/conversion_mapping/overview`}
                                >
                                  here
                                </a>
                              </Tooltip>
                            )}
                          >
                            <i className="fas fa-info-circle ms-1"></i>
                          </OverlayTrigger>
                        </Col>
                        <Col md={4}>
                          <Form.Select
                            disabled={!isAllowedToEdit}
                            onChange={this.changeValidationConversionModel}
                            value={variables.validationConversionModel}
                          >
                            <option>Select a conversion</option>
                            {conversionNames.map((conversionName) => (
                              <option key={conversionName}>
                                {conversionName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          checked={
                            variables.validation_remove_any_customer_email
                          }
                          disabled={!isAllowedToEdit}
                          onChange={() =>
                            this.handleCheckRemoveAnyPastAccount('validation')
                          }
                          label="Remove any past converted account"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <Row className="mx-auto mb-4">
                    <Col>
                      <LinkContainer
                        to={`/tenant/${tenant}/models/${modelId}/overview`}
                        activeClassName=""
                      >
                        <Button variant="outline-primary">Cancel</Button>
                      </LinkContainer>
                    </Col>
                    <Col md="auto">
                      {checkPermissions(
                        PROFILES_PERMISSIONS.ARCHITECT,
                        <OverlayTrigger
                          show={cantLoad}
                          overlay={
                            <Tooltip>
                              {`Model ${currentLoadingModels[0]} and ${currentLoadingModels[1]} are currently loading. Please wait until they both load to begin loading another model`}
                            </Tooltip>
                          }
                        >
                          <span>
                            <Button
                              variant="primary"
                              disabled={
                                isDataSetLoadingProgress ||
                                !isAllowedToEdit ||
                                cantLoad
                              }
                              onClick={this.buildDataset}
                            >
                              {isDataSetLoadingProgress
                                ? 'Loading...'
                                : 'Build and load dataset'}
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(PqlPage);
