import React from 'react';
import Store from '../../../store';

interface NodeLabelProps {
  nodeId: number;
  nodeData?: {
    name: string;
    percentPopulation: number;
    color: string;
  };
  store: Store;
  graphDimensions: { x: number; y: number };
}

interface NodeLabelState {
  lastPosition: { x: number; y: number };
}

export default class NodeLabel extends React.PureComponent<
  NodeLabelProps,
  NodeLabelState
> {
  constructor(props: NodeLabelProps) {
    super(props);
    this.setTreePosition = this.setTreePosition.bind(this);
    this.state = {
      lastPosition: { x: 1, y: 1 },
    };
  }

  setTreePosition(x: number, y: number) {
    const { lastPosition } = this.state;
    if (x !== lastPosition.x && y !== lastPosition.y) {
      this.setState({ lastPosition: { x, y } });
    }
  }

  render() {
    const { nodeId, nodeData } = this.props;
    const populationRatio = nodeData.percentPopulation;
    const nodeIndex = parseInt(nodeData.name, 10);

    const isSelected = nodeIndex === nodeId;

    // Adapt UI to node data
    const color = isSelected ? '#ffc107' : nodeData.color;
    const scale: number = 0.7 * Math.exp(populationRatio);

    const nodeStyle = {
      position: 'relative' as const,
      background: color ? color : '#1e77cc',
      width: 60,
      height: 60,
      transform: `translate(50%, 50%) scale(${scale < 1 ? 1 : scale})`,
      borderRadius: '50%',
    };

    const indexStyle = {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: isSelected ? '#000' : '#fff',
      fontSize: 30,
    };

    return (
      <div style={nodeStyle}>
        <p style={indexStyle} onClick={() => false}>
          {nodeIndex}
        </p>
      </div>
    );
  }
}
