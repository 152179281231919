import UnivariateAnalysisVariableBucket from './UnivariateAnalysisVariableBucket';

export default class UnivariateAnalysisVariableBucketWithShare extends UnivariateAnalysisVariableBucket {
  populationShare: number;

  conversionsShare: number;

  conversionRate: number;

  lift: number;

  lift7: number;

  disabled = false;

  constructor(
    population: number,
    populationShare: number,
    conversions: number,
    conversionsShare: number,
    rawName: string,
    name: string,
    conversionRate: number,
    lift: number,
    lift7: number
  ) {
    super(population, conversions, rawName, name);
    this.populationShare = populationShare;
    this.conversionsShare = conversionsShare;
    this.conversionRate = conversionRate;
    this.lift = lift;
    this.lift7 = lift7;
  }
}
