import React from 'react';
import LeadIcon from './../../front/assets/images/icons/lead.svg';
import ChartIcon from './../../front/assets/images/icons/chart.svg';
import SalesIcon from './../../front/assets/images/icons/sales.svg';
import CustomerFitOutput from './../../front/assets/images/customer_fit.png';
import MarketingLeadOutput from './../../front/assets/images/marketing_lead_output.png';
import ProductUserOutput from './../../front/assets/images/product_user_output.png';
import LeadEngagementOutput from './../../front/assets/images/lead_engagement_output.png';
import AccountOutput from './../../front/assets/images/account_output.png';
import CustomerFitIllus from './../../front/assets/images/illus/customer_fit.svg';
import MarketingLeadEngagementIllus from './../../front/assets/images/illus/marketing_lead_engagement.svg';
import ProductUserEngagementIllus from './../../front/assets/images/illus/product_user_engagement.svg';
import AccountEngagementIllus from './../../front/assets/images/illus/account_engagement.svg';
import LeadEngagementIllus from './../../front/assets/images/illus/lead_engagement.svg';
import { Model } from './ModelType';

export const Models: Model[] = [
  {
    name: 'Customer Fit',
    model: 'Customer Fit',
    type: 'customer_fit',
    color: 'cyan',
    description:
      'Predict which prospects fit your Ideal Customer Profile based on their demographic, firmographic and technographic attributes.',
    useCases: [
      {
        title: 'Increasing Sales Efficiency',
        text: 'Keep low quality leads out of your funnel so that reps can spend their time on your best leads.',
        icon: SalesIcon,
      },
      {
        title: 'Better Marketing Performance',
        text: 'Instantly know whether your marketing campaigns are bringing good leads or noise.',
        icon: ChartIcon,
      },
    ],
    output: CustomerFitOutput,
    more_description: '',
    requirements: 'CRM connected to MadKudu or upload dataset via CSV',
    illus: <img src={CustomerFitIllus} width={200} height={105} />,
  },
  {
    name: 'Marketing Lead Engagement',
    model: 'Engagement',
    type: 'mql',
    color: 'pink',
    description:
      'Predict which leads in your marketing funnel are more likely to convert based on their engagement with your web, marketing and email content.',
    useCases: [
      {
        title: 'Engaging Marketing Leads when they are hot',
        text: 'By quickly identifying who are the most engaged marketing leads your sales team can reach out to them at the right time.',
        icon: LeadIcon,
      },
    ],
    output: MarketingLeadOutput,
    more_description:
      'This model is designed specifically to identify MQLs in your Inbound marketing funnel. If you have a Product funnel, combine it with a Product user engagement model to cover all your Hybrid motion!',
    requirements:
      'Integration tracking web, marketing or email activity connected to MadKudu',
    illus: <img src={MarketingLeadEngagementIllus} width={160} height={125} />,
  },
  {
    name: 'Product User Engagement',
    model: 'Engagement',
    type: 'pql2',
    color: 'pink',
    description:
      'Predict which users in your product funnel are more likely to convert based on their engagement in your product but also with your web, marketing and email content.',
    useCases: [
      {
        title: 'Engaging Product Users when they are hot',
        text: 'By quickly identifying who are the users showing intent on your product your sales team can give them a nudge at the right time.',
        icon: LeadIcon,
      },
    ],
    output: ProductUserOutput,
    more_description:
      'This model is designed specifically to identify PQLs in your Product funnel. If you have a Marketing funnel, combine it with a Marketing user engagement model to cover all your Hybrid motion!',
    requirements: 'Integration tracking product activity connected to MadKudu',
    illus: <img src={ProductUserEngagementIllus} width={180} height={135} />,
  },
  {
    name: 'Lead Engagement',
    model: 'Engagement',
    type: 'pql',
    color: 'pink',
    description:
      'Predict which leads are more likely to convert based on their engagement in your product, web, marketing and email content.',
    useCases: [
      {
        title: 'Engaging Leads when they are hot',
        text: 'By quickly identifying who are the most engaged leads your sales team can reach out to them at the right time.',
        icon: LeadIcon,
      },
    ],
    output: LeadEngagementOutput,
    more_description:
      'This model is designed to identify MQLs and/or PQLs looking at all their buying journey.',
    requirements:
      'Integration tracking web, marketing or email activity connected to MadKudu',
    illus: <img src={LeadEngagementIllus} width={200} height={112} />,
  },
  {
    name: 'Account Engagement',
    model: 'Engagement',
    type: 'mqa',
    color: 'pink',
    description:
      'Predict which accounts are more likely to convert based on their contacts and users engagement with your product, web, marketing or email content.',
    useCases: [
      {
        title: 'Engaging with accounts when they are spiking',
        text: 'Identify those accounts showing interest in your product and content so your sales team can reach out to them at the right time.',
        icon: LeadIcon,
      },
    ],
    output: AccountOutput,
    more_description: '',
    requirements:
      'Integration tracking web, marketing or email activity connected to MadKudu',
    illus: <img src={AccountEngagementIllus} width={200} height={112} />,
  },
];
