import cloneDeep from 'lodash/cloneDeep';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Col,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
  Form,
  Alert,
} from 'react-bootstrap';
import { ModelType } from '../../../models/ModelItem';
import {
  IRulePageModel,
  OPTIONS_MAPPER_SUBJECT_VALUES,
  Rule,
} from '../../../models/rules';
import { Condition, Subject } from '../../../models/sqlform';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../CheckPermissions';
import Error from '../error/Error';
import RuleComponent from './RuleComponent';
import { aggregationOptionsMapper } from './utils';

const CREATING_RULE = true;
const MOUNTING = true;

interface RuleEditorProps {
  store?: Store;
  model: IRulePageModel;
  formFields: Subject[];
  updateRules: (rules: Rule[]) => void;
  saveRules: () => void;
  withRuleAndSegment: boolean;
  withConditionLogic?: boolean;
  conditionLogic?: string;
  handleConditionLogic?: (value: string) => void;
  noSave?: boolean;
  isAggregationsForm?: boolean;
  eventActivityFields?: Subject[];
  modelType: ModelType;
  isAllowedToEdit: boolean;
}

interface RuleEditorState {
  hasUpdatedLogic: boolean;
}

export default inject('store')(
  observer(
    class RuleEditor extends React.Component<RuleEditorProps, RuleEditorState> {
      constructor(props: RuleEditorProps) {
        super(props);
        this.createRule = this.createRule.bind(this);
        this.saveRules = this.saveRules.bind(this);
        this.getDefaultFormRule = this.getDefaultFormRule.bind(this);
        this.getDefaultAdvancedRule = this.getDefaultAdvancedRule.bind(this);
        this.updateNewRules = this.updateNewRules.bind(this);
        this.updateRulesArray = this.updateRulesArray.bind(this);
        this.updateConditionLogic = this.updateConditionLogic.bind(this);
        this.state = {
          hasUpdatedLogic: false,
        };
      }

      componentDidMount() {
        if (this.props.isAggregationsForm && !this.props.model.rules.length) {
          this.createRule('form', this.props.isAggregationsForm, MOUNTING);
        }
      }

      updateNewRules(creating = false) {
        const {
          conditionLogic,
          handleConditionLogic,
          model: { rules },
        } = this.props;
        const { hasUpdatedLogic } = this.state;
        if (!handleConditionLogic) return;

        let newConditionLogic = '';
        if (!conditionLogic || !hasUpdatedLogic) {
          newConditionLogic = rules.reduce((acc, _element, index) => {
            return `${acc} ${
              index > 0 ? ` AND $${index + 1}` : `$${index + 1}`
            }`;
          }, '');
        } else if (creating) {
          newConditionLogic = `${conditionLogic} AND $${rules.length}`;
          this.setState({ hasUpdatedLogic: true });
        } else {
          return;
        }

        handleConditionLogic(newConditionLogic);
      }

      updateConditionLogic(conditionLogic: string) {
        this.props.handleConditionLogic(conditionLogic);
        this.setState({ hasUpdatedLogic: true });
      }

      updateRulesArray(rules: []) {
        this.props.updateRules(cloneDeep(rules));
        this.updateNewRules();
      }

      getDefaultFormRule(): Rule {
        let defaultRule: Rule;
        const { formFields } = this.props;
        if (this.props.modelType === 'customer_fit') {
          const defaultCondition: Condition = {
            subject: {
              subject: 'domain',
              type: 'string',
              context: 'standard',
              tags: [],
            },
            verb: 'is',
            complements: [''],
            lower: true,
          };

          defaultRule = {
            ruleType: 'form',
            formConditions: [defaultCondition],
            rule: 'should be',
            segment: 'very good',
          };
        } else {
          const defaultCondition: Condition = {
            subject: formFields[0],
            verb: 'is',
            complements: [''],
            lower: true,
          };

          defaultRule = {
            ruleType: 'form',
            formConditions: [defaultCondition],
            rule: 'should be',
            segment: 'very high',
          };
        }

        return defaultRule;
      }

      getDefaultAdvancedRule() {
        const defaultRule: Rule = {
          ruleType: 'advanced',
          sqlConditions: '',
          rule: 'should be',
          segment: 'very good',
        };

        return defaultRule;
      }

      getDefaultAggregationsRules(mounting: boolean) {
        const defaultRule: Rule = {
          ruleType: 'form',
          rule: 'should be',
          segment: 'very good',
          permanent: true,
        };

        if (mounting) {
          const firstRule: Rule = {
            ...defaultRule,
            formConditions: [
              {
                subject: {
                  subject: 'is_positive_user_activity',
                  type: 'boolean',
                  context: 'standard',
                  tags: [],
                },
                verb: 'is',
                complements: ['true'],
                lower: false,
              },
            ],
          };

          const secondRule: Rule = {
            ...defaultRule,
            formConditions: [
              {
                subject: {
                  subject: 'activity_type',
                  type: 'string',
                  context: 'standard',
                  tags: [],
                },
                verb: 'is not',
                complements: ['Non-user Activity'],
                lower: true,
              },
            ],
          };

          this.props.handleConditionLogic('$1 AND $2');
          return [firstRule, secondRule];
        }

        const { eventActivityFields, store } = this.props;
        const { eventMetadata } = store;
        const defaultComplement =
          aggregationOptionsMapper(
            eventActivityFields[0].subject as OPTIONS_MAPPER_SUBJECT_VALUES,
            eventMetadata
          )[0]?.value ?? '';
        return [
          {
            ...defaultRule,
            permanent: false,
            formConditions: [
              {
                subject: eventActivityFields[0],
                verb: 'is',
                complements: [defaultComplement],
                lower: true,
              },
            ],
          },
        ] as Rule[];
      }

      createRule(
        ruleType: 'form' | 'advanced',
        isAggregationsForm?: boolean,
        mounting?: boolean
      ) {
        const newRules = this.props.model.rules;
        if (ruleType === 'form') {
          if (isAggregationsForm) {
            newRules.push(...this.getDefaultAggregationsRules(mounting));
          } else {
            newRules.push(this.getDefaultFormRule());
          }
        } else {
          newRules.push(this.getDefaultAdvancedRule());
        }
        this.props.updateRules(newRules);
      }

      async saveRules() {
        await this.props.saveRules();
      }

      render() {
        const {
          model,
          formFields,
          eventActivityFields,
          withRuleAndSegment,
          withConditionLogic,
          noSave,
          conditionLogic,
          isAggregationsForm,
          modelType,
          isAllowedToEdit,
        } = this.props;
        const { rules, saved, saving, loadingError, savingError } = model;
        const error =
          rules.filter(
            (c) =>
              (c.ruleType === 'form' &&
                c.formConditions.filter(
                  (c2) =>
                    c2.error ||
                    c2.complements.some((co) => !co || co?.trim().length === 0)
                ).length > 0) ||
              (c.ruleType === 'advanced' &&
                c.sqlConditions?.trim().length === 0)
          ).length > 0;

        const conditionLogicTip = (
          <Tooltip id="conditionLogicTooltip">
            You can use parenthesis. <br /> eg: <code>$1 AND ($2 OR $3)</code>
          </Tooltip>
        );
        if (!formFields?.length && modelType === 'mqa' && !isAggregationsForm) {
          return (
            <Alert variant="info">
              <p>
                We didn&apos;t find any defined aggregations. In order to create
                overrides, you need to create at least one aggregation.
              </p>
            </Alert>
          );
        }
        return (
          <>
            {savingError && (
              <Error message={savingError?.message} type={savingError?.type} />
            )}
            {loadingError && (
              <Error
                message={loadingError?.message}
                type={loadingError?.type}
              />
            )}
            <>
              {withConditionLogic && rules.length > 0 && (
                <>
                  <Form.Label className="fw-bold d-block">
                    Edit logic
                  </Form.Label>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={conditionLogicTip}
                  >
                    <Form.Control
                      type="text"
                      value={conditionLogic}
                      onChange={(e: any) =>
                        this.updateConditionLogic(e.target.value)
                      }
                      placeholder="$1 AND ($2 OR $3)"
                      name="conditionLogic"
                    />
                  </OverlayTrigger>
                </>
              )}
              {rules.length > 0 && (
                <>
                  <Row className="gx-1 mt-2">
                    <Col className="condition">
                      <span className="fw-bold ">Conditions</span>
                    </Col>
                    {withRuleAndSegment && (
                      <>
                        <Col className="rule" xs={2}>
                          <span className="fw-bold">Rule</span>
                        </Col>
                        <Col className="segment" xs={2}>
                          <span className="fw-bold">Segment</span>
                        </Col>
                      </>
                    )}
                  </Row>
                  {rules.map((rule: Rule, index: number) => (
                    <RuleComponent
                      index={index}
                      rule={rule}
                      key={index}
                      updateRules={this.updateRulesArray}
                      rules={rules}
                      formFields={formFields}
                      eventActivityFields={eventActivityFields}
                      withRuleAndSegment={withRuleAndSegment}
                      withSubConditions={!withConditionLogic}
                      isAggregationsForm={isAggregationsForm}
                      isAllowedToEdit={isAllowedToEdit}
                    />
                  ))}
                </>
              )}
              {checkPermissions(
                PROFILES_PERMISSIONS.ARCHITECT,
                <>
                  <ButtonToolbar className="mt-3">
                    {modelType === 'mqa' || isAggregationsForm ? (
                      <Button
                        className="me-2"
                        onClick={() => {
                          this.updateNewRules(CREATING_RULE);
                          this.createRule('form', isAggregationsForm);
                        }}
                      >
                        Create new rule
                      </Button>
                    ) : (
                      <DropdownButton
                        title="Create new rule"
                        id="dropdown-new-rule"
                        onSelect={() => {
                          this.updateNewRules(CREATING_RULE);
                        }}
                        className="me-2"
                      >
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() =>
                            this.createRule('form', isAggregationsForm)
                          }
                        >
                          Form mode
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="2"
                          onClick={() => this.createRule('advanced')}
                        >
                          Advanced Mode (SQL)
                        </Dropdown.Item>
                      </DropdownButton>
                    )}
                    {!noSave && (
                      <Button
                        variant="success"
                        onClick={this.saveRules}
                        disabled={saved || saving || error}
                      >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {saving
                          ? 'Saving...'
                          : saved
                          ? 'Already saved'
                          : 'Save'}
                      </Button>
                    )}
                  </ButtonToolbar>
                </>
              )}
            </>
          </>
        );
      }
    }
  )
);
