import React from 'react';
import { Card } from 'react-bootstrap';

type HeaderCardProps = {
  supportLink: string;
  tenant: number;
};

const HeaderCard = ({ supportLink, tenant }: HeaderCardProps) => {
  return (
    <Card border="light">
      <Card.Body>
        <p>
          A model is trained using a training dataset then validated on a
          validation dataset which shouldn&apos;t overlap the training dataset.
          Build these datasets from audiences and conversions defined in the{' '}
          <a
            href={`https://app.madkudu.com/org/${tenant}/mapping/audiences`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            Audience Mapping
          </a>{' '}
          and{' '}
          <a
            href={`https://app.madkudu.com/org/${tenant}/mapping/conversion_mapping/overview`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            Conversion Mapping
          </a>
        </p>
        <a
          href={supportLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-decoration-none text-primary"
        >
          <i aria-hidden className="fas fa-book-open"></i> How to choose the
          datasets parameters
        </a>
      </Card.Body>
    </Card>
  );
};

export default HeaderCard;
