import React, { ReactElement } from 'react';

const CustomToggle = React.forwardRef<HTMLAnchorElement>(function CustomToggle(
  {
    // eslint-disable-next-line react/prop-types
    children,
    // eslint-disable-next-line react/prop-types
    onClick,
  }: {
    children: ReactElement;
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  },
  ref
) {
  return (
    <a
      className="text-dark"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i aria-hidden className="fas fa-ellipsis-h"></i>
      {children}
    </a>
  );
});

export default CustomToggle;
