import { inject, observer } from 'mobx-react';
import React from 'react';
import DeployMode from '../../../models/deploy/DeployMode';
import { getModelFolders, ModelFolder } from '../../../models/ModelItem';
import Store from '../../../store';
import DeployTabComponent from './DeployTabComponent';

interface StandardFitTabProps {
  store?: Store;
  openDeployPopup: (
    deployMode: DeployMode,
    folders: ModelFolder[]
  ) => Promise<void>;
  openImpactPopup: () => Promise<void>;
}

export default inject('store')(
  observer(
    class StandardFitTab extends React.Component<
      StandardFitTabProps,
      Record<string, never>
    > {
      render() {
        const { activeModel } = this.props.store;
        const folders: ModelFolder[] = getModelFolders(activeModel, {
          forDeployment: true,
        });

        return (
          <DeployTabComponent
            enabled
            deployMode="standard"
            folders={folders}
            deployButtonLabel="Deploy model as Standard Fit"
            downloadButtonLabel={(context: string) =>
              `Download primary ${context} config`
            }
            title={
              <p>
                <b>Deployment as Standard Fit model </b> (This is for you If you
                are working with <b>only one Fit model</b>)
              </p>
            }
            openDeployPopup={this.props.openDeployPopup}
            openImpactPopup={this.props.openImpactPopup}
          >
            <div>
              <ul>
                <li>For your first customer fit model</li>
              </ul>
              <p>Or</p>
              <ul>
                <li>
                  To replace the current model marked “Live as Standard Fit
                  model”
                </li>
              </ul>
              <h6>
                Clicking on the button <b>Deploy model as standard Fit</b> will
                trigger the following actions
              </h6>
              <ul>
                <li>
                  Push to production this new model (in batch and in realtime)
                  on the platform (this means the platform will start
                  calculating scores for all your records)
                </li>
                <li>
                  Replace the current Customer Fit model flagged “Live as
                  Standard Fit model”, if any.
                </li>

                <li>
                  Update the score, segment and signals in your integrations
                  where a push configuration is enabled between MadKudu and the
                  integration. The update will start with the{' '}
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4407302132621-How-to-read-the-Processes-page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    next sync process.
                  </a>
                </li>
              </ul>
              <p className="mb-4 mt-4">
                Make sure of your edits before deploying.
              </p>
            </div>
          </DeployTabComponent>
        );
      }
    }
  )
);
