import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Store from '../../../store';
import { parseAggregationName, slugValue, beautifyValue } from './utils';
import cloneDeep from 'lodash/cloneDeep';

const MAX_LENGTH_OF_AGGREGATION_VALUE = 63;

interface StepFiveProps extends RouteComponentProps<any> {
  store?: Store;
  isAdvanced: boolean;
  updateIsFormInvalid: (isFormInvalid: boolean) => void;
  isFormInvalid: boolean;
}

interface StepFiveState {
  aggregationFormName: string;
}

class StepFiveComponent extends React.Component<StepFiveProps, StepFiveState> {
  constructor(props: StepFiveProps) {
    super(props);
    const { store, isAdvanced } = this.props;
    const { name, customName } =
      store.behavioralAggregationsPage.activeAggregation;
    this.state = {
      aggregationFormName: beautifyValue(isAdvanced ? customName : name),
    };
    this.handleCustomNameChange = this.handleCustomNameChange.bind(this);

    makeObservable(this, {
      handleCustomNameChange: action,
    });
  }

  handleCustomNameChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { updateIsFormInvalid } = this.props;
    const value = cloneDeep(event.target.value);
    this.setState({
      aggregationFormName: value,
    });
    const sluggedValue = slugValue(value);
    const { logic, lifetime } =
      this.props.store.behavioralAggregationsPage.activeAggregation;

    const name = parseAggregationName({
      logic,
      lifetime,
      custom: sluggedValue,
    });
    if (name?.length > MAX_LENGTH_OF_AGGREGATION_VALUE) {
      updateIsFormInvalid(true);
    } else {
      updateIsFormInvalid(false);
    }
    this.props.store.behavioralAggregationsPage.activeAggregation.name = name;
    this.props.store.behavioralAggregationsPage.activeAggregation.customName =
      sluggedValue;
  }

  render() {
    const { isAdvanced, store, isFormInvalid } = this.props;
    const { aggregationFormName } = this.state;
    const { behavioralAggregationsPage, isSuperKudu, isArchitect } = store;
    const { activeAggregation } = behavioralAggregationsPage;

    if (!activeAggregation) return null;

    return (
      <Form.Group className="mb-3" controlId="formAggregationName">
        <Form.Label className="fw-bold d-block">Aggregation name</Form.Label>
        {isAdvanced ? (
          <div>
            <div>
              <span className="fst-italic">
                {beautifyValue(activeAggregation.logic)}&nbsp;
              </span>
              <FormControl
                type="text"
                className="d-inline-block w-25"
                name="aggregationNameCustom"
                onChange={this.handleCustomNameChange}
                value={aggregationFormName ?? ''}
                placeholder="(no spaces)"
                disabled={
                  !(isSuperKudu || isArchitect) ||
                  activeAggregation.usedInLiveModel ||
                  activeAggregation.isSynced
                }
              />
              <span className="fst-italic">
                &nbsp;Last&nbsp;
                {activeAggregation.lifetime ? activeAggregation.lifetime : 'X'}
                &nbsp;Days
              </span>
            </div>
            <div>
              {isFormInvalid && (
                <span className="fst-italic text-danger">
                  {`Aggregation name exceeded ${MAX_LENGTH_OF_AGGREGATION_VALUE} characters.`}
                </span>
              )}
            </div>
          </div>
        ) : (
          <FormControl
            disabled={true}
            type="text"
            name="aggregationName"
            value={activeAggregation.name ?? ''}
            placeholder="The name is automatically generated"
          />
        )}
      </Form.Group>
    );
  }
}

export default inject('store')(withRouter(observer(StepFiveComponent)));
