import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

type StatusProps = {
  text: string;
  title: string;
  background:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light'
    | string;
};

function Status({ text, title, background }: StatusProps) {
  return (
    <OverlayTrigger placement="right-end" overlay={<Tooltip>{title}</Tooltip>}>
      <Badge className="me-2" bg={background}>
        {text}
      </Badge>
    </OverlayTrigger>
  );
}

export default Status;
