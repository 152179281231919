import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Store from '../../../store';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ModelNotesComponent from '../../components/ModelNotes';
import { LinkContainer } from 'react-router-bootstrap';
import Spinner from '../../components/Spinner';
import LoadedDatasetOverview from '../../components/overview/LoadedDatasetOverview';
import QuickLinksOverview from '../../components/overview/QuickLinksOverview';
import Error from '../../components/error/Error';
import BadDatasetMessage from '../../components/overview/BadDatasetMessage';

type MatchParams = {
  tenant: string;
  modelId: string;
};

interface OverviewProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

const Overview = inject('store')(
  observer(
    class Overview extends React.Component<
      OverviewProps,
      Record<string, never>
    > {
      interval: number;

      constructor(props: OverviewProps) {
        super(props);
        this.cleanCache = this.cleanCache.bind(this);
        this.loadData = this.loadData.bind(this);
      }

      async componentDidMount() {
        const { rootPage, tenant, modelId } = this.props.store;

        // If dataset is loading, no need to load data
        if (
          !rootPage.isDataSetLoadingProgress &&
          rootPage.duplicationStatus === 'succeeded'
        ) {
          await this.loadData();
          clearInterval(this.interval);
        } else {
          this.interval = window.setInterval(async () => {
            await Promise.all([
              this.props.store.rootPage.getDataSetLoadingProgress({
                tenant,
                modelId,
              }),
              this.props.store.rootPage.getDuplicationStatus({
                tenant,
                modelId,
              }),
            ]);
          }, 10000);
        }
      }

      UNSAFE_componentWillUnmount() {
        clearInterval(this.interval);
      }

      async cleanCache() {
        await this.props.store.cleanCache();
      }

      async loadData() {
        const { tenant, modelId } = this.props.store;
        await this.props.store.dataPage.get(tenant, modelId);
      }

      render() {
        const { csvPage, rootPage, activeModel, dataPage } = this.props.store;
        const { dataSets } = csvPage;
        const { dataSetMetadata } = dataSets;

        const {
          cleaningCache,
          isDataSetLoadingProgress,
          isDataSetLoadingError,
          duplicationStatus,
        } = rootPage;
        const {
          type: modelType,
          live,
          lastDeployedAt,
          updatedAt,
          createdAt,
          duplicateOf,
        } = activeModel;
        const { tenant, modelId } = this.props.match.params;

        const duplicateOfName = this.props.store.tenantModels.find(
          ({ modelId }) => modelId === duplicateOf
        )?.name;

        return (
          <div className="mt-4">
            {dataSets.uploadDataSetError && (
              <Error
                message={dataSets.uploadDataSetError.message}
                type={dataSets.uploadDataSetError.type}
              />
            )}
            <BadDatasetMessage />
            {isDataSetLoadingProgress ? (
              <Card
                className="justify-content-center d-flex align-items-center"
                style={{ height: '30rem' }}
              >
                <Spinner />
                Dataset loading in process, it can take a few minutes to a few
                hours. You&#180;ll receive an email when finished. Feel free to
                take a break, or browse{' '}
                <a
                  href="https://support.madkudu.com/hc/en-us/categories/4403776749453-Data-Science-Studio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  our documentation
                </a>{' '}
                to know what to expect next.
              </Card>
            ) : duplicationStatus === 'in_progress' ? (
              <Card
                className="justify-content-center d-flex align-items-center"
                style={{ height: '30rem' }}
              >
                <Spinner />
                Model duplication in progress, it can take a few minutes to.
                You&#180;ll receive an email when finished. Feel free to take a
                break, or browse{' '}
                <a
                  href="https://support.madkudu.com/hc/en-us/categories/4403776749453-Data-Science-Studio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  our documentation
                </a>{' '}
                to know what to expect next.
              </Card>
            ) : (
              <>
                <Row>
                  <Col sm={6}>
                    <Card border="light" className="h-100">
                      <Card.Body>
                        <h5 className="mb-3 fw-bold">Model info</h5>
                        {isDataSetLoadingError && (
                          <Alert variant="danger">
                            The last dataset loading attempt has failed. The
                            dataset currently loaded is the previous one.
                          </Alert>
                        )}
                        <p>
                          Model was created on{' '}
                          <b>{`${moment(createdAt).format(
                            'MMM DD, YYYY h:mma'
                          )}`}</b>
                        </p>
                        <p>
                          Last updated on{' '}
                          <b>{`${moment(
                            live ? lastDeployedAt : updatedAt
                          ).format('MMM DD, YYYY h:mma')}`}</b>
                        </p>
                        <p>
                          Duplicate of model{' '}
                          <a href={`/tenant/${tenant}/models/${duplicateOf}`}>
                            <b>#{duplicateOf}</b>&nbsp;
                            {duplicateOfName ? `(${duplicateOfName})` : ''}
                          </a>
                        </p>
                        <div className="mt-5">
                          <ModelNotesComponent />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={6}>
                    <QuickLinksOverview />
                  </Col>
                </Row>
                <div className="mt-4 mb-4">
                  {dataPage.overviews && (
                    <LoadedDatasetOverview
                      tenant={tenant}
                      modelId={modelId}
                      overviews={dataPage.overviews}
                      dataSetMetadata={dataSetMetadata}
                      cleanCache={this.cleanCache}
                      cleaningCache={cleaningCache}
                    />
                  )}
                </div>
              </>
            )}
            {!dataSetMetadata &&
              !isDataSetLoadingProgress &&
              duplicationStatus === 'succeeded' && (
                <Card
                  className="justify-content-center d-flex align-items-center"
                  style={{ height: '30rem' }}
                >
                  <p className="fw-bold">Start by loading a dataset</p>

                  <LinkContainer
                    to={
                      modelType === 'customer_fit'
                        ? `/tenant/${tenant}/models/${modelId}/import_data`
                        : `/tenant/${tenant}/models/${modelId}/import_data/${modelType}?`
                    }
                  >
                    <Button>Import data</Button>
                  </LinkContainer>
                </Card>
              )}
          </div>
        );
      }
    }
  )
);

export default withRouter(Overview);
