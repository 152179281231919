import { makeObservable, observable } from 'mobx';
import { SampleLead } from '../SampleLead';

export interface SpotCheckQueryTables {
  newTable: string;
  baseTable: string;
  analysisTable: string;
}

export class SpotCheckPageModel {
  leadsSpotCheck: SampleLead[];

  loadingSpotCheck: boolean;

  loadSpotCheckError: string;

  constructor(other?: SpotCheckPageModel) {
    if (other) {
      this.leadsSpotCheck = other.leadsSpotCheck;
      this.loadingSpotCheck = other.loadingSpotCheck;
      this.loadSpotCheckError = other.loadSpotCheckError;
    } else {
      this.leadsSpotCheck = null;
      this.loadingSpotCheck = false;
      this.loadSpotCheckError = null;
    }
    makeObservable(this, {
      leadsSpotCheck: observable,
      loadingSpotCheck: observable,
      loadSpotCheckError: observable,
    });
  }
}
