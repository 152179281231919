import React, { useEffect, useState } from 'react';
import { Button, Modal, Toast, ToastContainer } from 'react-bootstrap';
import ModelItem from '../../../models/ModelItem';
import useUndeploy from '../../hooks/useUndeploy';

interface UndeployProps {
  tenant: number;
  model: ModelItem;
}

const Undeploy = ({ tenant, model }: UndeployProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [{ loading, error, done }, undeploy] = useUndeploy(
    tenant,
    model.modelId
  );
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    setShowError(!!error);
    if (showModal) {
      setShowModal(!error);
    }
  }, [error]);

  useEffect(() => {
    if (done && !error) {
      window.location.reload();
    }
  }, [done, error]);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Button
        variant="outline-warning me-2"
        size="sm"
        onClick={toggleShowModal}
      >
        <i aria-hidden className="fas fa-undo"></i> Undeploy
      </Button>
      <Modal onHide={toggleShowModal} show={showModal}>
        <Modal.Header>
          <Modal.Title>Undeploy model {model.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will undeploy the model. Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={toggleShowModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={undeploy} disabled={loading}>
            {loading ? 'Undeploying...' : 'Undeploy'}
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        className="p-5"
        style={{ zIndex: 200 }}
        position="bottom-end"
      >
        <Toast
          show={showError}
          onClose={() => {
            setShowError(false);
          }}
          delay={10000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">
              An error has occurred while undeploying
            </strong>
          </Toast.Header>
          <Toast.Body>{error}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Undeploy;
