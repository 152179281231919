import { makeObservable, observable } from 'mobx';

export default class LtbSignals {
  activityTypeExclusionList: string;

  eventExclusionListFromSignals: string[];

  domainEventExclusionListFromSignals: string[];

  aggregationsToInclude: string[];

  constructor(
    activityTypeExclusionList: string,
    eventExclusionListFromSignals: string[],
    domainEventExclusionListFromSignals: string[],
    aggregationsToInclude: string[]
  ) {
    this.activityTypeExclusionList = activityTypeExclusionList;
    this.eventExclusionListFromSignals = eventExclusionListFromSignals;
    this.domainEventExclusionListFromSignals =
      domainEventExclusionListFromSignals;
    this.aggregationsToInclude = aggregationsToInclude;
    makeObservable(this, {
      activityTypeExclusionList: observable,
      eventExclusionListFromSignals: observable,
      domainEventExclusionListFromSignals: observable,
      aggregationsToInclude: observable,
    });
  }
}
