import React, { useState, useEffect, useRef } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import UnivariateAnalysisVariableBucketWithShare from '../../../models/univariate/UnivariateAnalysisVariableBucketWithShare';
import { formatPercentage } from '../../../utils/formatters';
import SortForm from './SortForm';

type Sort = 'population' | 'conversions' | 'name';
type Order = 'asc' | 'desc';

type PopulationConversionsChartProps = {
  bucketsWithShares: UnivariateAnalysisVariableBucketWithShare[];
  colors: string[];
  defaultSort: Sort;
};

const sortOptions = [
  { key: 'population', value: 'Population' },
  { key: 'conversions', value: 'Conversions' },
  { key: 'name', value: 'Name' },
];

function CustomLegend({
  payload,
}: {
  payload?: { color: string; value: string }[];
}): JSX.Element {
  return (
    <ul style={{ listStyle: 'none' }} className="ms-1 p-0">
      {payload.map(({ color, value }) => (
        <li
          style={{
            color,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          key={`item-${value}`}
          title={value}
        >
          {value}
        </li>
      ))}
    </ul>
  );
}

function PopulationConversionsChart({
  bucketsWithShares,
  colors,
  defaultSort,
}: PopulationConversionsChartProps): JSX.Element {
  const [order, setOrder] = useState<Order>('asc');
  const [sort, setSort] = useState<Sort>('population');
  const [hoveredBar, setHoveredBar] = useState<string>('');
  const [chartParentWidth, setChartParentWidth] = useState<number>(0);
  const chartParentRef = useRef(null);

  useEffect(() => {
    setSort(defaultSort);
  }, [defaultSort]);

  useEffect(() => {
    setChartParentWidth(chartParentRef?.current?.offsetWidth ?? 0);

    window.addEventListener('resize', handleResize, false);
  }, []);

  const handleResize = () => {
    setChartParentWidth(chartParentRef?.current?.offsetWidth ?? 0);
  };

  const handleSortChange = (selectedSort: Sort) => {
    setSort(selectedSort);
  };

  const handleOrderChange = (selectedOrder: Order) => {
    setOrder(selectedOrder);
  };

  const sortElements = (
    a: UnivariateAnalysisVariableBucketWithShare,
    b: UnivariateAnalysisVariableBucketWithShare
  ): number => {
    switch (sort) {
      case 'population':
        return order === 'asc'
          ? b.population - a.population
          : a.population - b.population;
      case 'conversions':
        return order === 'asc'
          ? b.conversions - a.conversions
          : a.conversions - b.conversions;
      case 'name':
      default:
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
    }
  };

  const sortedBucketsWithShares = bucketsWithShares.sort(sortElements);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any = [{ name: '% population' }, { name: '% conversions' }];

  sortedBucketsWithShares.forEach((bucketWithShares) => {
    data[0][bucketWithShares.name] = bucketWithShares.populationShare;
    data[1][bucketWithShares.name] = bucketWithShares.conversionsShare;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function CustomTooltip({ active, payload, label }: any): JSX.Element {
    if (active && payload && payload.length) {
      const item = payload.find(
        ({ dataKey }: { dataKey: string }) => dataKey === hoveredBar
      );

      if (item) {
        const { fill, value } = item;

        return (
          <div className="custom-tooltip border bg-light p-3">
            <p className="label m-0">{label}</p>
            <p
              className="value m-0"
              style={{ color: fill }}
            >{`${hoveredBar} : ${formatPercentage(value)}`}</p>
          </div>
        );
      }
    }

    return null;
  }

  return (
    <div ref={chartParentRef}>
      <BarChart height={416} width={chartParentWidth} data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          domain={[0, 100]}
          tickFormatter={(value: string) => `${Math.floor(Number(value))}`}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{ width: '40%' }}
          content={<CustomLegend />}
        />
        {sortedBucketsWithShares.map(({ name }, index) => (
          <Bar
            dataKey={name}
            stackId="a"
            fill={colors[index]}
            onMouseOver={() => {
              setHoveredBar(name);
            }}
            key={`bar-${name}`}
          />
        ))}
      </BarChart>
      <SortForm
        sort={sort}
        order={order}
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        onOrderChange={handleOrderChange}
      />
    </div>
  );
}

export default PopulationConversionsChart;
