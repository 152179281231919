import React, { useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import isEqual from 'lodash/isEqual';

import GenericSelect, { GenericSelectProps } from './GenericSelect';
import { Option } from './types';

/**
 * MadSelect is a single choice select component based on GenericMadSelect features.
 * Specific features :
 *    - Move selected options to the top of the list
 */
function MadSelect<MadOption extends Option, IsFree extends boolean = false>(
  props: GenericSelectProps<MadOption, false, IsFree>
) {
  const { value, options, onChange } = props;

  const [selectedOption, setSelectedOption] = useState<SingleValue<Option>>(
    value as SingleValue<Option>
  );

  // move selected option in front
  const orderedOptions = useMemo(() => {
    return [...options].sort((optionA, optionB) => {
      if (isEqual(optionA, selectedOption)) return -1;
      if (isEqual(optionB, selectedOption)) return 1;
      return 0;
    });
  }, [selectedOption, options]);

  return (
    <GenericSelect
      {...props}
      options={orderedOptions}
      onChange={(newValue, actionMeta) => {
        setSelectedOption(newValue);

        // forward onChange
        onChange(newValue, actionMeta);
      }}
    />
  );
}

export default MadSelect;
