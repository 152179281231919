import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import Computation from '../../../../models/computations/Computation';
import Store from '../../../../store';

interface AdvancedFormComponentProps {
  store?: Store;
  computation: Computation;
  handleAdvancedQueryChange: (query: string) => void;
  readonly: boolean;
}

export default inject('store')(
  observer(
    class AdvancedFormComponent extends React.Component<
      AdvancedFormComponentProps,
      Record<string, never>
    > {
      render() {
        const { computation, readonly, handleAdvancedQueryChange } = this.props;

        return (
          <div>
            <FormControl
              as="textarea"
              placeholder="SQL query"
              maxLength={999999999}
              rows={10}
              onChange={(e: any) => handleAdvancedQueryChange(e.target.value)}
              value={computation.configuration.modeProperties.advanced.query}
              disabled={readonly}
            />
          </div>
        );
      }
    }
  )
);
