export default function replaceScientificNotation(name: string): string {
  const regex = /([0-9])( - )?((\]|\[)(.*),(.*)(\]|\[)|null)/;
  const match = regex.exec(name);
  if (!match) return name;
  if (match[3] === 'null') return `${match[1]} - no value`;

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getBoundary = (
    value: string,
    bracket: string,
    position: 1 | 2
  ): string => {
    if (value === '+infy' || value === '+infty') return 'infinity';
    const s = value.split('E');
    const base10Value =
      s.length > 1 ? Number(s[0]) * 10 ** Number(s[1]) : Number(s[0]);

    let afterBracket: number;
    if (bracket === '[') {
      if (position === 1) {
        afterBracket = base10Value;
      } else {
        afterBracket = base10Value - 1;
      }
    } else if (position === 1) {
      afterBracket = base10Value + 1;
    } else {
      afterBracket = base10Value;
    }

    return numberWithCommas(afterBracket);
  };

  return `${match[1]} - From ${getBoundary(
    match[5],
    match[4],
    1
  )} to ${getBoundary(match[6], match[7], 2)}`;
}
