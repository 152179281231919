import { HttpExceptionType } from '../../../models/errors/HttpExceptionType';
import React from 'react';

interface ErrorMessageContentProps {
  type: HttpExceptionType;
  tenant: number;
  modelId: number;
  message: string;
}

export default function ErrorMessageContent({
  type,
  tenant,
  modelId,
  message,
}: ErrorMessageContentProps) {
  switch (type) {
    case 'missingComputation':
      return (
        <>
          <p>
            {message}&nbsp;Please reload your dataset&nbsp;
            <a
              className="text-decoration-none text-primary"
              href={`/tenant/${tenant}/models/${modelId}/import_data/mk_audience`}
            >
              here.
            </a>
          </p>
        </>
      );
    case 'computationFormErrorOnName':
      return (
        <div>
          Computation name must respect the following rules:
          <ul>
            <li>No special characters.</li>
            <li>At least 5 characters.</li>
            <li>Begins with a character, ends with character/number.</li>
          </ul>
        </div>
      );
    case 'computationFormErrorSameAsAttribute':
      return (
        <p>
          You can&apos;t name a computation with an attribute name from the
          attribute mapping, please choose a different name for this computation
        </p>
      );
    case 'computationFormErrorSameAsComputation':
      return <p>A computation with this name already exists.</p>;
    default:
      return <p>{message}</p>;
  }
}
