import axios from 'axios';
import { useState } from 'react';

const useUndeploy = (
  tenant: number,
  modelId: number
): [
  { loading: boolean; error: string; done: boolean },
  () => Promise<void>
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [done, setDone] = useState<boolean>(false);

  const undeploy = async () => {
    setLoading(true);
    try {
      await axios.put(`/api/tenant/${tenant}/model/${modelId}/undeploy`);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
      setDone(true);
    }
  };

  return [{ loading, error, done }, undeploy];
};

export default useUndeploy;
