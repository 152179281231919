import { inject, observer } from 'mobx-react';
import React from 'react';
import ModelItem from '../../models/ModelItem';
import Store from '../../store';
import { Badge } from 'react-bootstrap';

interface ModelStatusProps {
  store?: Store;
  model: ModelItem;
}

export default inject('store')(
  observer(
    class ModelStatus extends React.Component<ModelStatusProps> {
      render() {
        const { model } = this.props;
        if (model.type === 'customer_fit') {
          if (model.live) {
            if (model.is_primary) {
              return <Badge bg="success">Live as Standard Fit Model</Badge>;
            }
            return <Badge bg="success">Live as Multi Fit Model</Badge>;
          }
          if (!model.is_primary && model.lastDeployedAt) {
            return <Badge bg="success">Live as Secondary Fit Model</Badge>;
          }
        } else if (model.live) {
          return <Badge bg="success">Live</Badge>;
        }
        return <span />;
      }
    }
  )
);
