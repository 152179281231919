import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  Card,
  Row,
  Tooltip,
} from 'react-bootstrap';
import ModelBaseNames from '../../../models/modelBase/ModelBaseNames';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import ModelPerformanceGraphs from '../../components/ModelPerformanceGraphs';
import AUCCurve from '../../components/trees/AUCCurve';
import Spinner from '../../components/Spinner';

interface EnsemblingPageProps {
  store?: Store;
}

interface EnsemblingPageState {
  selectedTreeModalAUCCurve: number;
  isEnsemblingChanged: boolean;
}

export default inject('store')(
  observer(
    class EnsemblingPage extends React.Component<
      EnsemblingPageProps,
      EnsemblingPageState
    > {
      constructor(props: EnsemblingPageProps) {
        super(props);
        this.changeWeight = this.changeWeight.bind(this);
        this.changeThreshold = this.changeThreshold.bind(this);
        this.loadEnsemblingPerformances =
          this.loadEnsemblingPerformances.bind(this);
        this.autoEnsembling = this.autoEnsembling.bind(this);
        this.selectTreeModalAUCCurve = this.selectTreeModalAUCCurve.bind(this);
        this.isWeightsNotEqual100 = this.isWeightsNotEqual100.bind(this);
        this.state = {
          selectedTreeModalAUCCurve: null,
          isEnsemblingChanged: false,
        };
      }

      async componentDidMount() {
        const { activeModel, treesPage, ensemblingPage, tenant, modelId } =
          this.props.store;
        const { type, base } = activeModel;
        if (type === 'customer_fit' && base === 'trees') {
          if (!treesPage.trees?.length) {
            await this.props.store.loadAllTrees();
          }
        }

        if (!ensemblingPage.ensemble) {
          await ensemblingPage.loadConfiguration(tenant, modelId);
        }

        if (!this.isWeightsNotEqual100()) {
          await this.loadEnsemblingPerformances({ toInit: true });
        }
      }

      changeWeight(tree: string, weight: number) {
        this.props.store.ensemblingPage.ensemble.weights[tree] = weight;
        this.setState({
          isEnsemblingChanged: true,
        });
      }

      changeThreshold(threshold: string, value: number) {
        this.props.store.ensemblingPage.ensemble.thresholds[threshold] = value;
        this.setState({
          isEnsemblingChanged: true,
        });
      }

      async loadEnsemblingPerformances(options: { toInit: boolean }) {
        if (this.state.isEnsemblingChanged) {
          this.props.store.analyticsTrackEvent('Updated ensembling');
        }
        await this.props.store.loadEnsemblingPerformances({
          isProcessing: options.toInit,
        });
        this.setState({
          isEnsemblingChanged: false,
        });
      }

      async autoEnsembling() {
        await this.props.store.autoEnsembling({ isProcessing: false });
      }

      selectTreeModalAUCCurve(treeId: number) {
        this.setState({
          selectedTreeModalAUCCurve: treeId,
        });
      }

      isWeightsNotEqual100() {
        const { ensemble } = this.props.store.ensemblingPage;
        return (
          ensemble?.weights &&
          Object.values(ensemble.weights).reduce(
            (sum: number, weight) => sum + Number(weight) * 100,
            0
          ) !== 100.0
        );
      }

      render() {
        const { ensemblingPage, activeModel, isAllowedToEdit } =
          this.props.store;
        const {
          ensemble,
          loadingEnsemblingPerformances,
          ensemblingError,
          fetchingBestThresholds,
        } = ensemblingPage;
        const trees = this.props.store.treesPage.trees;

        let performances;
        const isTreeBased: boolean = activeModel.base === ModelBaseNames.trees;

        const weightsNotEqual100 = this.isWeightsNotEqual100();

        const { precisions } = this.state.selectedTreeModalAUCCurve
          ? trees.find(
              (tree) => tree.id === this.state.selectedTreeModalAUCCurve
            ).definition
          : { precisions: null };

        const weights: JSX.Element[] = !ensemble
          ? []
          : Object.keys(ensemble.weights).map((weight, index) => {
              const associatedTree = trees.find(
                (tree) => tree.id === Number(weight)
              );
              return (
                <FormGroup
                  as={Row}
                  key={`weight_${weight}`}
                  controlId={`weight_${weight}`}
                  className="mb-2"
                >
                  <FormLabel column sm={5}>
                    Tree {weight}
                  </FormLabel>
                  <Col sm={7}>
                    <InputGroup hasValidation={weightsNotEqual100}>
                      <FormControl
                        type="number"
                        value={ensemble.weights[weight] * 100}
                        disabled={!isAllowedToEdit}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.changeWeight(
                            weight,
                            Number(event.target.value) / 100
                          );
                        }}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                      {!associatedTree || associatedTree.loading ? (
                        <Button disabled variant="primary">
                          <i aria-hidden className="fas fa-spinner fa-spin" />
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip${index}`}>
                              Show AUC Curve
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={async () => {
                              // Load selected tree definitions
                              if (!associatedTree?.definition) {
                                await this.props.store.treesPage.loadOne(
                                  this.props.store.tenant,
                                  this.props.store.modelId,
                                  associatedTree.id
                                );
                              }
                              this.selectTreeModalAUCCurve(associatedTree.id);
                            }}
                            variant="primary"
                          >
                            <i aria-hidden className="fas fa-chart-line" />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Col>
                </FormGroup>
              );
            });

        const thresholds: JSX.Element[] = !ensemble
          ? []
          : ['very good', 'good', 'medium'].map((threshold) => {
              return (
                <FormGroup
                  as={Row}
                  key={`threshold_${threshold}`}
                  controlId={`threshold_${threshold}`}
                  className="mb-2"
                >
                  <FormLabel column sm={5}>
                    {threshold}
                  </FormLabel>
                  <Col sm={7}>
                    <InputGroup>
                      <FormControl
                        type="number"
                        step="0.1"
                        value={ensemble.thresholds[threshold]}
                        disabled={!isAllowedToEdit}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.changeThreshold(
                            threshold,
                            Number(event.target.value)
                          );
                        }}
                      />
                      <InputGroup.Text>
                        {isTreeBased ? '%' : 'points'}
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </FormGroup>
              );
            });

        if (loadingEnsemblingPerformances) {
          performances = <Spinner />;
        } else if (ensemble) {
          if (ensemble.performances) {
            performances = (
              <ModelPerformanceGraphs
                performances={ensemble.performances.performances}
                context="customer_fit"
                cardTitleSegment="Precision"
              />
            );
          }
        } else {
          performances = <Alert>No data</Alert>;
        }

        if (!this.props.store.ensemblingPage.ensemble) return <Spinner />;

        return (
          <div className="mt-3">
            {activeModel.type === 'customer_fit' && isTreeBased && (
              <Card border="light" className="mb-4">
                <Card.Body>
                  <Card.Text>
                    <p>
                      Adjust the weight of each tree in the scoring to optimize
                      the performance, and the segment thresholds to adjust the
                      volume of records in each segment.
                    </p>
                    <a
                      href="https://support.madkudu.com/hc/en-us/articles/4407471867405-Recall-and-Precision-how-is-the-performance-of-a-model-measured-"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none"
                    >
                      <i aria-hidden className="fas fa-book-open"></i> Recall or
                      Precision: how to optimize the model performance?
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
            {precisions && this.state.selectedTreeModalAUCCurve !== null && (
              <Modal
                show={true}
                onHide={() => this.selectTreeModalAUCCurve(null)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    AUC Curve for tree {this.state.selectedTreeModalAUCCurve}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AUCCurve precisions={precisions} />
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.selectTreeModalAUCCurve(null)}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
            <Row>
              <Col sm={3}>
                {isTreeBased && (
                  <Card border="light" className="mb-2">
                    <Card.Body>
                      <Card.Title className="text-primary">
                        Tree weights
                      </Card.Title>
                      <p>Importance of each tree in the score</p>
                      <Form>{weights}</Form>
                    </Card.Body>
                  </Card>
                )}
                <Card border="light" className="mb-2">
                  <Card.Body>
                    <Card.Title className="text-primary">
                      Segment thresholds
                    </Card.Title>
                    <p>
                      Minimum conversion rate needed to get the corresponding
                      segment
                    </p>
                    <Form>{thresholds}</Form>
                  </Card.Body>
                </Card>
                <div className="d-grid gap-2">
                  {!activeModel.live && (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          loadingEnsemblingPerformances ||
                          weightsNotEqual100 ||
                          fetchingBestThresholds
                        }
                        onClick={() =>
                          this.loadEnsemblingPerformances({
                            toInit: false,
                          })
                        }
                      >
                        {loadingEnsemblingPerformances
                          ? 'Saving and computing...'
                          : 'Save & Compute performance'}
                      </Button>
                      {checkPermissions(
                        PROFILES_PERMISSIONS.ARCHITECT,
                        <Button
                          variant="outline-primary"
                          onClick={this.autoEnsembling}
                          disabled={
                            loadingEnsemblingPerformances ||
                            fetchingBestThresholds
                          }
                        >
                          {fetchingBestThresholds
                            ? 'Fetching best parameters...'
                            : 'Auto-select the best parameters'}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col sm={8}>
                <Error message={ensemblingError} />
                {performances}
              </Col>
            </Row>
          </div>
        );
      }
    }
  )
);
