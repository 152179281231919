import { makeObservable, observable } from 'mobx';

export default class PointBasedScoringRulePageModel {
  isSaving: boolean;

  isLoading: boolean;

  isError: boolean;

  isSuccess: boolean;

  errorMessage: string;

  constructor(other?: PointBasedScoringRulePageModel) {
    if (other) {
      this.isSaving = other.isSaving;
      this.isLoading = other.isLoading;
      this.isError = other.isError;
      this.errorMessage = other.errorMessage;
      this.isSuccess = other.isSuccess;
    } else {
      this.isSaving = false;
      this.isLoading = false;
      this.isError = false;
      this.isSuccess = false;
      this.errorMessage = '';
    }
    makeObservable(this, {
      isSaving: observable,
      isLoading: observable,
      isError: observable,
      isSuccess: observable,
      errorMessage: observable,
    });
  }
}
