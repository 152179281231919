import { inject, observer } from 'mobx-react';
import queryString from 'querystring';
import React from 'react';
import { Col, Form, Row, Card } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Store from '../../../store';
import Error from '../../components/error/Error';
import UnivariateAnalysisCharts from '../../components/univariate/UnivariateAnalysisCharts';
import NewUnivariateAnalysisCharts from '../../components/univariate/NewUnivariateAnalysisCharts';

interface UnivariateAnalysisPageProps extends RouteComponentProps {
  store?: Store;
}

type UnivariateAnalysisPageState = {
  showUnknown: boolean;
  selectedVariable: string;
};

const UnivariateAnalysisPage = inject('store')(
  observer(
    class UnivariateAnalysisPage extends React.Component<
      UnivariateAnalysisPageProps,
      UnivariateAnalysisPageState
    > {
      constructor(props: UnivariateAnalysisPageProps) {
        super(props);

        this.state = {
          showUnknown: false,
          selectedVariable: '',
        };

        this.checkUnivariateAnalysisWithPersonal =
          this.checkUnivariateAnalysisWithPersonal.bind(this);
        this.toggleShowUnknown = this.toggleShowUnknown.bind(this);
        this.selectVariable = this.selectVariable.bind(this);
      }

      componentDidMount() {
        this.updateIncludePersonalFromQueryString(this.props.location.search);

        const queryParams = queryString.parse(
          this.props.location.search.split('?')[1]
        );

        const variable = queryParams.variable as string;
        if (variable) {
          this.setState({ selectedVariable: variable });
        }

        if (!this.props.store.univariateAnalysisPage.univariateAnalysis) {
          // Get computations to list them in the computations' list
          if (this.props.store.computationsPage.computations.length === 0) {
            this.props.store.getComputations();
          }
          this.props.store.loadUnivariateAnalysis();
        }
      }

      componentDidUpdate(): void {
        const { variables = [] } =
          this.props.store.univariateAnalysisPage.univariateAnalysis ?? {};
        if (!!variables.length && !this.state.selectedVariable) {
          this.setState({ selectedVariable: variables[0].id });
        }
      }

      checkUnivariateAnalysisWithPersonal() {
        this.props.store.checkUnivariateAnalysisWithPersonal();

        const search = queryString.parse(
          this.props.location.search.split('?')[1]
        );
        const newQueryString = {
          ...search,
          includePersonal:
            this.props.store.univariateAnalysisPage
              .univariateAnalysisWithPersonal,
        };
        const newUrl = `${this.props.location.pathname}?${queryString.stringify(
          newQueryString
        )}`;
        this.props.history.push(newUrl);

        this.props.store.loadUnivariateAnalysis();
        if (this.state.selectedVariable) {
          this.props.store.loadUnivariateAnalysisForVariable(
            this.state.selectedVariable
          );
        }
      }

      updateIncludePersonalFromQueryString(search: string) {
        const parsedSearch = queryString.parse(search.split('?')[1]);
        this.props.store.univariateAnalysisPage.univariateAnalysisWithPersonal =
          parsedSearch.includePersonal === 'true';
      }

      toggleShowUnknown() {
        this.setState({
          showUnknown: !this.state.showUnknown,
        });
      }

      selectVariable(id: string) {
        this.setState({
          selectedVariable: id,
        });

        if (!this.props.store.univariateAnalysisPage.univariateAnalysis)
          this.props.store.loadUnivariateAnalysisForVariable(id);
      }

      render() {
        const { tenant, modelId, univariateAnalysisPage } = this.props.store;
        const { showUnknown, selectedVariable } = this.state;
        const {
          univariateAnalysis,
          loadingUnivariateAnalysis,
          univariateAnalysisError,
          univariateAnalysisWithPersonal,
        } = univariateAnalysisPage;

        const sampleLinkTemplate = (
          variableTechnicalName: string,
          variableReadableName: string,
          bucketRawName: string,
          bucketName: string
        ) =>
          `/tenant/${tenant}/models/${modelId}/insights/${variableTechnicalName}/${variableReadableName}/${bucketRawName}/${bucketName}/leads?includePersonal=${univariateAnalysisWithPersonal}`;

        if (univariateAnalysisError) {
          return (
            <div className="mt-3">
              <Error message={univariateAnalysisError} />
            </div>
          );
        }

        return (
          <div className="mt-3 mb-5">
            <Card className="mb-3">
              <Card.Body>
                <p>
                  Understand how each computation of enrichment correlates to
                  conversion to identify the ones to use in your model.
                </p>
                <a
                  href="https://support.madkudu.com/hc/en-us/articles/4404624583949-Customer-Fit-Insights"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How to read
                  the Customer Fit Insights
                </a>
              </Card.Body>
            </Card>
            <Row className="mb-3">
              <Col>
                <div className="float-end">
                  <Form.Switch
                    className="d-inline-block me-3"
                    type="checkbox"
                    id="show_unknown_checkbox"
                    label="Include unknown values"
                    checked={showUnknown}
                    onChange={this.toggleShowUnknown}
                  />
                  <Form.Switch
                    className="d-inline-block"
                    type="checkbox"
                    id="is_personal_checkbox"
                    label="Include personal email"
                    checked={univariateAnalysisWithPersonal}
                    onChange={this.checkUnivariateAnalysisWithPersonal}
                  />
                </div>
              </Col>
            </Row>

            {!univariateAnalysis || loadingUnivariateAnalysis ? (
              <NewUnivariateAnalysisCharts
                univariateAnalysis={
                  univariateAnalysisPage.univariateAnalysisVariable
                }
                loadingUnivariateAnalysis={
                  univariateAnalysisPage.loadingUnivariateAnalysisVariable
                }
                univariateAnalysisError={
                  univariateAnalysisPage.univariateAnalysisVariableError
                }
                selectVariable={this.selectVariable}
                selectedVariable={this.state.selectedVariable}
                sampleLinkTemplate={sampleLinkTemplate}
                showUnknown={showUnknown}
              />
            ) : (
              <UnivariateAnalysisCharts
                univariateAnalysis={univariateAnalysis}
                selectVariable={this.selectVariable}
                selectedVariable={selectedVariable}
                sampleLinkTemplate={sampleLinkTemplate}
                showUnknown={showUnknown}
              />
            )}
          </div>
        );
      }
    }
  )
);
export default withRouter(UnivariateAnalysisPage);
