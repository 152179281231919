import React from 'react';
import { inject, observer } from 'mobx-react';
import Store from '../../../store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

type MatchParams = {
  tenant: string;
  modelId: string;
};

interface QuickLinksOverviewProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

const QuickLinks = inject('store')(
  observer(
    class QuickLinks extends React.Component<
      QuickLinksOverviewProps,
      Record<string, never>
    > {
      render() {
        const { tenant, modelId } = this.props.match.params;
        const { activeModel } = this.props.store;
        return (
          <Card border="light" className="h-100">
            <Card.Body>
              <h5 className="fw-bold mb-2">Quick links</h5>
              <div>
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/review/sample`}
                >
                  <Button variant="light text-primary mb-2">
                    <i aria-hidden className="fas fa-arrow-right" /> View a
                    sample of scored records
                  </Button>
                </LinkContainer>
              </div>
              <div>
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/model/signals`}
                >
                  <Button variant="light text-primary mb-2">
                    <i aria-hidden className="fas fa-arrow-right" />{' '}
                    {`Change ${
                      activeModel.type === 'customer_fit'
                        ? 'Customer Fit'
                        : activeModel.type.toUpperCase()
                    }
                    signals`}
                  </Button>
                </LinkContainer>
              </div>
              <div>
                {activeModel.type === 'customer_fit' ? (
                  <LinkContainer
                    to={`/tenant/${tenant}/models/${modelId}/model/overrides`}
                  >
                    <Button variant="light text-primary mb-2">
                      <i aria-hidden className="fas fa-arrow-right" /> Add
                      overrides to the model
                    </Button>
                  </LinkContainer>
                ) : (
                  <LinkContainer
                    to={`/tenant/${tenant}/models/${modelId}/model/event_weights`}
                  >
                    <Button variant="light text-primary">
                      <i aria-hidden className="fas fa-arrow-right" /> Change
                      event weights
                    </Button>
                  </LinkContainer>
                )}
              </div>
            </Card.Body>
          </Card>
        );
      }
    }
  )
);

export default withRouter(QuickLinks);
