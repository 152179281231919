import React from 'react';
import { Model, labelsColors } from '../../../models/create/ModelType';
import { IS_HYBRID } from '../../../utils/constants';

export const ModelCard = ({
  model,
  openPanel,
}: {
  model: Model;
  openPanel: (model: Model) => void;
}) => {
  return (
    <div
      onClick={() => openPanel(model)}
      className="tw-group tw-cursor-pointer tw-space-y-2 tw-rounded-lg tw-bg-white tw-p-4 tw-shadow-sm"
    >
      <div className="tw-relative tw-mb-4 tw-flex tw-h-40 tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-bg-[#eceef3] group-hover:tw-bg-opacity-70">
        {IS_HYBRID(model.type) && (
          <span className="tw-absolute tw-top-3 tw-left-3 tw-inline-block tw-rounded-full tw-bg-[#80E6DC] tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-text-green-900">
            Beta
          </span>
        )}
        {model.illus}
      </div>
      <div className="tw-flex tw-items-start">
        <div className="tw-text-[1.03rem] tw-font-semibold tw-text-gray-900 group-hover:tw-opacity-70">
          {model.name}
        </div>
        <span
          className="
           tw-ml-auto tw-inline-flex tw-h-7 tw-items-center tw-rounded-md tw-px-3 tw-text-sm tw-font-semibold tw-tracking-wide tw-text-white"
          style={{ backgroundColor: labelsColors[model.color] }}
        >
          {model.model}
        </span>
      </div>
      <div className="tw-text-sm tw-leading-relaxed tw-text-gray-500 group-hover:tw-opacity-80">
        {model.description}
      </div>
    </div>
  );
};
