import React from 'react';
import { FormLabel, Form } from 'react-bootstrap';

type SortFormProps = {
  sort: string;
  order: string;
  sortOptions: { key: string; value: string }[];
  onSortChange: (sort: string) => void;
  onOrderChange: (order: string) => void;
};

function SortForm({
  sort,
  order,
  sortOptions,
  onSortChange,
  onOrderChange,
}: SortFormProps): JSX.Element {
  const handleSortChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    onSortChange(target.value);
  };

  const handleOrderChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    onOrderChange(target.value);
  };

  return (
    <Form className="mt-auto text-center">
      <FormLabel className="fw-bold me-2">Sort by</FormLabel>
      <Form.Select
        className="d-inline w-auto"
        onChange={handleSortChange}
        value={sort}
      >
        {sortOptions.map(({ key, value }) => (
          <option value={key} key={key}>
            {value}
          </option>
        ))}
      </Form.Select>
      <Form.Select
        className="d-inline w-auto"
        onChange={handleOrderChange}
        value={order}
      >
        <option value="asc">asc</option>
        <option value="desc">desc</option>
      </Form.Select>
    </Form>
  );
}

export default SortForm;
