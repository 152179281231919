import { inject, observer } from 'mobx-react';
import React from 'react';
import { Col, Row, Card, Alert, Accordion } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Store from '../../../store';
import Error from '../../components/error/Error';

import FunnelBreakdownComponent from '../../components/performance_check/FunnelBreakdownComponent';
import LeadToRevenueComponent from '../../components/performance_check/LeadToRevenueComponent';
import ModelPerformanceGraphs from '../../components/ModelPerformanceGraphs';
import Spinner from '../../components/Spinner';
import PredictedLeadValue from '../../components/performance_check/PredictedLeadValue';

interface PerformanceCheckPageProps extends RouteComponentProps {
  store?: Store;
}

type PerformanceCheckPageState = {
  isLoading: boolean;
};

const PerformanceCheckPage = inject('store')(
  observer(
    class PerformanceCheckPage extends React.Component<
      PerformanceCheckPageProps,
      PerformanceCheckPageState
    > {
      scrollAnchor: React.RefObject<HTMLDivElement>;

      constructor(props: PerformanceCheckPageProps) {
        super(props);
        this.state = {
          isLoading: true,
        };

        this.executeScroll = this.executeScroll.bind(this);
      }

      async componentDidMount() {
        await this.props.store.loadValidationData({ isProcessing: true });

        if (this.props.store.activeModel.type === 'customer_fit') {
          await this.props.store.loadPerformanceCheck();
        }
        this.setState({
          isLoading: false,
        });
      }

      executeScroll() {
        this.scrollAnchor.current.scrollIntoView();
      }

      render() {
        const { validationPage, performanceCheckPage, activeModel } =
          this.props.store;

        const { performanceCheckError, performanceCheckData } =
          performanceCheckPage;

        const { validation } = validationPage;

        if (this.state.isLoading) {
          return (
            <>
              <Spinner />
              <div className="text-center">
                Calculating the performance of your model... This can take a few
                seconds to a few minutes depending on the volume of data to
                process
              </div>
            </>
          );
        }

        return (
          <div className="mt-3 mb-5">
            {performanceCheckError && <Error message={performanceCheckError} />}
            {validation.error && <Error message={validation.error} />}
            <>
              <Card border="light" className="mt-4 mb-4">
                <Card.Body>
                  <Card.Text>
                    Before deploying your model, review the performance of the
                    model. This is done on the validation dataset that is
                    different from the one used to configure the model (the
                    training dataset). The population and conversions here are
                    as defined in the validation dataset parameters.
                  </Card.Text>
                </Card.Body>
              </Card>
              {!!validation.performances && (
                <Row>
                  <ModelPerformanceGraphs
                    performances={validation.performances}
                    context={
                      activeModel.type === 'customer_fit'
                        ? 'customer_fit'
                        : 'behavioral'
                    }
                    page="performance"
                    cardTitleLead="Account and Conversion distribution by Segment"
                    colWidth={6}
                  />
                </Row>
              )}
              {performanceCheckData && (
                <>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Revenue and Lead value analysis
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          The population is the same as above, but looking at
                          Closed Won conversions.
                        </p>
                        <Alert>
                          <b>Heads up!</b> If this model is deployed, It is
                          expected to see a difference between these graphs and
                          the graphs in the Predictions monitoring pages because
                          the data is different. Here you find a one shot static
                          analysis based on the validation dataset uploaded
                          while the Predictions in the app are using the moving
                          last 6 months and therefore change overtime.
                        </Alert>
                        <Row className="mb-2 mt-2" ref={this.scrollAnchor}>
                          <Col xs={4}>
                            <FunnelBreakdownComponent
                              funnelBreakdown={
                                performanceCheckData.funnelBreakdown
                              }
                            />
                          </Col>
                          <Col xs={4}>
                            <LeadToRevenueComponent
                              leadToRevenue={performanceCheckData.leadToRevenue}
                            />
                          </Col>
                          <Col xs={4}>
                            <PredictedLeadValue
                              data={performanceCheckData.predictedLeadValue}
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              )}
            </>
          </div>
        );
      }
    }
  )
);

export default withRouter(PerformanceCheckPage);
