import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Card,
  Row,
  Button,
} from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import capitalize from 'lodash/capitalize';

import Store from '../../../store';
import Error from '../../components/error/Error';
import ModelPerformanceGraphs from '../../components/ModelPerformanceGraphs';
import Spinner from '../../components/Spinner';
import checkPermissions from '../../components/CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import { GRADIENT_COLORS } from '../../../utils';

interface BehavioralEnsemblingPageProps {
  store?: Store;
}

type BehavioralEnsemblingPageState = {
  isLoading: boolean;
  showAdvancedGraph: boolean;
};

export default inject('store')(
  observer(
    class BehavioralEnsemblingPage extends React.Component<
      BehavioralEnsemblingPageProps,
      BehavioralEnsemblingPageState
    > {
      myRef: React.RefObject<HTMLDivElement>;

      constructor(props: BehavioralEnsemblingPageProps) {
        super(props);

        this.myRef = React.createRef();

        this.changeThreshold = this.changeThreshold.bind(this);
        this.loadEnsemblingPerformances =
          this.loadEnsemblingPerformances.bind(this);
        this.executeScroll = this.executeScroll.bind(this);
        this.autoEnsembling = this.autoEnsembling.bind(this);

        this.state = {
          isLoading: true,
          showAdvancedGraph: false,
        };
      }

      async componentDidMount() {
        const { ensemblingPage, tenant, modelId } = this.props.store;
        await ensemblingPage.loadConfiguration(tenant, modelId);
        await this.loadEnsemblingPerformances({ toInit: true });

        this.setState({ isLoading: false });
      }

      changeThreshold(threshold: string, value: number) {
        this.props.store.ensemblingPage.ensemble.thresholds[threshold] = value;
      }

      async loadEnsemblingPerformances(options: { toInit: boolean }) {
        await this.props.store.loadEnsemblingPerformances({
          isProcessing: options.toInit,
        });
      }

      executeScroll() {
        this.myRef.current.scrollIntoView();
      }

      async autoEnsembling() {
        await this.props.store.autoEnsembling({ isProcessing: false });
      }

      render() {
        const { ensemblingPage, isAllowedToEdit } = this.props.store;
        const {
          ensemble,
          loadingEnsemblingPerformances,
          ensemblingError,
          fetchingBestThresholds,
        } = ensemblingPage;

        let performances;
        const thresholds: JSX.Element[] = !ensemble?.thresholds
          ? []
          : ['very high', 'high', 'medium'].map((key) => {
              return (
                <FormGroup
                  as={Row}
                  key={`threshold_${key}`}
                  controlId={`threshold_${key}`}
                  className="mb-2"
                >
                  <FormLabel column sm={5}>
                    {capitalize(key)}
                  </FormLabel>
                  <Col sm={7}>
                    <InputGroup>
                      <FormControl
                        type="number"
                        step="0.1"
                        disabled={!isAllowedToEdit}
                        value={ensemble?.thresholds[key]}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          this.changeThreshold(key, Number(event.target.value));
                        }}
                      />
                      <InputGroup.Text>points</InputGroup.Text>
                    </InputGroup>
                  </Col>
                </FormGroup>
              );
            });
        if (ensemble) {
          if (ensemble.behavioralPerformances) {
            const { behavioralThresholdsDeciles } =
              ensemble.behavioralPerformances;
            const totalConversions = behavioralThresholdsDeciles.reduce(
              (sum: number, seg) => sum + Number(seg.conversions),
              0
            );
            const data: {
              decile: number;
              '% of Conversions': number;
              'Conversion Rate': number;
            }[] = [];
            behavioralThresholdsDeciles
              .slice()
              .sort((a, b) => Number(a.tile) - Number(b.tile))
              .reduce((cumulated: number, decile) => {
                let newCumulated = Number(
                  (
                    cumulated +
                    (Number(decile.conversions) * 100.0) / totalConversions
                  ).toFixed(2)
                );
                if (newCumulated > 100) newCumulated = 100;

                data.push({
                  decile: Number(decile.tile) - 1,
                  '% of Conversions': newCumulated,
                  'Conversion Rate': Number(
                    (
                      (Number(decile.conversions) * 100) /
                      Number(decile.population)
                    ).toFixed(2)
                  ),
                });

                return newCumulated;
              }, 0);

            performances = (
              <>
                <ModelPerformanceGraphs
                  performances={ensemble.behavioralPerformances.performances}
                  context="behavioral"
                />
                <Button
                  variant="outline-secondary"
                  className="mt-4 mb-4 w-25"
                  onClick={() => {
                    this.setState({
                      showAdvancedGraph: !this.state.showAdvancedGraph,
                    });
                    setTimeout(() => this.executeScroll(), 500);
                  }}
                >
                  More details &gt;
                </Button>
                {this.state.showAdvancedGraph && (
                  <Card border="light" ref={this.myRef}>
                    <Card.Body>
                      <Card.Title>Granular model performance</Card.Title>
                      <p>
                        This is a more granular view of the other graphs showing
                        the % of population accounting for X% of the conversions
                        and the conversion rate of the cumulated population.
                      </p>
                      <div style={{ height: 500 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart width={500} height={300} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="decile"
                              label={{
                                value: 'Decile',
                                position: 'insideBottomRight',
                                offset: 0,
                              }}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              dataKey="% of Conversions"
                              stroke={GRADIENT_COLORS.maxBlue}
                            />
                            <Line
                              dataKey="Conversion Rate"
                              stroke={GRADIENT_COLORS.maxRed}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </>
            );
          }
        } else {
          performances = <Alert>No data</Alert>;
        }

        if (this.state.isLoading) return <Spinner />;

        return (
          <>
            <Card border="light" className="mb-4 mt-4">
              <Card.Body>
                <Card.Text>
                  <p>
                    Adjust the segment thresholds to optimize the performance
                    and the volume of records in each segment according to your
                    needs.
                  </p>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4407471867405-Recall-and-Precision-how-is-the-performance-of-a-model-measured-"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                  >
                    <i aria-hidden className="fas fa-book-open"></i> Recall or
                    Precision: how to optimize the model performance?
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
            <Row>
              <Col sm={3}>
                <Card border="light">
                  <Card.Body>
                    <Card.Title className="text-primary">
                      Segment thresholds
                    </Card.Title>
                    <p>
                      Minimum points needed to get the corresponding segment
                    </p>
                    <Form>{thresholds}</Form>
                  </Card.Body>
                </Card>
                {thresholds?.length > 0 && (
                  <Button
                    variant="primary"
                    className="w-100 mt-2"
                    disabled={
                      loadingEnsemblingPerformances ||
                      fetchingBestThresholds ||
                      !isAllowedToEdit
                    }
                    onClick={() =>
                      this.loadEnsemblingPerformances({ toInit: false })
                    }
                  >
                    {loadingEnsemblingPerformances
                      ? 'Saving and computing...'
                      : 'Save & Compute performance'}
                  </Button>
                )}
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,
                  <Button
                    variant="outline-primary"
                    className="w-100 mt-2"
                    disabled={
                      loadingEnsemblingPerformances ||
                      fetchingBestThresholds ||
                      !isAllowedToEdit
                    }
                    onClick={this.autoEnsembling}
                  >
                    {fetchingBestThresholds
                      ? 'Fetching best parameters...'
                      : 'Auto-select the best parameters'}
                  </Button>
                )}
              </Col>
              <Col sm={8}>
                <Error message={ensemblingError} />
                {performances}
              </Col>
            </Row>
          </>
        );
      }
    }
  )
);
