import { DirectedComputationConditionGroup } from '../models/computations/Computation';
import { getVerbSettings, Verb } from '../models/sqlform';

export function isStringVerb(verb: Verb) {
  return getVerbSettings(verb)?.characteristics.types.includes('string');
}

/**
 * Ensure all string-verb conditions have truthy "isLower" value if undefined.
 */
export function ensureLowerConditions(
  conditionGroups: DirectedComputationConditionGroup[]
) {
  return conditionGroups.map((group) => ({
    ...group,
    directedConditions: group.directedConditions.map((item) => ({
      ...item,
      condition: isStringVerb(item.condition.verb)
        ? {
            ...item.condition,
            isLower:
              typeof item.condition?.isLower === 'boolean'
                ? item.condition.isLower
                : true,
          }
        : item.condition,
    })),
  }));
}
