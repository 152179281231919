import { makeObservable, observable } from 'mobx';

import { CustomerFitSampleLead } from '../SampleLead';
import UnivariateAnalysis from './UnivariateAnalysis';
import UnivariateAnalysisVariable from './UnivariateAnalysisVariable';

export default class UnivariateAnalysisPageModel {
  univariateAnalysis: UnivariateAnalysis;

  univariateAnalysisVariable: UnivariateAnalysisVariable;

  loadingUnivariateAnalysis: boolean;

  loadingUnivariateAnalysisVariable: boolean;

  univariateAnalysisError: string;

  univariateAnalysisVariableError: string;

  univariateAnalysisWithPersonal: boolean;

  leads: CustomerFitSampleLead[];

  loadLeadsError: string;

  loadingLeads: boolean;

  refreshingUA: boolean;

  constructor(other?: UnivariateAnalysisPageModel) {
    if (other) {
      this.univariateAnalysis = other.univariateAnalysis;
      this.univariateAnalysisVariable = other.univariateAnalysisVariable;
      this.loadingUnivariateAnalysis = other.loadingUnivariateAnalysis;
      this.loadingUnivariateAnalysisVariable =
        other.loadingUnivariateAnalysisVariable;
      this.univariateAnalysisError = other.univariateAnalysisError;
      this.univariateAnalysisVariableError =
        other.univariateAnalysisVariableError;
      this.univariateAnalysisWithPersonal =
        other.univariateAnalysisWithPersonal;
      this.leads = other.leads;
      this.loadLeadsError = other.loadLeadsError;
      this.loadingLeads = other.loadingLeads;
      this.refreshingUA = other.refreshingUA;
    } else {
      this.leads = [];
      this.loadLeadsError = null;
      this.loadingLeads = false;
      this.univariateAnalysis = null;
      this.univariateAnalysisVariable = null;
      this.loadingUnivariateAnalysis = false;
      this.loadingUnivariateAnalysisVariable = false;
      this.univariateAnalysisError = null;
      this.univariateAnalysisVariableError = null;
      this.univariateAnalysisWithPersonal = false;
      this.refreshingUA = false;
    }
    makeObservable(this, {
      univariateAnalysis: observable,
      univariateAnalysisVariable: observable,
      loadingUnivariateAnalysis: observable,
      loadingUnivariateAnalysisVariable: observable,
      univariateAnalysisError: observable,
      univariateAnalysisVariableError: observable,
      univariateAnalysisWithPersonal: observable,
      leads: observable,
      loadLeadsError: observable,
      loadingLeads: observable,
      refreshingUA: observable,
    });
  }
}
