import React from 'react';
import { inject, observer } from 'mobx-react';
import ModelPerformances, { Threshold } from '../../models/ModelPerformances';
import {
  CHART_COLORS,
  ModelPerformanceGraphsContext,
  WEIGHTS,
} from '../../utils';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import Store from '../../store';
import {
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Cell,
} from 'recharts';

interface ModelPerformanceGraphsProps {
  store?: Store;
  performances: ModelPerformances;
  hideConversions?: boolean;
  hideRates?: boolean;
  context: ModelPerformanceGraphsContext;
  cardTitleSegment?: string;
  cardTitleLead?: string;
  colWidth?: number;
  page?: string;
}

export default inject('store')(
  observer(
    class ModelPerformanceGraphs extends React.Component<
      ModelPerformanceGraphsProps,
      Record<string, never>
    > {
      stackedBarChart() {
        const { performances, context } = this.props;

        const data = [
          { name: '% leads', low: 0, medium: 0, good: 0, 'very good': 0 },
          {
            name: '% conversions',
            low: 0,
            medium: 0,
            good: 0,
            'very good': 0,
          },
        ];

        Object.entries(performances.repartition).forEach(
          ([threshold, { leadsShare, conversionsShare }]) => {
            data[0][threshold as Threshold] = Math.round(leadsShare);
            data[1][threshold as Threshold] = Math.round(conversionsShare);
          }
        );

        const weights = WEIGHTS[context];

        return (
          <div style={{ height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[0, 100]} />
                <Tooltip
                  itemSorter={(item) =>
                    -weights[item.dataKey as keyof typeof weights]
                  }
                  itemStyle={{ color: 'black' }}
                  formatter={(value) => `${value}%`}
                />
                <Legend
                  formatter={(value) => (
                    <span style={{ color: 'black' }}>{value}</span>
                  )}
                />
                {Object.keys(performances.repartition)
                  .sort(
                    (a, b) =>
                      weights[a as keyof typeof weights] -
                      weights[b as keyof typeof weights]
                  )
                  .map((threshold) => (
                    <Bar
                      key={threshold}
                      dataKey={threshold}
                      stackId="a"
                      fill={CHART_COLORS[context][threshold].background}
                    >
                      <LabelList
                        dataKey={threshold}
                        fill="white"
                        formatter={(value: number) => `${value}%`}
                      />
                    </Bar>
                  ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      }

      horizontalBarChart() {
        const { performances, context } = this.props;

        const weights = WEIGHTS[context];

        const data = Object.entries(performances.repartition)
          .map(([threshold, { rate }]) => ({
            name: threshold,
            rate: Number(Number(rate).toFixed(2)),
          }))
          .sort(
            (a, b) =>
              weights[b.name as keyof typeof weights] -
              weights[a.name as keyof typeof weights]
          );

        return (
          <div style={{ height: 400 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 'dataMax']} />
                <YAxis dataKey="name" type="category" />
                <Tooltip formatter={(value) => `${value}%`} />
                <Bar dataKey="rate">
                  {data.map(({ name }) => (
                    <Cell
                      key={name}
                      fill={CHART_COLORS[context][name].background}
                    />
                  ))}
                  <LabelList
                    dataKey="rate"
                    fill="white"
                    formatter={(value: number) => `${value}%`}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      }

      render() {
        const {
          hideConversions,
          hideRates,
          cardTitleSegment,
          cardTitleLead,
          colWidth,
          context,
          page,
        } = this.props;
        const { activeModel } = this.props.store;
        return (
          <Row>
            <Col xs={colWidth || 12} className="mb-4">
              <Card border="light" className="mb-2 h-100">
                <Card.Body>
                  <Card.Title>
                    {context === 'lead_grade'
                      ? 'Lead Grade performance'
                      : cardTitleLead || 'Model performance'}
                  </Card.Title>
                  {activeModel.type === 'mqa'
                    ? 'How many accounts are identified as engaged? Do they represent the majority of your conversions?'
                    : 'How many leads are identified as qualified? Do they represent the majority of your conversions?'}

                  {this.stackedBarChart()}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={colWidth || 12} className="mb-4">
              <Card border="light" className="h-100">
                <Card.Body>
                  <Card.Title>
                    {cardTitleSegment || 'Segment conversion rate'}
                  </Card.Title>
                  <p>
                    How is each segment performing compared to the others?{' '}
                    {page !== 'performance' && (
                      <OverlayTrigger
                        rootClose={true}
                        placement="right"
                        overlay={
                          <Popover id="popover">
                            <Popover.Body>
                              Only the relative difference between the segment
                              conversion rates here is relevant, not the
                              absolute number given that the training dataset
                              conversion rate is artificial.{' '}
                              <a
                                href="https://support.madkudu.com/hc/en-us/articles/4422952901389-Why-is-the-Training-dataset-conversion-rate-higher-than-you-expect-"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Learn more.
                              </a>
                            </Popover.Body>
                          </Popover>
                        }
                        trigger="click"
                      >
                        <i
                          aria-hidden
                          className="fas fa-question-circle fa-lg ms-1 text-info"
                        />
                      </OverlayTrigger>
                    )}
                  </p>
                  {!hideConversions && !hideRates && this.horizontalBarChart()}
                  <p className="text-center">Conversion rate (in %)</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      }
    }
  )
);
