import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { inject, observer } from 'mobx-react';

import AdminPage from './Admin';
import ComputationsPage from './ComputationsPage';
import HelpCenter from './HelpCenter';
import NewComputationPage from './NewComputationPage';
import ModelNotFoundPage from './ModelNotFoundPage';
import ModelPage from './ModelPage';
import TenantPage from './TenantPage';
import SideBar from '../components/SideBar';
import ViewEditComputationPage from './ViewEditComputationPage';
import BehavioralAggregationsPage from './BehavioralAggregationsPage';
import NewBehavioralAggregationPage from './NewBehavioralAggregationPage';
import ViewEditBehavioralAggregationPage from './ViewEditBehavioralAggregationPage';
import FrequencyAnalysisPage from './FrequencyAnalysisPage';
import { APP_NAME } from '../../utils/constants';
import getPageInfo, { PageParams } from '../../utils/get-page-info';
import Store from '../../store';
import NewModelPage from './model/NewModel';

type StudioPageProps = {
  store?: Store;
};

function StudioPage({ store }: StudioPageProps) {
  const { tenant } = useParams<{ tenant: string }>();

  const match = useRouteMatch();
  const [pageName, setPageName] = useState<string>('');

  useEffect(() => {
    const pageInfo = getPageInfo(window.location.pathname);

    if (pageInfo && pageName !== pageInfo.pageName) {
      trackPageView(pageInfo.pageName, pageInfo.params);
      setPageName(pageInfo.pageName);
    }
  }, [window?.location?.pathname]);

  const trackPageView = (name: string, params?: PageParams) => {
    try {
      const { email } = store;

      const paramsToSend: PageParams & { model_type?: string } = {
        ...params,
      };

      if (params.model_id) {
        const { activeModel } = store;
        paramsToSend.model_type = activeModel.type;
      }

      window.analytics.page(name, {
        email,
        app: 'studio',
        ...paramsToSend,
      });
    } catch (e) {
      // avoid displaying analytics error for user
      // an error here means that the getPageName parser was unable to clean the page name for our analytics tool
    }
  };

  // Redirect user to setTenant page if no permissions to access the current tenant
  if (!store.isUserAuthorizedForTenant(tenant))
    return <Redirect to={`/?tenant=${tenant}`} />;

  return (
    <div className="studio d-flex">
      <Helmet>
        <title>
          {APP_NAME} - {pageName}
        </title>
      </Helmet>
      <SideBar />
      <Container fluid>
        <Switch>
          <Route path={`${match.path}/404`}>
            <ModelNotFoundPage />
          </Route>
          <Route path={`${match.path}/admin`}>
            <AdminPage />
          </Route>
          <Route path={`${match.path}/models/new`}>
            <NewModelPage />
          </Route>
          <Route path={`${match.path}/models/:modelId`}>
            <ModelPage />
          </Route>
          <Route path={`${match.path}/models`}>
            <TenantPage />
          </Route>
          <Route path={`${match.path}/computations/new`}>
            <NewComputationPage />
          </Route>
          <Route path={`${match.path}/computations/:computationName`}>
            <ViewEditComputationPage />
          </Route>
          <Route path={`${match.path}/computations`}>
            <ComputationsPage />
          </Route>
          <Route path={`${match.path}/helpCenter`}>
            <HelpCenter />
          </Route>
          <Route path={`${match.path}/aggregations/new`}>
            <NewBehavioralAggregationPage />
          </Route>
          <Route
            path={`${match.path}/aggregations/:aggregationName/frequency_analysis`}
          >
            <FrequencyAnalysisPage />
          </Route>
          <Route path={`${match.path}/aggregations/:aggregationName`}>
            <ViewEditBehavioralAggregationPage />
          </Route>
          <Route path={`${match.path}/aggregations`}>
            <BehavioralAggregationsPage />
          </Route>
          <Redirect from={match.path} to={`${match.path}/models`} />
        </Switch>
      </Container>
    </div>
  );
}

export default inject('store')(observer(StudioPage));
