import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { inject, observer } from 'mobx-react';
import { utc } from 'moment-mini';
import React from 'react';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Modal,
  Row,
  Card,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Store from '../../store';
import { PROFILES_PERMISSIONS } from '../../permissions';
import StepFive from '../components/behavioral_aggregations/StepFive';
import StepFour from '../components/behavioral_aggregations/StepFour';
import StepOne from '../components/behavioral_aggregations/StepOne';
import StepThree from '../components/behavioral_aggregations/StepThree';
import StepTwo from '../components/behavioral_aggregations/StepTwo';
import checkPermissions from '../components/CheckPermissions';
import {
  BehavioralAggregation,
  logicOptions as logicOptionsByType,
} from '../../models/aggregations/behavioral_aggregations';
import {
  parseAggregationDisplayName,
  parseMetaEventDisplayName,
} from '../../utils/aggregations';
import PageHeader from '../components/PageHeader';
import DeleteAggregationModal from '../components/behavioral_aggregations/DeleteAggregationModal';
import Spinner from '../components/Spinner';
import { LinkContainer } from 'react-router-bootstrap';

type MatchParams = {
  tenant: string;
  aggregationName: string;
};
interface ViewEditBehavioralAggregationPageProps
  extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface ViewEditBehavioralAggregationPageState {
  savePopup: boolean;
  aggregation?: BehavioralAggregation;
  hasLiveEvents?: boolean;
  deleting: boolean;
  shouldShowDeletionModal: boolean;
  isFormInvalid: boolean;
}

const ViewEditBehavioralAggregationPage = inject('store')(
  observer(
    class ViewEditBehavioralAggregationPage extends React.Component<
      ViewEditBehavioralAggregationPageProps,
      ViewEditBehavioralAggregationPageState
    > {
      constructor(props: ViewEditBehavioralAggregationPageProps) {
        super(props);

        this.state = {
          savePopup: false,
          deleting: false,
          shouldShowDeletionModal: false,
          isFormInvalid: false,
        };

        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
        this.showSavePopup = this.showSavePopup.bind(this);
        this.closeSavePopup = this.closeSavePopup.bind(this);
        this.shouldSaveOrDisplayPopup =
          this.shouldSaveOrDisplayPopup.bind(this);
        this.toggleDeletionModal = this.toggleDeletionModal.bind(this);
        this.deleteAggregation = this.deleteAggregation.bind(this);
        this.updateIsFormInvalid = this.updateIsFormInvalid.bind(this);
      }

      async componentDidMount() {
        const { tenant } = this.props.store;

        if (
          this.props.store.behavioralAggregationsPage.aggregations.length === 0
        ) {
          await this.props.store.behavioralAggregationsPage.get(tenant);
        }
        if (
          this.props.store.aggregatedEventsPage.aggregatedEvents.length === 0
        ) {
          await this.props.store.getAggregatedEvents();
        }

        const { aggregatedEvents } = this.props.store.aggregatedEventsPage;

        const aggregationName = this.props.match.params.aggregationName;
        const aggregation =
          this.props.store.behavioralAggregationsPage.aggregations.find(
            ({ name }) =>
              name === decodeURI(aggregationName).replace(/%2F/gi, '/')
          );

        this.props.store.behavioralAggregationsPage.activeAggregation =
          cloneDeep(aggregation);

        this.setState({
          aggregation,
          hasLiveEvents: aggregatedEvents.some(
            ({ aggregationName, usedInLiveModel }) =>
              aggregationName === aggregation.name && usedInLiveModel
          ),
        });
      }

      updateIsFormInvalid(isFormInvalid: boolean) {
        this.setState({
          isFormInvalid,
        });
      }

      async save() {
        const { behavioralAggregationsPage, tenant } = this.props.store;
        const { activeAggregation } = behavioralAggregationsPage;
        this.props.store.behavioralAggregationsPage.saving = true;
        activeAggregation.updatedAt = utc().format('YYYY-MM-DD');
        this.props.store.behavioralAggregationsPage.aggregations =
          behavioralAggregationsPage.aggregations.map((aggregation) => {
            if (aggregation.id === this.state.aggregation.id) {
              return {
                ...activeAggregation,
                released: false,
              };
            }
            return aggregation;
          });

        // propagate an aggregation change to all of its aggregated events
        if (!isEqual(this.state.aggregation, activeAggregation)) {
          await this.props.store.getAggregatedEvents();
          this.props.store.aggregatedEventsPage.aggregatedEvents =
            this.props.store.aggregatedEventsPage.aggregatedEvents.map(
              (aggEvent) => {
                if (aggEvent.aggregationName === this.state.aggregation.name) {
                  const { lifetime, logic, customName, name } =
                    activeAggregation;
                  const aggregationDisplayName = parseAggregationDisplayName(
                    logic,
                    customName || name,
                    lifetime
                  );
                  const metaEventDisplayName = parseMetaEventDisplayName(
                    aggregationDisplayName,
                    aggEvent.occurrences
                  );

                  return {
                    ...aggEvent,
                    name: metaEventDisplayName,
                    released: false,
                    aggregationName: activeAggregation.name,
                  };
                }
                return aggEvent;
              }
            );
          await this.props.store.saveAggregatedEvents();
        }

        await this.props.store.behavioralAggregationsPage.save(tenant);
        this.setState({ savePopup: false });
        this.back();
      }

      async showSavePopup() {
        this.setState({ savePopup: true });
      }

      closeSavePopup() {
        this.setState({ savePopup: false });
      }

      shouldSaveOrDisplayPopup() {
        const { aggregation, hasLiveEvents } = this.state;

        if (
          aggregation.usedInLiveModel ||
          aggregation.isSynced ||
          hasLiveEvents
        ) {
          return this.showSavePopup();
        }

        return this.save();
      }

      displaySavePopup() {
        const { savePopup } = this.state;
        const { saving } = this.props.store.behavioralAggregationsPage;
        return (
          <Modal show={savePopup} onHide={this.closeSavePopup} size="lg">
            <Modal.Header>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="warning">
                This aggregation is live, exported to your integration(s) or has
                live aggregated events.
              </Alert>
              <p>
                Are you sure you want to edit it? You will need to release the
                aggregations and aggregated events in order to see these changes
                in your model.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={this.closeSavePopup}
                disabled={saving}
                variant="outline-primary"
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={this.save} disabled={saving}>
                {saving ? 'Saving changes' : 'Save'}
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }

      toggleDeletionModal() {
        this.setState({
          shouldShowDeletionModal: !this.state.shouldShowDeletionModal,
        });
      }

      deleteAggregation() {
        return async () => {
          this.setState({ deleting: true });
          const { tenant, behavioralAggregationsPage } = this.props.store;
          const { aggregations } = behavioralAggregationsPage;
          this.props.store.behavioralAggregationsPage.aggregations =
            aggregations.filter(({ id }) => id !== this.state.aggregation.id);

          await this.props.store.behavioralAggregationsPage.save(tenant);
          // re-save aggregated events to filter out aggEvents that belong to deleted aggregation
          await this.props.store.saveAggregatedEvents();

          this.setState({ deleting: false });
          this.back();
        };
      }

      back() {
        this.props.history.push(
          `/tenant/${this.props.match.params.tenant}/aggregations`
        );
      }

      render() {
        const { behavioralAggregationsPage, isSuperKudu, isArchitect } =
          this.props.store;
        const {
          aggregation,
          hasLiveEvents,
          deleting,
          shouldShowDeletionModal,
          isFormInvalid,
        } = this.state;
        const { activeAggregation, saving } = behavioralAggregationsPage;
        const { logic, name, type } = activeAggregation;
        const isAdvanced = logicOptionsByType[type]
          ?.find(({ value }) => value === logic)
          ?.display.includes('(for a specific event or group of events)');
        const logicOptions = logicOptionsByType[activeAggregation.type] || [];

        if (!aggregation) {
          return <Spinner />;
        }

        return (
          <>
            <PageHeader>
              <Breadcrumb className="fs-5 mb-2">
                <LinkContainer
                  to={`/tenant/${this.props.match.params.tenant}/aggregations`}
                >
                  <Breadcrumb.Item>Behavioral aggregations</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active className="fw-bold">
                  {isSuperKudu || isArchitect
                    ? `Editing ${name}`
                    : `Viewing ${name}`}
                </Breadcrumb.Item>
              </Breadcrumb>
            </PageHeader>
            {this.displaySavePopup()}
            <div className="mt-3 mb-3">
              <Row>
                <Col xs={{ span: 6, offset: 3 }}>
                  <Card>
                    <Card.Body>
                      <StepOne />
                      <StepTwo logicOptions={logicOptions} />
                      <StepThree />
                      {isAdvanced && <StepFour />}
                      <StepFive
                        isAdvanced={isAdvanced}
                        updateIsFormInvalid={this.updateIsFormInvalid}
                        isFormInvalid={isFormInvalid}
                      />
                      {checkPermissions(
                        PROFILES_PERMISSIONS.ARCHITECT,
                        <div className="d-flex justify-content-center">
                          <Button
                            className="me-3"
                            variant="outline-primary"
                            onClick={this.back}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="me-3"
                            variant="primary"
                            disabled={saving || isFormInvalid}
                            onClick={this.shouldSaveOrDisplayPopup}
                          >
                            {saving ? 'Saving' : 'Save'}
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              this.toggleDeletionModal();
                            }}
                            disabled={
                              aggregation.usedInLiveModel ||
                              hasLiveEvents ||
                              aggregation.isSynced
                            }
                          >
                            Delete aggregation
                          </Button>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
            <DeleteAggregationModal
              display="aggregations"
              deleting={deleting}
              shouldShow={shouldShowDeletionModal}
              toggleModal={this.toggleDeletionModal}
              deleteAggregation={this.deleteAggregation()}
            />
          </>
        );
      }
    }
  )
);

export default withRouter(ViewEditBehavioralAggregationPage);
