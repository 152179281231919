import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from 'react-bootstrap';
import Store from '../../../store';

interface DuplicationModelModalProps {
  store?: Store;
  close: () => void;
  fromModel: number;
  isShown: boolean;
}

interface DuplicationModelModalState {
  newModelName: string;
  requestState: 'still' | 'sent' | 'succeeded' | 'failed';
}

const defaultState: DuplicationModelModalState = {
  newModelName: '',
  requestState: 'still',
};

export default inject('store')(
  observer(
    class DuplicationModelModal extends React.Component<
      DuplicationModelModalProps,
      DuplicationModelModalState
    > {
      interval: number;

      constructor(props: DuplicationModelModalProps) {
        super(props);

        this.state = defaultState;

        makeObservable(this, {
          duplicateModel: action,
        });

        this.handleModelNameChange = this.handleModelNameChange.bind(this);
        this.close = this.close.bind(this);
        this.duplicateModel = this.duplicateModel.bind(this);
      }

      handleModelNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ newModelName: event.target.value });
      }

      close() {
        this.setState(defaultState);
        clearInterval(this.interval);
        this.props.close();
      }

      async duplicateModel() {
        const { newModelName } = this.state;
        const {
          store: { tenant },
          fromModel,
        } = this.props;
        this.setState({ requestState: 'sent' });
        try {
          const modelId = await this.props.store.rootPage.duplicateModel(
            tenant,
            {
              name: newModelName,
              fromModelId: fromModel,
            }
          );
          this.setState({ requestState: 'succeeded' });
          window.location.href = `/tenant/${tenant}/models/${modelId}`;
        } catch (err) {
          this.setState({ requestState: 'failed' });
        }
      }

      render() {
        const {
          store: { tenant },
          isShown,
          fromModel,
        } = this.props;

        const { newModelName, requestState } = this.state;

        return (
          <Modal onHide={this.close} show={isShown}>
            <Modal.Header closeButton>
              <Modal.Title>
                Duplicate the model {fromModel} for tenant {tenant}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {requestState === 'succeeded' && (
                <Alert variant="success">
                  Model duplication is in progress...
                </Alert>
              )}
              {requestState === 'failed' && (
                <Alert variant="danger">
                  An error has occurred! Please retry later.
                </Alert>
              )}
              <Form className="mt-3">
                <FormGroup>
                  <FormLabel>Model name</FormLabel>
                  <FormControl
                    type="text"
                    value={newModelName}
                    onChange={this.handleModelNameChange}
                  />
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={this.close}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.duplicateModel}
                disabled={requestState !== 'still' || newModelName === ''}
              >
                Duplicate
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }
    }
  )
);
