import indexOf from 'lodash/indexOf';
import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  Form,
  Modal,
  ToggleButton,
} from 'react-bootstrap';

import cloneDeep from 'lodash/cloneDeep';
import {
  ComputationType,
  ConfigurationModeOfComputation,
} from '../../../models/computations/Computation';
import Store from '../../../store';
import { ensureLowerConditions } from '../../../utils/computations';
import { beautifyValue, slugValue } from '../behavioral_aggregations/utils';
import Error from '../error/Error';
import AdvancedFormComponent from './configuration_mode_forms/AdvancedFormComponent';
import ConditionalForm from './configuration_mode_forms/conditional/ConditionalForm';
import OneToOneFormComponent from './configuration_mode_forms/OneToOneFormComponent';

type ComputationFormProps = {
  store?: Store;
  isNewComputation: boolean;
  save: () => void;
  back: () => void;
};

type ComputationFormState = {
  showPublicFacingModal: boolean;
  showDeleteModal: boolean;
  showSaveModal: boolean;
  computationName: string;
};

export default inject('store')(
  observer(
    class ComputationForm extends React.Component<
      ComputationFormProps,
      ComputationFormState
    > {
      constructor(props: ComputationFormProps) {
        super(props);
        this.previous = this.previous.bind(this);
        this.selectType = this.selectType.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleOneToOneFieldChange =
          this.handleOneToOneFieldChange.bind(this);
        this.handleConfigurationModeChange =
          this.handleConfigurationModeChange.bind(this);
        this.handleAdvancedQueryChange =
          this.handleAdvancedQueryChange.bind(this);
        this.saveDefinition = this.saveDefinition.bind(this);
        this.handleCheckTag = this.handleCheckTag.bind(this);
        this.preSave = this.preSave.bind(this);
        this.confirmPublicFacing = this.confirmPublicFacing.bind(this);
        this.cancelPublicFacing = this.cancelPublicFacing.bind(this);
        this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);

        this.state = {
          showPublicFacingModal: false,
          showDeleteModal: false,
          showSaveModal: false,
          computationName:
            beautifyValue(
              this.props.store.computationsPage.activeComputation.name
            ) ?? '',
        };

        makeObservable(this, {
          previous: action,
          selectType: action,
          handleNameChange: action,
          handleTagsChange: action,
          handleOneToOneFieldChange: action,
          handleConfigurationModeChange: action,
          handleAdvancedQueryChange: action,
          saveDefinition: action,
        });
      }

      previous() {
        const { back } = this.props;
        this.props.store.computationsPage.formError = null;
        back();
      }

      selectType(type: ComputationType) {
        this.props.store.computationsPage.activeComputation.type = type;
        this.props.store.computationsPage.activeComputation.configuration.modeProperties.conditional.elseValue =
          type === 'number' ? '0' : 'NULL';
      }

      handleNameChange(name: string) {
        this.setState({
          computationName: name,
        });
      }

      handleTagsChange(tags: string[]) {
        this.props.store.computationsPage.activeComputation.tags = tags;
      }

      handleConfigurationModeChange(
        configurationMode: ConfigurationModeOfComputation
      ) {
        this.props.store.computationsPage.activeComputation.configuration.mode =
          configurationMode;
      }

      // One to one
      handleOneToOneFieldChange(field: string) {
        this.props.store.computationsPage.activeComputation.configuration.modeProperties.oneToOne.field =
          field;
      }

      // Advanced
      handleAdvancedQueryChange(query: string) {
        this.props.store.computationsPage.activeComputation.configuration.modeProperties.advanced.query =
          query;
      }

      saveDefinition() {
        const { activeComputation, computations, metadataFields } =
          this.props.store.computationsPage;
        const { isNewComputation } = this.props;
        const { computationName } = this.state;

        this.props.store.computationsPage.formError = null;
        const regexExpValidateComputationName =
          /^[a-zA-Z]+(( |_)|[a-zA-Z]|\d)*([a-zA-Z]|\d)$/g;
        const numberOfOccurenceOfComputationNameRequired: number =
          isNewComputation ? 0 : 1;
        if (
          !regexExpValidateComputationName.test(computationName) ||
          computationName?.length <= 4
        ) {
          this.props.store.computationsPage.formError = {
            message: 'invalid computation name.',
            type: 'computationFormErrorOnName',
          };
          return;
        }
        const sluggedComputationName = slugValue(computationName);

        const isComputationNameAlreadyExists: boolean =
          computations.filter(
            (computation) =>
              computation.name.toLowerCase() === sluggedComputationName
          ).length > numberOfOccurenceOfComputationNameRequired;

        // if the computation is already LIVE due to a lot of computation that have already
        // a same name as an attribute mapping, removing the check avoid blocking the user
        const isComputationNameSameAsAttribute: boolean =
          !activeComputation.usedInLiveModel &&
          metadataFields
            // filter only attribute mapping
            .filter((metadataField) => metadataField.type.includes('attribute'))
            // search if there is the same name as this computation
            .some(
              (metadataField) =>
                metadataField.name.toLowerCase() === sluggedComputationName
            );

        if (isComputationNameAlreadyExists) {
          this.props.store.computationsPage.formError = {
            message: 'A computation with this name already exists.',
            type: 'computationFormErrorSameAsComputation',
          };
        } else if (isComputationNameSameAsAttribute) {
          this.props.store.computationsPage.formError = {
            message:
              "You can't name a computation with an attribute name from the attribute mapping, please choose a different name for this computation",
            type: 'computationFormErrorSameAsAttribute',
          };
        } else {
          activeComputation.name = sluggedComputationName;
          this.props.save();
          this.props.store.computationsPage.formError = null;
        }
      }

      handleCheckTag(tag: string) {
        const { activeComputation } = this.props.store.computationsPage;
        if (activeComputation.tags.includes(tag)) {
          const newTags = activeComputation.tags.filter((t) => t !== tag);
          this.handleTagsChange(newTags);
        } else {
          activeComputation.tags.push(tag);
          this.handleTagsChange(activeComputation.tags);
        }
      }

      preSave() {
        const { activeComputation } = this.props.store.computationsPage;

        const conditions = cloneDeep(
          activeComputation.configuration?.modeProperties?.conditional
            ?.conditions
        );

        if (Array.isArray(conditions)) {
          activeComputation.configuration.modeProperties.conditional.conditions =
            ensureLowerConditions(conditions);
        }

        if (activeComputation.tags.includes('Public-Facing')) {
          this.setState({ showPublicFacingModal: true });
          return;
        }

        if (activeComputation.usedInLiveModel || activeComputation.isSynced) {
          this.toggleShowSaveModal();
          return;
        }

        this.saveDefinition();
      }

      confirmPublicFacing() {
        const { activeComputation } = this.props.store.computationsPage;

        this.setState({
          showPublicFacingModal: false,
        });

        if (activeComputation.usedInLiveModel || activeComputation.isSynced) {
          this.toggleShowSaveModal();
          return;
        }

        this.saveDefinition();
      }

      cancelPublicFacing() {
        this.handleCheckTag('Public-Facing');
        this.setState({ showPublicFacingModal: false });
      }

      toggleShowDeleteModal() {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
      }

      async deleteComputation() {
        const { computations, activeComputation } =
          this.props.store.computationsPage;
        const computationIndex: number = indexOf(
          computations,
          activeComputation
        );
        this.props.store.computationsPage.computations.splice(
          computationIndex,
          1
        );
        await this.props.store.computationsPage.saveComputationsCache(
          this.props.store.tenant
        );
        this.toggleShowDeleteModal();
        this.previous();
      }

      toggleShowSaveModal() {
        this.setState({ showSaveModal: !this.state.showSaveModal });
      }

      render() {
        const { store, isNewComputation } = this.props;
        const {
          showPublicFacingModal,
          showDeleteModal,
          showSaveModal,
          computationName,
        } = this.state;

        const { computationsPage } = store;
        const {
          loadingError,
          savingError,
          formError,
          message,
          activeComputation,
          isSaving,
        } = computationsPage;

        const readonly = activeComputation.context === 'standard';

        const attributeFields =
          this.props.store.computationsPage.metadataFields.filter(
            ({ name, type }) =>
              type === 'attribute' && !['domain', 'email'].includes(name)
          );

        return (
          <>
            {(loadingError || formError || savingError) && (
              <Error
                message={
                  loadingError || formError?.message || savingError?.message
                }
                type={formError?.type || savingError?.type}
              />
            )}
            {message && (
              <Alert variant="success">
                <h4 dangerouslySetInnerHTML={{ __html: message }} />
              </Alert>
            )}
            <Form.Group className="mb-3" controlId="formComputationName">
              <Form.Label className="fw-bold">Computation name</Form.Label>
              <Form.Control
                type="text"
                value={computationName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.handleNameChange(event.target.value)
                }
                placeholder="Enter Computation Name"
                disabled={
                  !isNewComputation ||
                  activeComputation.usedInLiveModel ||
                  activeComputation.isSynced
                }
              />
              <Form.Text className="text-muted">
                Once created, the computation names cannot be changed. If
                required, you can create a new computation and delete the old
                one.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formComputationType">
              <Form.Label className="fw-bold">Computation type</Form.Label>
              <div>
                <ToggleButton
                  id="type-string"
                  type="radio"
                  variant="outline-primary me-3"
                  checked={activeComputation.type === 'string'}
                  value="string"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.selectType(
                      event.currentTarget.value as ComputationType
                    );
                  }}
                  disabled={readonly}
                >
                  String
                </ToggleButton>
                <ToggleButton
                  id="type-number"
                  type="radio"
                  variant="outline-primary"
                  checked={activeComputation.type === 'number'}
                  value="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.selectType(
                      event.currentTarget.value as ComputationType
                    );
                  }}
                  disabled={readonly}
                >
                  Number / Boolean (1 = true ; 0 = false)
                </ToggleButton>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formConfigurationMode">
              <Form.Label className="fw-bold">Configuration mode</Form.Label>
              <div>
                <ToggleButton
                  id="mode-conditional"
                  type="radio"
                  variant="outline-primary me-3"
                  checked={
                    activeComputation.configuration.mode === 'conditional'
                  }
                  value="conditional"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.handleConfigurationModeChange(
                      event.currentTarget
                        .value as ConfigurationModeOfComputation
                    );
                  }}
                  disabled={readonly}
                >
                  Conditional
                </ToggleButton>
                <ToggleButton
                  id="mode-one-to-one"
                  type="radio"
                  variant="outline-primary me-3"
                  checked={activeComputation.configuration.mode === 'oneToOne'}
                  value="oneToOne"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.handleConfigurationModeChange(
                      event.currentTarget
                        .value as ConfigurationModeOfComputation
                    );
                  }}
                  disabled={readonly}
                >
                  oneToOne
                </ToggleButton>
                <ToggleButton
                  id="mode-advanced"
                  type="radio"
                  variant="outline-primary"
                  checked={activeComputation.configuration.mode === 'advanced'}
                  value="advanced"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.handleConfigurationModeChange(
                      event.currentTarget
                        .value as ConfigurationModeOfComputation
                    );
                  }}
                  disabled={readonly}
                >
                  Advanced
                </ToggleButton>
              </div>
            </Form.Group>
            <span className="mb-2 fw-bold d-block">Definition</span>
            {activeComputation.configuration.mode === 'oneToOne' && (
              <OneToOneFormComponent
                key={`oneToOne_form_component`}
                computation={activeComputation}
                handleOneToOneFieldChange={this.handleOneToOneFieldChange}
                formFields={attributeFields}
                readonly={readonly}
              />
            )}
            {activeComputation.configuration.mode === 'advanced' && (
              <AdvancedFormComponent
                key={`advanced_form_component`}
                computation={activeComputation}
                handleAdvancedQueryChange={this.handleAdvancedQueryChange}
                readonly={readonly}
              />
            )}
            {activeComputation.configuration.mode === 'conditional' && (
              <ConditionalForm />
            )}

            <span className="d-block mt-3 mb-3 fw-bold">Options</span>

            <Form.Check
              type="checkbox"
              id="most-frequently-used"
              label={
                <>
                  <span className="d-block">Mark as favorite</span>
                  <span className="fst-italic text-secondary">
                    The computation will appear in the Customer Fit Insights
                    list with a star
                  </span>
                </>
              }
              checked={activeComputation.tags.includes('Most Frequently Used')}
              onChange={() => this.handleCheckTag('Most Frequently Used')}
              disabled={readonly}
            />

            <Form.Check
              type="checkbox"
              id="public-facing"
              label={
                <>
                  <span className="d-block">
                    Available in MadKudu API (use cases: realtime scoring, web
                    personalization, webhooks...)
                  </span>
                  <span className="text-danger">
                    Important: the API is client-side, therefore computations
                    containing sensitive information (revenue, PII data...)
                    should not be made available here.
                  </span>
                </>
              }
              checked={activeComputation.tags.includes('Public-Facing')}
              onChange={() => this.handleCheckTag('Public-Facing')}
              disabled={readonly}
            />
            <ButtonToolbar className="mt-3 justify-content-center">
              <Button
                className="me-3"
                variant="outline-primary"
                onClick={() => {
                  this.previous();
                }}
              >
                {readonly ? 'Go back' : 'Cancel'}
              </Button>
              {!readonly && (
                <>
                  <Button
                    variant="primary"
                    onClick={this.preSave}
                    disabled={
                      !computationName ||
                      computationName.trim().length === 0 ||
                      isSaving
                    }
                  >
                    {isNewComputation ? 'Create computation' : 'Save changes'}
                  </Button>
                  {!isNewComputation &&
                    !activeComputation.usedInLiveModel &&
                    !activeComputation.isSynced && (
                      <Button
                        variant="danger"
                        onClick={this.toggleShowDeleteModal}
                        disabled={isSaving}
                        className="ms-3"
                      >
                        Delete computation
                      </Button>
                    )}
                </>
              )}
            </ButtonToolbar>

            <Modal
              show={showPublicFacingModal}
              onHide={this.cancelPublicFacing}
            >
              <Modal.Header>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  The tag <b>Available in MadKudu API</b> will expose the
                  computation in a public API that is visible to anyone. You
                  need to check with the customer and get their approval before
                  doing this.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-primary"
                  onClick={this.cancelPublicFacing}
                >
                  Cancel
                </Button>
                {activeComputation.context === 'configured' && (
                  <Button variant="primary" onClick={this.confirmPublicFacing}>
                    Confirm
                  </Button>
                )}
              </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={this.toggleShowDeleteModal}>
              <Modal.Header>
                <Modal.Title>Delete {computationName}?</Modal.Title>
              </Modal.Header>
              <Modal.Body>Deleting this computation is irreversible</Modal.Body>
              <Modal.Footer>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={this.toggleShowDeleteModal}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  disabled={isSaving}
                  onClick={async () => {
                    await this.deleteComputation();
                  }}
                >
                  Delete computation
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showSaveModal}
              onHide={() => {
                this.toggleShowSaveModal();
              }}
            >
              <Modal.Header>
                <Modal.Title>Update {computationName}?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This computation you are about to edit is used in a Live model.
                <br />
                Are you sure you want to edit this computation? This can change
                the model and impact the scores generated by the model(s) using
                this computation.
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="me-3"
                  variant="outline-primary"
                  onClick={() => {
                    this.toggleShowSaveModal();
                  }}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
                <Button
                  variant="warning"
                  disabled={isSaving}
                  onClick={this.saveDefinition}
                >
                  Update computation
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);
