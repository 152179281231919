import { inject, observer } from 'mobx-react';
import React from 'react';
import DeployMode from '../../../models/deploy/DeployMode';
import { getModelFolders, ModelFolder } from '../../../models/ModelItem';
import Store from '../../../store';
import DeployTabComponent from './DeployTabComponent';

interface MultiFitTabProps {
  store?: Store;
  openDeployPopup: (
    deployMode: DeployMode,
    folders: ModelFolder[]
  ) => Promise<void>;
  openImpactPopup: () => Promise<void>;
}

export default inject('store')(
  observer(
    class MultiFitTab extends React.Component<
      MultiFitTabProps,
      Record<string, never>
    > {
      render() {
        const { activeModel, tenantModels } = this.props.store;
        const enabled = tenantModels.some(
          (model) =>
            model.live &&
            model.is_primary &&
            model.modelId !== activeModel.modelId
        );
        const folders: ModelFolder[] = getModelFolders(activeModel, {
          forDeployment: true,
        });

        return (
          <DeployTabComponent
            enabled={enabled}
            deployMode="multi"
            folders={folders}
            deployButtonLabel="Deploy model as Multi Fit"
            downloadButtonLabel={(context: string) =>
              `Download ${context} config for multi fit`
            }
            disabledMessage="You must first deploy a Standard Customer Fit Model."
            title={
              <p>
                <b>Deployment as Multi Fit model </b> (This is for you If you
                are working with <b>more than one Fit model</b>)
              </p>
            }
            openDeployPopup={this.props.openDeployPopup}
            openImpactPopup={this.props.openImpactPopup}
          >
            <div>
              <ul>
                <li>
                  To deploy a new model in parallel of the Standard or other
                  Multi Fit models
                </li>
              </ul>
              <h6>
                Clicking on the button <b>Deploy as Multi Fit</b> will trigger
                the following actions
              </h6>
              <ul>
                <li>
                  Push to production this new model (in batch and in realtime)
                  on the platform (this means the platform will start
                  calculating scores for all your records)
                </li>
                <li>
                  It will <b>not</b> replace any existing model but it will
                  deploy this model in parallel of any existing Customer Fit
                  model marked Live (Standard or Multi).
                </li>
                <li>
                  Update the score, segment and signals in your integrations
                  where a push configuration is enabled between MadKudu and the
                  integration. The update will start with the{' '}
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4407302132621-How-to-read-the-Processes-page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    next sync process.
                  </a>
                </li>
              </ul>
              <p className="mt-4 mb-4">
                Make sure of your edits before deploying.
              </p>
            </div>
          </DeployTabComponent>
        );
      }
    }
  )
);
