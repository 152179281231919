import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Card,
  Alert,
  FormLabel,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import distinctColors from 'distinct-colors';

import LiftChart from './LiftChart';
import PopulationConversionsChart from './PopulationConversionsChart';
import SummaryTable from './SummaryTable';
import UnivariateAnalysisVariableBucket from '../../../models/univariate/UnivariateAnalysisVariableBucket';
import UnivariateAnalysisVariableBucketWithShare from '../../../models/univariate/UnivariateAnalysisVariableBucketWithShare';
import replaceScientificNotation from '../../../utils/replace-scientific-notation';
import Computation from '../../../models/computations/Computation';
import { inject, observer } from 'mobx-react';
import Store from '../../../store';
import Spinner from '../Spinner';
import Error from '../error/Error';
import { snakeCaseToHumanReadable } from '../../../utils';
import UnivariateAnalysisVariable from '../../../models/univariate/UnivariateAnalysisVariable';
import {
  VariableOrder,
  VariableSort,
} from '../../../models/univariate/UnivariateAnalysis';

const filterBucketOnUnknown = (
  buckets: UnivariateAnalysisVariableBucket[],
  bucket: UnivariateAnalysisVariableBucket,
  showUnknown: boolean
): boolean => {
  return (
    buckets.length === 1 ||
    showUnknown ||
    !(bucket.name && bucket.name.toLowerCase().includes('unknown'))
  );
};

const getBucketsWithShares = (
  buckets: UnivariateAnalysisVariableBucket[],
  showUnknown: boolean,
  noScientificNotation: boolean
): UnivariateAnalysisVariableBucketWithShare[] => {
  const sumPopulation = buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .reduce(
      (sum: number, bucket: UnivariateAnalysisVariableBucket) =>
        Number(sum) + Number(bucket.population),
      0
    );
  const sumConversions = buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .reduce(
      (sum: number, bucket: UnivariateAnalysisVariableBucket) =>
        Number(sum) + Number(bucket.conversions),
      0
    );
  const totalConversionRate =
    sumPopulation > 0 ? (sumConversions / sumPopulation) * 100 : 0;

  return buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .map((bucket) => {
      const populationShare =
        sumPopulation > 0 ? (bucket.population / sumPopulation) * 100 : 0;
      const conversionsShare =
        sumConversions > 0 ? (bucket.conversions / sumConversions) * 100 : 0;
      const conversionRate =
        bucket.population > 0
          ? (bucket.conversions / bucket.population) * 100
          : 0;
      const lift =
        totalConversionRate > 0
          ? (conversionRate - totalConversionRate) / totalConversionRate
          : 0;
      const lift7 =
        sumConversions > 0 && sumPopulation > 0
          ? (sumConversions - bucket.conversions) /
              (sumPopulation - bucket.population) /
              (sumConversions / sumPopulation) -
            1
          : 0;

      const name = noScientificNotation
        ? replaceScientificNotation(bucket.name)
        : bucket.name;
      return new UnivariateAnalysisVariableBucketWithShare(
        bucket.population,
        populationShare,
        bucket.conversions,
        conversionsShare,
        bucket.rawName,
        name,
        conversionRate,
        lift,
        lift7
      );
    });
};

interface UnivariateAnalysisChartsProps {
  store?: Store;
  selectedVariable: string;
  selectVariable: (variableName: string) => void;
  univariateAnalysis: UnivariateAnalysisVariable;
  loadingUnivariateAnalysis: boolean;
  univariateAnalysisError: string;
  customerMode?: boolean;
  sampleLinkTemplate?: (
    variableTechnicalName: string,
    variableReadableName: string,
    bucketRawName: string,
    bucketName: string
  ) => string;
  showUnknown: boolean;
}

interface UnivariateAnalysisChartsState {
  searchBar: string;
  sort: VariableSort;
  order: VariableOrder;
}

class NewUnivariateAnalysisCharts extends React.Component<
  UnivariateAnalysisChartsProps,
  UnivariateAnalysisChartsState
> {
  constructor(props: UnivariateAnalysisChartsProps) {
    super(props);
    this.selectVariable = this.selectVariable.bind(this);
    this.selectSort = this.selectSort.bind(this);
    this.selectOrder = this.selectOrder.bind(this);
    this.onSearchBarChange = this.onSearchBarChange.bind(this);
    this.onSearchBarClear = this.onSearchBarClear.bind(this);
    this.renderCharts = this.renderCharts.bind(this);

    this.state = {
      searchBar: '',
      sort: 'alphabetical',
      order: 'asc',
    };
  }

  selectVariable(variable: string) {
    this.props.selectVariable(variable);
  }

  selectSort(e: any) {
    const sort: VariableSort = e.target.value;
    const order = sort === 'alphabetical' ? 'asc' : 'desc';
    this.setState({
      sort,
      order,
    });
  }

  selectOrder(e: any) {
    this.setState({
      order: e.target.value,
    });
  }

  onSearchBarChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ searchBar: e.target.value });
  }

  onSearchBarClear() {
    this.setState({ searchBar: '' });
  }

  cleanVariables(computations: Computation[]) {
    const { selectedVariable } = this.props;

    return computations
      .slice()
      .sort((a, b) =>
        this.state.order === 'desc'
          ? b.name.localeCompare(a.name)
          : a.name.localeCompare(b.name)
      )
      .map(({ name }) => (
        <ListGroupItem
          key={name}
          active={name === selectedVariable}
          onClick={() => this.selectVariable(name)}
        >
          {snakeCaseToHumanReadable(name)}
        </ListGroupItem>
      ));
  }

  renderCharts() {
    const {
      univariateAnalysis,
      loadingUnivariateAnalysis,
      univariateAnalysisError,
      customerMode,
      showUnknown,
      selectedVariable,
      sampleLinkTemplate,
    } = this.props;

    if (
      !selectedVariable ||
      (!loadingUnivariateAnalysis &&
        !univariateAnalysis &&
        !univariateAnalysisError)
    ) {
      return (
        <Alert variant="primary">
          Select a computation from the left in order to load its insights
        </Alert>
      );
    }

    if (loadingUnivariateAnalysis) {
      return <Spinner />;
    }

    if (univariateAnalysisError) {
      return <Error message={univariateAnalysisError} />;
    }

    let cols: JSX.Element[] = [<Col key="col_empty" sm={10}></Col>];

    const bucketsWithShares = getBucketsWithShares(
      univariateAnalysis.buckets,
      showUnknown,
      customerMode
    );

    const { defaultSort }: { defaultSort: 'population' | 'name' } =
      bucketsWithShares.length > 0 && bucketsWithShares[0].name.match(/^\d/)
        ? { defaultSort: 'name' }
        : { defaultSort: 'population' };

    if (bucketsWithShares.length <= 20) {
      const colors: string[] = [
        '#1e77cc',
        '#dc3912',
        '#ff9900',
        '#109618',
        '#990099',
        '#0099c6',
        '#dd4477',
        '#66aa00',
        '#b82e2e',
        '#316395',
        '#994499',
        '#22aa99',
        '#aaaa11',
        '#6633cc',
        '#e67300',
        '#8b0707',
        '#651067',
        '#329262',
        '#5574a6',
        '#3b3eac',
        '#b77322',
        '#16d620',
        '#b91383',
        '#f4359e',
        '#9c5935',
        '#a9c413',
        '#2a778d',
        '#668d1c',
        '#bea413',
        '#0c5922',
        '#743411',
      ];
      const additionalColors: string[] = distinctColors({
        count: bucketsWithShares.length - colors.length,
      }).map((color) => color.hex());

      colors.push(...additionalColors);

      cols = [
        <Row key="row_1">
          <Col key="col_popConv" sm={6}>
            <Card className="h-100">
              <Card.Body className="flex-column">
                <Card.Title>
                  Impact of {univariateAnalysis.variableName} on conversion
                </Card.Title>
                <PopulationConversionsChart
                  bucketsWithShares={bucketsWithShares}
                  colors={colors}
                  defaultSort={defaultSort}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col key="col_lift" sm={6}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>
                  Lift factor of {univariateAnalysis.variableName}
                </Card.Title>
                <p>
                  when lift &gt; 0, a lead with this trait is more likely to
                  convert
                  <br />
                  when lift &lt; 0, a lead with this trait is less likely to
                  convert
                </p>
                <LiftChart
                  bucketsWithShares={bucketsWithShares}
                  defaultSort={defaultSort}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>,
        <React.Fragment key="row_2">
          {!customerMode && (
            <Card className="mt-3">
              <Card.Body>
                <Card.Title>
                  Details{' '}
                  <span className="fst-italic fs-6 fw-light float-end">
                    Data from the training dataset
                  </span>
                </Card.Title>
                <div className="mt-3">
                  <SummaryTable
                    variable={univariateAnalysis}
                    bucketsWithShares={bucketsWithShares}
                    linkTemplate={sampleLinkTemplate}
                    hideLift7Factor={customerMode}
                  />
                </div>
              </Card.Body>
            </Card>
          )}
        </React.Fragment>,
      ];
    } else {
      cols = [
        <Row key="row_1">
          <Col key="col_lift" sm={12}>
            <Card className="h-100">
              <Card.Body>
                <Card.Title>
                  Lift factor of {univariateAnalysis.variableName}
                </Card.Title>
                <p>
                  when lift &gt; 0, a lead with this trait is more likely to
                  convert
                  <br />
                  when lift &lt; 0, a lead with this trait is less likely to
                  convert
                </p>
                <LiftChart
                  bucketsWithShares={bucketsWithShares}
                  defaultSort={defaultSort}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>,
        <Card key="row_2" className="mt-3">
          <Card.Body>
            <Card.Title>
              Details{' '}
              <span className="fst-italic fs-6 fw-light float-end">
                Data from the training dataset
              </span>
            </Card.Title>
            <div className="mt-3">
              <SummaryTable
                variable={univariateAnalysis}
                bucketsWithShares={bucketsWithShares}
                linkTemplate={sampleLinkTemplate}
                hideLift7Factor={customerMode}
              />
            </div>
          </Card.Body>
        </Card>,
      ];
    }

    return cols;
  }

  render() {
    const {
      store: { computationsPage },
      customerMode,
    } = this.props;
    const { computations } = computationsPage;
    const { searchBar, sort, order } = this.state;

    const cleanedVariables = searchBar
      ? this.cleanVariables(
          computations.filter(({ name }) =>
            snakeCaseToHumanReadable(name)
              .toLowerCase()
              .includes(snakeCaseToHumanReadable(searchBar).toLowerCase())
          )
        )
      : this.cleanVariables(computations);

    return (
      <>
        <Row>
          <Col sm={3}>
            <Card>
              <Card.Body>
                <Card.Title>Computations</Card.Title>
                <Form onSubmit={(e) => e.preventDefault()} className="mb-2">
                  <FormGroup>
                    <InputGroup>
                      <FormControl
                        type="text"
                        value={searchBar}
                        onChange={this.onSearchBarChange}
                        placeholder="Search"
                      />
                      <Button onClick={this.onSearchBarClear}>
                        <i aria-hidden className="fas fa fa-times" />
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Form>
                {!customerMode && !!cleanedVariables.length && (
                  <Form className="mb-3">
                    <FormGroup>
                      <FormLabel className="me-2">Sort by</FormLabel>
                      <Form.Select
                        className="d-inline w-auto me-1"
                        onChange={this.selectSort}
                        value={sort}
                      >
                        <option value="alphabetical" key="alphabetical">
                          Alphabetical
                        </option>
                        <option disabled value="relevance" key="relevance">
                          Relevance
                        </option>
                      </Form.Select>
                      <Form.Select
                        className="d-inline w-auto"
                        onChange={this.selectOrder}
                        value={order}
                      >
                        <option value="asc">asc</option>
                        <option value="desc">desc</option>
                      </Form.Select>
                      <OverlayTrigger
                        overlay={
                          <Popover className="p-2">
                            <div>
                              <strong>Goal:</strong> identify computations that
                              best identify converters.
                            </div>
                            <div>
                              <strong>Formula:</strong>{' '}
                              <code>
                                |conversionRate / avgConversionRate - 1|
                              </code>
                            </div>
                            <div>
                              <strong>Filter population &ge; 100</strong>
                            </div>
                          </Popover>
                        }
                      >
                        <i
                          aria-hidden
                          className="fas fa-question-circle fa-lg ms-1 text-info"
                        />
                      </OverlayTrigger>
                    </FormGroup>
                  </Form>
                )}
                <ListGroup style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                  {cleanedVariables}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={9}>{this.renderCharts()}</Col>
        </Row>
      </>
    );
  }
}

export default inject('store')(observer(NewUnivariateAnalysisCharts));
