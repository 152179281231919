import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import UnivariateAnalysisVariable from '../../../models/univariate/UnivariateAnalysisVariable';
import UnivariateAnalysisVariableBucketWithShare from '../../../models/univariate/UnivariateAnalysisVariableBucketWithShare';
import Table from '../generic_components/Table';
import { Link } from 'react-router-dom';

interface SummaryTableProps {
  variable: UnivariateAnalysisVariable;
  bucketsWithShares: UnivariateAnalysisVariableBucketWithShare[];
  linkTemplate?: (
    variableTechnicalName: string,
    variableReadableName: string,
    bucketRawName: string,
    bucketName: string
  ) => string;
  hideLift7Factor: boolean;
}

export default class SummaryTable extends React.Component<
  SummaryTableProps,
  never
> {
  render(): React.ReactNode {
    const { bucketsWithShares, variable, linkTemplate } = this.props;

    const totals = bucketsWithShares.reduce(
      (a, b) => ({
        population: a.population + b.population,
        conversions: a.conversions + b.conversions,
        populationShare: a.populationShare + b.populationShare,
        conversionsShare: a.conversionsShare + b.conversionsShare,
      }),
      {
        population: 0,
        conversions: 0,
        populationShare: 0,
        conversionsShare: 0,
      }
    );

    const columnHelper =
      createColumnHelper<UnivariateAnalysisVariableBucketWithShare>();
    const columns: ColumnDef<
      UnivariateAnalysisVariableBucketWithShare,
      unknown
    >[] = [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
        footer: 'Totals',
      }),
      columnHelper.accessor('population', {
        cell: (info) => info.getValue(),
        header: 'Population',
        footer: `${totals.population}`,
      }),
      columnHelper.accessor('conversions', {
        cell: (info) => info.getValue(),
        header: 'Conversions',
        footer: `${totals.conversions}`,
      }),
      {
        accessorFn: ({ conversionRate }) => +Number(conversionRate).toFixed(2),
        id: 'conversionRate',
        header: 'Conversion rate',
        cell: (info) => info.getValue(),
        footer: ((totals.conversions * 100) / totals.population).toFixed(2),
      },
      {
        accessorFn: ({ lift }) => +Number(lift).toFixed(2),
        id: 'lift',
        header: 'Lift factor',
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: ({ lift7 }) => +Number(lift7).toFixed(2),
        id: 'lift7',
        header: 'Lift 7 factor',
        cell: (info) => info.getValue(),
      },
      {
        accessorFn: ({ populationShare }) =>
          +Number(populationShare).toFixed(2),
        id: 'populationShare',
        header: '% population',
        cell: (info) => info.getValue(),
        footer: `${totals.populationShare}`,
      },
      {
        accessorFn: ({ conversionsShare }) =>
          +Number(conversionsShare).toFixed(2),
        id: 'conversionsShare',
        header: '% conversion',
        cell: (info) => info.getValue(),
        footer: totals.conversionsShare.toFixed(2),
      },
      {
        accessorFn: ({ rawName, name }) => (
          <Link
            to={linkTemplate(variable.id, variable.variableName, rawName, name)}
          >
            See sample of leads
          </Link>
        ),
        id: 'sample',
        header: 'Sample',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => info.getValue(),
      },
    ];

    return <Table data={bucketsWithShares} columns={columns} />;
  }
}
