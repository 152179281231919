import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import React from 'react';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from '../front/App';
import Store from '../store';

declare let window: {
  __PRELOADED_STATE__?: Store;
  location: {
    pathname: string;
  };
  store: Store;
};

configure({
  enforceActions: 'never',
});

const store = new Store(window.__PRELOADED_STATE__);

hydrate(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('app')
);

if (!window.store) {
  window.store = store;
}

declare const module: any;
if (module.hot) {
  module.hot.accept();
}
