import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, FormControl, FormCheck } from 'react-bootstrap';

import { PROFILES_PERMISSIONS } from '../../../../permissions';
import Store from '../../../../store';
import checkPermissions from '../../CheckPermissions';
import { SignalsValue } from '../../../../models/signals';

interface LegacySignalsProps {
  store?: Store;
  saveSignals: () => void;
}

export default inject('store')(
  observer(
    class LegacySignalsPage extends React.Component<
      LegacySignalsProps,
      Record<string, never>
    > {
      constructor(props: LegacySignalsProps) {
        super(props);

        makeObservable(this, {
          handleSignalValueChange: action,
          saveSignals: action,
        });

        this.handleSignalValueChange = this.handleSignalValueChange.bind(this);
        this.handleSignalCustomConfigChange =
          this.handleSignalCustomConfigChange.bind(this);
        this.saveSignals = this.saveSignals.bind(this);
      }

      handleSignalValueChange(value: SignalsValue) {
        this.props.store.signalsPage.signals.customerFitSignals.type = value;
        if (
          value === 'custom' &&
          !this.props.store.signalsPage.signals.customerFitSignals.config
        ) {
          this.props.store.signalsPage.signals.customerFitSignals.config =
            this.props.store.signalsPage.signals.customerFitSignals.defaultConfig;
        }
      }

      handleSignalCustomConfigChange(e: any) {
        this.props.store.signalsPage.signals.customerFitSignals.config =
          e.target.value;
      }

      async saveSignals() {
        await this.props.saveSignals();
      }

      render() {
        const {
          isAllowedToEdit,
          signalsPage: { signals, savingSignals },
        } = this.props.store;

        return (
          <React.Fragment>
            <form>
              <FormCheck
                type="radio"
                checked={signals.customerFitSignals.type === 'off'}
                disabled={!isAllowedToEdit || savingSignals}
                onChange={() => this.handleSignalValueChange('off')}
                label="Turn Signals off"
              />
              <FormCheck
                type="radio"
                checked={signals.customerFitSignals.type === 'default'}
                disabled={!isAllowedToEdit || savingSignals}
                onChange={() => this.handleSignalValueChange('default')}
                label={
                  <>
                    Turn the{' '}
                    <a
                      href="https://github.com/MadKudu/springbok-signals/blob/master/customer_fit/boilerplate.yaml"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      default signals
                    </a>{' '}
                    on
                  </>
                }
              />
              <FormCheck
                type="radio"
                checked={signals.customerFitSignals.type === 'custom'}
                disabled={!isAllowedToEdit || savingSignals}
                onChange={() => this.handleSignalValueChange('custom')}
                label="Turn Signals on, and configure a custom configuration"
              />
              {signals.customerFitSignals.type === 'custom' ? (
                <FormControl
                  as="textarea"
                  placeholder="Enter the custom configuration"
                  rows={15}
                  disabled={!isAllowedToEdit || savingSignals}
                  onChange={this.handleSignalCustomConfigChange}
                  autoFocus={true}
                  value={
                    signals.customerFitSignals.config
                      ? signals.customerFitSignals.config
                      : ''
                  }
                />
              ) : null}
            </form>
            {checkPermissions(
              PROFILES_PERMISSIONS.ARCHITECT,
              <Button
                variant="primary"
                className="pull-right"
                onClick={this.saveSignals}
                style={{ marginTop: '15px' }}
                disabled={
                  (signals.customerFitSignals?.type === 'custom' &&
                    (!signals.customerFitSignals?.config ||
                      signals.customerFitSignals?.config.trim().length ===
                        0)) ||
                  !isAllowedToEdit ||
                  savingSignals
                }
              >
                {savingSignals ? 'Saving...' : 'Save & test'}
              </Button>
            )}
          </React.Fragment>
        );
      }
    }
  )
);
