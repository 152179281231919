import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Store from '../../../store';
import { parseAggregationName } from './utils';

interface StepThreeProps extends RouteComponentProps<any> {
  store?: Store;
}

class StepThreeComponent extends React.Component<
  StepThreeProps,
  Record<string, never>
> {
  constructor(props: StepThreeProps) {
    super(props);

    this.handleAggregationLifetimeChange =
      this.handleAggregationLifetimeChange.bind(this);

    makeObservable(this, {
      handleAggregationLifetimeChange: action,
    });
  }

  handleAggregationLifetimeChange(e: any) {
    const { customName, logic, type } =
      this.props.store.behavioralAggregationsPage.activeAggregation;
    const { value } = e.target;

    // ensure that value is between 1 and 90
    if ((Number(value) < 1 || Number(value) > 90) && value !== '') return;

    this.props.store.behavioralAggregationsPage.activeAggregation.lifetime =
      value;

    if (logic && type && value) {
      // modifying lifeline automatically changes name -> '<type> - <logic_with_lifetime_insterted>'
      this.props.store.behavioralAggregationsPage.activeAggregation.name =
        parseAggregationName({
          logic,
          lifetime: value,
          custom: customName,
        });
    }
    this.props.store.behavioralAggregationsPage.stepConfiguration[3].validated =
      true;
  }

  render() {
    const { behavioralAggregationsPage, isSuperKudu, isArchitect } =
      this.props.store;
    const { activeAggregation } = behavioralAggregationsPage;

    if (!activeAggregation) return null;

    return (
      <Form.Group className="mb-3" controlId="formLifetime">
        <Form.Label className="fw-bold">Lifetime</Form.Label>
        <FormControl
          type="number"
          min={1}
          max={90}
          name="aggregationLifetime"
          value={activeAggregation.lifetime || ''}
          placeholder="Enter an integer 0 < X <= 90"
          onChange={this.handleAggregationLifetimeChange}
          disabled={
            !(isSuperKudu || isArchitect) ||
            activeAggregation.usedInLiveModel ||
            activeAggregation.isSynced
          }
        />
        <Form.Text className="text-muted">
          An aggregation is defined on a time: on the past X days. Value of X
          must be between 1 and 90. Recommendation is to aggregate on the past
          15, 30, or 60 days.
        </Form.Text>
      </Form.Group>
    );
  }
}

export default inject('store')(withRouter(observer(StepThreeComponent)));
