import { inject, observer } from 'mobx-react';
import React from 'react';
import { Card, Modal, Button } from 'react-bootstrap';

import ModelBaseNames from '../../../models/modelBase/ModelBaseNames';
import { ModelType } from '../../../models/ModelItem';
import { Rule } from '../../../models/rules';
import Store from '../../../store';
import RuleEditor from '../../components/rules/RuleEditor';
import RulesPerformance from '../../components/rules/RulesPerformance';
import Spinner from '../../components/Spinner';

interface OverridesPageProps {
  store?: Store;
}

type OverridesPageState = {
  showImpactAnalysis: boolean;
  isLoading: boolean;
};

export default inject('store')(
  observer(
    class OverridesPage extends React.Component<
      OverridesPageProps,
      OverridesPageState
    > {
      constructor(props: OverridesPageProps) {
        super(props);
        this.updateOverrideRules = this.updateOverrideRules.bind(this);
        this.saveOverrideRules = this.saveOverrideRules.bind(this);
        this.toggleShowImpactAnalysis =
          this.toggleShowImpactAnalysis.bind(this);

        this.state = {
          showImpactAnalysis: false,
          isLoading: true,
        };
      }

      async componentDidMount() {
        const { tenant, modelId } = this.props.store;
        await Promise.all([
          this.props.store.fetchFormFields(),
          this.props.store.overridesPage.get({ tenant, modelId }),
          this.props.store.loadAllTrees(),
          this.props.store.loadOverrideEnsembleData(),
        ]);

        this.setState({
          isLoading: false,
        });
      }

      async updateOverrideRules(newRules: Rule[]) {
        await this.props.store.updateOverrideRules(newRules);
      }

      async saveOverrideRules() {
        const { tenant, modelId } = this.props.store;
        this.props.store.analyticsTrackEvent('Updated override');
        await this.props.store.saveOverrideRules();
        await this.props.store.overridesPage.get({ modelId, tenant });
      }

      documentationLink(modelType: ModelType) {
        if (modelType === 'mqa') {
          return 'https://support.madkudu.com/hc/en-us/articles/4418042968461';
        }
        return 'https://support.madkudu.com/hc/en-us/articles/4404492809997-Customer-Fit-Overrides';
      }

      toggleShowImpactAnalysis() {
        this.setState({ showImpactAnalysis: !this.state.showImpactAnalysis });
      }

      render() {
        const { store } = this.props;
        const { overridesPage, formFields, activeModel, isAllowedToEdit } =
          store;
        const isTreeBased: boolean = activeModel.base === ModelBaseNames.trees;

        return (
          <div className="mt-3 mb-5">
            <Card className="mb-3">
              <Card.Body>
                <p>
                  Add business rules on top of the historical predictions to
                  force the{" "}{activeModel.type === "customer_fit" ? "customer fit segment of some leads based on their traits" : "likelihood to buy segment of some accounts based on their activity"}.
                </p>
                <a
                  href={this.documentationLink(activeModel.type)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-primary"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How to create
                  overrides
                </a>
              </Card.Body>
            </Card>

            {this.state.isLoading ? (
              <Spinner />
            ) : (
              <>
                <div className="mb-3 d-flex">
                  <Button
                    className="ms-auto"
                    onClick={this.toggleShowImpactAnalysis}
                  >
                    Overrides Impact analysis
                  </Button>
                </div>
                <Card>
                  <Card.Body>
                    {activeModel.type === 'customer_fit' && (
                      <p className="fst-italic text-secondary">
                        The overrides are automatically sorted in this page by
                        order of how they are applied, taking into account the
                        potential interactions between them. Overrides
                        downgrading the segment always prevail over overrides
                        boosting the segment.
                      </p>
                    )}

                    <RuleEditor
                      model={overridesPage}
                      formFields={formFields}
                      updateRules={this.updateOverrideRules}
                      saveRules={this.saveOverrideRules}
                      withRuleAndSegment={true}
                      modelType={activeModel.type}
                      isAllowedToEdit={isAllowedToEdit}
                    />
                  </Card.Body>
                </Card>
              </>
            )}

            <Modal
              size="lg"
              show={this.state.showImpactAnalysis}
              onHide={this.toggleShowImpactAnalysis}
            >
              <Modal.Header closeButton>
                <Modal.Title>Overrides Impact analysis</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <RulesPerformance
                  context={
                    activeModel.type === 'customer_fit'
                      ? 'customer_fit'
                      : 'behavioral'
                  }
                  isTreeBased={isTreeBased}
                  overridesPage={overridesPage}
                />
              </Modal.Body>
            </Modal>
          </div>
        );
      }
    }
  )
);
