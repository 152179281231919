import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Feature } from '../../../models/feature_eval/FeatureEvaluation';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import Spinner from '../../components/Spinner';
import Table from '../../components/generic_components/Table/Table';
import EditableNumberCell from '../../components/generic_components/Table/EditableNumberCell';

const COLUMN_VISIBILITY_BAG = 'columnVisibility';
const DEFAULT_COLUMN_VISIBILITY = {
  activityType: false,
  negativeUserActivity: false,
  computedFactorLoading: false,
  computedDecayDays: false,
  averageForConverted: false,
  averageForNonConverted: false,
  didX: false,
  didNotDoX: false,
  didXConversionRate: false,
  didNotDoXConversionRate: false,
  lift: false,
  recallConversions: false,
  recallNonConversions: false,
  averageForConvertedXFactorLoading: false,
};

interface FeaturesPageProps {
  store?: Store;
}

interface FeaturesPageState {
  columnVisibility: Partial<Record<keyof Feature, boolean>>;
}

export default inject('store')(
  observer(
    class FeaturesPage extends React.Component<
      FeaturesPageProps,
      FeaturesPageState
    > {
      constructor(props: FeaturesPageProps) {
        super(props);

        this.saveFeatureEvaluation = this.saveFeatureEvaluation.bind(this);
        this.resetFeatureEvaluation = this.resetFeatureEvaluation.bind(this);
        this.onCellChange = this.onCellChange.bind(this);

        this.state = {
          columnVisibility: DEFAULT_COLUMN_VISIBILITY,
        };
      }

      async componentDidMount() {
        const savedColumnVisibility =
          localStorage.getItem(COLUMN_VISIBILITY_BAG) ?? null;
        if (savedColumnVisibility) {
          this.setState({
            columnVisibility: JSON.parse(savedColumnVisibility),
          });
        }

        await this.props.store.loadFeatureEvaluation();
      }

      onCellChange(index: number, columnId: keyof Feature, value: unknown) {
        const { featureEvaluationPage } = this.props.store;
        const feature = featureEvaluationPage.featureEvaluation.features[index];
        const newFeature = { ...feature, [columnId]: value };
        featureEvaluationPage.featureEvaluation.features[index] = newFeature;
      }

      async saveFeatureEvaluation() {
        const { featureEvaluationPage } = this.props.store;

        const hasInvalidEvaluations =
          featureEvaluationPage.featureEvaluation.features.some(
            (feature) => !feature.decayDays
          );

        if (hasInvalidEvaluations) {
          featureEvaluationPage.error =
            'Feature evaluation lifespan can not be 0';

          return;
        }

        await this.props.store.saveFeatureEvaluation();
      }

      async resetFeatureEvaluation() {
        await this.props.store.resetFeatureEvaluation();
      }

      render() {
        const { featureEvaluationPage, isAllowedToEdit, tenant } =
          this.props.store;
        const { featureEvaluation, loading, error, saving } =
          featureEvaluationPage;

        if (loading) {
          return <Spinner />;
        }

        const columnHelper = createColumnHelper<Feature>();

        const columns: ColumnDef<Feature, unknown>[] = [
          columnHelper.accessor('metaEvent', {
            cell: (info) => info.getValue(),
            header: 'Event',
          }),
          columnHelper.accessor('factorLoading', {
            cell: (info) =>
              isAllowedToEdit ? (
                <EditableNumberCell {...info} />
              ) : (
                info.getValue()
              ),
            header: 'Importance (in points)',
          }),
          columnHelper.accessor('decayDays', {
            cell: (info) =>
              isAllowedToEdit ? (
                <EditableNumberCell {...info} />
              ) : (
                info.getValue()
              ),
            header: 'Lifespan (in days)',
          }),
          columnHelper.accessor('activityType', {
            cell: (info) => info.getValue(),
            header: 'Activity Type',
          }),
          columnHelper.accessor('negativeUserActivity', {
            cell: (info) => info.getValue(),
            header: 'Negative User Activity',
          }),
          columnHelper.accessor('computedFactorLoading', {
            cell: (info) => info.getValue() ?? null,
            header: 'Suggested importance',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('computedDecayDays', {
            cell: (info) => info.getValue() ?? null,
            header: 'Suggested lifespan',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('averageForConverted', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Average for converted',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('averageForNonConverted', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Average for non-converted',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('didX', {
            cell: (info) => Number(info.getValue()),
            header: 'Did X',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('didNotDoX', {
            cell: (info) => Number(info.getValue()),
            header: 'Did not do X',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('didXConversionRate', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Did X conversion rate',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('didNotDoXConversionRate', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Did not do X conversion rate',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('lift', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Lift',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('recallConversions', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Recall conversions',
            enableColumnFilter: false,
          }),
          columnHelper.accessor('recallNonConversions', {
            cell: (info) => Number(info.getValue()).toFixed(2),
            header: 'Recall non-conversions',
            enableColumnFilter: false,
          }),
          {
            accessorFn: ({ averageForConverted, factorLoading }) =>
              Number((averageForConverted * factorLoading).toFixed(2)),
            id: 'averageForConvertedXFactorLoading',
            header: 'Average for converted * factor loading',
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
        ];

        const columnVisibility = this.state.columnVisibility;

        return (
          <div className="pt-4">
            {error && <Error message={error} />}

            <Card border="light" className="mt-4">
              <Card.Body>
                <p>
                  The contribution of each event in the scoring is defined by
                  its Importance and its Lifespan, which are suggested based on
                  the analysis of your historical conversions but which you can
                  edit based on your business needs . The events are defined in
                  the{' '}
                  <a
                    href={`https://app.madkudu.com/org/${tenant}/mapping/event_mapping`}
                  >
                    Event mapping
                  </a>
                  .
                </p>
                <a
                  href="https://support.madkudu.com/hc/en-us/articles/4406317334925-How-to-update-your-Likelihood-to-Buy-scoring-model-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-inline-block text-decoration-none text-primary mb-4"
                >
                  <i aria-hidden className="fas fa-book-open"></i> How is the
                  Likelihood to Buy score calculated
                </a>
                <Table
                  data={featureEvaluation?.features ?? []}
                  columns={columns}
                  onCellChange={this.onCellChange}
                  defaultColumnVisibility={columnVisibility}
                  columnVisibilityBag={COLUMN_VISIBILITY_BAG}
                  additionalButtons={checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <>
                      <Button
                        variant="success me-2"
                        onClick={this.saveFeatureEvaluation}
                        disabled={saving || !isAllowedToEdit}
                      >
                        {saving ? 'Saving...' : 'Save'}
                      </Button>
                      <Button
                        variant="danger me-2"
                        onClick={this.resetFeatureEvaluation}
                        disabled={!isAllowedToEdit}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                />
              </Card.Body>
            </Card>
          </div>
        );
      }
    }
  )
);
