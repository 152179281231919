import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Subject } from '../../../models/sqlform';
import Store from '../../../store';
import RuleEditor from '../rules/RuleEditor';
import { IRulePageModel, Rule } from '../../../models/rules';
import Spinner from '../Spinner';

interface StepFourProps extends RouteComponentProps<any> {
  store?: Store;
}

interface StepFourState {
  isLoading: boolean;
}

const EVENT_ACTIVITY_FIELDS: Subject[] = [
  {
    subject: 'meta_event',
    type: 'string',
    context: 'standard',
    tags: [],
  },
  {
    subject: 'is_positive_user_activity',
    type: 'boolean',
    context: 'standard',
    tags: [],
  },
  {
    subject: 'activity_type',
    type: 'string',
    context: 'standard',
    tags: [],
  },
];

class StepFourComponent extends React.Component<StepFourProps, StepFourState> {
  constructor(props: StepFourProps) {
    super(props);

    this.state = {
      isLoading: true,
    };
    this.handleConditionLogicChange =
      this.handleConditionLogicChange.bind(this);
    this.updateRules = this.updateRules.bind(this);

    makeObservable(this, {
      handleConditionLogicChange: action,
      updateRules: action,
    });
  }

  async componentDidMount() {
    await this.props.store.loadEventMetadata();
    this.setState({ isLoading: false });
    this.props.store.behavioralAggregationsPage.stepConfiguration[4].validated =
      true;
  }

  handleConditionLogicChange(value: string) {
    this.props.store.behavioralAggregationsPage.activeAggregation.conditionLogic =
      value;
  }

  updateRules(rules: Rule[]) {
    this.props.store.behavioralAggregationsPage.activeAggregation.conditions.rules =
      rules;
  }

  render() {
    const { activeAggregation } = this.props.store.behavioralAggregationsPage;
    const { isArchitect, isSuperKudu } = this.props.store;
    const { isLoading } = this.state;
    if (!activeAggregation) return null;
    if (isLoading) {
      return (
        <div className="mb-3">
          <Spinner />
        </div>
      );
    }
    const pageModel: IRulePageModel = {
      rules: activeAggregation.conditions.rules ?? [],
      ensemble: null,
      loading: false,
      saving: false,
      hasJustRearranged: false,
      loadingEnsemble: false,
      loadingError: null,
      saved: false,
      savingError: null,
    };

    return (
      <div className="mb-3">
        <span className="d-inline-block fs-5 fw-bold mb-2">
          Specific events or activities included in the aggregations
        </span>
        <RuleEditor
          model={pageModel}
          formFields={[]}
          updateRules={this.updateRules}
          saveRules={() => {
            // Do nothing
          }}
          withRuleAndSegment={false}
          conditionLogic={activeAggregation.conditionLogic}
          handleConditionLogic={this.handleConditionLogicChange}
          eventActivityFields={EVENT_ACTIVITY_FIELDS}
          withConditionLogic
          noSave
          isAggregationsForm
          modelType={activeAggregation.type === 'email' ? 'pql' : 'mqa'}
          isAllowedToEdit={isSuperKudu || isArchitect}
        />
      </div>
    );
  }
}

export default inject('store')(withRouter(observer(StepFourComponent)));
