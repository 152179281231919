import { makeObservable, observable } from 'mobx';

import { LtbDatasetVariables } from '../audience/ltb/LtbDataset';
import GenericDatasetPageModel from './GenericDatasetPageModel';

export default class MqaPageModel extends GenericDatasetPageModel {
  variables: LtbDatasetVariables;

  conversionNames: string[];

  constructor(other?: MqaPageModel) {
    super();
    if (other) {
      this.variables = other.variables;
      this.conversionNames = other.conversionNames;
    } else {
      this.variables = {
        trainingDates: '',
        evaluationDate: '',
        trainingConversionModel: '',
        validationConversionModel: '',
        rebalancingRatio: 0,
        customerFitIncluded: '',
        metaEventExclusionList: '',
        domainActivityTypeExclusionList: '',
        domainMetaEventExclusionList: '',
        activityTypeExclusionList: '',
      };
      this.conversionNames = [];
    }
    makeObservable(this, {
      variables: observable,
    });
  }
}
