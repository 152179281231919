export default class UnivariateAnalysisVariableBucket {
  population: number;

  conversions: number;

  rawName: string;

  name: string;

  constructor(
    population: number,
    conversions: number,
    rawName: string,
    name: string
  ) {
    this.population = population;
    this.conversions = conversions;
    this.rawName = rawName;
    this.name = name;
  }
}
