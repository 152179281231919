import { ModelType } from '../models/ModelItem';

export const APP_NAME = 'Data Studio';

export const TENANT_CONFIG_REPO = 'tenant-config';
export const SPRINGBOK_SIGNALS_REPO = 'springbok-signals';
export const MAX_NUMBER_OF_LOADING_DATASETS = 2;

export const LEAD_LTBS: ModelType[] = ['pql', 'pql2', 'mql'];

export const MODEL_TYPES_NAMES = {
  customer_fit: 'Customer Fit',
  pql: 'Lead Likelihood to Buy',
  mqa: 'Account Likelihood to Buy',
  pql2: 'PQL',
  mql: 'MQL',
};

export const IS_LLTB = (modelType: ModelType): boolean =>
  LEAD_LTBS.includes(modelType);

export const IS_HYBRID = (modelType: ModelType): boolean =>
  ['pql2', 'mql'].includes(modelType);
