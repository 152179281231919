import { action, makeObservable, observable } from 'mobx';
import superagent from 'superagent';
import CsvPageModel from '../models/dataset/CsvPageModel';
import RedshiftPageModel from '../models/dataset/RedshiftPageModel';
import DataSets from '../models/audience/DataSets';
import DataSetType from '../models/audience/DataSetType';
import { LtbDatasetVariables } from '../models/audience/ltb/LtbDataset';
import { AggregatedEventsPageModel } from '../models/aggregations/aggregated_events';
import {
  BehavioralAggregation,
  BehavioralAggregationsPageModel,
} from '../models/aggregations/behavioral_aggregations';
import ComputationsPageModel from '../models/computations/ComputationsPageModel';
import DeployPageModel from '../models/deploy';
import DeployMode from '../models/deploy/DeployMode';
import { EnsemblePerformance, EnsemblingPageModel } from '../models/ensembling';
import FeatureEvaluationPageModel from '../models/feature_eval/FeatureEvaluationPageModel';
import LeadGradePageModel from '../models/lead_grade/LeadGradePageModel';
import ModelBaseNames from '../models/modelBase/ModelBaseNames';
import ModelBasePageDataModel from '../models/modelBase/ModelBasePageDataModel';
import ModelItem, { ModelBuildType, ModelFolder } from '../models/ModelItem';
import NodePageModel from '../models/NodePageModel';
import { PerformanceCheckPageModel } from '../models/performance_check';
import { IsSuperKudu, Permission } from '../models/Permissions';
import GroupPointBasedRulesDataModel from '../models/pointBasedForm/GroupPointBasedRulesDataModel';
import PointBasedScoringRulePageModel from '../models/pointBasedForm/PointBasedScoringRulePageModel';
import RootPageModel from '../models/root';
import { Rule, RulePageModel } from '../models/rules';
import {
  BehavioralSampleLead,
  CustomerFitSampleLead,
} from '../models/SampleLead';
import { Signals, SignalsPageModel } from '../models/signals';
import { SmoothingPageModel } from '../models/smoothing';
import { SpotCheckPageModel } from '../models/spotcheck';
import { SqlForm, Subject } from '../models/sqlform';
import TreeHistory from '../models/tree/TreeHistory';
import Tree from '../models/tree/TreeLite';
import TreesPageModel from '../models/tree/TreesPageModel';
import UnivariateAnalysisPageModel from '../models/univariate/UnivariateAnalysisPageModel';
import Validation, { ValidationPageModel } from '../models/validation';
import { extractNodeFromTreeStructure } from '../utils';
import { MetadataField } from '../models/metadataFields';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { TrainingDatasetVariables } from '../models/audience/DataSet';
import UnivariateAnalysis from '../models/univariate/UnivariateAnalysis';
import UnivariateAnalysisVariable from '../models/univariate/UnivariateAnalysisVariable';
import UnivariateAnalysisVariableBucket from '../models/univariate/UnivariateAnalysisVariableBucket';
import { EventMetadata } from '../models/event_metadata';
import MqaPageModel from '../models/dataset/MqaPageModel';
import MkAudiencePageModel from '../models/dataset/MkAudiencePageModel';
import PqlPageModel from '../models/dataset/PqlPageModel';
import axios from 'axios';
import { DataPageModel } from '../models/data/DataPageModel';
import { IS_LLTB } from '../utils/constants';

const defaultError = {
  message: 'An error has occurred',
  type: 'other',
  status: 500,
};

export default class Store {
  tenant: number;

  tenantName: string;

  modelId: number;

  tenantModels: ModelItem[];

  email: string;

  isModelIdValid: boolean;

  activeModel: ModelItem;

  userPermissions: Permission[];

  isSuperKudu: IsSuperKudu;

  isArchitect: boolean;

  isAllowedToEdit: boolean;

  // Models
  rootPage: RootPageModel;

  csvPage: CsvPageModel;

  redshiftPage: RedshiftPageModel;

  aggregatedEventsPage: AggregatedEventsPageModel;

  behavioralAggregationsPage: BehavioralAggregationsPageModel;

  dataPage: DataPageModel;

  computationsPage: ComputationsPageModel;

  univariateAnalysisPage: UnivariateAnalysisPageModel;

  treesPage: TreesPageModel;

  smoothingPage: SmoothingPageModel;

  ensemblingPage: EnsemblingPageModel;

  nodePage: NodePageModel;

  validationPage: ValidationPageModel;

  overridesPage: RulePageModel;

  signalsPage: SignalsPageModel;

  spotCheckPage: SpotCheckPageModel;

  performanceCheckPage: PerformanceCheckPageModel;

  deployPage: DeployPageModel;

  mqaPage: MqaPageModel;

  pqlPage: PqlPageModel;

  mkAudiencePage: MkAudiencePageModel;

  formFields: Subject[];

  groupsPointBasedRules: GroupPointBasedRulesDataModel[];

  featureEvaluationPage: FeatureEvaluationPageModel;

  leadGradePage: LeadGradePageModel;

  modelBasePage: ModelBasePageDataModel;

  pointBasedScoringRulePage: PointBasedScoringRulePageModel;

  eventMetadata: EventMetadata;

  constructor(state?: Store) {
    if (state) {
      this.tenant = state.tenant;
      this.tenantName = state.tenantName;
      this.modelId = state.modelId;
      this.email = state.email;
      this.tenantModels = state.tenantModels;
      this.isModelIdValid = state.isModelIdValid;
      this.activeModel = state.activeModel;
      this.userPermissions = state.userPermissions;
      this.isSuperKudu = state.isSuperKudu;
      this.isArchitect = state.isArchitect;
      this.isAllowedToEdit = state.isAllowedToEdit;
      this.rootPage = new RootPageModel(state.rootPage);
      this.csvPage = new CsvPageModel(state.csvPage);
      this.redshiftPage = new RedshiftPageModel(state.redshiftPage);
      this.aggregatedEventsPage = new AggregatedEventsPageModel(
        state.aggregatedEventsPage
      );
      this.behavioralAggregationsPage = new BehavioralAggregationsPageModel(
        state.behavioralAggregationsPage
      );
      this.dataPage = new DataPageModel(state.dataPage);
      this.computationsPage = new ComputationsPageModel(state.computationsPage);
      this.univariateAnalysisPage = new UnivariateAnalysisPageModel(
        state.univariateAnalysisPage
      );
      this.treesPage = new TreesPageModel(state.treesPage);
      this.smoothingPage = new SmoothingPageModel(state.smoothingPage);
      this.ensemblingPage = new EnsemblingPageModel(state.ensemblingPage);
      this.nodePage = new NodePageModel(state.nodePage);
      this.validationPage = new ValidationPageModel(state.validationPage);
      this.overridesPage = new RulePageModel(state.overridesPage);
      this.signalsPage = new SignalsPageModel(state.signalsPage);
      this.spotCheckPage = new SpotCheckPageModel(state.spotCheckPage);
      this.performanceCheckPage = new PerformanceCheckPageModel(
        state.performanceCheckPage
      );
      this.deployPage = new DeployPageModel(state.deployPage);
      this.mkAudiencePage = new MkAudiencePageModel(state.mkAudiencePage);
      this.mqaPage = new MqaPageModel(state.mqaPage);
      this.pqlPage = new PqlPageModel(state.pqlPage);
      this.featureEvaluationPage = new FeatureEvaluationPageModel(
        state.featureEvaluationPage
      );
      this.leadGradePage = new LeadGradePageModel(state.leadGradePage);
      this.formFields = state.formFields;
      this.groupsPointBasedRules = state.groupsPointBasedRules;
      this.modelBasePage = new ModelBasePageDataModel(state.modelBasePage);
      this.pointBasedScoringRulePage = new PointBasedScoringRulePageModel(
        state.pointBasedScoringRulePage
      );
      this.eventMetadata = state.eventMetadata;
    } else {
      this.tenant = null;
      this.tenantName = null;
      this.modelId = null;
      this.tenantModels = [];
      this.isModelIdValid = false;
      this.activeModel = null;
      this.userPermissions = [];
      this.isSuperKudu = false;
      this.isArchitect = false;
      this.isAllowedToEdit = false;
      this.eventMetadata = {
        activityTypes: [],
        metaEventsDisplay: [],
        metaEvents: [],
      };
      this.rootPage = new RootPageModel();
      this.csvPage = new CsvPageModel();
      this.redshiftPage = new RedshiftPageModel();
      this.aggregatedEventsPage = new AggregatedEventsPageModel();
      this.behavioralAggregationsPage = new BehavioralAggregationsPageModel();
      this.dataPage = new DataPageModel();
      this.computationsPage = new ComputationsPageModel();
      this.univariateAnalysisPage = new UnivariateAnalysisPageModel();
      this.treesPage = new TreesPageModel();
      this.smoothingPage = new SmoothingPageModel();
      this.ensemblingPage = new EnsemblingPageModel();
      this.nodePage = new NodePageModel();
      this.validationPage = new ValidationPageModel();
      this.overridesPage = new RulePageModel();
      this.signalsPage = new SignalsPageModel();
      this.spotCheckPage = new SpotCheckPageModel();
      this.performanceCheckPage = new PerformanceCheckPageModel();
      this.deployPage = new DeployPageModel();
      this.mkAudiencePage = new MkAudiencePageModel();
      this.mqaPage = new MqaPageModel();
      this.pqlPage = new PqlPageModel();
      this.featureEvaluationPage = new FeatureEvaluationPageModel();
      this.leadGradePage = new LeadGradePageModel();
      this.formFields = null;
      this.groupsPointBasedRules = null;
      this.modelBasePage = new ModelBasePageDataModel();
      this.pointBasedScoringRulePage = new PointBasedScoringRulePageModel();
    }

    makeObservable(this, {
      tenant: observable,
      tenantName: observable,
      modelId: observable,
      tenantModels: observable,
      isModelIdValid: observable,
      activeModel: observable,
      userPermissions: observable,
      isSuperKudu: observable,
      isArchitect: observable,
      rootPage: observable,
      csvPage: observable,
      redshiftPage: observable,
      aggregatedEventsPage: observable,
      behavioralAggregationsPage: observable,
      dataPage: observable,
      computationsPage: observable,
      univariateAnalysisPage: observable,
      treesPage: observable,
      smoothingPage: observable,
      ensemblingPage: observable,
      nodePage: observable,
      validationPage: observable,
      overridesPage: observable,
      signalsPage: observable,
      spotCheckPage: observable,
      performanceCheckPage: observable,
      deployPage: observable,
      mqaPage: observable,
      pqlPage: observable,
      formFields: observable,
      groupsPointBasedRules: observable,
      featureEvaluationPage: observable,
      leadGradePage: observable,
      modelBasePage: observable,
      pointBasedScoringRulePage: observable,
      eventMetadata: observable,
      refreshModel: action,
      updateModelName: action,
      updateModelArchiveStatus: action,
      saveModelNotes: action,
      updateModelContext: action,
      cleanCache: action,
      cleanValidationCache: action,
      uploadDataSetsFiles: action,
      automateBuildModel: action,
      uploadLtbDataset: action,
      recompute: action,
      getTenantAudiences: action,
      getDefaultDatasetLtbVariables: action,
      loadUnivariateAnalysis: action,
      getUnivariateAnalysisLeads: action,
      getNodeLeads: action,
      getNodeUnivariate: action,
      checkUnivariateAnalysisWithPersonal: action,
      checkNodeUnivariateAnalysisWithPersonal: action,
      loadAllTrees: action,
      loadTree: action,
      resetTree: action,
      autoSelectBestTrees: action,
      replaceSplitCondition: action,
      insertChildNode: action,
      resetSplitCondition: action,
      toggleResetTreePopup: action,
      deleteSplitCondition: action,
      updateSplitCondition: action,
      toggleAdvancedModePopup: action,
      toggleHistoryPopup: action,
      getTreeHistory: action,
      getCommitContent: action,
      updateTreeDefinition: action,
      loadSmoothingConfig: action,
      saveSmoothingConfig: action,
      loadEnsemblingPerformances: action,
      autoEnsembling: action,
      updateOverrideRules: action,
      rearrangeRules: action,
      saveOverrideRules: action,
      loadValidationData: action,
      loadOverrideEnsembleData: action,
      getSpotCheckLeads: action,
      loadPerformanceCheck: action,
      getLastCommit: action,
      deployModel: action,
      updateDeployStatus: action,
      loadSignals: action,
      saveSignals: action,
      cleanEnsemblingOverridesValidation: action,
      cleanPostEnsembling: action,
      cleanPostValidation: action,
      fetchFormFields: action.bound,
      getComputations: action,
      initComputations: action,
      deleteComputationsCache: action,
      saveComputationsArgo: action,
      loadFeatureEvaluation: action,
      saveFeatureEvaluation: action,
      resetFeatureEvaluation: action,
      getLeadGradeConfiguration: action,
      getLeadGradeHelpMatrixes: action,
      calculateLeadGradePerformance: action,
      saveModelBase: action,
      getFormFieldsAndPointBasedRules: action,
      saveGroupsPointBasedRules: action,
      getMetadataFieldsForComputations: action,
      fetchForCsvFile: action,
      createFrequencyAnalysis: action,
      loadEventMetadata: action,
    });

    this.resetSplitCondition();
  }

  resetModels() {
    this.dataPage = new DataPageModel();
    this.univariateAnalysisPage = new UnivariateAnalysisPageModel();
    this.treesPage = new TreesPageModel();
    this.smoothingPage = new SmoothingPageModel();
    this.ensemblingPage = new EnsemblingPageModel();
    this.nodePage = new NodePageModel();
    this.validationPage = new ValidationPageModel();
    this.overridesPage = new RulePageModel();
    this.signalsPage = new SignalsPageModel();
    this.leadGradePage = new LeadGradePageModel();
    this.performanceCheckPage = new PerformanceCheckPageModel();
  }

  async getTenantName() {
    const { data } = await axios.get(`/api/tenant/${this.tenant}`);

    this.tenantName = data.name;
  }

  async refreshModel() {
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.activeModel.modelId}`)
      .then((res) => {
        this.activeModel = res.body;
      });
  }

  async updateModelName(modelId: number, newName: string) {
    this.rootPage.updatingModelName = true;

    return superagent
      .put(`/api/tenant/${this.tenant}/model/${modelId}/name`)
      .query({ name: newName })
      .then(() => {
        this.rootPage.updatingModelName = false;
        this.rootPage.updateModelNameError = null;
      })
      .catch(
        (e) => (this.rootPage.updateModelNameError = e.response.body.message)
      );
  }

  async updateModelArchiveStatus(modelId: number, isArchived: boolean) {
    this.rootPage.archivingModel = true;
    return superagent
      .put(`/api/tenant/${this.tenant}/model/${modelId}/archive`)
      .send({ isArchived })
      .then(() => (window.location.href = `/tenant/${this.tenant}`))
      .catch((e) => {
        this.rootPage.updateModelArchiveStatusError = e.response.body.message;
      });
  }

  async saveModelNotes(notes: string): Promise<void> {
    this.rootPage.updatingModelNotes = true;
    return superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/notes`)
      .send({ notes })
      .then(() => {
        this.activeModel.notes = notes;
        this.rootPage.updatingModelNotes = false;
      })
      .catch((e) => {
        throw new Error(e);
      });
  }

  async updateModelContext(context: {
    batch: boolean;
    realtime: boolean;
  }): Promise<void> {
    this.rootPage.updatingContext = true;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/context`)
      .send(context)
      .then(() => {
        this.activeModel.realtime = context.realtime;
        this.activeModel.batch = context.batch;
      })
      .catch((e) => {
        throw new Error(e);
      });
    this.rootPage.updatingContext = false;
  }

  async getTenantModels() {
    await superagent
      .get(`/api/tenant/${this.tenant}/models`)
      .then(({ body: models }) => {
        this.tenantModels = models;
      })
      .catch((e) => {
        throw new Error(e);
      });
  }

  async getTenantAudiences() {
    try {
      const { data } = await axios.get(`/api/tenant/${this.tenant}/audiences`);
      const audiences = data as string[];

      const userReadyAudiences = ['all'].concat(audiences);

      this.pqlPage.audiences = userReadyAudiences;
      this.mkAudiencePage.audiences = userReadyAudiences;
    } catch (err) {
      throw new Error(err);
    }
  }

  async getTenantConversionNames() {
    try {
      const { data: conversionNames } = await axios.get(
        `/api/tenant/${this.tenant}/conversion`
      );

      this.mqaPage.conversionNames = conversionNames;
      this.pqlPage.conversionNames = conversionNames;
      this.mkAudiencePage.conversionNames = conversionNames;
    } catch (err) {
      throw new Error(err);
    }
  }

  async cleanCache() {
    this.rootPage.cleaningCache = true;
    this.resetModels();
    await axios.post(
      `/api/tenant/${this.tenant}/model/${this.modelId}/cleancache`
    );
    this.rootPage.cleaningCache = false;
    this.csvPage.dataSets = new DataSets();
    this.csvPage.dataSets.setSelectedTab(this.csvPage.dataSets.selectedTab);
  }

  async cleanCacheForModel(modelId: number) {
    await superagent.post(
      `/api/admin/tenant/${this.tenant}/modelId/${modelId}/cleancache`
    );
  }

  // for all tenants and all models, excluding bull/sessions
  async cleanWholeCache() {
    await superagent.post('/api/admin/cleancache/all');
  }

  async cleanSessionsCache() {
    await superagent.post('/api/admin/cleancache/sessions');
  }

  async cleanBullCache() {
    await superagent.post('/api/admin/cleancache/bull');
  }

  async cleanComputationsCache() {
    await axios.post('/api/admin/cleancache/computations');
  }

  async cleanValidationCache() {
    this.rootPage.cleaningCache = true;
    this.validationPage = new ValidationPageModel();
    this.performanceCheckPage = new PerformanceCheckPageModel();
    await superagent.post(
      `/api/tenant/${this.tenant}/model/${this.modelId}/cleancache/validation`
    );
    this.rootPage.cleaningCache = false;
    this.dataPage = new DataPageModel();
    this.csvPage.dataSets = new DataSets();
    this.csvPage.dataSets.setSelectedTab(this.csvPage.dataSets.selectedTab);
  }

  async uploadDataSetsFiles(dataset: DataSetType) {
    const dataSets = dataset
      ? [
          this.csvPage.dataSets.inputFileDataSets.find(
            ({ type }) => type === dataset
          ),
        ]
      : this.csvPage.dataSets.inputFileDataSets.slice();
    const data = new FormData();

    dataSets.forEach((dataSet) => {
      data.append(dataSet.type, dataSet.file ? dataSet.file : dataSets[0].file);
    });

    if (dataset && dataset === 'validation') {
      await this.cleanValidationCache();
    } else {
      await this.cleanCache();
    }

    this.rootPage.isDataSetLoadingProgress = true;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/datasets/files`)
      .send(data)
      .then(() => {
        this.csvPage.dataSets.uploadDataSetError = null;
        this.rootPage.isDataSetLoadingProgress = true;
      })
      .catch(async (error) => {
        await axios.post(
          `/api/tenant/${this.tenant}/model/${this.modelId}/cleancache`
        );
        this.rootPage.isDataSetLoadingProgress = false;
        this.csvPage.dataSets.uploadDataSetError =
          error?.response?.body ?? defaultError;
      });
  }

  async automateBuildModel(
    modelBuildType: ModelBuildType,
    dataset?: DataSetType
  ) {
    this.rootPage.isDataSetLoadingProgress = true;
    const modelType = this.activeModel.type;

    let variables: TrainingDatasetVariables;
    if (modelBuildType === 'mk_audience') {
      variables = this.mkAudiencePage.variables;
    }

    if (dataset && dataset === 'validation') {
      await this.cleanValidationCache();
    } else {
      await this.cleanCache();
    }

    return superagent
      .put(
        `/api/tenant/${this.tenant}/model/${this.modelId}/datasets/${modelBuildType}`
      )
      .send({ dataset, variables, modelType })
      .then(() => {
        this.csvPage.dataSets.uploadDataSetError = null;
        this.rootPage.isDataSetLoadingProgress = true;
      })
      .catch((e) => {
        this.rootPage.isDataSetLoadingProgress = false;
        this.csvPage.dataSets.uploadDataSetError =
          e?.response?.body ?? defaultError;
      });
  }

  async uploadDataSetsFromRedshift(datasetType: DataSetType) {
    const modelType = this.activeModel.type;
    let datasets: { type: string; table: string }[];
    if (datasetType) {
      const selectedDataset = this.csvPage.dataSets.inputTableDataSets.find(
        ({ type }) => type === datasetType
      );
      datasets = [
        {
          type: selectedDataset.type,
          table: selectedDataset.table,
        },
      ];
    } else {
      const trainingTable = this.csvPage.dataSets.inputTableDataSets.find(
        (t) => t.type === 'training'
      );
      datasets = this.csvPage.dataSets.inputTableDataSets.map((d) => ({
        type: d.type,
        table: d.table || trainingTable.table,
      }));
    }
    if (datasetType === 'validation') {
      await this.cleanValidationCache();
    } else {
      await this.cleanCache();
    }
    this.rootPage.isDataSetLoadingProgress = true;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/datasets/tables`)
      .send({ datasets, modelType })
      .then(() => {
        this.csvPage.dataSets.uploadDataSetError = null;
        this.rootPage.isDataSetLoadingProgress = true;
      })
      .catch((e) => {
        this.rootPage.isDataSetLoadingProgress = false;
        this.csvPage.dataSets.uploadDataSetError =
          e?.response?.body ?? defaultError;
      });
  }

  async uploadLtbDataset(dataset: DataSetType, variables: LtbDatasetVariables) {
    this.rootPage.isDataSetLoadingProgress = true;
    const modelType = this.activeModel.type;
    if (dataset && dataset === 'validation') {
      await this.cleanValidationCache();
    } else {
      await this.cleanCache();
    }
    return superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/datasets/ltb`)
      .send({ dataset, variables, modelType, modelId: this.modelId })
      .then(() => {
        this.csvPage.dataSets.uploadDataSetError = null;
        this.rootPage.isDataSetLoadingProgress = true;
      })
      .catch((e) => {
        this.rootPage.isDataSetLoadingProgress = false;
        this.csvPage.dataSets.uploadDataSetError =
          e?.response?.body ?? defaultError;
      });
  }

  async recompute() {
    await this.cleanCache();
    this.rootPage.isDataSetLoadingProgress = true;
    try {
      await axios.put(
        `/api/tenant/${this.tenant}/model/${this.modelId}/recompute`
      );
      this.csvPage.dataSets.uploadDataSetError = null;
    } catch (error) {
      this.csvPage.dataSets.uploadDataSetError =
        error?.response?.data ?? defaultError;
      this.rootPage.isDataSetLoadingProgress = false;
    }
  }

  async fetchForCsvFile() {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/datasets/find/audience`
      )
      .then(() => {
        this.csvPage.isOldFileFound = true;
      })
      .catch(() => {
        this.csvPage.isOldFileFound = false;
      });
  }

  async getDefaultDatasetVariables(buildType: ModelBuildType) {
    try {
      const { body } = await superagent.get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/datasets/${buildType}`
      );
      switch (buildType) {
        case 'mk_audience':
          this.mkAudiencePage.variables = body;
          break;
        case 'csv_file':
          this.csvPage.variables = body;
          break;
        case 'from_redshift_tables':
          this.redshiftPage.variables = body;
          break;
        default:
          break;
      }
    } catch (error) {
      this.mkAudiencePage.error = error.message;
    }
  }

  async getDefaultDatasetLtbVariables() {
    const { type } = this.activeModel;
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.modelId}/datasets/ltb`)
      .query({ type })
      .then((res) => {
        if (type === 'mqa') this.mqaPage.variables = res.body;
        if (IS_LLTB(type)) this.pqlPage.variables = res.body;
      });
  }

  async loadUnivariateAnalysis() {
    this.univariateAnalysisPage.loadingUnivariateAnalysis = true;
    this.univariateAnalysisPage.univariateAnalysisError = null;
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.modelId}/univariate`)
      .query({
        withIsPersonal:
          this.univariateAnalysisPage.univariateAnalysisWithPersonal,
      })
      .then((res) => {
        const univariateAnalysis: UnivariateAnalysis = res.body;
        this.univariateAnalysisPage.univariateAnalysis = univariateAnalysis;
      })
      .catch((e) => {
        this.univariateAnalysisPage.univariateAnalysisError =
          e?.response?.body.message;
      });

    this.univariateAnalysisPage.loadingUnivariateAnalysis = false;
  }

  async loadUnivariateAnalysisForVariable(variable: string) {
    this.univariateAnalysisPage.loadingUnivariateAnalysisVariable = true;
    this.univariateAnalysisPage.univariateAnalysisVariableError = null;
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/univariate/${variable}`
      )
      .query({
        withIsPersonal:
          this.univariateAnalysisPage.univariateAnalysisWithPersonal,
      })
      .then((res) => {
        this.univariateAnalysisPage.univariateAnalysisVariable = res.body;
      })
      .catch((e) => {
        this.univariateAnalysisPage.univariateAnalysisVariableError =
          e?.response?.body?.message ??
          `An error has happened while loading ${variable} insights}`;
      });

    this.univariateAnalysisPage.loadingUnivariateAnalysisVariable = false;
  }

  async getUnivariateAnalysisLeads(
    variableId: string,
    value: string,
    treeId?: string,
    nodeId?: string
  ) {
    this.univariateAnalysisPage.loadingLeads = true;
    this.univariateAnalysisPage.loadLeadsError = null;
    // getting leads from a global UA variable, or from a tree node's UA variable
    // treeId and nodeId will be defined in the case of the latter
    const urlToCall =
      treeId && nodeId
        ? `/api/tenant/${this.tenant}/model/${this.modelId}/univariate/${variableId}/value/${value}/trees/${treeId}/nodes/${nodeId}/leads`
        : `/api/tenant/${this.tenant}/model/${this.modelId}/univariate/${variableId}/value/${value}/leads`;

    await superagent
      .get(urlToCall)
      .query({
        withIsPersonal:
          this.univariateAnalysisPage.univariateAnalysisWithPersonal,
      })
      .then((res) => {
        const leads: CustomerFitSampleLead[] = res.body;
        this.univariateAnalysisPage.leads = leads.map(
          (lead: CustomerFitSampleLead) => {
            const newLead = new CustomerFitSampleLead(
              lead.email,
              lead.score,
              lead.segment,
              lead.signals,
              lead.hasConverted,
              lead.employees,
              lead.amountRaised,
              lead.marketCap,
              lead.isFortune500Company,
              lead.country,
              lead.predictiveTraffic,
              lead.industry,
              lead.hasEnterpriseTechnology,
              lead.tree1Node,
              lead.tree2Node,
              lead.tree3Node
            );
            return newLead;
          }
        );
      })
      .catch((e) => {
        this.univariateAnalysisPage.loadLeadsError = e.response.body.message;
      });

    this.univariateAnalysisPage.loadingLeads = false;
  }

  async getNodeLeads(treeId: number, nodeId: number, target: number) {
    this.nodePage.leads = null;
    this.nodePage.loadLeadsError = undefined;
    this.nodePage.loadingLeads = true;

    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}/leads/${target}`
      )
      .query({ withIsPersonal: this.nodePage.univariateAnalysisWithPersonal })
      .then((res) => {
        const leads: CustomerFitSampleLead[] = res.body;
        this.nodePage.leads = leads.map((lead: CustomerFitSampleLead) => {
          const newLead = new CustomerFitSampleLead(
            lead.email,
            lead.score,
            lead.segment,
            lead.signals,
            lead.hasConverted,
            lead.employees,
            lead.amountRaised,
            lead.marketCap,
            lead.isFortune500Company,
            lead.country,
            lead.predictiveTraffic,
            lead.industry,
            lead.hasEnterpriseTechnology,
            lead.tree1Node,
            lead.tree2Node,
            lead.tree3Node
          );
          return newLead;
        });
      })
      .catch((e) => {
        this.nodePage.loadLeadsError = e.response.body.message;
      });
    this.nodePage.loadingLeads = false;
  }

  async getNodeUnivariate(treeId: number, nodeId: number) {
    this.nodePage.univariateAnalysis = null;
    this.nodePage.loadingUnivariateAnalysis = true;
    this.nodePage.univariateAnalysisError = null;
    // load trees
    const { trees } = this.treesPage;
    if (!trees?.length) {
      await this.loadAllTrees();
    }
    await this.loadTree(Number(treeId));
    const selectedTree = this.treesPage.trees.find(
      (t) => Number(t.id) === Number(treeId)
    );
    // get conditions for this node
    const { rawSumConditions } = extractNodeFromTreeStructure(
      selectedTree.definition.structure,
      Number(nodeId)
    );

    await superagent
      .post(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}/univariate`
      )
      .send({ conditions: rawSumConditions })
      .query({ withIsPersonal: this.nodePage.univariateAnalysisWithPersonal })
      .then((res) => {
        const univariateAnalysis: UnivariateAnalysis = res.body;
        // Rebuilding univariate to take advantage of Mobx
        this.nodePage.univariateAnalysis = new UnivariateAnalysis(
          univariateAnalysis.variables.map(
            (variables) =>
              new UnivariateAnalysisVariable(
                variables.id,
                variables.variableName,
                variables.buckets.map(
                  (bucket) =>
                    new UnivariateAnalysisVariableBucket(
                      bucket.population,
                      bucket.conversions,
                      bucket.rawName,
                      bucket.name
                    )
                ),
                variables.tags
              )
          )
        );
      })
      .catch((e) => {
        this.nodePage.univariateAnalysisError = e.response.body.message;
      });
    this.nodePage.loadingUnivariateAnalysis = false;
  }

  async getNodeUnivariateForVariable(
    treeId: number,
    nodeId: number,
    variable: string
  ) {
    this.nodePage.univariateAnalysisVariable = null;
    this.nodePage.loadingUnivariateAnalysisVariable = true;
    this.nodePage.univariateAnalysisVariableError = null;

    // load trees
    const { trees } = this.treesPage;
    if (!trees?.length) {
      await this.loadAllTrees();
    }
    await this.loadTree(Number(treeId));
    const selectedTree = this.treesPage.trees.find(
      (t) => Number(t.id) === Number(treeId)
    );

    // get conditions for this node
    const { rawSumConditions } = extractNodeFromTreeStructure(
      selectedTree.definition.structure,
      Number(nodeId)
    );

    await superagent
      .post(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}/univariate/${variable}`
      )
      .send({ conditions: rawSumConditions })
      .query({ withIsPersonal: this.nodePage.univariateAnalysisWithPersonal })
      .then((res) => {
        this.nodePage.univariateAnalysisVariable = res.body;
      })
      .catch((e) => {
        this.nodePage.univariateAnalysisVariableError = e.response.body.message;
      });

    this.nodePage.loadingUnivariateAnalysisVariable = false;
  }

  checkUnivariateAnalysisWithPersonal() {
    this.univariateAnalysisPage.univariateAnalysisWithPersonal =
      !this.univariateAnalysisPage.univariateAnalysisWithPersonal;
  }

  checkNodeUnivariateAnalysisWithPersonal() {
    this.nodePage.univariateAnalysisWithPersonal =
      !this.nodePage.univariateAnalysisWithPersonal;
  }

  async loadAllTrees() {
    await this.treesPage.loadAll(this.tenant, this.modelId);
  }

  async loadTree(treeId: number): Promise<Tree> {
    return this.treesPage.loadOne(this.tenant, this.modelId, treeId);
  }

  async resetTree(treeId: number): Promise<void> {
    this.treesPage.resetingTree = true;

    await this.treesPage.resetTree(this.tenant, this.modelId, treeId);
    await this.cleanEnsemblingOverridesValidation();
    await this.loadTree(treeId);
    this.treesPage.resetingTree = false;
    this.toggleResetTreePopup();
  }

  async autoSelectBestTrees() {
    await this.treesPage.loadBestTrees(this.tenant, this.modelId);
    await this.loadTree(1);
  }

  async replaceSplitCondition(
    treeId: number,
    treeName: string,
    nodeId: number
  ) {
    this.treesPage.savingSplitCondition = true;

    await superagent
      .put(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}`
      )
      .query({ treeName: treeName || treeId })
      .send(this.treesPage.currentSplitCondition)
      .catch((err) => (this.treesPage.savingSplitConditionError = err));

    this.treesPage.activeReplaceCondition = false;
    await this.loadTree(treeId);
    await this.cleanEnsemblingOverridesValidation();

    this.treesPage.savingSplitCondition = false;
    this.resetSplitCondition();
  }

  async insertChildNode(treeId: number, treeName: string, nodeId: number) {
    this.treesPage.savingSplitCondition = true;

    await superagent
      .put(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}/add`
      )
      .query({ treeName: treeName || treeId })
      .send(this.treesPage.currentSplitCondition)
      .catch((err) => (this.treesPage.savingSplitConditionError = err));
    this.treesPage.activeInsertChildNode = false;
    await this.loadTree(treeId);
    await this.cleanEnsemblingOverridesValidation();

    this.treesPage.savingSplitCondition = false;
    this.resetSplitCondition();
  }

  resetSplitCondition() {
    this.treesPage.currentSplitCondition = {
      conditionLogic: '',
      conditions: [],
    };
  }

  toggleResetTreePopup() {
    this.treesPage.resetTreeIsShown = !this.treesPage.resetTreeIsShown;
  }

  async deleteSplitCondition(treeId: number, treeName: string, nodeId: number) {
    this.treesPage.deletingSplitCondition = true;

    await superagent
      .delete(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/nodes/${nodeId}`
      )
      .query({ treeName: treeName || treeId })
      .catch((err) => (this.treesPage.deletingSplitConditionError = err));
    this.treesPage.activeDeleteCondition = false;
    await this.loadTree(treeId);
    await this.cleanEnsemblingOverridesValidation();

    this.treesPage.deletingSplitCondition = false;
    this.resetSplitCondition();
  }

  updateSplitCondition(splitCondition: SqlForm) {
    this.treesPage.currentSplitCondition = splitCondition;
  }

  toggleAdvancedModePopup() {
    this.treesPage.advancedModeIsShown = !this.treesPage.advancedModeIsShown;
  }

  toggleHistoryPopup() {
    this.treesPage.historyIsShown = !this.treesPage.historyIsShown;
  }

  async getTreeHistory(treeId: number) {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/history`
      )
      .then(
        (treeHistory) =>
          (this.treesPage.treeHistory = treeHistory.body.map(
            (item: TreeHistory) => {
              const { sha, commitMessage, author, date } = item;
              return new TreeHistory(sha, commitMessage, author, date);
            }
          ))
      )
      .catch((e) => (this.treesPage.treeError = e.response.body.message));
  }

  async getCommitContent(treeId: number, sha: string) {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}/ref/${sha}`
      )
      .then((res) => {
        this.treesPage.commitContent = res.text;
        this.treesPage.isCommitDisplayLoading = false;
      })
      .catch((e) => (this.treesPage.treeError = e.response.body.message));
  }

  async updateTreeDefinition(
    treeId: number,
    treeName: string,
    body?: string
  ): Promise<void> {
    this.treesPage.treeError = null;
    this.treesPage.updatingTree = true;

    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/trees/${treeId}`)
      .query({ treeName: treeName || treeId })
      .send({
        tree: body || this.treesPage.trees[treeId - 1].definition.yaml,
      })
      .catch((e) => {
        this.treesPage.treeError = e.response.body.message;
      });
    await this.loadTree(treeId);
    this.treesPage.updatingTree = false;
    await this.loadTree(treeId);
    await this.cleanEnsemblingOverridesValidation();
  }

  async loadSmoothingConfig() {
    this.smoothingPage.loadingSmoothingConfig = true;
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.modelId}/smoothing`)
      .then((smoothing) => {
        this.smoothingPage.smoothing = smoothing.body;
      })
      .catch((e) => {
        this.smoothingPage.smoothingConfigError = e.message.error;
      });
    this.smoothingPage.loadingSmoothingConfig = false;
  }

  async saveSmoothingConfig() {
    this.smoothingPage.savingSmoothingConfig = true;
    this.smoothingPage.smoothingConfigSaved = false;
    this.smoothingPage.smoothingConfigError = null;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/smoothing`)
      .send(this.smoothingPage.smoothing)
      .catch((e) => {
        this.smoothingPage.smoothingConfigError = e.response.body.message;
      });
    this.smoothingPage.savingSmoothingConfig = false;
    await this.cleanEnsemblingOverridesValidation();
  }

  async loadEnsemblingPerformances(options: { isProcessing: boolean }) {
    const { isProcessing } = options;
    await this.ensemblingPage.loadPerformance(this.tenant, this.modelId, {
      modelType: this.activeModel.type,
      isProcessing,
    });
    this.cleanPostEnsembling();
  }

  async autoEnsembling({ isProcessing }: { isProcessing: boolean }) {
    await this.ensemblingPage.auto(this.tenant, this.modelId, {
      modelType: this.activeModel.type,
      isProcessing,
    });
    await this.cleanPostEnsembling();
  }

  async updateOverrideRules(newRules: Rule[]) {
    this.overridesPage.rules = newRules;
    this.overridesPage.saved = false;
    this.overridesPage.hasJustRearranged = false;
  }

  rearrangeRules() {
    this.overridesPage.hasJustRearranged = true;
  }

  async saveOverrideRules() {
    await this.overridesPage.save({
      tenant: this.tenant,
      modelId: this.modelId,
    });
    await this.cleanPostValidation();
  }

  async loadValidationData(options: { isProcessing: boolean }) {
    const { isProcessing } = options;
    this.validationPage.validation.error = null;
    try {
      const response = await superagent
        .put(`/api/tenant/${this.tenant}/model/${this.modelId}/validate`)
        .query({ isProcessing });
      this.performanceCheckPage = new PerformanceCheckPageModel();
      this.validationPage.validation.performances = response.body.performances;
      this.validationPage.validation.smoothingResults =
        response.body.smoothingResults;
    } catch (e) {
      this.validationPage.validation.error = e.response.body.message;
    }
  }

  async loadOverrideEnsembleData() {
    this.overridesPage.loadingEnsemble = true;
    this.overridesPage.ensemble.error = null;
    this.overridesPage.ensemble.performances = null;
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/ensembling/override`
      )
      .then((ensembling) => {
        const { body } = ensembling;

        this.overridesPage.ensemble.performances = new EnsemblePerformance(
          body.weights,
          body.thresholds,
          body.performances
        );
        this.overridesPage.ensemble.thresholds = body.thresholds;
        this.overridesPage.ensemble.weights = body.weights;
      })
      .catch((e) => {
        this.overridesPage.ensemble.error = e.response.body.message;
      });

    this.overridesPage.loadingEnsemble = false;
  }

  async getSpotCheckLeads(falseNegatives: boolean) {
    this.spotCheckPage.loadingSpotCheck = true;
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.modelId}/leads_sample`)
      .query({ falseNegatives })
      .then((res) => {
        const leads: CustomerFitSampleLead[] = res.body;
        const { type: modelType } = this.activeModel;
        if (modelType === 'customer_fit') {
          this.spotCheckPage.leadsSpotCheck = leads.map(
            (lead: CustomerFitSampleLead) => {
              const newLead = new CustomerFitSampleLead(
                lead.email,
                lead.score,
                lead.segment,
                lead.signals,
                lead.hasConverted,
                lead.employees,
                lead.amountRaised,
                lead.marketCap,
                lead.isFortune500Company,
                lead.country,
                lead.predictiveTraffic,
                lead.industry,
                lead.hasEnterpriseTechnology,
                lead.tree1Node,
                lead.tree2Node,
                lead.tree3Node
              );
              return newLead;
            }
          );
        } else {
          this.spotCheckPage.leadsSpotCheck = leads.map((lead: any) => {
            const newLead = new BehavioralSampleLead(
              lead.email,
              lead.score,
              lead.segment,
              lead.signals,
              lead.hasConverted,
              lead.domain
            );
            return newLead;
          });
        }
      })
      .catch((e) => {
        this.spotCheckPage.loadSpotCheckError = e.response.body.message;
        this.spotCheckPage.leadsSpotCheck = null;
      });
    this.spotCheckPage.loadingSpotCheck = false;
  }

  async loadPerformanceCheck() {
    this.performanceCheckPage.loadingPerformanceCheckData = true;
    this.performanceCheckPage.performanceCheckData = null;
    await superagent
      .get(`/api/tenant/${this.tenant}/model/${this.modelId}/performance_check`)
      .then((response) => {
        this.performanceCheckPage.performanceCheckData = response.body;
      })
      .catch((err) => {
        this.performanceCheckPage.performanceCheckError =
          err.response.body.message;
      });
    this.performanceCheckPage.loadingPerformanceCheckData = false;
  }

  async getLastCommit(folder: ModelFolder, mode: DeployMode) {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${
          this.activeModel.modelId
        }/last_commit/${mode}?folder=${encodeURIComponent(folder)}`
      )
      .then(
        (response) => (this.deployPage.lastCommits[folder] = response.body)
      );
  }

  async deployModel(folders: ModelFolder[], mode: DeployMode) {
    this.deployPage.isDeploying = true;
    this.deployPage.modelIsDeployed = false;
    this.deployPage.deploymentError = null;

    try {
      await superagent
        .post(`/api/tenant/${this.tenant}/model/${this.modelId}/deploy/${mode}`)
        .send({ folders });

      await this.refreshModel();
    } catch (e) {
      this.deployPage.deploymentError = e.response.body.message;
    } finally {
      await this.updateDeployStatus();
    }
  }

  async updateDeployStatus() {
    const response = await superagent.get(
      `/api/tenant/${this.tenant}/model/${this.modelId}/deploy/status`
    );

    if (response.body.status === 'OK') {
      this.deployPage.isDeploying = false;
      this.deployPage.modelIsDeployed = true;
      this.deployPage.deployPopupIsShown = false;
    } else if (response.body.status === 'ON_GOING') {
      this.deployPage.isDeploying = true;
      this.deployPage.deployPopupIsShown = true;
      this.deployPage.modelIsDeployed = false;
    } else if (response.body.status === 'NOT_OK') {
      this.deployPage.isDeploying = false;
      this.deployPage.deployPopupIsShown = false;
    }
  }

  allowNewModelDeploy() {
    this.deployPage.modelIsDeployed = false;
  }

  async loadSignals() {
    this.signalsPage.loadingSignals = true;

    try {
      const { data: signals } = await axios.get<Signals>(
        `/api/tenant/${this.tenant}/model/${this.modelId}/signals`
      );
      this.signalsPage.signals = new Signals({
        customerFitSignals: signals.customerFitSignals,
        ltbSignals: signals.ltbSignals,
        signalsV2: signals.signalsV2,
      });
    } catch (e) {
      this.signalsPage.signalsError = e;
    }

    this.signalsPage.loadingSignals = false;
  }

  async saveSignals() {
    this.signalsPage.savingSignals = true;
    this.signalsPage.signalsSaved = false;

    try {
      await axios.put(
        `/api/tenant/${this.tenant}/model/${this.modelId}/signals`,
        this.signalsPage.signals
      );

      this.signalsPage.signalsSaved = true;
      this.signalsPage.signalsError = null;
    } catch (e) {
      this.signalsPage.signalsError = e.response?.data ?? {
        message: 'Unknown error.',
        type: 'other',
        status: 500,
      };
    }

    this.signalsPage.savingSignals = false;
    this.spotCheckPage.leadsSpotCheck = null;
  }

  async cleanEnsemblingOverridesValidation() {
    await this.ensemblingPage.loadConfiguration(this.tenant, this.modelId);
    this.cleanPostEnsembling();
  }

  cleanPostEnsembling() {
    this.overridesPage.rules = [];
    this.cleanPostValidation();
  }

  cleanPostValidation() {
    this.validationPage = new ValidationPageModel({
      validation: new Validation(null, null, null),
    });
    this.performanceCheckPage = new PerformanceCheckPageModel();
    this.spotCheckPage = new SpotCheckPageModel();
    this.leadGradePage = new LeadGradePageModel();
  }

  async fetchFormFields() {
    if (!this.formFields) {
      await superagent
        .get(`/api/tenant/${this.tenant}/model/${this.modelId}/formfields`)
        .then((res) => {
          const formFields: Subject[] = res.body;
          this.formFields = formFields;
        });
    }
  }

  async getComputations() {
    await superagent
      .get(`/api/tenant/${this.tenant}/computations`)
      .then((result) => {
        this.computationsPage.computations = result.body.computations;
        this.computationsPage.computationsInProgress =
          result.body.computationsInProgress;
      });
  }

  async getMetadataFieldsForComputations() {
    const listOfPromises = [];
    listOfPromises.push(this.getMetadataFields('account_computations_custom'));
    listOfPromises.push(this.getMetadataFields('email_computations_custom'));
    if (this.computationsPage.computations?.length === 0) {
      await this.getComputations();
    }
    const computations: MetadataField[] =
      this.computationsPage.computations.map((computation) => {
        return {
          name: computation.name,
          type: computation.type,
        }; // transform to MetadataField DS
      });
    // get the metadata fields and merge with computations
    const resultedFields = (await Promise.all(listOfPromises))
      .flat()
      .concat(computations)
      .map((field) => {
        const newField = field;
        const isFieldTypeStringOrNumber: boolean = [
          'string',
          'number',
        ].includes(field.type);
        newField.type = isFieldTypeStringOrNumber ? field.type : 'attribute';
        return newField;
      });
    // remove duplications if there is any
    const resultedNoDuplicatedFields = uniqBy(
      resultedFields,
      (field: MetadataField) => {
        return field.name;
      }
    );
    // sort by name
    const sortedFieldsByNames = orderBy(
      resultedNoDuplicatedFields,
      (field: MetadataField) => {
        return field.name;
      }
    );
    this.computationsPage.metadataFields = sortedFieldsByNames;
  }

  async getMetadataFields(type: string): Promise<MetadataField[]> {
    return superagent
      .get(`/api/tenant/${this.tenant}/metadata_fields/type/${type}`)
      .then((result) => {
        return result.body as MetadataField[];
      });
  }

  async initComputations() {
    await superagent
      .post(`/api/tenant/${this.tenant}/computations/init`)
      .catch((e) => {
        this.computationsPage.loadingError = e;
      });
    this.computationsPage.computationsInProgress = false;
  }

  async deleteComputationsCache() {
    await superagent.post(
      `/api/tenant/${this.tenant}/computations/delete_cache`
    );
    this.computationsPage.computationsInProgress = false;
  }

  async saveComputationsArgo() {
    await superagent.post(`/api/tenant/${this.tenant}/computations/save_argo`);
  }

  async getAggregatedEvents() {
    this.aggregatedEventsPage.loading = true;

    await superagent
      .get(`/api/tenant/${this.tenant}/aggregated_events`)
      .then((res) => {
        this.aggregatedEventsPage.loading = false;
        this.aggregatedEventsPage.aggregatedEvents = res.body || [];
      })
      .catch((e) => {
        this.aggregatedEventsPage.loading = false;
        this.aggregatedEventsPage.error = e.message;
      });
  }

  setChosenAggregation(aggregationName: string) {
    this.aggregatedEventsPage.chosenAggregatedEvents =
      this.aggregatedEventsPage.aggregatedEvents.filter(
        (aggregatedEvent) => aggregatedEvent.aggregationName === aggregationName
      );
  }

  async saveAggregatedEvents() {
    this.aggregatedEventsPage.saving = true;
    this.aggregatedEventsPage.error = null;

    await superagent
      .post(`/api/tenant/${this.tenant}/aggregated_events`)
      .send({
        aggregations: this.behavioralAggregationsPage.aggregations,
        aggregatedEvents: this.aggregatedEventsPage.aggregatedEvents,
      })
      .then(() => {
        this.aggregatedEventsPage.saving = false;
        this.aggregatedEventsPage.saved = true;
      })
      .catch((e) => {
        this.aggregatedEventsPage.saving = false;
        this.aggregatedEventsPage.error = e.message;
      });
  }

  async createFrequencyAnalysis(
    activeAggregation: BehavioralAggregation,
    model: ModelItem
  ) {
    this.aggregatedEventsPage.loading = true;
    this.aggregatedEventsPage.error = null;
    this.aggregatedEventsPage.frequencyAnalysis = [];

    await superagent
      .post(
        `/api/tenant/${this.tenant}/model/${model.modelId}/aggregated_events/frequency_analysis`
      )
      .send({ activeAggregation, modelType: model.type })
      .then((res) => {
        this.aggregatedEventsPage.loading = false;
        this.aggregatedEventsPage.frequencyAnalysis = res.body || [];
      })
      .catch((e) => {
        this.aggregatedEventsPage.loading = false;
        this.aggregatedEventsPage.error = e.message;
      });
  }

  async releaseAggregations() {
    await superagent.post(`/api/tenant/${this.tenant}/release_aggregations`);
  }

  async loadFeatureEvaluation() {
    this.featureEvaluationPage.load(this.tenant, this.modelId);
  }

  async saveFeatureEvaluation() {
    this.resetModels();
    this.featureEvaluationPage.save(this.tenant, this.modelId);
  }

  async resetFeatureEvaluation() {
    this.resetModels();
    this.featureEvaluationPage.reset(this.tenant, this.modelId);
  }

  async getLeadGradeConfiguration() {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/lead_grade/configuration`
      )
      .then((leadGrade) => {
        this.leadGradePage.configuration = leadGrade.body;
      })
      .catch((e) => {
        this.leadGradePage.error = e.response.body.message;
      });
  }

  async getLeadGradeHelpMatrixes() {
    await superagent
      .get(
        `/api/tenant/${this.tenant}/model/${this.modelId}/lead_grade/matrixes`
      )
      .then((matrixes) => {
        this.leadGradePage.helpMatrixes = matrixes.body;
      })
      .catch((e) => {
        this.leadGradePage.error = e.response.body.message;
      });
  }

  async calculateLeadGradePerformance(options: { isProcessing: boolean }) {
    const { isProcessing } = options;
    this.leadGradePage.loadingPerformance = true;
    this.leadGradePage.performances = null;
    this.leadGradePage.error = null;

    const { configuration } = this.leadGradePage;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/lead_grade`)
      .query({ isProcessing })
      .send(configuration)
      .then((leadGrade) => {
        this.leadGradePage.performances = leadGrade.body;
      })
      .catch((e) => {
        this.leadGradePage.error = e.response.body.message;
      });
    this.leadGradePage.loadingPerformance = false;
  }

  // ModelBase Functions
  async saveModelBase(base: ModelBaseNames) {
    this.modelBasePage.isSaving = true;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/modelBase/${base}`)
      .then(async () => {
        this.activeModel.base = base;
        this.modelBasePage.isSaving = false;
      });
  }

  async getPointBasedRules() {
    if (!this.groupsPointBasedRules) {
      await superagent
        .get(`/api/tenant/${this.tenant}/model/${this.modelId}/pointBasedRules`)
        .then((result) => {
          this.groupsPointBasedRules = result.body.map((element: any) => {
            return new GroupPointBasedRulesDataModel(
              element.number,
              element.rules
            );
          });
        });
    }
  }

  async getFormFieldsAndPointBasedRules() {
    this.pointBasedScoringRulePage.isLoading = true;
    await this.fetchFormFields();
    await this.getPointBasedRules();
    this.pointBasedScoringRulePage.isLoading = false;
  }

  async saveGroupsPointBasedRules() {
    this.pointBasedScoringRulePage.isSaving = true;
    this.pointBasedScoringRulePage.isError = false;
    this.pointBasedScoringRulePage.isSuccess = false;
    await superagent
      .put(`/api/tenant/${this.tenant}/model/${this.modelId}/pointBasedRules`)
      .send(this.groupsPointBasedRules)
      .then(() => {
        this.pointBasedScoringRulePage.isSaving = false;
        this.pointBasedScoringRulePage.isSuccess = true;
        this.pointBasedScoringRulePage.isError = false;
      })
      .catch((error) => {
        this.pointBasedScoringRulePage.isSaving = false;
        this.pointBasedScoringRulePage.isSuccess = false;
        this.pointBasedScoringRulePage.isError = true;
        this.pointBasedScoringRulePage.errorMessage = JSON.stringify(
          error.message
        );
      });
  }

  async loadEventMetadata() {
    try {
      const { data } = await axios.get<EventMetadata>(
        `/api/tenant/${this.tenant}/events_metadata`
      );
      this.eventMetadata = data;
    } catch (error) {
      // do nothing
    }
  }

  analyticsTrackEvent(eventName: string) {
    const { type, modelId } = this.activeModel;

    window.analytics.track(eventName, {
      tenant: this.tenant,
      email: this.email,
      model_type: type,
      model_id: modelId,
    });
  }

  isUserAuthorizedForTenant(tenant: string | number) {
    if (this.isSuperKudu) return true;

    const userTenantPermissions = this.userPermissions.map(
      ({ tenant }) => tenant
    );
    return userTenantPermissions.includes(Number(tenant));
  }
}
