import React from 'react';
import { Alert } from 'react-bootstrap';
import { HttpExceptionType } from '../../../models/errors/HttpExceptionType';
import { inject, observer } from 'mobx-react';
import Store from '../../../store';
import ErrorMessageContent from './ErrorMessageContent';

interface ErrorProps {
  store?: Store;
  title?: string;
  type?: HttpExceptionType;
  message: string;
}

function Error({ type, store, title, message }: ErrorProps) {
  // It's possible that the component is called with a null message or empty
  if (!message?.length) {
    return null;
  }
  const { tenant, modelId } = store;
  return (
    <Alert variant="danger">
      <h4>{title ?? 'An error has occurred'}</h4>
      <ErrorMessageContent
        tenant={tenant}
        message={message}
        modelId={modelId}
        type={type}
      />
    </Alert>
  );
}

export default inject('store')(observer(Error));
