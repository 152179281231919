import { inject, observer } from 'mobx-react';
import React from 'react';
import Store from '../../../store';
import { ModelPerformanceGraphsContext } from '../../../utils';
import ModelPerformanceGraphs from '../ModelPerformanceGraphs';
import ModelSummaryParameters from '../ModelSummaryParameters';
import { RulePageModel } from '../../../models/rules';

interface RulesPerformanceProps {
  store?: Store;
  context: ModelPerformanceGraphsContext;
  isTreeBased: boolean;
  overridesPage: RulePageModel;
}

export default inject('store')(
  observer(
    class RulesPerformance extends React.Component<
      RulesPerformanceProps,
      unknown
    > {
      render() {
        const { context, isTreeBased, overridesPage } = this.props;

        return (
          <>
            {context === 'customer_fit' && (
              <ModelSummaryParameters
                weights={overridesPage?.ensemble?.weights}
                thresholds={overridesPage?.ensemble?.thresholds}
                isTreeBased={isTreeBased}
              />
            )}
            {overridesPage?.ensemble?.performances?.performances && (
              <ModelPerformanceGraphs
                performances={
                  overridesPage?.ensemble?.performances?.performances
                }
                context={context}
              />
            )}
          </>
        );
      }
    }
  )
);
