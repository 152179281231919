import { inject, observer } from 'mobx-react';
import queryString from 'querystring';
import React from 'react';
import { Button, FormCheck, Card } from 'react-bootstrap';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import Store from '../../../store';
import Error from '../../components/error/Error';
import Table from '../../components/generic_components/Table';
import Spinner from '../../components/Spinner';
import { CustomerFitSampleLead } from '../../../models/SampleLead';

const COLUMN_VISIBILITY_BAG = 'nodeLeadsColumnVisibility';
const DEFAULT_COLUMN_VISIBILITY = {
  employees: false,
  amountRaised: false,
  marketCap: false,
  isFortune500Company: false,
  country: false,
  predictiveTraffic: false,
  industry: false,
  hasEnterpriseTechnology: false,
};

type MatchParams = {
  tenant: string;
  modelId: string;
  treeId: string;
  nodeId: string;
  target: string;
};

interface NodeLeadsPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface NodeLeadsPageState {
  columnVisibility: Partial<Record<keyof CustomerFitSampleLead, boolean>>;
}

const NodeLeadsPage = inject('store')(
  observer(
    class NodeLeadsPage extends React.Component<
      NodeLeadsPageProps,
      NodeLeadsPageState
    > {
      constructor(props: NodeLeadsPageProps) {
        super(props);
        this.reloadLeads = this.reloadLeads.bind(this);
        this.checkUnivariateAnalysisWithPersonal =
          this.checkUnivariateAnalysisWithPersonal.bind(this);

        this.state = {
          columnVisibility: DEFAULT_COLUMN_VISIBILITY,
        };
      }

      async componentDidMount() {
        const savedColumnVisibility =
          localStorage.getItem(COLUMN_VISIBILITY_BAG) ?? null;
        if (savedColumnVisibility) {
          this.setState({
            columnVisibility: JSON.parse(savedColumnVisibility),
          });
        }

        this.updateIncludePersonalFromQueryString(this.props.location.search);
        const { treeId, nodeId, target } = this.props.match.params;
        await this.props.store.getNodeLeads(
          Number(treeId),
          Number(nodeId),
          Number(target)
        );
      }

      async reloadLeads() {
        const { treeId, nodeId, target } = this.props.match.params;
        await this.props.store.getNodeLeads(
          Number(treeId),
          Number(nodeId),
          Number(target)
        );
      }

      async checkUnivariateAnalysisWithPersonal() {
        this.props.store.checkNodeUnivariateAnalysisWithPersonal();

        const { univariateAnalysisWithPersonal } = this.props.store.nodePage;
        await this.reloadLeads();

        const search = queryString.parse(this.props.location.search);
        const newQueryString = {
          ...search,
          includePersonal: univariateAnalysisWithPersonal,
        };
        const newUrl = `${this.props.location.pathname}?${queryString.stringify(
          newQueryString
        )}`;

        this.props.history.push(newUrl);
      }

      updateIncludePersonalFromQueryString(search: string) {
        const parsedSearch = queryString.parse(search);
        this.props.store.nodePage.univariateAnalysisWithPersonal =
          parsedSearch.includePersonal === 'true';
      }

      render() {
        const { tenant, modelId, treeId, nodeId, target } =
          this.props.match.params;
        const { nodePage } = this.props.store;
        const converted = target === '1' ? 'converted' : "didn't convert";

        const columnHelper = createColumnHelper<CustomerFitSampleLead>();

        const columns: ColumnDef<CustomerFitSampleLead, unknown>[] = [
          columnHelper.accessor('email', {
            cell: (info) => info.getValue(),
            header: 'Email',
          }),
          columnHelper.accessor('employees', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Employees',
          }),
          columnHelper.accessor('amountRaised', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Amount raised',
          }),
          columnHelper.accessor('marketCap', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Market cap',
          }),
          {
            accessorFn: ({ isFortune500Company }: CustomerFitSampleLead) =>
              isFortune500Company ? 'yes' : 'no',
            id: 'isFortune500Company',
            header: 'Fortune 500 company',
            cell: (info) => info.getValue(),
          },
          columnHelper.accessor('country', {
            cell: (info) => info.getValue(),
            header: 'Country',
          }),
          columnHelper.accessor('predictiveTraffic', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Predicted traffic',
          }),
          columnHelper.accessor('industry', {
            cell: (info) => info.getValue(),
            header: 'Industry',
          }),
          {
            accessorFn: ({ hasEnterpriseTechnology }: CustomerFitSampleLead) =>
              hasEnterpriseTechnology ? 'yes' : 'no',
            id: 'hasEnterpriseTechnology',
            header: 'Has entreprise technology',
            cell: (info) => info.getValue(),
          },
          {
            accessorFn: ({ hasConverted }) => (hasConverted ? 'yes' : 'no'),
            id: 'hasConverted',
            header: 'Has converted',
            cell: (info) => info.getValue(),
          },
        ];

        const columnVisibility = this.state.columnVisibility;

        return (
          <div className="mt-3 mb-5">
            {nodePage.loadLeadsError && (
              <Error message={nodePage.loadLeadsError} />
            )}
            <Link
              className="d-block mb-3"
              to={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}?includePersonal=${nodePage.univariateAnalysisWithPersonal}`}
            >
              Back to tree configuration
            </Link>
            <Card className="mb-3">
              <Card.Body>
                <h3>
                  Sample of leads who {converted} for tree {treeId} - node{' '}
                  {nodeId}
                </h3>
              </Card.Body>
            </Card>

            {!nodePage.loadingLeads ? (
              <Card>
                <Card.Body>
                  <FormCheck
                    checked={nodePage.univariateAnalysisWithPersonal}
                    onChange={this.checkUnivariateAnalysisWithPersonal}
                    label={'Include "is_personal" values'}
                  />
                  <Table
                    data={nodePage.leads}
                    columns={columns}
                    defaultColumnVisibility={columnVisibility}
                    columnVisibilityBag={COLUMN_VISIBILITY_BAG}
                    additionalButtons={
                      <Button onClick={this.reloadLeads}>Reload leads</Button>
                    }
                  />
                </Card.Body>
              </Card>
            ) : (
              <Spinner />
            )}
          </div>
        );
      }
    }
  )
);

export default withRouter(NodeLeadsPage);
