import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import Store from '../../../store';
import Error from '../../components/error/Error';
import Table from '../../components/generic_components/Table';
import classNames from 'classnames';
import Spinner from '../../components/Spinner';
import {
  BehavioralSampleLead,
  CustomerFitSampleLead,
} from '../../../models/SampleLead';

const COLUMN_VISIBILITY_BAG = 'spotCheckColumnVisibility';
const DEFAULT_COLUMN_VISIBILITY = {
  employees: false,
  amountRaised: false,
  marketCap: false,
  isFortune500Company: false,
  country: false,
  predictiveTraffic: false,
  industry: false,
  hasEnterpriseTechnology: false,
};

type Lead = CustomerFitSampleLead | BehavioralSampleLead;

interface SpotCheckPageProps extends RouteComponentProps {
  store?: Store;
}

interface SpotCheckPageState {
  falseNegativesFormCheck: boolean;
  isLoading: boolean;
  columnVisibility: Partial<Record<keyof CustomerFitSampleLead, boolean>>;
}

const SpotCheckPage = inject('store')(
  observer(
    class SpotCheckPage extends React.Component<
      SpotCheckPageProps,
      SpotCheckPageState
    > {
      constructor(props: SpotCheckPageProps) {
        super(props);
        this.handleGetLeadsSample = this.handleGetLeadsSample.bind(this);
        this.handleFalseNegativesFormCheckChange =
          this.handleFalseNegativesFormCheckChange.bind(this);

        this.state = {
          falseNegativesFormCheck: false,
          isLoading: true,
          columnVisibility: DEFAULT_COLUMN_VISIBILITY,
        };
      }

      async componentDidMount() {
        const savedColumnVisibility =
          localStorage.getItem(COLUMN_VISIBILITY_BAG) ?? null;
        if (savedColumnVisibility) {
          this.setState({
            columnVisibility: JSON.parse(savedColumnVisibility),
          });
        }

        await this.props.store.loadValidationData({ isProcessing: true });
        await this.handleGetLeadsSample();
        this.setState({ isLoading: false });
      }

      async handleGetLeadsSample() {
        this.props.store.analyticsTrackEvent(
          'Requested a new dataset to spot check'
        );
        await this.props.store.getSpotCheckLeads(
          this.state.falseNegativesFormCheck
        );
      }

      handleFalseNegativesFormCheckChange() {
        this.setState(
          {
            falseNegativesFormCheck: !this.state.falseNegativesFormCheck,
          },
          async () => {
            await this.handleGetLeadsSample();
          }
        );
      }

      render() {
        const {
          tenant,
          validationPage,
          spotCheckPage,
          activeModel: { modelId, type },
        } = this.props.store;
        const { leadsSpotCheck, loadingSpotCheck, loadSpotCheckError } =
          spotCheckPage;
        const {
          validation: { error: validationError },
        } = validationPage;

        if (this.state.isLoading) {
          return <Spinner />;
        }

        const columnHelper = createColumnHelper<Lead>();

        const idColumn =
          type === 'mqa'
            ? columnHelper.accessor('domain', {
                cell: (info) => info.getValue(),
                header: 'Account',
              })
            : columnHelper.accessor('email', {
                cell: (info) => info.getValue(),
                header: 'Email',
              });

        const customerFitColumns: ColumnDef<Lead, unknown>[] = [
          columnHelper.accessor('tree1Node', {
            cell: (info) => {
              const value = info.getValue();
              return (
                <Link
                  to={`/tenant/${tenant}/models/${modelId}/model/trees/1/nodes/${value}`}
                >
                  Node {value}
                </Link>
              );
            },
            header: 'Tree 1',
          }),
          columnHelper.accessor('tree2Node', {
            cell: (info) => {
              const value = info.getValue();
              return (
                <Link
                  to={`/tenant/${tenant}/models/${modelId}/model/trees/2/nodes/${value}`}
                >
                  Node {value}
                </Link>
              );
            },
            header: 'Tree 2',
          }),
          columnHelper.accessor('tree3Node', {
            cell: (info) => {
              const value = info.getValue();
              return (
                <Link
                  to={`/tenant/${tenant}/models/${modelId}/model/trees/3/nodes/${value}`}
                >
                  Node {value}
                </Link>
              );
            },
            header: 'Tree 3',
          }),
          columnHelper.accessor('employees', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Employees',
          }),
          columnHelper.accessor('amountRaised', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Amount raised',
          }),
          columnHelper.accessor('marketCap', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Market cap',
          }),
          {
            accessorFn: ({ isFortune500Company }: CustomerFitSampleLead) =>
              isFortune500Company ? 'yes' : 'no',
            id: 'isFortune500Company',
            header: 'Fortune 500 company',
            cell: (info) => info.getValue(),
          },
          columnHelper.accessor('country', {
            cell: (info) => info.getValue(),
            header: 'Country',
          }),
          columnHelper.accessor('predictiveTraffic', {
            cell: (info) =>
              info.getValue() && Number(info.getValue()).toLocaleString(),
            header: 'Predicted traffic',
          }),
          columnHelper.accessor('industry', {
            cell: (info) => info.getValue(),
            header: 'Industry',
          }),
          {
            accessorFn: ({ hasEnterpriseTechnology }: CustomerFitSampleLead) =>
              hasEnterpriseTechnology ? 'yes' : 'no',
            id: 'hasEnterpriseTechnology',
            header: 'Has entreprise technology',
            cell: (info) => info.getValue(),
          },
        ];

        const columns: ColumnDef<Lead, unknown>[] = [
          idColumn,
          columnHelper.accessor('score', {
            cell: (info) => info.getValue(),
            header: 'Score',
          }),
          columnHelper.accessor('segment', {
            cell: (info) => info.getValue(),
            header: 'Segment',
          }),
          columnHelper.accessor('signals', {
            cell: (info) => (
              <ul className="list-unstyled">
                {info
                  .getValue()
                  .replaceAll('\\n', '\n')
                  .split('\n')
                  .map((signal) => (
                    <li key={signal}>{signal}</li>
                  ))}
              </ul>
            ),
            header: 'Signals',
          }),
          {
            accessorFn: ({ hasConverted }) => (hasConverted ? 'yes' : 'no'),
            id: 'hasConverted',
            header: 'Has converted',
            cell: (info) => info.getValue(),
          },
        ];
        if (type === 'customer_fit') columns.push(...customerFitColumns);

        const columnVisibility = this.state.columnVisibility;

        return (
          <div className="mt-3 mb-5">
            {(validationError || loadSpotCheckError) && (
              <Error message={validationError ?? loadSpotCheckError} />
            )}
            {leadsSpotCheck && (
              <Card>
                <Card.Body>
                  <p>
                    Here is a list of 100 random leads, from the validation
                    dataset, scored with this model to let you review the output
                    of the model.
                  </p>
                  <Form.Check
                    type="checkbox"
                    id="falseNegativesFormCheck"
                    label="View only low/medium leads who converted (false negative)"
                    name="falseNegativesFormCheck"
                    onChange={this.handleFalseNegativesFormCheckChange}
                    checked={this.state.falseNegativesFormCheck}
                  />
                  <Table
                    data={leadsSpotCheck as Lead[]}
                    columns={columns}
                    defaultColumnVisibility={
                      type === 'customer_fit' ? columnVisibility : null
                    }
                    columnVisibilityBag={
                      type === 'customer_fit' ? COLUMN_VISIBILITY_BAG : null
                    }
                    additionalButtons={
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="reload-spot-check">New sample</Tooltip>
                        }
                      >
                        <Button
                          className="ms-auto"
                          onClick={this.handleGetLeadsSample}
                          disabled={loadingSpotCheck}
                        >
                          <i
                            className={classNames('fas fa-redo', {
                              'fa-spin': loadingSpotCheck,
                            })}
                          ></i>
                        </Button>
                      </OverlayTrigger>
                    }
                  />
                </Card.Body>
              </Card>
            )}
          </div>
        );
      }
    }
  )
);

export default withRouter(SpotCheckPage);
