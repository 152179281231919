import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import Store from '../../store';

type AdminPageProps = {
  store?: Store;
  main?: React.Component;
};

type AdminPageState = {
  modelId: number;
  cleaning: boolean;
};

const AdminPage = inject('store')(
  observer(
    class AdminPage extends React.Component<AdminPageProps, AdminPageState> {
      constructor(props: AdminPageProps) {
        super(props);

        this.state = {
          cleaning: false,
          modelId: null,
        };

        this.cleanBullCache = this.cleanBullCache.bind(this);
        this.cleanModelCache = this.cleanModelCache.bind(this);
        this.cleanSessionCache = this.cleanSessionCache.bind(this);
        this.cleanWholeCache = this.cleanWholeCache.bind(this);
        this.cleanComputationsCache = this.cleanComputationsCache.bind(this);
        this.onPickModel = this.onPickModel.bind(this);
      }

      async cleanModelCache() {
        this.setState({ cleaning: true });
        await this.props.store.cleanCacheForModel(this.state.modelId);
        this.setState({ cleaning: false });
      }

      async cleanWholeCache() {
        this.setState({ cleaning: true });
        await this.props.store.cleanWholeCache();
        this.setState({ cleaning: false });
      }

      async cleanSessionCache() {
        this.setState({ cleaning: true });
        await this.props.store.cleanSessionsCache();
        this.setState({ cleaning: false });
      }

      async cleanBullCache() {
        this.setState({ cleaning: true });
        await this.props.store.cleanBullCache();
        this.setState({ cleaning: false });
      }

      async cleanComputationsCache() {
        this.setState({ cleaning: true });
        await this.props.store.cleanComputationsCache();
        this.setState({ cleaning: false });
      }

      onPickModel(e: any) {
        this.setState({ modelId: e.target.value });
      }

      render() {
        const { tenantModels } = this.props.store;
        const { cleaning, modelId } = this.state;
        return (
          <React.Fragment>
            <Row style={{ marginTop: 20 }}>
              <Col sm={2}>
                <p>Pick a model to clean its cache:</p>
              </Col>
              <Col sm={2}>
                <Form.Select value={modelId} onChange={this.onPickModel}>
                  <option value={null} disabled selected>
                    Select model
                  </option>
                  {tenantModels.map(({ modelId: id }) => (
                    <option key={id}>{id}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={2}>
                <Button
                  disabled={modelId === null || cleaning}
                  onClick={this.cleanModelCache}
                >
                  {cleaning ? 'Cleaning...' : 'Clean'}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col sm={2}>
                <p>Clean cache for every tenant and model</p>
              </Col>
              <Col sm={2}>
                <Button disabled={cleaning} onClick={this.cleanWholeCache}>
                  {cleaning ? 'Cleaning...' : 'Clean'}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col sm={2}>
                <p>Clean sessions cache</p>
              </Col>
              <Col sm={2}>
                <Button disabled={cleaning} onClick={this.cleanSessionCache}>
                  {cleaning ? 'Cleaning...' : 'Clean'}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col sm={2}>
                <p>Clean bull cache</p>
              </Col>
              <Col sm={2}>
                <Button disabled={cleaning} onClick={this.cleanBullCache}>
                  {cleaning ? 'Cleaning...' : 'Clean'}
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col sm={2}>
                <p>Clean computations cache for all tenants</p>
              </Col>
              <Col sm={2}>
                <Button
                  disabled={cleaning}
                  onClick={this.cleanComputationsCache}
                >
                  {cleaning ? 'Cleaning...' : 'Clean'}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
    }
  )
);

export default AdminPage;
