import { makeObservable, observable } from 'mobx';
import {
  AggregationsType,
  BehavioralAggregation,
} from './behavioral_aggregations';

export type AggregatedEvent = {
  name: string;
  released: boolean;
  aggregationName?: string;
  occurrences?: number;
  type?: AggregationsType;
  usedInLiveModel?: boolean;
  isProductEvent: boolean;
};

export type FrequencyAnalysis = {
  aggregated_event_display: string;
  number_of_occurences_of_event_last_x_days: number;
  percent_of_people_who_did_action: number;
  phi: number;
  prob_conversion_if_not_this: string;
  prob_conversion_if_this: string;
};

export class AggregatedEventsPageModel {
  aggregatedEvents: AggregatedEvent[];

  chosenAggregatedEvents: AggregatedEvent[];

  activeAggregation: BehavioralAggregation;

  frequencyAnalysis: FrequencyAnalysis[];

  loading: boolean;

  modalSaveActive: boolean;

  saving: boolean;

  error: string;

  saved: boolean;

  constructor(other?: AggregatedEventsPageModel) {
    if (other) {
      this.aggregatedEvents = other.aggregatedEvents;
      this.activeAggregation = other.activeAggregation;
      this.frequencyAnalysis = other.frequencyAnalysis;
      this.chosenAggregatedEvents = other.chosenAggregatedEvents;
      this.loading = other.loading;
      this.modalSaveActive = other.modalSaveActive;
      this.saving = other.saving;
      this.error = other.error;
      this.saved = other.saved;
    } else {
      this.aggregatedEvents = [];
      this.activeAggregation = {
        id: null,
        type: null,
        logic: null,
        lifetime: null,
        name: null,
        released: false,
        isProductEvent: false,
        conditions: { rules: [] },
        conditionLogic: '$1 AND $2',
      };
      this.frequencyAnalysis = [];
      this.chosenAggregatedEvents = [];
      this.loading = false;
      this.modalSaveActive = false;
      this.saving = false;
      this.error = null;
      this.saved = false;
    }
    makeObservable(this, {
      aggregatedEvents: observable,
      activeAggregation: observable,
      frequencyAnalysis: observable,
      chosenAggregatedEvents: observable,
      loading: observable,
      modalSaveActive: observable,
      saving: observable,
      error: observable,
      saved: observable,
    });
  }
}
