import { makeObservable, observable } from 'mobx';
import DataSet from './DataSet';
import DataSetRecord from './DataSetRecord';
import DataSetType from './DataSetType';
import InputDataSet from './InputDataSet';

interface InputFileDataSetConstructorProps {
  file?: File;
  content?: string;
  name: string;
  table?: string;
  tenant: number;
  modelId: number;
  type: DataSetType;
}

export default class InputFileDataSet extends InputDataSet {
  file: File;

  content: string;

  constructor({
    file,
    content,
    modelId,
    name,
    tenant,
    table,
    type,
  }: InputFileDataSetConstructorProps) {
    super({ tenant, modelId, type, name, table });
    this.file = file;
    this.content = content;
    makeObservable(this, {
      file: observable,
      content: observable,
    });
  }

  async countRecordsInDataSet(): Promise<number> {
    const lines = this.content.split('\n').filter((line) => line.includes('@'));
    return Promise.resolve(lines.length);
  }

  async convertDataSet(): Promise<DataSet> {
    const lines = this.content.split(/\r\n|\n/g);
    // Always force header to lowercase
    const headers: string[] = lines[0]
      .split(',')
      .map((header) => header.toLowerCase());
    const records: DataSetRecord[] = lines
      .filter((line) => line.includes('@'))
      .map((line) => {
        const values = line.split(',');
        const initialValue: Record<string, string | number> = {};
        const fields = headers.reduce((map, header, i) => {
          // eslint-disable-next-line no-param-reassign
          map[header] = values[i].trim();
          return map;
        }, initialValue);
        fields.target = Number(fields.target);

        return new DataSetRecord(fields);
      });
    const size = await this.countRecordsInDataSet();
    return new DataSet(this.type, this.name, size, { records });
  }
}
