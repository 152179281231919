import { makeObservable, observable } from 'mobx';
import { SignalsValue } from './types';

export default class CustomerFitSignals {
  type: SignalsValue;

  defaultConfig: string;

  config?: string;

  constructor(type: SignalsValue, defaultConfig: string, config?: string) {
    this.type = type;
    this.defaultConfig = defaultConfig;
    this.config = config;
    makeObservable(this, {
      type: observable,
      defaultConfig: observable,
      config: observable,
    });
  }
}
