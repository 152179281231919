import React, { ReactElement } from 'react';
import { Spinner as BSSpinner } from 'react-bootstrap';

const Spinner = (): ReactElement => (
  <div className="d-flex justify-content-center">
    <BSSpinner animation="border" role="status" className="mt-5">
      <span className="visually-hidden">Loading...</span>
    </BSSpinner>
  </div>
);

export default Spinner;
