import { makeObservable, observable } from 'mobx';
import IHttpException from '../errors/IHttpException';
import DataSetMetadata from './DataSetMetadata';
import InputFileDataSet from './InputFileDataSet';
import InputTableDataSet from './InputTableDataSet';

export default class DataSets {
  tenant: number;

  modelId: number;

  selectedTab = 1;

  uploadDataSetError: IHttpException;

  dataSetMetadata: DataSetMetadata[];

  // DataSets
  inputFileDataSets: InputFileDataSet[];

  inputTableDataSets: InputTableDataSet[];

  constructor(other?: DataSets) {
    if (other) {
      this.selectedTab = other.selectedTab;
      this.dataSetMetadata = other.dataSetMetadata;

      this.uploadDataSetError = other.uploadDataSetError;

      // DataSets
      this.inputFileDataSets = other.inputFileDataSets;

      this.inputTableDataSets = other.inputTableDataSets;
    } else {
      this.selectedTab = 1;
      this.dataSetMetadata = null;

      this.uploadDataSetError = null;

      // DataSets
      this.inputFileDataSets = [
        new InputFileDataSet({
          modelId: this.modelId,
          tenant: this.tenant,
          name: 'Training Dataset',
          type: 'training',
        }),
        new InputFileDataSet({
          modelId: this.modelId,
          tenant: this.tenant,
          name: 'Validation Dataset',
          type: 'validation',
        }),
      ];

      this.inputTableDataSets = [
        new InputTableDataSet({
          tenant: this.tenant,
          modelId: this.modelId,
          name: 'Training Dataset',
          type: 'training',
        }),
        new InputTableDataSet({
          tenant: this.tenant,
          modelId: this.modelId,
          name: 'Valdiation Dataset',
          type: 'validation',
        }),
      ];
    }
    makeObservable(this, {
      selectedTab: observable,
      uploadDataSetError: observable,
      dataSetMetadata: observable,
      inputFileDataSets: observable,
      inputTableDataSets: observable,
    });
  }

  setSelectedTab(selectedTab: number) {
    this.selectedTab = selectedTab;
  }

  setTableValue(type: string, value: string) {
    const field = this.inputTableDataSets.find((d) => d.type === type);
    field.table = value;
  }
}
