import { inject, observer } from 'mobx-react';
import React from 'react';
import { Card, Form, FormCheck } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ModelBaseNames from '../../models/modelBase/ModelBaseNames';
import Store from '../../store';
import Spinner from '../components/Spinner';

interface ModelTypeChoicePageProps extends RouteComponentProps<any> {
  store?: Store;
}

interface ModelTypeChoicePageState {
  falseNegativesFormCheck: boolean;
}

const ModelBaseChoicePage = inject('store')(
  observer(
    class ModelBaseChoicePage extends React.Component<
      ModelTypeChoicePageProps,
      ModelTypeChoicePageState
    > {
      constructor(props: ModelTypeChoicePageProps) {
        super(props);

        this.handleBaseValueChange = this.handleBaseValueChange.bind(this);
      }

      async handleBaseValueChange(base: ModelBaseNames) {
        await this.props.store.saveModelBase(base);
      }

      renderBodyContent() {
        const { activeModel, modelBasePage, isAllowedToEdit } =
          this.props.store;
        return (
          <div className="d-flex justify-content-center">
            <Card border="light" className="mt-4 w-75">
              <Card.Body>
                <Card.Title className="mb-4">
                  Select the type of model to build
                </Card.Title>
                <Form>
                  <FormCheck
                    type="radio"
                    checked={activeModel.base === ModelBaseNames.points}
                    disabled={!isAllowedToEdit}
                    onChange={() =>
                      this.handleBaseValueChange(ModelBaseNames.points)
                    }
                    label={
                      <>
                        <p className="fw-bold">Rule-based</p>
                        <p>
                          A rule-based model uses a system of points associated
                          to rules. It is adapted when you have a good idea of
                          the rules to implement or when you don&apos;t have
                          enough data in a training dataset to create a
                          tree-based mode.
                        </p>
                      </>
                    }
                  />
                  <FormCheck
                    type="radio"
                    checked={activeModel.base === ModelBaseNames.trees}
                    disabled={!isAllowedToEdit}
                    onChange={() =>
                      this.handleBaseValueChange(ModelBaseNames.trees)
                    }
                    label={
                      <>
                        <p className="fw-bold">Tree-based</p>
                        <p>
                          A tree-based model uses decision trees to predict the
                          score of a record, based on historical conversions. It
                          is adapted to identify multiple ideal customer
                          profiles (by regions, company size...etc) because
                          taking into account the interaction between rules. It
                          should be used with a large enough training dataset (
                          {'>'} 1,000 records).
                        </p>
                      </>
                    }
                  />
                </Form>
              </Card.Body>
            </Card>
            {modelBasePage.isSaving && <Spinner />}
          </div>
        );
      }

      render() {
        const { store } = this.props;
        const { modelBasePage } = store;
        return (
          <>
            {modelBasePage.isLoading ? <Spinner /> : this.renderBodyContent()}
          </>
        );
      }
    }
  )
);

export default withRouter(ModelBaseChoicePage);
