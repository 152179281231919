import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Button,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Card,
  Row,
  Col,
  Badge,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Nav,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment-mini';

import ModelItem, { ModelType } from '../../models/ModelItem';
import { PROFILES_PERMISSIONS } from '../../permissions';
import Store from '../../store';
import checkPermissions from '../components/CheckPermissions';
import Error from '../components/error/Error';
import ModelStatus from '../components/ModelStatus';
import PageHeader from '../components/PageHeader';
import DuplicationModelModal from '../components/modals/DuplicationModelModal';
import partition from 'lodash/partition';
import Table from '../components/generic_components/Table';
import CustomToggle from '../components/generic_components/CustomToggle';
import { MODEL_TYPES_NAMES } from '../../utils/constants';

type OperationType = 'create' | 'edit';
const CREATE: OperationType = 'create';
const EDIT: OperationType = 'edit';

type MatchParams = {
  tenant: string;
};

interface TenantPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface TenantPageState {
  duplicateModelModalIsShown: boolean;
  updateModelNameModalIsShown: boolean;
  modelBeingUpdatedId: number;
  modelBeingUpdatedName: string;
  fromModel: number;
  newModelName: string;
  newModelType: ModelType;
  saving: boolean;
  showArchivedModels: boolean;
  activeModel?: ModelItem;
  showArchiveModal: boolean;
}

const TenantPage = inject('store')(
  observer(
    class TenantPage extends React.Component<TenantPageProps, TenantPageState> {
      constructor(props: TenantPageProps) {
        super(props);

        this.state = {
          duplicateModelModalIsShown: false,
          updateModelNameModalIsShown: false,
          modelBeingUpdatedId: null,
          modelBeingUpdatedName: '',
          fromModel: null,
          newModelName: '',
          newModelType: 'customer_fit',
          saving: false,
          showArchivedModels: false,
          showArchiveModal: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.openDuplicateModelModal = this.openDuplicateModelModal.bind(this);
        this.handleModelNameChange = this.handleModelNameChange.bind(this);
        this.handleModelTypeChange = this.handleModelTypeChange.bind(this);
        this.createNewModelAndRedirect =
          this.createNewModelAndRedirect.bind(this);
        this.updateModelName = this.updateModelName.bind(this);
        this.openUpdateModelNameModal =
          this.openUpdateModelNameModal.bind(this);
        this.toggleShowArchivedModels =
          this.toggleShowArchivedModels.bind(this);
        this.toggleArchiveModal = this.toggleArchiveModal.bind(this);
        this.handleArchiveButtonClick =
          this.handleArchiveButtonClick.bind(this);
      }

      async componentDidMount() {
        await this.props.store.getTenantModels();
      }

      closeModal() {
        this.setState({
          duplicateModelModalIsShown: false,
          updateModelNameModalIsShown: false,
          modelBeingUpdatedId: null,
          modelBeingUpdatedName: '',
          newModelName: '',
          saving: false,
        });
      }

      openDuplicateModelModal(fromModel: number) {
        this.setState({ fromModel, duplicateModelModalIsShown: true });
      }

      handleModelNameChange(newValue: string, operation: OperationType) {
        if (operation === CREATE) this.setState({ newModelName: newValue });
        if (operation === EDIT)
          this.setState({ modelBeingUpdatedName: newValue });
      }

      handleModelTypeChange(newValue: ModelType) {
        this.setState({ newModelType: newValue });
      }

      toggleShowArchivedModels() {
        this.setState({ showArchivedModels: !this.state.showArchivedModels });
      }

      async createNewModelAndRedirect() {
        this.setState({
          saving: true,
        });

        const { newModelName, newModelType } = this.state;
        const { tenant } = this.props.store;
        const newModelId: number = await this.props.store.rootPage.createModel(
          tenant,
          { name: newModelName, type: newModelType }
        );

        window.location.href = `/tenant/${this.props.match.params.tenant}/models/${newModelId}`;
      }

      openUpdateModelNameModal(modelId: number, modelName: string) {
        this.setState({
          updateModelNameModalIsShown: true,
          modelBeingUpdatedId: modelId,
          modelBeingUpdatedName: modelName,
        });
      }

      async updateModelName(modelId: number, name: string) {
        await this.props.store.updateModelName(modelId, name);
        window.location.reload();
      }

      async updateModelArchiveStatus(modelId: number, isArchived: boolean) {
        await this.props.store.updateModelArchiveStatus(modelId, isArchived);
      }

      toggleArchiveModal() {
        this.setState({ showArchiveModal: !this.state.showArchiveModal });
      }

      async handleArchiveButtonClick() {
        await this.updateModelArchiveStatus(
          this.state.activeModel.modelId,
          !this.state.activeModel.is_archived
        );
      }

      render() {
        const { tenantModels, rootPage } = this.props.store;
        const {
          createModelError,
          updateModelNameError,
          updateModelArchiveStatusError,
          updatingModelName,
          archivingModel,
        } = rootPage;
        const {
          duplicateModelModalIsShown,
          updateModelNameModalIsShown,
          modelBeingUpdatedId,
          modelBeingUpdatedName,
          fromModel,
          showArchivedModels,
        } = this.state;

        const isEditedModelNameValid = modelBeingUpdatedName.trim().length > 0;

        const [liveModels, draftModels] = partition(
          tenantModels,
          ({ live }) => live
        );

        const theDraftModels = !showArchivedModels
          ? draftModels.filter(({ is_archived }) => !is_archived)
          : draftModels;

        const { tenant } = this.props.match.params;

        const columnHelper = createColumnHelper<ModelItem>();

        const columns: ColumnDef<ModelItem, unknown>[] = [
          columnHelper.accessor('modelId', {
            cell: (info) => info.getValue(),
            header: 'Model ID',
          }),
          {
            accessorFn: ({ type }) => MODEL_TYPES_NAMES[type],
            id: 'type',
            header: 'Model Type',
            cell: (info) => info.getValue(),
          },
          columnHelper.accessor('name', {
            cell: (info) => {
              const value = info.getValue();
              const { modelId } = info.row.original;
              return (
                <a href={`/tenant/${tenant}/models/${modelId}`}>{value}</a>
              );
            },
            header: 'Model Name',
          }),
          {
            accessorFn: (row): string => {
              const scoringTypes = [];
              if (row.realtime) scoringTypes.push('real-time');
              if (row.batch) scoringTypes.push('batch');

              return scoringTypes.join(' and ') || '-';
            },
            id: 'scoringType',
            header: 'Scoring Type',
            cell: (info) => {
              const value = info.getValue() as string;

              return (
                <>
                  {value.includes('real-time') && (
                    <Badge bg="secondary" className="ms-1 me-1">
                      Real-time
                    </Badge>
                  )}
                  {value.includes('batch') && (
                    <Badge bg="secondary" className="ms-1 me-1">
                      Batch
                    </Badge>
                  )}
                </>
              );
            },
            sortingFn: (rowA, rowB) => {
              const getScore = (row: ModelItem) => {
                let score = 0;
                if (row.batch) score += 2;
                if (row.realtime) score += 1;

                return score;
              };

              const scoreA = getScore(rowA.original);
              const scoreB = getScore(rowB.original);

              return scoreA - scoreB;
            },
            filterFn: ({ original: { realtime, batch } }, _, filterValue) => {
              if (filterValue === 'real-time') return realtime;
              if (filterValue === 'batch') return batch;
              if (filterValue === 'real-time and batch')
                return realtime && batch;

              return !realtime && !batch;
            },
          },
          {
            accessorFn: (row): string => {
              if (row.live) return 'live';
              if (row.is_archived) return 'archived';

              return '-';
            },
            id: 'status',
            header: 'Status',
            cell: (info) => {
              const value = info.getValue();

              if (value === 'live')
                return <ModelStatus model={info.row.original} />;

              if (value === 'archived')
                return <Badge bg="secondary">Archived</Badge>;

              return null;
            },
            sortingFn: (rowA, rowB) => {
              const getScore = (row: ModelItem) => {
                let score = 0;
                if (row.live) score += 2;
                if (row.is_archived) score += 1;

                return score;
              };

              const scoreA = getScore(rowA.original);
              const scoreB = getScore(rowB.original);

              return scoreA - scoreB;
            },
            filterFn: ({ original: { live, is_archived } }, _, filterValue) => {
              if (filterValue === 'live') return live;
              if (filterValue === 'archived') return is_archived;

              return !live && !is_archived;
            },
          },
          {
            accessorFn: (row) =>
              // Data discovery dag keeps updating models when they are live.
              // As a workaround we show last deployed at date for live models.
              row.live ? row.lastDeployedAt : row.updatedAt,
            cell: (info) => moment(info.getValue()).format('ll'),
            id: 'updatedAt',
            header: 'Updated at',
            enableColumnFilter: false,
          },
          {
            accessorFn: (row) => {
              return checkPermissions(
                PROFILES_PERMISSIONS.ARCHITECT,
                <td>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <OverlayTrigger
                        trigger="hover"
                        placement="left"
                        defaultShow={row.live}
                        overlay={
                          <Tooltip id="rename-action">
                            Live models cannot be renamed
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          onClick={() => {
                            this.openUpdateModelNameModal(
                              row.modelId,
                              row.name
                            );
                          }}
                          disabled={row.live}
                          style={{ pointerEvents: 'visible' }}
                        >
                          Rename
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <Dropdown.Item
                        onClick={() => {
                          this.openDuplicateModelModal(row.modelId);
                        }}
                      >
                        Duplicate
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <OverlayTrigger
                        trigger="hover"
                        placement="left"
                        defaultShow={row.live}
                        overlay={
                          <Tooltip id="archive-action">
                            Live models cannot be archived
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          onClick={() => {
                            this.setState({
                              activeModel: row,
                            });
                            this.toggleArchiveModal();
                          }}
                          disabled={row.live}
                          style={{ pointerEvents: 'visible' }}
                        >
                          {row.is_archived ? 'Un-archive' : 'Archive'}
                        </Dropdown.Item>
                      </OverlayTrigger>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              );
            },
            id: 'actions',
            enableSorting: false,
            header: 'Actions',
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
        ];

        const liveColumns: ColumnDef<ModelItem, unknown>[] = columns.map(
          (column) => ({ ...column, enableColumnFilter: false })
        );

        const updateModelNameModal = (
          <Modal onHide={this.closeModal} show={updateModelNameModalIsShown}>
            <Modal.Header>
              <Modal.Title>Rename model</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <FormGroup>
                  <FormLabel className="fw-bold">Model name</FormLabel>
                  <FormControl
                    type="text"
                    value={modelBeingUpdatedName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleModelNameChange(event.target.value, EDIT)
                    }
                  />
                </FormGroup>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={this.closeModal}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await this.updateModelName(
                    modelBeingUpdatedId,
                    modelBeingUpdatedName
                  );
                }}
                disabled={updatingModelName || !isEditedModelNameValid}
              >
                {updatingModelName ? 'Saving...' : 'Save'}
              </Button>
            </Modal.Footer>
          </Modal>
        );

        return (
          <>
            {updateModelNameModal}
            <DuplicationModelModal
              fromModel={fromModel}
              close={this.closeModal}
              isShown={duplicateModelModalIsShown}
            />
            <PageHeader>
              <Row>
                <Col>
                  <h2>Models</h2>
                </Col>
                <Col className="text-end">
                  {checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <Nav.Link as={NavLink} to={`/tenant/${tenant}/models/new`}>
                      <Button variant="primary">
                        <i className="fas fa-plus" /> Create new model
                      </Button>
                    </Nav.Link>
                  )}
                </Col>
              </Row>
            </PageHeader>
            <div className="mt-3 mb-5">
              {createModelError && <Error message={createModelError} />}
              {updateModelNameError && <Error message={updateModelNameError} />}
              {updateModelArchiveStatusError && (
                <Error message={updateModelArchiveStatusError} />
              )}

              {!!liveModels.length && (
                <>
                  <h3 className="mt-4">My live models</h3>
                  <Card>
                    <Card.Body>
                      <Table data={liveModels} columns={liveColumns} />
                    </Card.Body>
                  </Card>
                </>
              )}

              {!!theDraftModels.length && (
                <>
                  <Row className="mt-4">
                    <Col>
                      <h3>My draft models</h3>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Form.Check
                        id="switch-archived"
                        type="switch"
                        label="Show archived models"
                        checked={showArchivedModels}
                        onChange={this.toggleShowArchivedModels}
                      />
                    </Col>
                  </Row>

                  <Card>
                    <Card.Body>
                      <Table
                        data={theDraftModels}
                        columns={columns}
                        defaultSorting={[{ id: 'updatedAt', desc: true }]}
                        defaultPageSize={50}
                      />
                    </Card.Body>
                  </Card>
                </>
              )}
            </div>

            <Modal
              show={this.state.showArchiveModal}
              onHide={this.toggleArchiveModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state?.activeModel?.is_archived
                    ? 'Un-archive'
                    : 'Archive'}{' '}
                  model
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to{' '}
                {this.state?.activeModel?.is_archived
                  ? 'un-archive'
                  : 'archive'}{' '}
                this model?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.toggleArchiveModal}>
                  Close
                </Button>
                <Button
                  disabled={archivingModel}
                  variant="primary"
                  onClick={this.handleArchiveButtonClick}
                >
                  {this.state?.activeModel?.is_archived
                    ? 'Un-archive'
                    : 'Archive'}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);

export default withRouter(TenantPage);
