import React, { useState } from 'react';
import { SingleDatePicker as ASingleDatePicker } from 'react-dates';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface SingleDatePickerProps {
  date: string;
  minDate?: string;
  maxDate?: string;
  onDateChange: (date: moment.Moment) => void;
  onDelete?: () => void;
}

const SingleDatePicker = ({
  date,
  minDate,
  maxDate,
  onDateChange,
  onDelete,
}: SingleDatePickerProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="d-inline-block position-relative">
      <ASingleDatePicker
        date={moment(date)}
        id="date"
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat="LL"
        isOutsideRange={(date) => {
          const isBefore = minDate ? date.isBefore(moment(minDate)) : false;
          const isAfter = maxDate ? date.isAfter(moment(maxDate)) : false;

          return isBefore || isAfter;
        }}
        small
        onDateChange={onDateChange}
        numberOfMonths={1}
      />
      {onDelete && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip">Delete date</Tooltip>}
        >
          <i
            aria-hidden
            className="fas fa-trash fa-xs position-absolute"
            style={{ top: '5px', right: '5px', cursor: 'pointer' }}
            onClick={onDelete}
          />
        </OverlayTrigger>
      )}
    </div>
  );
};

export default SingleDatePicker;
