import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import {
  Alert,
  Button,
  Col,
  Card,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import DataSetType from '../../../../models/audience/DataSetType';
import InputFileDataSet from '../../../../models/audience/InputFileDataSet';
import Store from '../../../../store';
import { getDatasetParameter } from '../../../../utils';
import Error from '../../../components/error/Error';
import checkPermissions from '../../../components/CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../../permissions';
import Spinner from '../../../components/Spinner';
import { MAX_NUMBER_OF_LOADING_DATASETS } from '../../../../utils/constants';

interface AudiencePageProps extends RouteComponentProps {
  store?: Store;
}

interface AudiencePageState {
  loading: boolean;
  currentLoadingModels: number[];
}

const AudiencePage = inject('store')(
  observer(
    class AudiencePage extends React.Component<
      AudiencePageProps,
      AudiencePageState
    > {
      errorMessageRef: React.RefObject<HTMLDivElement>;

      constructor(props: AudiencePageProps) {
        super(props);

        this.state = { loading: true, currentLoadingModels: [] };

        makeObservable(this, {
          handleDataSetChange: action,
          handleOnClickDownload: action,
        });

        this.handleDataSetChange = this.handleDataSetChange.bind(this);
        this.uploadDataSetsFiles = this.uploadDataSetsFiles.bind(this);
        this.handleOnClickDownload = this.handleOnClickDownload.bind(this);

        this.errorMessageRef = React.createRef();
      }

      async componentDidMount() {
        const { tenant } = this.props.store;
        const [currentLoadingModels] = await Promise.all([
          this.props.store.mkAudiencePage.getCurrentLoadingModels(tenant),
          this.props.store.fetchForCsvFile(),
        ]);

        this.setState({ loading: false, currentLoadingModels });
      }

      componentDidUpdate(): void {
        const {
          csvPage: { dataSets },
        } = this.props.store;

        if (dataSets.uploadDataSetError) {
          this.errorMessageRef.current.scrollIntoView();
        }
      }

      async uploadDataSetsFiles() {
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        await this.props.store.uploadDataSetsFiles(dataset);
        const { tenant, modelId, csvPage } = this.props.store;
        if (!csvPage.dataSets.uploadDataSetError) {
          this.props.history.push(
            `/tenant/${tenant}/models/${modelId}/overview`
          );
        }
      }

      handleDataSetChange(event: ChangeEvent<HTMLInputElement>, type: string) {
        const field = this.props.store.csvPage.dataSets.inputFileDataSets.find(
          (d) => d.type === type
        );
        field.file = event.target.files[0];
      }

      async handleOnClickDownload() {
        const { tenant, modelId } = this.props.store;
        await this.props.store.csvPage.downloadCsvFile(tenant, modelId);
      }

      renderAlert() {
        const {
          isDownloadedSuccessfully,
          isOldFileFound,
          isErrorOccurred,
          isDownloading,
        } = this.props.store.csvPage;
        if (isOldFileFound) {
          if (isDownloadedSuccessfully) {
            return (
              <Alert variant="success">
                The actual training dataset has been downloaded successfully.
              </Alert>
            );
          }
          if (isErrorOccurred) {
            return (
              <Alert variant="danger">
                Something went wrong while downloading your file.
              </Alert>
            );
          }
          return (
            <Alert variant="warning">
              <Row>
                <Col xs={9}>
                  A training dataset file has been found, click here to
                  download.
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    disabled={isDownloading}
                    onClick={this.handleOnClickDownload}
                  >
                    {isDownloading ? 'Downloading...' : 'Download'}
                  </Button>
                </Col>
              </Row>
            </Alert>
          );
        }
        return <Alert variant="warning">No old csv file found.</Alert>;
      }

      render() {
        const { csvPage, isAllowedToEdit, tenant, modelId } = this.props.store;
        const { loading, currentLoadingModels } = this.state;
        const { dataSets } = csvPage;
        const dataset: DataSetType = getDatasetParameter(
          this.props.location.search
        );
        const dataSetsValid = dataSets.inputFileDataSets.find(
          (t) => t.type === (dataset || 'training')
        ).file;

        const getFieldInput = (set: InputFileDataSet) => (
          <Form.Group
            as={Row}
            controlId={`field_file_${set.type}`}
            key={set.type}
          >
            <Col sm="auto">
              <Form.Label className="pt-1 fw-bold">File:</Form.Label>
            </Col>
            <Col sm={4}>
              <Form.Control
                type="file"
                disabled={!isAllowedToEdit}
                accept=".csv"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.handleDataSetChange(e, set.type)
                }
              />
            </Col>
          </Form.Group>
        );

        const cantLoad =
          currentLoadingModels.length >= MAX_NUMBER_OF_LOADING_DATASETS;

        if (loading) {
          return <Spinner />;
        }

        return (
          <>
            <LinkContainer
              to={`/tenant/${tenant}/models/${modelId}/overview`}
              activeClassName=""
            >
              <Button variant="link" className="me-2">
                Go back to Overview
              </Button>
            </LinkContainer>
            <div className="w-75 mt-4 mx-auto d-grid gap-4">
              <h4>Edit model datasets</h4>
              {this.renderAlert()}
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {dataset
                      ? String(dataset).charAt(0).toUpperCase() +
                        dataset.slice(1)
                      : 'Training '}
                    dataset
                  </Card.Title>
                  <p>
                    The <b>training dataset</b> must contain two columns:
                    ‘email’ and ‘target’.
                  </p>
                  <ul>
                    <li>
                      email: contains the email of the record. If using a list
                      of domains append “madkudu@” to the domains.
                    </li>
                    <li>
                      target: value of 1 if the record has converted, 0
                      otherwise
                    </li>
                  </ul>
                  {dataSets.uploadDataSetError && (
                    <div ref={this.errorMessageRef}>
                      <Error
                        message={dataSets.uploadDataSetError.message}
                        type={dataSets.uploadDataSetError.type}
                      />
                    </div>
                  )}
                  {getFieldInput(
                    dataSets.inputFileDataSets.find(
                      (t) => t.type === (dataset || 'training')
                    )
                  )}
                </Card.Body>
              </Card>

              {!dataset && (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title className="fw-bold">
                      Validation dataset
                    </Card.Title>
                    <p>
                      The <b>validation dataset</b> must contain the following
                      columns
                    </p>
                    <ul>
                      <li>
                        email: contains the email of the record. If using a list
                        of domains append “madkudu@” to the domains.
                      </li>
                      <li>
                        target: value of 1 if the record has converted, 0
                        otherwise
                      </li>
                      <li>
                        amount: if the record has converted, value of the
                        opportunity
                      </li>
                      <li>
                        target_closed_won: Values 1 or 0 to identify if the
                        records converted into a Closed Won opportunity
                      </li>
                      <li>
                        amount_closed_won: if converted into a Closed Won
                        opportunity, the amount value of the deal
                      </li>
                    </ul>
                    <p>
                      This will allow evaluating the impact of the model on the
                      revenue pipeline
                    </p>
                    {getFieldInput(
                      dataSets.inputFileDataSets.find(
                        (t) => t.type === 'validation'
                      )
                    )}
                  </Card.Body>
                </Card>
              )}
              <div className="mx-auto mb-4">
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/import_data`}
                  activeClassName=""
                >
                  <Button variant="outline-primary" className="me-2">
                    Cancel
                  </Button>
                </LinkContainer>

                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,
                  <OverlayTrigger
                    show={cantLoad}
                    overlay={
                      <Tooltip>
                        {`Model ${currentLoadingModels[0]} and ${currentLoadingModels[1]} are currently loading. Please wait until they both load to begin loading another model`}
                      </Tooltip>
                    }
                  >
                    <span>
                      <Button
                        variant="primary"
                        disabled={
                          !dataSetsValid || !isAllowedToEdit || cantLoad
                        }
                        onClick={this.uploadDataSetsFiles}
                      >
                        Save audience
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </>
        );
      }
    }
  )
);

export default withRouter(AudiencePage);
