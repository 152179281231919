import React from 'react';
import { inject, observer } from 'mobx-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  Col,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  ComputationCondition,
  DirectedComputationCondition,
  DirectedComputationConditionGroup,
} from '../../../../../models/computations/Computation';
import Store from '../../../../../store';
import DirectedCondition from './DirectedCondition';

interface DirectedConditionGroupProps {
  store?: Store;
  directedConditionGroup: DirectedComputationConditionGroup;
  directedConditionGroupIndex: number;
}

const DirectedConditionGroup = inject('store')(
  observer(function DirectedConditionGroup({
    store,
    directedConditionGroup,
    directedConditionGroupIndex,
  }: DirectedConditionGroupProps) {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id: directedConditionGroup._id });

    const activeComputation = store.computationsPage.activeComputation;

    const readonly = activeComputation.context === 'standard';

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    const handleTargetComputationChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      directedConditionGroup.targetComputation = event.target.value;
    };

    const handleOnClickRemoveDirectedConditionsGroup = () => {
      activeComputation.configuration.modeProperties.conditional.conditions.splice(
        directedConditionGroupIndex,
        1
      );
    };

    const handleAddDirectedCondition = (direction?: 'AND' | 'OR') => {
      const newComputationCondition: ComputationCondition = {
        field: store.computationsPage.metadataFields[0]?.name || '',
        verb: 'is',
        values: [''],
        isLower: false,
      };
      const newDirectedComputationCondition: DirectedComputationCondition = {
        condition: newComputationCondition,
        direction,
      };
      directedConditionGroup.directedConditions.push(
        newDirectedComputationCondition
      );
    };

    const direction =
      directedConditionGroup.directedConditions.length > 1
        ? directedConditionGroup.directedConditions[1].direction
        : null;

    return (
      <div
        className="p-1"
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <Row className="gx-1">
          <Col xs={1} className="pt-2">
            <span className="fw-bold">IF</span>
          </Col>
          <Col xs={7}>
            {directedConditionGroup.directedConditions.map(
              (directedCondition, directedConditionIndex) => (
                <DirectedCondition
                  key={directedConditionIndex}
                  directedConditionGroup={directedConditionGroup}
                  directedCondition={directedCondition}
                  directedConditionIndex={directedConditionIndex}
                />
              )
            )}
          </Col>
          <Col xs={4}>
            <span className="fw-bold me-3">THEN</span>
            <FormControl
              type={
                activeComputation.type === 'string'
                  ? 'text'
                  : activeComputation.type
              }
              value={directedConditionGroup.targetComputation}
              onChange={handleTargetComputationChange}
              className="d-inline-block w-50"
              placeholder="value"
              disabled={readonly}
            />
            {directedConditionGroupIndex !== 0 && (
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip">Remove the IF condition</Tooltip>
                }
              >
                <Button
                  onClick={handleOnClickRemoveDirectedConditionsGroup}
                  disabled={readonly}
                  className="ms-3"
                  variant="danger"
                >
                  <i aria-hidden className="fas fa-trash" />
                </Button>
              </OverlayTrigger>
            )}
          </Col>
        </Row>

        {!readonly && (
          <Row>
            <Col xs={{ span: 3, offset: direction ? 1 : 0 }}>
              {direction === 'OR' || (
                <Button
                  variant="outline-primary"
                  className="me-3"
                  onClick={() => handleAddDirectedCondition('AND')}
                >
                  + AND
                </Button>
              )}
              {direction === 'AND' || (
                <Button
                  variant="outline-primary"
                  onClick={() => handleAddDirectedCondition('OR')}
                >
                  + OR
                </Button>
              )}
            </Col>
          </Row>
        )}
        <hr />
      </div>
    );
  })
);

export default DirectedConditionGroup;
