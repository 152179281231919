/*
TODO

- Add ADMIN role
- ADMIN: [PERMISSIONS.USER, PERMISSIONS.ADMIN]
- Add ADMIN condition in isAllowed BEFORE user condition to ensure `true` is returned before next role checks
*/

import { Permission, Role } from './models/Permissions';
import { UserModel } from './models/User';

const ROLES: { [key: string]: Role } = {
  USER: 'user',
  ADMIN: 'admin',
  SUPERKUDU: 'superKudu',
  ARCHITECT: 'architect',
};

// roles allowed per profile
export const PROFILES_PERMISSIONS: { [key: string]: Role[] } = {
  USER: [ROLES.USER, ROLES.SUPERKUDU, ROLES.ADMIN, ROLES.ARCHITECT],
  ARCHITECT: [ROLES.ARCHITECT, ROLES.SUPERKUDU, ROLES.ADMIN],
  SUPERKUDU: [ROLES.SUPERKUDU],
};

export function shouldAllowForTenant(
  tenant: number,
  userPermissions: Permission[],
  allowedRoles: Role[]
): boolean {
  return !!userPermissions.find((permission) => {
    return (
      Number(permission.tenant) === Number(tenant) &&
      allowedRoles.includes(permission.role)
    );
  });
}

export function extractAllowedTenantsOfUser(user: UserModel): number[] {
  return user.permissions.map((permission) => {
    return permission.tenant;
  });
}

export function checkIsArchitect(
  tenant: number,
  userPermissions: Permission[]
): boolean {
  return !!userPermissions.find((permission) => {
    return (
      permission.tenant === tenant &&
      (permission.role === 'architect' || permission.role === 'admin')
    );
  });
}
