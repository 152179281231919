import { ModelType } from '../../models/ModelItem';

export const labelsColors = {
  cyan: '#58BFCD',
  pink: '#D26580',
};

export type Model = {
  name: string;
  model: string;
  type: ModelType;
  color: keyof typeof labelsColors;
  description: string;
  output?: string;
  more_description: string;
  useCases: { title: string; text: string; icon: string }[];
  requirements: string;
  illus: React.ReactNode;
};
