import { makeObservable, observable } from 'mobx';
import DeployMode from './deploy/DeployMode';
import ModelBaseNames from './modelBase/ModelBaseNames';

export type ModelFolder =
  | 'analysis/account_fit'
  | 'analysis/customer_fit'
  // | 'analysis/customer_fit/diagnosis'
  | 'realtime/customer_fit'
  | 'realtime/customer_fit/diagnosis'
  | 'analysis/behavioral_pql'
  | 'analysis/behavioral_pql/evaluation'
  | 'analysis/behavioral_mqa'
  | 'analysis/behavioral_mqa/evaluation'
  | 'analysis/lead_grade'
  | 'analysis/lead_grade/evaluation';
export type ExportFolder = 'export_contacts' | 'export_accounts';

export type CustomerFitModelType = 'customer_fit';

/**
 * Note: Historical Lead LTB (`pql`) = actual PQL (`pql2`) + MQL (`mql`)
 */
export type LeadBehavioralModelType = 'pql' | 'pql2' | 'mql';
export type BehavioralModelType = LeadBehavioralModelType | 'mqa';
export type ModelType = CustomerFitModelType | BehavioralModelType;

export type ModelBuildType =
  | 'hubspot'
  | 'salesforce'
  | 'marketo'
  | 'ltb'
  | 'mk_audience'
  | 'csv_file'
  | 'from_redshift_tables';

// number(score here) is the priority, the highest is the first to get generated
export const MODEL_FOLDERS_SCORES: Record<ModelFolder, number> = {
  'analysis/account_fit': 0,
  'analysis/customer_fit': 0,
  // 'analysis/customer_fit/diagnosis': 0,
  'realtime/customer_fit': 0,
  'realtime/customer_fit/diagnosis': 0,
  'analysis/behavioral_pql': 0,
  'analysis/behavioral_pql/evaluation': 0,
  'analysis/behavioral_mqa': 0,
  'analysis/behavioral_mqa/evaluation': 0,
  'analysis/lead_grade': 2,
  'analysis/lead_grade/evaluation': 1,
};

/**
 * Sort the folders following their scores defined in the const <MODEL_FOLDERS_SCORES>
 * @param folders: ModelFolder[]
 * @return ModelFolder[]
 */
export function sortFoldersByPriority(folders: ModelFolder[]): ModelFolder[] {
  //DESC SORT
  return folders.sort(
    (folderA, folderB) =>
      MODEL_FOLDERS_SCORES[folderB] - MODEL_FOLDERS_SCORES[folderA]
  );
}

interface GetModelFilePathParams {
  tenant: number;
  modelType: ModelType;
  modelId: number;
  folder: ModelFolder;
  mode: DeployMode;
}

export function getModelFilePath({
  tenant,
  modelType,
  modelId,
  folder,
  mode,
}: GetModelFilePathParams): string {
  let fileName = `${tenant}.yaml`;
  if (modelType === 'customer_fit') {
    fileName = `${tenant}${mode !== 'standard' ? `_${modelId}` : ''}.yaml`;
  }

  return `${folder}/${fileName}`;
}

export function getModelFolders(
  model: ModelItem,
  options: { batchOnly?: true; forDeployment?: true } = {}
): ModelFolder[] {
  const { type } = model;
  const folders: ModelFolder[] = [];
  switch (type) {
    case 'customer_fit':
      if (model.realtime && !options.batchOnly) {
        folders.push(
          'realtime/customer_fit',
          'realtime/customer_fit/diagnosis'
        );
      }
      if (model.batch) {
        if (!options.forDeployment) {
          folders.push('analysis/account_fit');
        }
        folders.push(
          'analysis/customer_fit'
          // 'analysis/customer_fit/diagnosis'
        );
      }
      return folders;
    case 'pql':
    case 'pql2':
    case 'mql':
      folders.push('analysis/behavioral_pql');
      folders.push('analysis/behavioral_pql/evaluation');
      folders.push('analysis/lead_grade');
      folders.push('analysis/lead_grade/evaluation');
      return folders;
    case 'mqa':
      folders.push('analysis/behavioral_mqa');
      folders.push('analysis/behavioral_mqa/evaluation');
      return folders;
    default:
      return folders;
  }
}

export function getExportFolders(): ExportFolder[] {
  return ['export_contacts', 'export_accounts'];
}

export function getModelMode(model: ModelItem): DeployMode {
  if (model.is_primary) return 'standard';
  if (model.live) return 'multi';
  return null;
}

export function transformModelName(modelName: string) {
  const lowerCaseModelName = modelName.toLowerCase();
  const noSpecialCharacters = lowerCaseModelName.replace(/[^a-zA-Z0-9]/g, '_');
  const noNumbers = noSpecialCharacters.replace(/[0-9]/g, 'x');
  // encode string to be sure that there is nothing tricky left
  const encoded = encodeURI(noNumbers);
  return encoded;
}

export default class ModelItem {
  modelId?: number;

  name: string;

  updatedAt?: string;

  createdAt?: string;

  lastDeployedAt?: string;

  lastDeployedBy?: string;

  notes?: string;

  realtime: boolean;

  batch: boolean;

  live: boolean;

  type: ModelType;

  is_primary: boolean;

  base: ModelBaseNames;

  is_archived?: boolean;

  duplicateOf?: number;

  buildType?: ModelBuildType;

  constructor(
    modelId: number,
    name: string,
    updatedAt: string,
    createdAt: string,
    lastDeployedAt: string,
    lastDeployedBy: string,
    notes: string,
    realtime: boolean,
    batch: boolean,
    live: boolean,
    type: ModelType,
    is_primary: boolean,
    base: ModelBaseNames,
    is_archived: boolean,
    duplicateOf: number,
    buildType: ModelBuildType
  ) {
    this.modelId = modelId;
    this.name = name;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.lastDeployedAt = lastDeployedAt;
    this.lastDeployedBy = lastDeployedBy;
    this.notes = notes;
    this.realtime = realtime;
    this.batch = batch;
    this.live = live;
    this.type = type;
    this.is_primary = is_primary;
    this.base = base;
    this.is_archived = is_archived;
    this.duplicateOf = duplicateOf;
    this.buildType = buildType;

    makeObservable(this, {
      modelId: observable,
      name: observable,
      updatedAt: observable,
      createdAt: observable,
      lastDeployedAt: observable,
      lastDeployedBy: observable,
      notes: observable,
      realtime: observable,
      batch: observable,
      live: observable,
      type: observable,
      is_primary: observable,
      base: observable,
      is_archived: observable,
      duplicateOf: observable,
      buildType: observable,
    });
  }
}
