import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { Accordion, Alert, Button, FormControl } from 'react-bootstrap';

import checkPermissions from '../../CheckPermissions';
import LegacySignals from './LegacySignals';
import { PROFILES_PERMISSIONS } from '../../../../permissions';
import SignalsRules from './SignalsRules';
import Store from '../../../../store';
import { parseYamlSignalsToUi, signalGroupPlaceHolder } from './utils';
import { action } from 'mobx';

interface SignalsGroupComponentProps {
  store?: Store;
  saveSignals: () => void;
}

function SignalsGroupComponent({
  store,
  saveSignals,
}: SignalsGroupComponentProps) {
  const { signalsPage, fetchFormFields, formFields, isAllowedToEdit } = store;
  const { savingSignals, signals, isErrorOnForm } = signalsPage;
  const { customerFitSignals, signalsV2 } = signals;

  const [signalsParsingError, setSignalsParsingError] = useState(false);

  const displaySignals = action(() => {
    if (customerFitSignals && !signalsV2) {
      try {
        const signalGroups = parseYamlSignalsToUi(customerFitSignals);

        signals.signalsV2 = signalGroups;

        setSignalsParsingError(false);
      } catch (_e) {
        setSignalsParsingError(true);
      }
    }
  });

  useEffect(() => {
    const asyncActions = async () => {
      if (!formFields) {
        await fetchFormFields();
      }
      displaySignals();
    };

    asyncActions();
  }, []);

  useEffect(() => displaySignals(), [customerFitSignals]);

  if (signalsParsingError) {
    return (
      <>
        <Alert variant="danger">
          Error parsing signals through the UI. Falling back to advanced signals
          mode
        </Alert>
        <LegacySignals saveSignals={saveSignals} />
      </>
    );
  }

  if (!signalsV2) return null;

  return (
    <>
      <Accordion className="mb-3">
        {signalsV2.map((signalGroup, signalGroupIndex) => {
          return (
            <Accordion.Item
              eventKey={`signal_group_${signalGroupIndex}`}
              key={`signal_group_${signalGroupIndex}`}
            >
              <Accordion.Header>
                <FormControl
                  className="d-inline-block w-25"
                  type="text"
                  disabled={!isAllowedToEdit || savingSignals}
                  value={signalGroup.groupName}
                  placeholder="Signal group name"
                  onChange={action((e) => {
                    signalsV2[signalGroupIndex].groupName = e.target.value;
                  })}
                />
              </Accordion.Header>
              <Accordion.Body>
                <SignalsRules signalGroupIndex={signalGroupIndex} />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {checkPermissions(
        PROFILES_PERMISSIONS.ARCHITECT,
        <div className="mt-3 mb-4">
          <Button
            variant="primary"
            className="me-1"
            onClick={action(() => {
              const signalGroup = signalGroupPlaceHolder(formFields);
              signalsV2.push(signalGroup);
            })}
            disabled={!isAllowedToEdit || savingSignals}
          >
            Add signal group
          </Button>
          <Button
            variant="success"
            onClick={saveSignals}
            disabled={!isAllowedToEdit || savingSignals || isErrorOnForm}
          >
            {savingSignals ? 'Saving...' : 'Save & test'}
          </Button>
        </div>
      )}
    </>
  );
}

export default inject('store')(observer(SignalsGroupComponent));
