import { action, makeObservable, observable } from 'mobx';
import { Rule } from '../rules';
import { Subject } from '../sqlform';
import axios from 'axios';
import { AggregatedEvent } from './aggregated_events';

export type BehavioralAggregation = {
  id: string;
  type: AggregationsType;
  logic: string;
  name: string;
  conditions: { rules: Rule[] };
  conditionLogic: string;
  lifetime: string;
  released: boolean;
  isProductEvent: boolean;
  customName?: string;
  updatedAt?: string;
  usedInLiveModel?: boolean;
  isSynced?: boolean;
  subRows?: AggregatedEvent[]; // I am adding this in order to be able to expand events in react-table
};

export type LogicOptions = {
  [key: string]: { display: string; value: string }[];
};

export type AggregationsType = 'email' | 'account';

export const logicOptions: LogicOptions = {
  email: [
    {
      display: 'Number of active days last X days',
      value: 'email_number_active_days',
    },
    {
      display:
        'Number of active days last X days (for a specific event or group of events)',
      value: 'email_number_active_days_with',
    },
    {
      display: 'Number of activities done in the last X days',
      value: 'email_number_activities',
    },
    {
      display:
        'Number of activities done in the last X days (for a specific event or group of events)',
      value: 'email_number',
    },
  ],
  account: [
    {
      display: 'Number of active emails last X days',
      value: 'account_number_active_emails',
    },
    {
      display:
        'Number of active emails last X days (for a specific event or group of events)',
      value: 'account_number_active_emails_who',
    },
    {
      display: 'Number of active days last X days',
      value: 'account_number_active_days',
    },
    {
      display:
        'Number of active days last X days (for a specific event or group of events)',
      value: 'account_number_active_days_with',
    },
    {
      display: 'Number of activities done in the last X days',
      value: 'account_number_activities',
    },
    {
      display:
        'Number of activities done in the last X days (for a specific event or group of events)',
      value: 'account_number',
    },
  ],
};

export const displayNameTemplates: { [key: string]: string } = {
  email_number_active_days: 'Active {{days}} last {{nbOfDays}} {{days}}',
  email_number_active_days_with:
    'Active {{days}} with {{name}} last {{nbOfDays}} {{days}}',
  email_number_activities: 'Activities last {{nbOfDays}} {{days}}',
  email_number: '{{name}} last {{nbOfDays}} {{days}}',
  account_number_active_emails: 'Active emails last {{nbOfDays}} {{days}}',
  account_number_active_emails_who:
    'Active emails who {{name}} last {{nbOfDays}} {{days}}',
  account_number_active_days: 'Active {{days}} last {{nbOfDays}} {{days}}',
  account_number_active_days_with:
    'Active {{days}} with {{name}} last {{nbOfDays}} {{days}}',
  account_number_activities: 'Activities last {{nbOfDays}} {{days}}',
  account_number: '{{name}} last {{nbOfDays}} {{days}}',
};

export class BehavioralAggregationsPageModel {
  aggregations: BehavioralAggregation[];

  activeAggregation: BehavioralAggregation;

  loading: boolean;

  modalSaveActive: boolean;

  saving: boolean;

  error: string;

  saved: boolean;

  formStep: number;

  stepConfiguration: { [step: number]: { validated: boolean } };

  eventActivityFields: Subject[];

  constructor(other?: BehavioralAggregationsPageModel) {
    if (other) {
      this.aggregations = other.aggregations;
      this.loading = other.loading;
      this.modalSaveActive = other.modalSaveActive;
      this.saving = other.saving;
      this.error = other.error;
      this.saved = other.saved;
      this.activeAggregation = other.activeAggregation;
      this.formStep = other.formStep;
      this.stepConfiguration = other.stepConfiguration;
    } else {
      this.aggregations = [];
      this.activeAggregation = {
        id: null,
        type: null,
        logic: null,
        lifetime: null,
        name: null,
        released: false,
        isProductEvent: false,
        conditions: { rules: [] },
        conditionLogic: '$1 AND $2',
      };
      this.loading = false;
      this.modalSaveActive = false;
      this.saving = false;
      this.error = null;
      this.saved = false;
      this.formStep = 1;
      this.stepConfiguration = {
        1: { validated: false },
        2: { validated: false },
        3: { validated: false },
        4: { validated: false },
        5: { validated: false },
      };
    }
    makeObservable(this, {
      aggregations: observable,
      activeAggregation: observable,
      loading: observable,
      modalSaveActive: observable,
      saving: observable,
      error: observable,
      saved: observable,
      formStep: observable,
      stepConfiguration: observable,
      get: action,
      save: action,
    });
  }

  async get(tenant: number): Promise<void> {
    this.loading = true;
    this.error = null;
    try {
      const { data } = await axios.get<BehavioralAggregation[]>(
        `/api/tenant/${tenant}/behavioral_aggregations`
      );
      this.aggregations = data;
    } catch (error) {
      this.error = error.response?.data?.message ?? error?.message;
    } finally {
      this.loading = false;
    }
  }

  async save(tenant: number): Promise<void> {
    this.saving = true;
    this.error = null;
    try {
      await axios.post(
        `/api/tenant/${tenant}/behavioral_aggregations`,
        this.aggregations
      );
      this.saved = true;
    } catch (err) {
      this.error = err.response?.data?.message ?? err?.message;
    } finally {
      this.saving = false;
    }
  }
}
