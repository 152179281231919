import React, { ReactElement, useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  ColumnFiltersState,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table';
import {
  Button,
  FormControl,
  FormSelect,
  OverlayTrigger,
  Row,
  Col,
  Table as BSTable,
  Tooltip,
  Modal,
  FormCheck,
} from 'react-bootstrap';

import useSkipper from './useSkipper';
import { Data } from './types';
import Filter from './Filter';

interface TableComponentProps<R extends Data> {
  data: R[];
  columns: ColumnDef<R, unknown>[];
  onCellChange?: (index: number, columnId: string, value: unknown) => void;
  defaultColumnVisibility?: Partial<Record<keyof R, boolean>>;
  defaultSorting?: SortingState;
  defaultPageSize?: number;
  columnVisibilityBag?: string;
  additionalButtons?: ReactElement;
}

export default function Table<R extends Data>({
  data: defaultData,
  columns,
  onCellChange,
  defaultColumnVisibility,
  defaultSorting,
  defaultPageSize,
  columnVisibilityBag,
  additionalButtons,
}: TableComponentProps<R>) {
  const [data, setData] = useState(defaultData);
  const [show, setShow] = useState(false);

  const [columnVisibility, setColumnVisibility] = useState(
    defaultColumnVisibility ?? {}
  );
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(defaultSorting ?? []);

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    setColumnVisibility(defaultColumnVisibility);
  }, [defaultColumnVisibility]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      expanded,
    },
    initialState: {
      pagination: {
        pageSize: defaultPageSize ?? 10,
      },
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: ({ subRows }) => subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex();

        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
        onCellChange(rowIndex, columnId, value);
      },
    },
  });

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    localStorage.setItem(columnVisibilityBag, JSON.stringify(columnVisibility));
    setShow(false);
  };

  return (
    <>
      {(defaultColumnVisibility || additionalButtons) && (
        <Row className="mb-2">
          {defaultColumnVisibility && (
            <Col>
              <Button variant="primary" onClick={handleShow}>
                Show columns
              </Button>

              <Modal show={show} onHide={handleClose} size="sm">
                <Modal.Header closeButton>
                  <Modal.Title>Show columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>You can choose the columns to show here!</p>
                  <FormCheck
                    type="checkbox"
                    label="Toggle all"
                    checked={table.getIsAllColumnsVisible()}
                    onChange={table.getToggleAllColumnsVisibilityHandler()}
                  />
                  {table.getAllLeafColumns().map((column) => (
                    <FormCheck
                      key={column.id}
                      type="checkbox"
                      label={column.columnDef.header}
                      checked={column.getIsVisible()}
                      onChange={column.getToggleVisibilityHandler()}
                    />
                  ))}
                </Modal.Body>
              </Modal>
            </Col>
          )}
          {additionalButtons && (
            <Col className="text-end">{additionalButtons}</Col>
          )}
        </Row>
      )}
      <div className="mw-100 overflow-auto">
        <BSTable borderless hover>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder || (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer user-select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {(header.column.getCanSort() &&
                            {
                              asc: <i className="fa fa-sort-asc ms-1" />,
                              desc: <i className="fa fa-sort-desc ms-1" />,
                            }[header.column.getIsSorted() as string]) ?? (
                            <i className="fa fa-sort ms-1" />
                          )}
                        </div>
                        {header.column.getCanFilter() && (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        )}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </BSTable>
        {data.length > 10 && (
          <div className="d-flex align-items-center gap-2">
            <OverlayTrigger overlay={<Tooltip>First page</Tooltip>}>
              <Button
                variant="light"
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </Button>
            </OverlayTrigger>

            <OverlayTrigger overlay={<Tooltip>Previous page</Tooltip>}>
              <Button
                variant="light"
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Next page</Tooltip>}>
              <Button
                variant="light"
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Last page</Tooltip>}>
              <Button
                variant="light"
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </Button>
            </OverlayTrigger>
            <span className="d-flex align-items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="d-flex align-items-center gap-1">
              | Go to page:
              <FormControl
                className="w-auto"
                type="number"
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
              />
            </span>
            <FormSelect
              className="w-auto"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </FormSelect>
          </div>
        )}
      </div>
    </>
  );
}
