import classNames from 'classnames';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import startCase from 'lodash/startCase';

import { ModelType } from '../../../models/ModelItem';
import {
  Condition,
  Subject,
  getVerbsSettings,
  Verb,
} from '../../../models/sqlform';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import checkPermissions from '../CheckPermissions';
import { Option } from '../generic_components/select_component/types';
import { EventMetadata } from '../../../models/event_metadata';
import { aggregationOptionsMapper } from './utils';
import {
  OPTIONS_MAPPER_SUBJECT_VALUES,
  OPTIONS_MAPPER_SUBJECT_VALUES_TO_LABELS,
} from '../../../models/rules';
import MadSelect from '../generic_components/select_component/MadSelect';

const verbsSettings = getVerbsSettings();

interface RuleConditionsProps {
  formFields: Subject[];
  conditions: Condition[];
  ruleIndex: number;
  isAggregationsForm: boolean;
  deleteCondition: (conditionIndex: number, ruleIndex: number) => void;
  handleSubjectChange: (value: string, conditionIndex: number) => void;
  handleVerbChange: (verb: Verb, conditionIndex: number) => void;
  handleComplementChange: (
    value: string,
    conditionIndex: number,
    complementIndex: number
  ) => void;
  handleAddComplement: (conditionIndex: number) => void;
  handleRemoveComplement: (
    conditionIndex: number,
    complementIndex: number
  ) => void;
  handleLower: (conditionIndex: number) => void;
  modelType: ModelType;
  withRuleAndSegment: boolean;
  isAllowedToEdit: boolean;
  eventMetadata?: EventMetadata;
  eventActivityFields?: Subject[];
}

export default class RuleConditionsComponent extends React.Component<
  RuleConditionsProps,
  Record<string, never>
> {
  render() {
    const {
      conditions,
      handleSubjectChange,
      handleVerbChange,
      handleComplementChange,
      deleteCondition,
      ruleIndex,
      handleAddComplement,
      handleRemoveComplement,
      handleLower,
      formFields,
      modelType,
      eventActivityFields,
      isAggregationsForm,
      withRuleAndSegment,
      eventMetadata,
      isAllowedToEdit,
    } = this.props;

    const subjectOptions =
      eventActivityFields?.map(({ subject }) => ({
        label:
          OPTIONS_MAPPER_SUBJECT_VALUES_TO_LABELS[
            subject as OPTIONS_MAPPER_SUBJECT_VALUES
          ],
        value: subject,
      })) ??
      formFields?.map(({ subject }) => ({
        label: startCase(subject),
        value: subject,
      })) ??
      [];

    return (
      <>
        {conditions.map((condition: Condition, conditionIndex: number) => {
          const conditionVerbSettings = verbsSettings.find(
            ({ value }) => value === condition.verb
          );

          return (
            <Form key={conditionIndex}>
              <FormGroup key={`form_group_${conditionIndex}`}>
                <Row className="gx-1">
                  <Col xs={6}>
                    <div className="d-flex align-items-center">
                      <span
                        className={classNames('fw-bold me-2', {
                          'ms-2': conditionIndex > 0,
                        })}
                      >
                        {conditionIndex > 0 ? 'AND' : 'IF'}
                      </span>
                      <div className="flex-fill">
                        <MadSelect
                          isDisabled={!isAllowedToEdit}
                          isSearchable
                          value={{
                            label: condition.subject.subject,
                            value: condition.subject.subject,
                          }}
                          options={subjectOptions}
                          onChange={({ value }) => {
                            handleSubjectChange(value, conditionIndex);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={3}>
                    <MadSelect
                      isSearchable
                      isDisabled={!isAllowedToEdit}
                      value={{
                        label: condition.verb,
                        value: condition.verb,
                      }}
                      options={verbsSettings
                        .filter(
                          ({ modelTypes, characteristics: { types } }) =>
                            modelTypes.includes(modelType) &&
                            types.includes(condition.subject.type)
                        )
                        .map(({ value }) => ({ label: value, value: value }))}
                      onChange={({ value }) => {
                        handleVerbChange(value, conditionIndex);
                      }}
                    />
                    {condition.subject.type === 'string' && (
                      <Form.Check
                        type="checkbox"
                        checked={condition.lower}
                        disabled={!isAllowedToEdit}
                        onChange={() => handleLower(conditionIndex)}
                        label="Case-insensitive"
                      />
                    )}
                  </Col>
                  <Col xs={3}>
                    <div className="d-flex align-items-center">
                      <div className="flex-fill">
                        {condition.complements.map(
                          (complement, complementIndex) =>
                            condition.complements.length >
                            conditionVerbSettings.characteristics.min ? (
                              <InputGroup
                                className="mb-1"
                                key={`compl_${complementIndex}`}
                              >
                                {isAggregationsForm ? (
                                  <MadSelect
                                    isFree
                                    key={`compl_${complementIndex}`}
                                    isDisabled={!isAllowedToEdit}
                                    value={{
                                      value: complement,
                                      label: complement,
                                    }}
                                    options={aggregationOptionsMapper(
                                      condition.subject
                                        .subject as OPTIONS_MAPPER_SUBJECT_VALUES,
                                      eventMetadata
                                    )}
                                    className="mb-1"
                                    onChange={(option: Option) =>
                                      handleComplementChange(
                                        option.value,
                                        conditionIndex,
                                        complementIndex
                                      )
                                    }
                                  />
                                ) : (
                                  <FormControl
                                    key={`compl_${complementIndex}`}
                                    type="text"
                                    disabled={!isAllowedToEdit}
                                    value={complement ?? ''}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      handleComplementChange(
                                        event.target.value,
                                        conditionIndex,
                                        complementIndex
                                      )
                                    }
                                  />
                                )}
                                {checkPermissions(
                                  PROFILES_PERMISSIONS.ARCHITECT,
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="right">Delete value</Tooltip>
                                    }
                                  >
                                    <Button
                                      onClick={() =>
                                        handleRemoveComplement(
                                          conditionIndex,
                                          complementIndex
                                        )
                                      }
                                      disabled={!isAllowedToEdit}
                                      variant="outline-secondary"
                                    >
                                      <i aria-hidden className="fas fa-times" />
                                    </Button>
                                  </OverlayTrigger>
                                )}
                              </InputGroup>
                            ) : isAggregationsForm ? (
                              <MadSelect
                                isFree
                                key={`compl_${complementIndex}`}
                                isDisabled={!isAllowedToEdit}
                                value={{
                                  value: complement,
                                  label: complement,
                                }}
                                className="mb-1"
                                options={aggregationOptionsMapper(
                                  condition.subject
                                    .subject as OPTIONS_MAPPER_SUBJECT_VALUES,
                                  eventMetadata
                                )}
                                onChange={(option: Option) =>
                                  handleComplementChange(
                                    option.value,
                                    conditionIndex,
                                    complementIndex
                                  )
                                }
                              />
                            ) : (
                              <FormControl
                                key={`compl_${complementIndex}`}
                                type="text"
                                disabled={!isAllowedToEdit}
                                value={complement ?? ''}
                                className="mb-1"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  handleComplementChange(
                                    event.target.value,
                                    conditionIndex,
                                    complementIndex
                                  )
                                }
                              />
                            )
                        )}
                        {conditionVerbSettings.characteristics.max >
                          condition.complements.length &&
                          checkPermissions(
                            PROFILES_PERMISSIONS.ARCHITECT,
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip">Add new value</Tooltip>
                              }
                            >
                              <Button
                                onClick={() =>
                                  handleAddComplement(conditionIndex)
                                }
                                disabled={!isAllowedToEdit}
                                className="float-end mb-2"
                              >
                                <i aria-hidden className="fas fa-plus" />
                              </Button>
                            </OverlayTrigger>
                          )}
                      </div>

                      {!isAggregationsForm &&
                        conditionIndex > 0 &&
                        checkPermissions(
                          PROFILES_PERMISSIONS.ARCHITECT,
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip">Delete condition</Tooltip>
                            }
                          >
                            <Button
                              onClick={() =>
                                deleteCondition(conditionIndex, ruleIndex)
                              }
                              disabled={
                                conditions.length === 1 || !isAllowedToEdit
                              }
                              variant="outline-secondary"
                              className="ms-1"
                            >
                              <i aria-hidden className="fas fa-times" />
                            </Button>
                          </OverlayTrigger>
                        )}
                      {withRuleAndSegment && conditionIndex === 0 && (
                        <span className="fw-bold ms-2 me-2">THEN</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          );
        })}
      </>
    );
  }
}
