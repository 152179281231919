import { inject, observer } from 'mobx-react';
import React from 'react';
import { Badge, Form, Nav, Button, Alert } from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import Store from '../../store';
import PageHeader from '../components/PageHeader';
import DuplicationModelModal from '../components/modals/DuplicationModelModal';
import DeployPage from './model/DeployPage';
import UnivariateAnalysisPage from './model/UnivariateAnalysisPage';
import ModelDetailsPage from './model/ModelDetailsPage';
import ReviewPage from './model/ReviewPage';
import Overview from './model/Overview';
import FeatureEvaluationPage from './model/FeatureEvaluationPage';
import UnivariateLeadsPage from './model/UnivariateLeadsPage';
import { PROFILES_PERMISSIONS } from '../../permissions';
import checkPermissions from '../components/CheckPermissions';
import AudiencePage from './model/buildDataset/AudiencePage';
import DataSetsTabs from '../components/audience/DataSetsTabs';
import MkAudiencePage from './model/buildDataset/build_dataset_pages/MkAudiencePage';
import MqaPage from './model/buildDataset/build_dataset_pages/MqaPage';
import RedshiftImportPage from './model/buildDataset/DatabaseImportPage';
import PqlPage from './model/buildDataset/build_dataset_pages/PqlPage';
import classNames from 'classnames';
import ModelStatus from '../components/ModelStatus';
import Undeploy from '../components/deploy/Undeploy';
import queryString from 'querystring';
import { IS_LLTB, MODEL_TYPES_NAMES } from '../../utils/constants';

interface MatchParams {
  tenant: string;
  modelId: string;
}
interface ModelPageProps extends RouteComponentProps<MatchParams> {
  store?: Store;
}

interface ModelPageState {
  showDuplicatingModal: boolean;
  newModelName: string;
  showUndeployModal: boolean;
}
const ModelPage = inject('store')(
  observer(
    class ModelPage extends React.Component<ModelPageProps, ModelPageState> {
      constructor(props: ModelPageProps) {
        super(props);
        this.state = {
          showDuplicatingModal: false,
          newModelName: '',
          showUndeployModal: false,
        };
        this.handleBatchChange = this.handleBatchChange.bind(this);
        this.handleRealtimeChange = this.handleRealtimeChange.bind(this);
        this.openDuplicateModelModal = this.openDuplicateModelModal.bind(this);
        this.closeDuplicatingModal = this.closeDuplicatingModal.bind(this);
        this.toggleShowUndeployModal = this.toggleShowUndeployModal.bind(this);
      }

      componentDidMount() {
        const { isModelIdValid, tenant } = this.props.store;
        if (!isModelIdValid) {
          this.props.history.replace(`/tenant/${tenant}/404`);
        }
      }

      async handleBatchChange() {
        const { activeModel } = this.props.store;
        const { batch, realtime } = activeModel;
        await this.props.store.updateModelContext({ realtime, batch: !batch });
      }

      async handleRealtimeChange() {
        const { activeModel } = this.props.store;
        const { batch, realtime } = activeModel;
        await this.props.store.updateModelContext({
          batch,
          realtime: !realtime,
        });
      }

      closeDuplicatingModal() {
        this.setState({
          showDuplicatingModal: false,
          newModelName: '',
        });
      }

      openDuplicateModelModal() {
        this.setState({ showDuplicatingModal: true });
      }

      toggleShowUndeployModal() {
        this.setState({ showUndeployModal: !this.state.showUndeployModal });
      }

      render() {
        const { tenant, modelId } = this.props.match.params;
        const basePath = `/tenant/${tenant}/models/${modelId}`;
        const {
          activeModel,
          rootPage: { updatingContext, isDataSetLoadingProgress },
          isAllowedToEdit,
          csvPage: {
            dataSets: { dataSetMetadata },
          },
        } = this.props.store;
        const { showDuplicatingModal } = this.state;
        const isModelReady = !!dataSetMetadata && !isDataSetLoadingProgress;
        const parsedUrlQuery = queryString.parse(this.props.location.search);
        const showDuplicationWarningMessage =
          parsedUrlQuery['?showDuplicationWarningMessage'];

        return (
          <>
            <PageHeader>
              <div className="d-flex align-items-center">
                <h2>{activeModel.name}</h2>

                {updatingContext && (
                  <i aria-hidden className="fas fa-spinner fa-spin" />
                )}
                <Badge
                  className={classNames('mx-4', {
                    'cfit-badge': activeModel.type === 'customer_fit',
                    'mqa-badge': activeModel.type === 'mqa',
                    'pql-badge': IS_LLTB(activeModel.type),
                  })}
                >
                  {MODEL_TYPES_NAMES[activeModel.type]}
                </Badge>
                {!updatingContext && (
                  <div className="ms-auto">
                    {checkPermissions(
                      PROFILES_PERMISSIONS.ARCHITECT,
                      <>
                        {isAllowedToEdit || (
                          <>
                            <span className="fst-italic me-1 text-muted me-4">
                              This model is live and cannot be edited
                            </span>

                            <Button
                              variant="outline-info me-2"
                              size="sm"
                              onClick={this.openDuplicateModelModal}
                            >
                              <i aria-hidden className="far fa-copy"></i>{' '}
                              Duplicate
                            </Button>

                            {activeModel.is_primary || (
                              <Undeploy
                                tenant={Number(tenant)}
                                model={activeModel}
                              />
                            )}
                          </>
                        )}
                        <ModelStatus model={activeModel} />
                        {activeModel.type === 'customer_fit' && (
                          <Form.Check
                            className="ms-2"
                            type="checkbox"
                            id="realtime"
                            label="Realtime"
                            inline
                            checked={activeModel.realtime}
                            disabled={!isAllowedToEdit}
                            onChange={this.handleRealtimeChange}
                          />
                        )}
                        <Form.Check
                          className="ms-2"
                          type="checkbox"
                          label="Batch"
                          id="batch"
                          inline
                          checked={activeModel.batch}
                          disabled={!isAllowedToEdit}
                          onChange={this.handleBatchChange}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div>
                {showDuplicationWarningMessage === 'true' && (
                  <Alert variant="warning">
                    If you plan to create new computations for this model,
                    please rebuild a dataset{' '}
                    <a href={`${basePath}/import_data/mk_audience`}>here</a>{' '}
                    before.
                  </Alert>
                )}
              </div>
            </PageHeader>
            <Nav className="second-level-nav bg-white">
              <Nav.Link
                className="text-dark"
                as={NavLink}
                to={`${basePath}/overview`}
              >
                Overview
              </Nav.Link>
              <Nav.Link
                className={classNames({ 'text-dark': isModelReady })}
                as={NavLink}
                to={`${basePath}/insights`}
                disabled={!isModelReady}
              >
                Insights
              </Nav.Link>
              <Nav.Link
                className={classNames({ 'text-dark': isModelReady })}
                as={NavLink}
                to={`${basePath}/model`}
                activeClassName="active-parent"
                disabled={!isModelReady}
              >
                Model
              </Nav.Link>
              <Nav.Link
                className={classNames({ 'text-dark': isModelReady })}
                as={NavLink}
                to={`${basePath}/review`}
                activeClassName="active-parent"
                disabled={!isModelReady}
              >
                Review
              </Nav.Link>
              <Nav.Link
                className={classNames({ 'text-dark': isModelReady })}
                as={NavLink}
                to={`${basePath}/deployment`}
                disabled={!isModelReady}
              >
                Deployment
              </Nav.Link>
            </Nav>
            <Switch>
              <Route path={`${this.props.match.path}/overview`}>
                <Overview />
              </Route>
              <Route
                path={`${this.props.match.path}/insights/:variableId/:variableName/:value/:readableValue/trees/:treeId/nodes/:nodeId/leads`}
              >
                <UnivariateLeadsPage />
              </Route>
              <Route
                path={`${this.props.match.path}/insights/:variableId/:variableName/:value/:readableValue/leads`}
              >
                <UnivariateLeadsPage />
              </Route>
              <Route path={`${this.props.match.path}/insights`}>
                {activeModel.type === 'customer_fit' ? (
                  <UnivariateAnalysisPage />
                ) : (
                  <FeatureEvaluationPage />
                )}
              </Route>
              <Route path={`${this.props.match.path}/model`}>
                <ModelDetailsPage />
              </Route>
              <Route path={`${this.props.match.path}/review`}>
                <ReviewPage />
              </Route>
              <Route path={`${this.props.match.path}/deployment`}>
                <DeployPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/mqa`}>
                <MqaPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/pql`}>
                <PqlPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/mql`}>
                <PqlPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/pql2`}>
                <PqlPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/mk_audience`}>
                <MkAudiencePage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/database`}>
                <RedshiftImportPage />
              </Route>
              <Route path={`${this.props.match.path}/import_data/audience`}>
                <AudiencePage />
              </Route>
              <Route path={`${this.props.match.path}/import_data`}>
                <DataSetsTabs />
              </Route>
              <Redirect
                from={this.props.match.path}
                to={`${this.props.match.path}/overview`}
              />
            </Switch>
            <DuplicationModelModal
              fromModel={Number(modelId)}
              close={this.closeDuplicatingModal}
              isShown={showDuplicatingModal}
            />
          </>
        );
      }
    }
  )
);
export default withRouter(ModelPage);
