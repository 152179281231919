import { makeObservable, observable } from 'mobx';
import axios from 'axios';
import { saveAs } from 'file-saver';

import DataSets from '../audience/DataSets';
import { CsvFileTrainingVariables } from '../audience/CsvFileDataset';
import GenericDatasetPageModel from './GenericDatasetPageModel';

export default class CsvPageModel extends GenericDatasetPageModel {
  dataSets: DataSets;

  isErrorOccurred: boolean;

  isDownloadedSuccessfully: boolean;

  isOldFileFound: boolean;

  isDownloading: boolean;

  variables: CsvFileTrainingVariables;

  constructor(other?: CsvPageModel) {
    super();
    if (other) {
      this.dataSets = new DataSets(other.dataSets);
      this.isErrorOccurred = other.isErrorOccurred;
      this.isDownloadedSuccessfully = other.isDownloadedSuccessfully;
      this.isOldFileFound = other.isOldFileFound;
      this.isDownloading = other.isDownloading;
      this.variables = other.variables;
    } else {
      this.dataSets = new DataSets();
      this.isErrorOccurred = false;
      this.isDownloadedSuccessfully = false;
      this.isOldFileFound = false;
      this.isDownloading = false;
      this.variables = {
        trainingFileName: '',
        validationFileName: '',
      };
    }
    makeObservable(this, {
      dataSets: observable,
      isErrorOccurred: observable,
      isDownloadedSuccessfully: observable,
      isOldFileFound: observable,
      isDownloading: observable,
      variables: observable,
    });
  }

  async downloadCsvFile(tenant: number, modelId: number) {
    this.isDownloading = true;
    try {
      const { data } = await axios.get(
        `/api/tenant/${tenant}/model/${modelId}/datasets/download/audience`
      );
      this.isDownloading = false;
      this.isErrorOccurred = false;
      this.isDownloadedSuccessfully = true;

      const blob = new Blob([data], { type: 'application/octet-stream' });
      saveAs(blob, `actual_training_dataset_${modelId}.csv`);
    } catch (err) {
      this.isDownloading = false;
      this.isErrorOccurred = true;
      this.isDownloadedSuccessfully = false;
    }
  }
}
