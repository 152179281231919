import axios from 'axios';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Form, Card, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Store from '../../store';

import GoogleLogo from '../assets/images/icons/google.svg';
import MadKuduLogo from '../assets/images/madkudu_horns_only_dark.svg';

interface LoginPageProps {
  store?: Store;
}

function getQueryString(queryString: string) {
  const name = queryString.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  if (!window.location) return '';
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1]);
}

function LoginPage({ store }: LoginPageProps) {
  const { search } = useLocation();
  const tenantFromSearchParams = new URLSearchParams(search).get('tenant');

  const [defaultTenant, setDefaultTenant] = useState('');
  const [tenant, setTenant] = useState(tenantFromSearchParams ?? '');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoadingIam, setIsLoadingIam] = useState(true);
  const [isAccessing, setIsAccessing] = useState(false);

  useEffect(() => {
    async function checkIfUserIsLoggedIn() {
      setIsLoadingIam(true);

      try {
        const { data } = await axios.get<{
          connected: boolean;
          tenant?: number;
        }>('/api/iam');

        setIsLoggedIn(!!data.connected);

        const allowedTenant = data.tenant.toString();
        if (!tenant) setTenant(allowedTenant);
        setDefaultTenant(allowedTenant);
      } catch (error) {
        setIsLoggedIn(false);
      }

      setIsLoadingIam(false);
    }

    checkIfUserIsLoggedIn();
  }, []);

  function googleLogin() {
    const redirect = getQueryString('redirect');

    if (redirect) {
      window.location.href = `/api/login/google?redirect=${redirect}`;
    } else if (tenant) {
      window.location.href = `/api/login/google?redirect=/tenant/${tenant}`;
    } else {
      window.location.href = '/api/login/google';
    }
  }

  const isTenantValid = !(tenant.length < 4 || Number.isNaN(Number(tenant)));

  const isTenantAccessAllowed: boolean =
    isTenantValid && store.isUserAuthorizedForTenant(tenant);

  async function access() {
    if (!isTenantAccessAllowed) return;

    setIsAccessing(true);
    window.location.href = `/tenant/${tenant}`;
  }

  return (
    <div className="bg-dark tw-flex tw-h-full tw-items-center tw-justify-center">
      <Card className="tw-min-h-[300px] tw-w-[373px] tw-p-3">
        <Card.Body className="text-start">
          <div className="tw-full tw-mb-4 tw-flex tw-flex-col tw-items-center">
            <div className="tw-h-[113px]">
              <img
                className="tw-h-full tw-w-full"
                src={MadKuduLogo}
                alt="MadKudu logo (horns only)"
              />
            </div>
            <h1 className="fw-bolder tw-text-[rgb(24, 43, 82)] -tw-mt-6">
              Data Studio
            </h1>
            {isLoadingIam && <Spinner className="tw-mt-4" />}
          </div>

          {isLoggedIn && !isLoadingIam && (
            <div className="tw-flex tw-flex-col tw-gap-3">
              <Form.Group>
                <div className="tw-inline-flex tw-w-full tw-justify-between">
                  <Form.Label className="fw-bold">Enter a tenant ID</Form.Label>
                  <Form.Label
                    className="tw-cursor-pointer"
                    onClick={() => setTenant(defaultTenant)}
                  >
                    <a className="tw-no-underline">Reset</a>
                  </Form.Label>
                </div>
                <InputGroup>
                  <InputGroup.Text>#</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="3356"
                    size="lg"
                    onChange={(e) => {
                      setTenant(e.currentTarget.value);
                    }}
                    isValid={isTenantValid}
                    isInvalid={
                      tenant && (!isTenantValid || !isTenantAccessAllowed)
                    }
                    disabled={isAccessing}
                    value={tenant}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') access();
                    }}
                  />
                </InputGroup>
                {tenant && !isTenantValid && (
                  <Form.Text className="text-danger">
                    {'Tenant ID needs to be a number of 4 digits or more.'}
                  </Form.Text>
                )}
                {isTenantValid && !isTenantAccessAllowed && (
                  <Form.Text className="text-danger">
                    {"You don't have permissions to access this tenant."}
                  </Form.Text>
                )}
              </Form.Group>
              <Button
                className="w-100"
                size="lg"
                disabled={
                  !isTenantValid || isAccessing || !isTenantAccessAllowed
                }
                type="submit"
                onClick={access}
              >
                {isAccessing ? (
                  <span className="tw-inline-flex tw-items-center tw-gap-1">
                    <Spinner className="tw-mr-2" variant="light" size="sm" />
                    Accessing...
                  </span>
                ) : (
                  'Access'
                )}
              </Button>
              {isLoggedIn && store.isSuperKudu && (
                <a
                  className=" tw-mt-3"
                  href={`https://app.madkudu.com/admin/tenant/list`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Don’t know your account number?{' '}
                  <i aria-hidden className="fas fa-external-link-alt"></i>
                </a>
              )}
            </div>
          )}

          {!isLoggedIn && !isLoadingIam && (
            <div className="d-flex flex-column gap-4">
              <button
                className="tw-text-gray tw-inline-flex tw-h-16 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-md tw-border tw-border-solid !tw-border-gray-200 tw-bg-white tw-px-5 tw-text-lg tw-font-medium tw-shadow hover:tw-bg-gray-50 focus:tw-outline-none"
                onClick={googleLogin}
              >
                <span className="tw-inline-flex tw-items-center tw-gap-2">
                  <img
                    src={GoogleLogo}
                    alt="Google logo"
                    height={30}
                    width={30}
                  />
                  Sign in with Google
                </span>
              </button>
              <a
                href={`https://app.madkudu.com/`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Can’t log in? Connect through the App{' '}
                <i aria-hidden className="fas fa-external-link-alt"></i>
              </a>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default inject('store')(observer(LoginPage));
