import React, { useMemo } from 'react';
import { Column, Table } from '@tanstack/table-core';

import { Data } from './types';
import DebouncedInput from '../../DebouncedInput';

export default function Filter<R extends Data>({
  column,
  table,
}: {
  column: Column<R, unknown>;
  table: Table<R>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  if (typeof firstValue === 'number') {
    const [min, max] = column.getFacetedMinMaxValues() ?? [0, 0];
    if (min === max) return null;

    return (
      <div>
        <div className="flex space-x-2">
          <DebouncedInput
            type="number"
            min={Number(min ?? '')}
            max={Number(max ?? '')}
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                value,
                old?.[1],
              ])
            }
            placeholder={`Min ${min}`}
            className="w-25 border rounded me-1"
          />
          <DebouncedInput
            type="number"
            min={Number(max ?? '')}
            max={Number(max ?? '')}
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={(value) =>
              column.setFilterValue((old: [number, number]) => [
                old?.[0],
                value,
              ])
            }
            placeholder={`Max ${max}`}
            className="w-25 border rounded"
          />
        </div>
        <div className="h-1" />
      </div>
    );
  }

  if (sortedUniqueValues.length <= 1) return null;

  return (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: string, index) => (
          <option value={value} key={`${index}-${value}`} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-50 border rounded"
        list={column.id + 'list'}
      />
    </>
  );
}
