import capitalize from 'lodash/capitalize';
import {
  AggregationsType,
  displayNameTemplates,
} from '../models/aggregations/behavioral_aggregations';

export function parseAggregationDisplayName(
  logic: string,
  name: string,
  days: string
) {
  const day = Number(days) === 1 ? 'day' : 'days';
  const cleanedName = name.replace(/_+/g, ' ');
  return displayNameTemplates[logic]
    .replace(/{{name}}/g, cleanedName)
    .replace(/{{nbOfDays}}/g, days)
    .replace(/{{days}}/g, day);
}

export function parseMetaEventDisplayName(
  aggregationDisplayName: string,
  occurrences: number
) {
  return `At least ${occurrences} ${aggregationDisplayName}`;
}

export function parseMetaEventName(
  metaEventDisplayName: string,
  type: AggregationsType
) {
  return `${capitalize(type)} - ${metaEventDisplayName}`;
}
