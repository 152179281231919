import { makeObservable, observable } from 'mobx';
import TreeNodePrecision from './TreeNodePrecision';
import TreeNodeStats from './TreeNodeStats';
import TreePerformance from './TreePerformance';
import TreeStructure from './TreeStructure';

export default class TreeDefinition {
  yaml: string;

  name: string;

  stats: TreeNodeStats[];

  structure: TreeStructure | null;

  performances: TreePerformance[] | null;

  precisions: TreeNodePrecision[] | null;

  constructor(
    yaml: string,
    name: string,
    stats: TreeNodeStats[],
    structure: TreeStructure,
    performances: TreePerformance[],
    precisions: TreeNodePrecision[]
  ) {
    this.yaml = yaml;
    this.name = name;
    this.stats = stats;
    this.structure = structure;
    this.performances = performances;
    this.precisions = precisions;
    makeObservable(this, {
      yaml: observable,
      stats: observable,
      structure: observable,
      performances: observable,
      precisions: observable,
    });
  }
}
