import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
} from 'recharts';

import TreeNodePrecision from '../../../models/tree/TreeNodePrecision';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const element = payload[0].payload;

    if (element.population === 0) return null;

    return (
      <div className="border border-primary bg-white p-1">
        <h6 className="fw-bold">Node(s): {element.ids.join(', ')}</h6>
        <ul className="list-unstyled">
          <li>% of population: {element.percentTotalPopulation}%</li>
          <li>% of conversions: {element.percentTotalConversion}%</li>
          <li>conversion rate: {element.conversionRate}%</li>
        </ul>
      </div>
    );
  }

  return null;
};

type AUCCurveProps = {
  precisions: TreeNodePrecision[];
  ref?: React.RefObject<any>;
};

const AUCCurve = React.forwardRef(function AUCCurve(
  { precisions }: AUCCurveProps,
  ref: React.RefObject<any>
) {
  const [chartParentWidth, setChartParentWidth] = useState<number>(0);
  const chartParentRef = useRef(null);

  const data = precisions.map((precision) => ({
    ...precision,
    percentTotalPopulation: Number(precision.percentTotalPopulation.toFixed(2)),
    percentTotalConversion: Number(precision.percentTotalConversion.toFixed(2)),
    conversionRate: Number((precision.conversionRate * 100).toFixed(2)),
  }));

  data.unshift(new TreeNodePrecision());

  useEffect(() => {
    setChartParentWidth(chartParentRef?.current?.offsetWidth ?? 0);

    window.addEventListener('resize', handleResize, false);
  }, []);

  const handleResize = () => {
    setChartParentWidth(chartParentRef?.current?.offsetWidth ?? 0);
  };

  if (!data || !data.length) {
    return null;
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>AUC curve</Card.Title>
        <div ref={chartParentRef}>
          <LineChart
            height={chartParentWidth}
            width={chartParentWidth}
            data={data}
            ref={ref}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              dataKey="percentTotalPopulation"
              label={{
                value: '% of population',
                position: 'insideBottomRight',
                offset: 0,
              }}
            />
            <YAxis
              type="number"
              label={{
                value: '% of conversions',
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              dataKey="percentTotalConversion"
              stroke="red"
              activeDot={{ r: 8 }}
              isAnimationActive={false}
            />
            <ReferenceLine
              stroke="blue"
              segment={[
                { x: 0, y: 0 },
                { x: 100, y: 100 },
              ]}
            />
          </LineChart>
        </div>
      </Card.Body>
    </Card>
  );
});

export default AUCCurve;
