/* eslint-disable max-classes-per-file */
import { makeObservable, observable } from 'mobx';

type ScoresRepartition = { score: number; customers: number }[];

export class SmoothingResults {
  convertedPerScore: ScoresRepartition;

  paganPerScore: ScoresRepartition;

  constructor(
    convertedPerScore: ScoresRepartition,
    paganPerScore: ScoresRepartition
  ) {
    this.convertedPerScore = convertedPerScore;
    this.paganPerScore = paganPerScore;
    makeObservable(this, {
      convertedPerScore: observable,
      paganPerScore: observable,
    });
  }
}

export type SmoothingConfigValue = 'default_demo' | 'custom';

export class SmoothingConfig {
  type: SmoothingConfigValue;

  config?: string;

  constructor(type: SmoothingConfigValue, config?: string) {
    this.type = type;
    this.config = config;
    makeObservable(this, {
      type: observable,
      config: observable,
    });
  }
}

export class SmoothingPageModel {
  smoothing: SmoothingConfig;

  loadingSmoothingConfig: boolean;

  savingSmoothingConfig: boolean;

  smoothingConfigError: string;

  smoothingConfigSaved: boolean;

  constructor(other?: SmoothingPageModel) {
    if (other) {
      this.smoothing = other.smoothing;
      this.loadingSmoothingConfig = other.loadingSmoothingConfig;
      this.savingSmoothingConfig = other.savingSmoothingConfig;
      this.smoothingConfigError = other.smoothingConfigError;
      this.smoothingConfigSaved = other.smoothingConfigSaved;
    } else {
      this.smoothing = null;
      this.loadingSmoothingConfig = false;
      this.savingSmoothingConfig = false;
      this.smoothingConfigError = null;
      this.smoothingConfigSaved = false;
    }
    makeObservable(this, {
      smoothing: observable,
      loadingSmoothingConfig: observable,
      savingSmoothingConfig: observable,
      smoothingConfigError: observable,
      smoothingConfigSaved: observable,
    });
  }
}
