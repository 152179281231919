import { FrequencyAnalysis } from '../../../models/aggregations/aggregated_events';
import capitalize from 'lodash/capitalize';

export function parseAggregationName(args: {
  logic: string;
  lifetime: string;
  custom?: string;
}) {
  const { custom, logic, lifetime } = args;

  return `${custom ? `${logic}_${custom}_` : ''}${
    custom
      ? 'last_X_days'.replace('X', lifetime).replace(/ /g, '_')
      : logic
          .replace('X', lifetime)
          // make all letters lowercase, remove excess text, transform to snakecase
          .split('')
          .map((l) => l.toLowerCase())
          .join('')
          .replace(/ /g, '_')
          .concat('_last_X_days'.replace('X', lifetime))
  }`;
}

// stringify for display, replacing "_" with spaces and removing agg type prefix
export function stringifyAggregationName(parsedName: string) {
  // remove everything before first "_" ("email_" or "account_")
  const isEmailAggregation = parsedName.startsWith('email_');
  const isAccountAggregation = parsedName.startsWith('account_');
  // eslint-disable-next-line no-nested-ternary
  const prefixIndex = isEmailAggregation
    ? 6
    : isAccountAggregation
    ? 8
    : parsedName.indexOf('_') + 1;
  return capitalize(parsedName.slice(prefixIndex).replace(/_/g, ' '));
}

export function slugValue(string: string | undefined): string {
  return string?.replace(/ /g, '_')?.toLowerCase();
}

export function beautifyValue(string: string | undefined): string {
  return string
    ?.split('_')
    .map((word) => capitalize(word))
    .join(' ');
}

export function getHighestPhiAgg(
  frequencyA: FrequencyAnalysis,
  frequencyB: FrequencyAnalysis
): FrequencyAnalysis {
  // if two events tied for highest phi, get one with smallest number of occurrences
  if (frequencyA && frequencyB) {
    if (frequencyA.phi > frequencyB.phi) {
      return frequencyA;
    }
    if (
      frequencyA.number_of_occurences_of_event_last_x_days <
      frequencyB.number_of_occurences_of_event_last_x_days
    ) {
      return frequencyA;
    }
    return frequencyB;
  }
  return null;
}
