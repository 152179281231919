import React, { useMemo } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Col,
  Form,
  FormCheck,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';

import MadSelect from '../../generic_components/select_component/MadSelect';
import checkPermissions from '../../CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../../permissions';
import {
  getVerbSettings,
  getVerbsSettings,
  Verb,
} from '../../../../models/sqlform';
import Store from '../../../../store';
import {
  getVerbBoundaries,
  signalConditionPlaceHolder,
  signalPlaceHolder,
} from './utils';
import { SignalDirection } from '../../../../models/signals';
import { action } from 'mobx';

interface SignalsRulesProps {
  store?: Store;
  signalGroupIndex: number;
}

const verbsSettings = getVerbsSettings({ signals: true });

const SIGNAL_DIRECTIONS_OPTIONS: {
  label: SignalDirection;
  value: SignalDirection;
}[] = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
];

const FORM_COMP_HEIGHT = '38px';

function SignalsRulesComponent({ store, signalGroupIndex }: SignalsRulesProps) {
  const { activeModel, formFields, signalsPage, isAllowedToEdit } = store;
  const {
    savingSignals,
    signals,
    signalsFormError,
    validateSignalDescriptionField,
    signalErrorKey,
  } = signalsPage;
  const { signalsV2 } = signals;
  const modelType = activeModel.type;

  const formFieldOptions = useMemo(
    () =>
      (formFields || []).map(({ subject }) => ({
        label: subject,
        value: subject,
      })),
    [formFields]
  );

  const signalDescriptionOptions = useMemo(
    () => [{ label: 'null', value: 'null' }, ...formFieldOptions],
    [formFieldOptions]
  );

  const signalConditionFieldOptions = useMemo(
    () =>
      // add 'undefined' as first element of field list, to serve as default value
      // when field wasn't correctly unparsed.
      [{ label: 'undefined', value: 'undefined' }, ...formFieldOptions],
    [formFieldOptions]
  );

  const verbsSettingsOptions: {
    label: Verb;
    value: Verb;
  }[] = useMemo(
    () =>
      verbsSettings
        .filter(({ modelTypes }) =>
          modelTypes.includes(modelType === 'mqa' ? 'mqa' : 'customer_fit')
        )
        .map(({ value }) => ({
          label: value,
          value,
        })),
    [modelType]
  );

  const conditionallyRefreshValueField = (
    signalIndex: number,
    conditionIndex: number
  ) => {
    const { values, verb } =
      signalsV2[signalGroupIndex].signals[signalIndex].conditions[
        conditionIndex
      ];

    // if length of current values is greater than allowed max with verb, clear
    if (values.length > getVerbBoundaries(verb).max) {
      signalsV2[signalGroupIndex].signals[signalIndex].conditions[
        conditionIndex
      ].values = [''];
    }
  };

  if (!formFields) return null;

  return (
    <Form>
      {signalsV2[signalGroupIndex].signals.map((signal, signalIndex) => {
        const isAndConditions = signal.conditions.find(
          // don't take the direction of first condition into account
          (condition, index) => condition.direction === 'AND' && index !== 0
        );
        const isOrConditions = signal.conditions.find(
          (condition, index) => condition.direction === 'OR' && index !== 0
        );
        const displayOrButton =
          (!isAndConditions && isOrConditions) ||
          signal.conditions.length === 1;
        const displayAndButton =
          (isAndConditions && !isOrConditions) ||
          signal.conditions.length === 1;

        // get detected error on a field for current signal
        const signalError = (field: string) =>
          signalsFormError[
            signalErrorKey({ field, signalGroupIndex, signalIndex })
          ];

        return (
          <div key={`signal_group_${signalGroupIndex}_row_${signalIndex}`}>
            <Row className="mb-4 my-auto">
              <Col xs={1} className="my-auto text-center">
                <h6>Display</h6>
              </Col>
              <Col xs={1}>
                <MadSelect
                  controlStyles={{
                    height: FORM_COMP_HEIGHT,
                  }}
                  isDisabled={!isAllowedToEdit || savingSignals}
                  isSearchable={false}
                  options={SIGNAL_DIRECTIONS_OPTIONS}
                  value={{
                    label: signal.direction,
                    value: signal.direction,
                  }}
                  onChange={action(({ value }) => {
                    signalsV2[signalGroupIndex].signals[signalIndex].direction =
                      value;
                  })}
                />
              </Col>

              <Col xs={3}>
                <FormControl
                  isInvalid={!!signalError('description.field')}
                  type="text"
                  style={{ height: FORM_COMP_HEIGHT }}
                  name="description_field"
                  disabled={!isAllowedToEdit || savingSignals}
                  value={signal.description.field}
                  placeholder="Signal will display as this..."
                  onChange={action(
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                      const description = event.target.value;

                      validateSignalDescriptionField({
                        description,
                        signalGroupIndex,
                        signalIndex,
                      });

                      signalsV2[signalGroupIndex].signals[
                        signalIndex
                      ].description.field = description;
                    }
                  )}
                />
                {!!signalError('description.field') && (
                  <div className="tw-mt-1 tw-text-red-500">
                    {signalError('description.field')}
                  </div>
                )}
              </Col>
              <Col xs={3}>
                <MadSelect
                  controlStyles={{
                    height: FORM_COMP_HEIGHT,
                  }}
                  isDisabled={!isAllowedToEdit || savingSignals}
                  options={signalDescriptionOptions}
                  value={
                    signal.description.value
                      ? {
                          label: signal.description.value,
                          value: signal.description.value,
                        }
                      : {
                          label: 'null',
                          value: 'null',
                        }
                  }
                  onChange={action(({ value }) => {
                    signalsV2[signalGroupIndex].signals[
                      signalIndex
                    ].description.value = value;
                  })}
                />
              </Col>
              <Col xs={1} className="text-end my-auto">
                <h6>Priority</h6>
              </Col>
              <Col xs={1}>
                <FormControl
                  type="number"
                  style={{ height: FORM_COMP_HEIGHT }}
                  min={0}
                  max={100}
                  name="priority"
                  disabled={!isAllowedToEdit || savingSignals}
                  value={signal.priority}
                  onChange={action(
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                      signalsV2[signalGroupIndex].signals[
                        signalIndex
                      ].priority = Number(event.target.value);
                    }
                  )}
                />
              </Col>
              <Col xs={1}>
                {checkPermissions(
                  PROFILES_PERMISSIONS.ARCHITECT,

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">Remove the signal display</Tooltip>
                    }
                  >
                    <Button
                      variant="danger"
                      onClick={action(() =>
                        signalsV2[signalGroupIndex].signals.splice(
                          signalIndex,
                          1
                        )
                      )}
                      disabled={!isAllowedToEdit || savingSignals}
                    >
                      <i aria-hidden className="fas fa-times"></i>
                    </Button>
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
            <hr className="semi-divider" />
            {signal.conditions.map((condition, conditionIndex) => {
              const conditionVerbSettings = getVerbSettings(condition.verb);

              return (
                <Row
                  className="mb-3"
                  key={`signal_group_${signalGroupIndex}_row_${signalIndex}_condition_${conditionIndex}`}
                >
                  {conditionIndex === 0 && (
                    <Col xs={1} className="text-center">
                      <h6>Definition</h6>
                    </Col>
                  )}
                  {conditionIndex > 0 && (
                    <Col xs={1} className="pt-1 text-end">
                      {condition.direction || 'AND'}
                    </Col>
                  )}
                  <Col xs={3}>
                    <MadSelect
                      controlStyles={{
                        height: FORM_COMP_HEIGHT,
                      }}
                      isDisabled={!isAllowedToEdit || savingSignals}
                      options={signalConditionFieldOptions}
                      value={{
                        label: condition.field,
                        value: condition.field,
                      }}
                      onChange={action(({ value }) => {
                        signalsV2[signalGroupIndex].signals[
                          signalIndex
                        ].conditions[conditionIndex].field = value;
                      })}
                    />
                  </Col>
                  <Col xs={2}>
                    <Row>
                      <Col xs={12}>
                        <MadSelect
                          controlStyles={{
                            height: FORM_COMP_HEIGHT,
                          }}
                          isDisabled={!isAllowedToEdit || savingSignals}
                          options={verbsSettingsOptions}
                          value={{
                            label: condition.verb,
                            value: condition.verb,
                          }}
                          onChange={action(({ value }) => {
                            signalsV2[signalGroupIndex].signals[
                              signalIndex
                            ].conditions[conditionIndex].verb = value;

                            const verbSettings = getVerbSettings(value);
                            // Reset lower to false, if the verb doesn't apply to strings
                            if (
                              verbSettings.characteristics.types.includes(
                                'string'
                              )
                            ) {
                              signalsV2[signalGroupIndex].signals[
                                signalIndex
                              ].conditions[conditionIndex].lower = false;
                            }
                            conditionallyRefreshValueField(
                              signalIndex,
                              conditionIndex
                            );
                          })}
                        />
                        {conditionVerbSettings.characteristics.types.includes(
                          'string'
                        ) && (
                          <Col xs={12}>
                            <FormCheck
                              type="checkbox"
                              name="lower_field"
                              disabled={!isAllowedToEdit || savingSignals}
                              checked={signal.conditions[conditionIndex].lower}
                              onChange={action(() => {
                                signalsV2[signalGroupIndex].signals[
                                  signalIndex
                                ].conditions[conditionIndex].lower =
                                  !signal.conditions[conditionIndex].lower;
                              })}
                              label="Case-insensitive"
                            />
                          </Col>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {condition.verb !== 'is known' &&
                    condition.verb !== 'is unknown' && (
                      <React.Fragment>
                        <Col xs={4}>
                          {condition.values.map((value, valueIndex) => (
                            <Row
                              className={classNames('gx-1', {
                                'mt-1': valueIndex,
                              })}
                              key={`signal_group_${signalGroupIndex}_row_${signalIndex}_condition_${conditionIndex}_value_${valueIndex}`}
                            >
                              <Col xs={8}>
                                <FormControl
                                  type="text"
                                  name="value"
                                  style={{ height: FORM_COMP_HEIGHT }}
                                  disabled={!isAllowedToEdit || savingSignals}
                                  value={value}
                                  placeholder="Enter value..."
                                  onChange={action(
                                    (
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      signalsV2[signalGroupIndex].signals[
                                        signalIndex
                                      ].conditions[conditionIndex].values[
                                        valueIndex
                                      ] = event.target.value;
                                    }
                                  )}
                                />
                              </Col>
                              <Col xs={4}>
                                {condition.values.length &&
                                  valueIndex === condition.values.length - 1 &&
                                  condition.values.length <
                                    getVerbBoundaries(condition.verb).max && (
                                    <OverlayTrigger
                                      placement="left"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Add a value
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="primary"
                                        disabled={
                                          !isAllowedToEdit || savingSignals
                                        }
                                        onClick={action(() =>
                                          signalsV2[signalGroupIndex].signals[
                                            signalIndex
                                          ].conditions[
                                            conditionIndex
                                          ].values.push('')
                                        )}
                                        className="me-1"
                                      >
                                        +
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                {condition.values.length >
                                  getVerbBoundaries(condition.verb).min && (
                                  <OverlayTrigger
                                    placement="left"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        Remove the value
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="danger"
                                      disabled={
                                        !isAllowedToEdit || savingSignals
                                      }
                                      onClick={action(() =>
                                        signalsV2[signalGroupIndex].signals[
                                          signalIndex
                                        ].conditions[
                                          conditionIndex
                                        ].values.splice(valueIndex, 1)
                                      )}
                                    >
                                      -
                                    </Button>
                                  </OverlayTrigger>
                                )}
                                {(condition.values.length === 1 ||
                                  valueIndex === condition.values.length - 1) &&
                                  signal.conditions.length > 1 && (
                                    <OverlayTrigger
                                      placement="left"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          Remove sub-condition
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="outline-danger"
                                        disabled={
                                          !isAllowedToEdit || savingSignals
                                        }
                                        onClick={action(() =>
                                          signalsV2[signalGroupIndex].signals[
                                            signalIndex
                                          ].conditions.splice(conditionIndex, 1)
                                        )}
                                      >
                                        <i
                                          aria-hidden
                                          className="fas fa-times"
                                        ></i>
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </React.Fragment>
                    )}
                </Row>
              );
            })}

            <Col>
              <div className="ms-5 ps-5 mb-3">
                {displayOrButton && (
                  <Button
                    variant="outline-primary"
                    onClick={action(() =>
                      signalsV2[signalGroupIndex].signals[
                        signalIndex
                      ].conditions.push(
                        signalConditionPlaceHolder(formFields, 'OR')
                      )
                    )}
                    disabled={!isAllowedToEdit || savingSignals}
                    className="me-1"
                  >
                    + OR
                  </Button>
                )}
                {displayAndButton && (
                  <Button
                    variant="outline-primary"
                    disabled={!isAllowedToEdit || savingSignals}
                    onClick={action(() =>
                      signalsV2[signalGroupIndex].signals[
                        signalIndex
                      ].conditions.push(
                        signalConditionPlaceHolder(formFields, 'AND')
                      )
                    )}
                  >
                    + AND
                  </Button>
                )}
              </div>
            </Col>

            <hr></hr>
          </div>
        );
      })}

      <Row className="text-start">
        <Col xs={2}>
          <Button
            className="me-1"
            onClick={action(() =>
              signalsV2[signalGroupIndex].signals.push(
                signalPlaceHolder(formFields)
              )
            )}
            disabled={!isAllowedToEdit || savingSignals}
          >
            Add signal variable
          </Button>
        </Col>
        <Col className="my-auto">
          <p className="m-0 fst-italic">
            The order of definitions is important as only the first signal
            definition verified in a group will be displayed.
          </p>
        </Col>
        <Col xs={2} className="text-end">
          <Button
            variant="danger"
            onClick={action(() => signalsV2.splice(signalGroupIndex, 1))}
            disabled={!isAllowedToEdit || savingSignals}
          >
            Remove signal group
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default inject('store')(observer(SignalsRulesComponent));
