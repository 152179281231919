import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Accordion,
  Breadcrumb,
  Card,
  Col,
  Form,
  Row,
  Button,
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router';
import Store from '../../store';

import PageHeader from '../components/PageHeader';
import Spinner from '../components/Spinner';
import CorrelationGraph from '../components/behavioral_aggregations/CorrelationGraph';
import CorrelationTable from '../components/behavioral_aggregations/CorrelationTable';
import { ModelType } from '../../models/ModelItem';
import { LinkContainer } from 'react-router-bootstrap';
import checkPermissions from '../components/CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../permissions';
import { LEAD_LTBS, MODEL_TYPES_NAMES } from '../../utils/constants';
import MadSelect from '../components/generic_components/select_component/MadSelect';

type MatchParams = {
  tenant: string;
  aggregationName: string;
};

type FrequencyAnalysisPageProps = {
  store?: Store;
};

const FrequencyAnalysisPage = inject('store')(
  observer(function FrequencyAnalysisPage({
    store,
  }: FrequencyAnalysisPageProps) {
    const [selectedModelId, setSelectedModelId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(true);
    const { tenant, aggregationName } = useParams<MatchParams>();
    const history = useHistory();

    const decodedAggregationName = decodeURI(aggregationName).replace(
      /%2F/gi,
      '/'
    );

    useEffect(() => {
      async function loadData() {
        if (!store.behavioralAggregationsPage.aggregations.length) {
          await store.behavioralAggregationsPage.get(store.tenant);
        }
        if (!store.aggregatedEventsPage.aggregatedEvents.length) {
          await store.getAggregatedEvents();
        }
        store.setChosenAggregation(decodedAggregationName);
        setLoading(false);
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadData();
    }, []);

    if (loading) {
      return <Spinner />;
    }

    const aggregation = store.behavioralAggregationsPage.aggregations.find(
      // Handle escaped slashes in aggregation names
      ({ name }) => name === decodedAggregationName
    );

    const selectedModel = store.tenantModels.find(
      ({ modelId }) => modelId === selectedModelId
    );

    const save = async () => {
      const { aggregatedEventsPage } = store;
      const { aggregatedEvents, chosenAggregatedEvents } = aggregatedEventsPage;

      // eslint-disable-next-line no-param-reassign
      store.aggregatedEventsPage.aggregatedEvents = aggregatedEvents.concat(
        chosenAggregatedEvents
      );
      await store.saveAggregatedEvents();
      // eslint-disable-next-line no-param-reassign
      store.aggregatedEventsPage.chosenAggregatedEvents = [];
      history.push(`/tenant/${tenant}/aggregations`);
    };

    const desiredModelsTypes: ModelType[] =
      aggregation.type === 'account' ? ['mqa'] : LEAD_LTBS;

    return (
      <>
        <PageHeader>
          <Breadcrumb className="fs-5 mb-2">
            <LinkContainer to={`/tenant/${tenant}/aggregations`}>
              <Breadcrumb.Item>Behavioral aggregations</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active className="fw-bold">
              Frequency analysis {aggregation.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </PageHeader>
        <div className="mt-3 mb-5">
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col xs={6}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formDataset">
                      <Form.Label className="fw-bold">
                        Select dataset from model
                      </Form.Label>
                      <MadSelect
                        isSearchable
                        value={
                          selectedModelId
                            ? {
                                value: `${selectedModelId}`,
                                label: `${selectedModel.name} - ${
                                  MODEL_TYPES_NAMES[selectedModel.type]
                                }`,
                              }
                            : null
                        }
                        options={store.tenantModels
                          .filter(
                            ({ is_archived, type }) =>
                              !is_archived &&
                              desiredModelsTypes.includes(type) &&
                              (aggregation.isProductEvent
                                ? type !== 'mql'
                                : true)
                          )
                          .map(({ modelId, name, type }) => ({
                            value: `${modelId}`,
                            label: `${name} - ${MODEL_TYPES_NAMES[type]}`,
                          }))}
                        onChange={({ value }) => {
                          setSelectedModelId(Number(value));
                        }}
                        placeholder="Select model..."
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {!!selectedModelId && (
            <>
              <CorrelationGraph
                aggregation={aggregation}
                model={selectedModel}
              />
              {!!store.aggregatedEventsPage.frequencyAnalysis.length && (
                <>
                  <Accordion className="mb-3">
                    <Accordion.Item eventKey="details">
                      <Accordion.Header>Details</Accordion.Header>
                      <Accordion.Body>
                        <CorrelationTable />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {checkPermissions(
                    PROFILES_PERMISSIONS.ARCHITECT,
                    <Button
                      className="mb-3"
                      disabled={
                        store.aggregatedEventsPage.saving ||
                        !store.aggregatedEventsPage.chosenAggregatedEvents
                          .length
                      }
                      onClick={async () => {
                        await save();
                      }}
                    >
                      Save and create aggregated event(s)
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  })
);

export default FrequencyAnalysisPage;
