import { inject, observer } from 'mobx-react';
import { utc } from 'moment-mini';
import React from 'react';
import { Alert, Breadcrumb, Button, Card, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Store from '../../store';
import { snakeCaseToHumanReadable } from '../../utils';
import ComputationForm from '../components/computations/ComputationForm';
import PageHeader from '../components/PageHeader';
import Spinner from '../components/Spinner';

interface ViewEditComputationPageProps extends RouteComponentProps<any> {
  store?: Store;
}

type State = {
  showModal: boolean;
  modalConfirmEdit: boolean;
};

const ViewEditComputationPage = inject('store')(
  observer(
    class ViewEditComputationPage extends React.Component<
      ViewEditComputationPageProps,
      State
    > {
      constructor(props: ViewEditComputationPageProps) {
        super(props);

        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = { showModal: false, modalConfirmEdit: false };
      }

      async componentDidMount() {
        const { computationName } = this.props.match.params;
        this.props.store.computationsPage.loading = true;
        await this.props.store.getComputations();
        await this.props.store.getMetadataFieldsForComputations();
        if (this.props.store.computationsPage.computations.length === 0) {
          await this.props.store.initComputations();
          await this.props.store.getComputations();
        }

        this.props.store.computationsPage.activeComputation =
          this.props.store.computationsPage.computations.find(
            ({ name }) => name === computationName
          );
        this.props.store.computationsPage.activeComputation.oldName =
          computationName;
        this.props.store.computationsPage.message = null;
        this.props.store.computationsPage.formError = null;
        this.props.store.computationsPage.savingError = null;
        this.props.store.computationsPage.loading = false;

        if (
          this.props.store.computationsPage.activeComputation.usedInLiveModel
        ) {
          this.setState({ showModal: true });
        }
      }

      async save() {
        const { computationsPage } = this.props.store;
        const { activeComputation } = computationsPage;
        activeComputation.updatedAt = utc().format('YYYY-MM-DD');
        activeComputation.isDraft = true;

        // replace computation
        // get index
        const index = this.props.store.computationsPage.computations.findIndex(
          (c) => c.name === activeComputation.name
        );
        this.props.store.computationsPage.computations[index] =
          activeComputation;

        // Safeguard against editing a computation that is used in live model(s)
        if (activeComputation.usedInLiveModel && !this.state.modalConfirmEdit) {
          this.setState({ showModal: true });
          return;
        }

        const saved: boolean =
          await this.props.store.computationsPage.saveComputationsCache(
            this.props.store.tenant
          );
        if (saved) {
          this.back();
        }
      }

      back() {
        const { tenant } = this.props.store;
        this.props.history.push({
          pathname: `/tenant/${tenant}/computations`,
        });
      }

      handleClose = () => {
        this.props.history.length > 1
          ? this.props.history.goBack()
          : window.close();
      };

      handleConfirmation = () => {
        this.setState({ modalConfirmEdit: true, showModal: false });
      };

      render() {
        const {
          computationsPage: { activeComputation, loading },
        } = this.props.store;

        const { showModal } = this.state;

        if (loading || !activeComputation) {
          return <Spinner />;
        }

        return (
          <>
            <PageHeader>
              <Breadcrumb className="fs-5 mb-2">
                <LinkContainer
                  to={`/tenant/${this.props.match.params.tenant}/computations`}
                >
                  <Breadcrumb.Item>Computations</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active className="fw-bold">
                  {activeComputation.context === 'standard'
                    ? `Viewing `
                    : `Editing `}
                  {snakeCaseToHumanReadable(activeComputation.name)}
                </Breadcrumb.Item>
              </Breadcrumb>
            </PageHeader>

            <div className="mt-3 mb-5">
              <Card className="w-75 mx-auto">
                <Card.Body>
                  <ComputationForm
                    back={this.back}
                    save={this.save}
                    isNewComputation={false}
                  />
                </Card.Body>
              </Card>
            </div>

            <Modal
              show={activeComputation !== null && showModal}
              onHide={this.handleClose}
            >
              <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert variant="warning">
                  Warning! The computation you are about to edit is used in live
                  model(s).
                </Alert>
                <p>Are you sure you want to edit this computation?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.handleConfirmation}>
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  )
);

export default withRouter(ViewEditComputationPage);
