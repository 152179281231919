/* eslint-disable max-classes-per-file */
import { action, computed, makeObservable, observable } from 'mobx';
import IHttpException from '../errors/IHttpException';
import Signals from './Signals';

export * from './types';
export { default as CustomerFitSignals } from './CustomerFitSignals';
export { default as LtbSignals } from './LtbSignals';
export { default as Signals } from './Signals';

export class SignalsPageModel {
  signals: Signals;

  loadingSignals: boolean;

  savingSignals: boolean;

  signalsError: IHttpException;

  signalsSaved: boolean;

  signalsFormError: Record<string, string>;

  constructor(other?: SignalsPageModel) {
    if (other) {
      this.signals = other.signals;
      this.loadingSignals = other.loadingSignals;
      this.savingSignals = other.savingSignals;
      this.signalsError = other.signalsError;
      this.signalsSaved = other.signalsSaved;
      this.signalsFormError = other.signalsFormError;
    } else {
      this.signals = new Signals({});
      this.loadingSignals = false;
      this.savingSignals = false;
      this.signalsError = null;
      this.signalsSaved = false;
      this.signalsFormError = {};
    }

    makeObservable(this, {
      signals: observable,
      loadingSignals: observable,
      savingSignals: observable,
      signalsError: observable,
      signalsSaved: observable,
      signalsFormError: observable,
      isErrorOnForm: computed,
      validateSignalDescriptionField: action.bound,
    });
  }

  get isErrorOnForm(): boolean {
    return Object.keys(this.signalsFormError).length > 0;
  }

  signalErrorKey = ({
    field,
    signalGroupIndex,
    signalIndex,
  }: {
    field: string;
    signalGroupIndex: number;
    signalIndex: number;
  }) => `signal_group_${signalGroupIndex}_row_${signalIndex}_${field}`;

  validateSignalDescriptionField({
    description,
    signalGroupIndex,
    signalIndex,
  }: {
    description: string;
    signalGroupIndex: number;
    signalIndex: number;
  }): void {
    const forbiddenValues = ["'"];
    const descriptionFieldErrorKey = this.signalErrorKey({
      field: 'description.field',
      signalGroupIndex,
      signalIndex,
    });

    const hasForbiddenChars = forbiddenValues.some((char) =>
      description.includes(char)
    );

    if (!hasForbiddenChars) {
      delete this.signalsFormError[descriptionFieldErrorKey];
      return;
    }

    this.signalsFormError[descriptionFieldErrorKey] = `
      Signal name cannot contain the following characters: ${forbiddenValues.join(
        ', '
      )}
    `;
  }
}
