import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import checkPermissions from '../CheckPermissions';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import ClickableCard from '../generic_components/ClickableCard';
import Store from '../../../store';
import DataSetType from '../../../models/audience/DataSetType';

interface DataSetsTabsProps {
  store?: Store;
  dataSetToReplace?: DataSetType;
}

export default inject('store')(
  observer(
    class DataSetsTabs extends React.Component<
      DataSetsTabsProps,
      Record<string, never>
    > {
      constructor(props: Record<string, never>) {
        super(props);

        makeObservable(this, {
          handleSelect: action,
        });

        this.handleSelect = this.handleSelect.bind(this);
      }

      handleSelect(tab: number) {
        this.props.store.csvPage.dataSets.selectedTab = tab;
      }

      render() {
        const { store, dataSetToReplace } = this.props;
        const { tenant, modelId } = store;

        return (
          <div className="d-flex justify-content-center mt-4">
            <div className="w-50 d-grid gap-4">
              <ClickableCard
                text="Build from your integrations (CRM, MAP, S3...)"
                icon={
                  <i aria-hidden className="fas fa-network-wired fa-3x"></i>
                }
                link={
                  dataSetToReplace
                    ? `/tenant/${tenant}/models/${modelId}/import_data/mk_audience?dataset=${dataSetToReplace}`
                    : `/tenant/${tenant}/models/${modelId}/import_data/mk_audience`
                }
              />
              <ClickableCard
                text="Upload from CSV"
                icon={<i aria-hidden className="fas fa-file-csv fa-3x" />}
                link={
                  dataSetToReplace
                    ? `/tenant/${tenant}/models/${modelId}/import_data/audience?dataset=${dataSetToReplace}`
                    : `/tenant/${tenant}/models/${modelId}/import_data/audience`
                }
              />
              {checkPermissions(
                PROFILES_PERMISSIONS.SUPERKUDU,
                <ClickableCard
                  text="From MadKudu's database(Redshift)"
                  icon={<i aria-hidden className="fas fa-database fa-3x"></i>}
                  link={
                    dataSetToReplace
                      ? `/tenant/${tenant}/models/${modelId}/import_data/database?dataset=${dataSetToReplace}`
                      : `/tenant/${tenant}/models/${modelId}/import_data/database`
                  }
                />
              )}
              <div className="mx-auto">
                <LinkContainer
                  to={`/tenant/${tenant}/models/${modelId}/overview`}
                  activeClassName=""
                >
                  <Button variant="outline-primary">Go back</Button>
                </LinkContainer>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);
