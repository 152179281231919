import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FormControl,
  ListGroup,
  ListGroupItem,
  Modal,
  Card,
  Row,
  Table,
  Dropdown,
} from 'react-bootstrap';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import TreeHistory from '../../../models/tree/TreeHistory';
import TreeStructure from '../../../models/tree/TreeStructure';
import { PROFILES_PERMISSIONS } from '../../../permissions';
import Store from '../../../store';
import { flattenTree } from '../../../utils';
import checkPermissions from '../../components/CheckPermissions';
import Error from '../../components/error/Error';
import AUCCurve from '../../components/trees/AUCCurve';
import TreeSideBar from '../../components/trees/TreeSideBar';
import Spinner from '../../components/Spinner';
import CustomToggle from '../../components/generic_components/CustomToggle';
import TreeGradient from '../../assets/tree_gradient.svg';

interface TreePageProps extends RouteComponentProps<any> {
  store?: Store;
}

interface TreePageState {
  actualTransitionCoords: {
    x: number;
    y: number;
    zoom: number;
  };
}

const TreePage = inject('store')(
  observer(
    class TreePage extends React.Component<TreePageProps, TreePageState> {
      private chartRef: React.RefObject<any>;

      constructor(props: TreePageProps) {
        super(props);

        makeObservable(this, {
          displayCommitFile: action,
        });

        this.state = {
          actualTransitionCoords: { x: 350, y: 20, zoom: 0.5 },
        };
        this.selectNode = this.selectNode.bind(this);
        this.resetTree = this.resetTree.bind(this);
        this.closeResetPopup = this.closeResetPopup.bind(this);
        this.toggleResetTree = this.toggleResetTree.bind(this);
        this.closeAdvancedMode = this.closeAdvancedMode.bind(this);
        this.toggleAdvancedMode = this.toggleAdvancedMode.bind(this);
        this.closeHistory = this.closeHistory.bind(this);
        this.toggleHistory = this.toggleHistory.bind(this);
        this.displayCommitFile = this.displayCommitFile.bind(this);
        this.handleTreeDefinitionChange =
          this.handleTreeDefinitionChange.bind(this);
        this.updateTreeDefinition = this.updateTreeDefinition.bind(this);
        this.setActualTransitionCoords =
          this.setActualTransitionCoords.bind(this);

        this.chartRef = React.createRef<any>();
      }

      async componentDidMount() {
        const { treeId } = this.props.match.params;
        await this.props.store.loadTree(Number(treeId));
      }

      async componentDidUpdate(prevProps: TreePageProps) {
        const { treeId } = this.props.match.params;
        if (treeId !== prevProps.match.params.treeId) {
          await this.props.store.loadTree(Number(treeId));
        }
      }

      selectNode(
        nodeId: number,
        actualTransitionCoords?: { x: number; y: number; zoom: number }
      ) {
        const { tenant, modelId } = this.props.store;
        const { treeId } = this.props.match.params;
        if (actualTransitionCoords) {
          this.setActualTransitionCoords(actualTransitionCoords);
        }
        this.props.history.push({
          pathname: `/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/${nodeId}`,
        });
      }

      setActualTransitionCoords(coords: {
        x: number;
        y: number;
        zoom: number;
      }) {
        const { actualTransitionCoords } = this.state;

        if (
          actualTransitionCoords.x !== coords.x ||
          actualTransitionCoords.y !== coords.y ||
          actualTransitionCoords.zoom !== coords.zoom
        ) {
          this.setState({
            actualTransitionCoords: coords,
          });
        }
      }

      toggleResetTree() {
        this.props.store.toggleResetTreePopup();
      }

      closeResetPopup() {
        this.props.store.toggleResetTreePopup();
      }

      toggleAdvancedMode() {
        this.props.store.toggleAdvancedModePopup();
      }

      closeAdvancedMode() {
        this.props.store.toggleAdvancedModePopup();
      }

      toggleHistory(treeId: number) {
        return async () => {
          await this.props.store.getTreeHistory(treeId);
          this.props.store.toggleHistoryPopup();
        };
      }

      closeHistory() {
        this.props.store.toggleHistoryPopup();

        this.props.store.treesPage.treeHistory =
          this.props.store.treesPage.treeHistory.map((item) => ({
            ...item,
            active: false,
          }));
        this.props.store.treesPage.commitContent = '';
        this.props.store.treesPage.treeHistory = [];
      }

      displayCommitFile(treeId: number, commit: TreeHistory) {
        return async () => {
          this.props.store.treesPage.treeHistory =
            this.props.store.treesPage.treeHistory.map((item) => ({
              ...item,
              active:
                item.active && item.sha !== commit.sha ? false : item.active,
            }));
          // eslint-disable-next-line no-param-reassign
          commit.active = true;
          this.props.store.treesPage.isCommitDisplayLoading = true;
          await this.props.store.getCommitContent(treeId, commit.sha);
        };
      }

      async resetTree(treeId: number) {
        await this.props.store.resetTree(treeId);
      }

      handleTreeDefinitionChange(e: any) {
        const treeId = Number(this.props.match.params.treeId);
        this.props.store.treesPage.trees[treeId - 1].definition.yaml =
          e.target.value;
      }

      async updateTreeDefinition(
        treeId: number,
        treeName: string,
        commitContent?: string
      ) {
        const { historyIsShown, advancedModeIsShown } =
          this.props.store.treesPage;

        await this.props.store.updateTreeDefinition(
          treeId,
          treeName,
          commitContent
        );

        if (historyIsShown) this.closeHistory();
        if (advancedModeIsShown) this.closeAdvancedMode();
      }

      render() {
        const { tenant, modelId, treesPage, isAllowedToEdit } =
          this.props.store;
        const {
          trees,
          resetingTree,
          resetTreeIsShown,
          advancedModeIsShown,
          historyIsShown,
          updatingTree,
          savingSplitConditionError,
          deletingSplitConditionError,
          treeHistory,
          commitContent,
          isCommitDisplayLoading,
        } = treesPage;
        const treeId = Number(this.props.match.params.treeId);
        const tree = trees[treeId - 1];

        const re = this.props.location.pathname.match(/nodes\/([0-9]+)/);
        const nodeId: number = re && re[1] && Number(re[1]);

        let smallLeaves;
        let bigLeaves;

        if (tree && tree.definition && tree.definition.structure) {
          const allNodes = flattenTree(tree.definition.structure);
          const leaves = allNodes
            .filter((n) => n.children.length === 0)
            .sort((t, t2) => t.population - t2.population);
          const leavesBreakpoint = Math.ceil(leaves.length / 2);
          smallLeaves = leaves.splice(0, leavesBreakpoint);
          bigLeaves = leaves.reverse();
        }

        let performances = <div />;
        if (tree && tree.definition && tree.definition.performances) {
          const perfs = tree.definition.performances.map((perf, index) => {
            return (
              <p key={`perf_${perf.customersShare}_id${index}`}>
                <b>{Math.round(perf.customersShare)}%</b> of customers make{' '}
                <b>{Math.round(perf.conversionsShare)}%</b> of conversions.
              </p>
            );
          });
          performances = (
            <Card className="mb-2">
              <Card.Body>
                <Card.Title>Tree performance</Card.Title>
                <div className="mt-4">{perfs}</div>
              </Card.Body>
            </Card>
          );
        }

        let conversionRatesByNode = <div />;
        if (tree && tree.definition && tree.definition.precisions) {
          const tableData = tree.definition.precisions.map((node) => {
            return {
              nodes: node.ids.join(', '),
              conversions: Number(node.conversions) || 0,
              population: Number(node.population),
              percentTotalConversion: node.percentTotalConversion.toFixed(2),
              percentTotalPopulation: node.percentTotalPopulation.toFixed(2),
              conversionRate: Number((node.conversionRate * 100).toFixed(2)),
            };
          });
          conversionRatesByNode = (
            <Card>
              <Card.Body>
                <Card.Title>Nodes ranked by conversion rate</Card.Title>
                <Table className="mt-4" striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Conversions</th>
                      <th>Population</th>
                      <th>Population %</th>
                      <th>Conversion %</th>
                      <th>Conversion rate %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData
                      .sort((d1, d2) => d2.conversionRate - d1.conversionRate)
                      .map((data, index) => {
                        return (
                          <tr
                            key={`nodes_${data.nodes}_id${index}`}
                            onMouseOver={() => {
                              if (!this.chartRef.current) {
                                return;
                              }

                              const realIndex = index + 1;

                              const activeItem =
                                this.chartRef.current.state
                                  .formattedGraphicalItems?.[0].props.points[
                                  realIndex
                                ];

                              this.chartRef.current.setState(
                                {
                                  activeTooltipIndex: realIndex,
                                },
                                () => {
                                  this.chartRef.current.handleItemMouseEnter({
                                    tooltipPayload: [activeItem],
                                    tooltipPosition: {
                                      x: activeItem.x,
                                      y: activeItem.y,
                                    },
                                  });
                                }
                              );
                            }}
                            onMouseLeave={() => {
                              if (!this.chartRef.current) {
                                return;
                              }
                              this.chartRef.current.setState({
                                isTooltipActive: false,
                              });
                            }}
                          >
                            <td>{data.nodes}</td>
                            <td>{data.conversions}</td>
                            <td>{data.population}</td>
                            <td>{data.percentTotalPopulation}</td>
                            <td>{data.percentTotalConversion}</td>
                            <td>{data.conversionRate}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          );
        }

        const getLeafButtonsGroup = (leaves: TreeStructure[]) => {
          return (
            <ButtonGroup vertical>
              {leaves.map((leaf: TreeStructure) => (
                <Button
                  key={leaf.id}
                  active={nodeId ? leaf.id === nodeId : false}
                  onClick={() => this.selectNode(leaf.id)}
                  variant="outline-secondary"
                >
                  Node {leaf.id}
                  <br />
                  {Math.round(leaf.percentPopulation * 100)}% pop -{' '}
                  {Math.round(leaf.percentConversions * 100)}% conv
                </Button>
              ))}
            </ButtonGroup>
          );
        };

        const confirmResetDialog = (
          <Modal onHide={this.closeResetPopup} show={resetTreeIsShown}>
            <Modal.Header>
              <Modal.Title>
                Are you sure you want to reset Tree {treeId}?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Consider using the <code>History / Undo</code> button to undo
                your changes to the tree.
              </p>
            </Modal.Body>
            <Modal.Footer>
              {!resetingTree && (
                <Button
                  onClick={this.closeResetPopup}
                  disabled={resetingTree}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                onClick={() => this.resetTree(treeId)}
                disabled={resetingTree}
              >
                {resetingTree ? 'Reseting tree...' : 'Reset tree'}
              </Button>
            </Modal.Footer>
          </Modal>
        );

        let advancedModeDialog;
        if (tree && tree.definition) {
          advancedModeDialog = (
            <Modal
              onHide={this.closeAdvancedMode}
              show={advancedModeIsShown}
              size="lg"
            >
              <Modal.Header>
                <Modal.Title>Tree {treeId} - Advanced mode</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Please import the code from Workbench:</p>
                <FormControl
                  as="textarea"
                  placeholder="Tree definition"
                  rows={15}
                  onChange={this.handleTreeDefinitionChange}
                  autoFocus={true}
                  value={tree.definition.yaml}
                />
              </Modal.Body>
              <Modal.Footer>
                {!updatingTree && (
                  <Button
                    variant="outline-primary"
                    onClick={this.closeAdvancedMode}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={() => this.updateTreeDefinition(treeId, tree.name)}
                  disabled={
                    updatingTree ||
                    !tree.definition.yaml ||
                    tree.definition.yaml.trim().length === 0
                  }
                >
                  {updatingTree ? 'Importing tree...' : 'Import tree'}
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }

        const historyDialog = (
          <Modal onHide={this.closeHistory} show={historyIsShown} size="lg">
            <Modal.Header>
              <Modal.Title>Past commits for tree {treeId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={4}>
                  <ListGroup
                    style={{ maxHeight: '500px', overflowY: 'scroll' }}
                  >
                    {treeHistory?.map((item, index) => (
                      <ListGroupItem
                        active={item.active}
                        onClick={this.displayCommitFile(treeId, item)}
                        key={index}
                      >
                        {item.author === 'Paul Cothenet'
                          ? 'Springbok'
                          : item.author}
                        <br />
                        {item.date}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
                <Col xs={8}>
                  {isCommitDisplayLoading ? (
                    <div style={{ marginTop: '180px' }}>
                      <Spinner />
                    </div>
                  ) : (
                    <FormControl
                      as="textarea"
                      rows={3}
                      style={{ width: '100%', height: '500px' }}
                      value={commitContent}
                      disabled={true}
                    />
                  )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.closeHistory} variant="outline-primary">
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  this.updateTreeDefinition(treeId, tree.name, commitContent)
                }
                disabled={isCommitDisplayLoading || updatingTree}
              >
                {updatingTree ? 'Updating...' : 'Update'}
              </Button>
            </Modal.Footer>
          </Modal>
        );

        const TreeViz = React.lazy(
          () => import('../../components/trees/TreeViz')
        );

        return (
          <>
            {confirmResetDialog}
            {advancedModeDialog}
            {historyDialog}
            {savingSplitConditionError && (
              <Error
                message={`Could not save split condition - ${savingSplitConditionError}`}
              />
            )}
            {deletingSplitConditionError && (
              <Error
                message={`Could not delete split condition - ${deletingSplitConditionError}`}
              />
            )}
            <>
              <Card border="light" className="mb-4">
                <Card.Body>
                  <p>
                    Each node of the tree splits the total population into
                    populations with different conversion rates to isolate the
                    very good leads from the low converting populations to
                    predict the Fit of a lead.
                  </p>
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4404491327501-Customer-Fit-Decision-Tree-based-model"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none me-1"
                  >
                    <i aria-hidden className="fas fa-book-open"></i> How to read
                    a tree{' '}
                  </a>
                  and
                  <a
                    href="https://support.madkudu.com/hc/en-us/articles/4425559081357-Customer-Fit-How-to-Create-or-Edit-a-Decision-Tree"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none ms-1"
                  >
                    <i aria-hidden className="fas fa-book-open"></i>
                    How to build/edit a tree
                  </a>
                </Card.Body>
              </Card>
              {
                // eslint-disable-next-line no-nested-ternary
                tree && tree.definition ? (
                  <Row>
                    <Col sm={2}>
                      {bigLeaves.length && smallLeaves.length && (
                        <Card border="light">
                          <Card.Body>
                            <Card.Title>💡 Recommendations</Card.Title>
                            <p>Big nodes you may want to split</p>
                            {getLeafButtonsGroup(bigLeaves)}
                            <p className="mt-2">
                              Small nodes you may want to trim
                            </p>
                            {getLeafButtonsGroup(smallLeaves)}
                          </Card.Body>
                        </Card>
                      )}
                    </Col>
                    <Col sm={7}>
                      {!updatingTree ? (
                        <React.Suspense fallback={<Spinner />}>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col md="auto">
                                  <Card.Title>Tree Visualization</Card.Title>
                                </Col>
                                <Col className="float-end text-end">
                                  <img
                                    src={TreeGradient}
                                    alt="Tree gradient scale"
                                    style={{
                                      maxHeight: '300px',
                                      maxWidth: '100%',
                                      fill: 'white',
                                    }}
                                  />
                                </Col>
                                {checkPermissions(
                                  PROFILES_PERMISSIONS.ARCHITECT,
                                  <Col className="float-end" xs={1}>
                                    <Dropdown className="float-end">
                                      <Dropdown.Toggle
                                        as={CustomToggle}
                                      ></Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={this.toggleHistory(treeId)}
                                          disabled={!isAllowedToEdit}
                                        >
                                          History / undo
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={this.toggleAdvancedMode}
                                          disabled={!isAllowedToEdit}
                                        >
                                          Advanced mode
                                        </Dropdown.Item>

                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                          onClick={this.toggleResetTree}
                                          disabled={
                                            resetingTree || !isAllowedToEdit
                                          }
                                        >
                                          {resetingTree
                                            ? 'Reseting...'
                                            : 'Reset to default'}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                )}
                              </Row>

                              <TreeViz
                                treeId={treeId}
                                nodeId={nodeId}
                                store={this.props.store}
                                selectNode={this.selectNode}
                                setActualTransitionCoords={
                                  this.setActualTransitionCoords
                                }
                                actualTransitionCoords={
                                  this.state.actualTransitionCoords
                                }
                              />
                            </Card.Body>
                          </Card>
                        </React.Suspense>
                      ) : (
                        <Spinner />
                      )}
                    </Col>
                    <Col sm={3}>
                      <Route
                        exact
                        path={`/tenant/${tenant}/models/${modelId}/model/trees/${treeId}/nodes/:nodeId`}
                        render={(props: any) => (
                          <TreeSideBar
                            treeId={treeId}
                            nodeId={Number(props.match.params.nodeId)}
                            treeName={tree.name}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                ) : // eslint-disable-next-line no-nested-ternary
                tree && tree.loading ? (
                  <Spinner />
                ) : tree && tree.error ? (
                  <Alert variant="danger">
                    <h4>
                      The tree could not be loaded for the following reason:
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: tree.error }} />
                    <br />
                    <ButtonGroup vertical>
                      <Button
                        onClick={this.toggleHistory(treeId)}
                        variant="info"
                      >
                        Revert tree to a previous state
                      </Button>
                    </ButtonGroup>
                  </Alert>
                ) : (
                  <Alert variant="info">No loaded tree.</Alert>
                )
              }
            </>
            <Row className="mt-2">
              <Col sm={8}>{conversionRatesByNode}</Col>
              <Col sm={4}>
                {performances}
                {tree && tree.definition && tree.definition.precisions && (
                  <AUCCurve
                    ref={this.chartRef}
                    precisions={tree.definition.precisions}
                  />
                )}
              </Col>
            </Row>
          </>
        );
      }
    }
  )
);

export default withRouter(TreePage);
