import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';
import 'react-dates/initialize';

import favicon from './assets/favicon.ico';
import LoginPage from './pages/LoginPage';
import StudioPage from './pages/StudioPage';
import { APP_NAME } from '../utils/constants';

import 'react-dates/lib/css/_datepicker.css';
import './assets/_datepicker.css';
import './assets/main.scss';

if (__BROWSER__) {
  require('../scripts/analytics');
  require('../scripts/rollbar');
}

const App = () => (
  <>
    <Helmet
      defaultTitle={APP_NAME}
      link={[{ rel: 'icon', type: 'image/x-icon', href: favicon }]}
    />

    <Switch>
      <Route path="/tenant/:tenant">
        <StudioPage />
      </Route>
      <Route path="/">
        <LoginPage />
      </Route>
    </Switch>
  </>
);

export default App;
