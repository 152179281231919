import { useCallback, useMemo } from 'react';
import partition from 'lodash/partition';
import Gradient from 'javascript-color-gradient';

interface ColorRange {
  min: string;
  max: string;
}

const generateGradient = (
  firstColor: string,
  lastColor: string,
  midpoint: number
) => {
  const colorGradient = new Gradient();
  colorGradient.setMidpoint(midpoint);
  colorGradient.setGradient(firstColor, lastColor);

  return colorGradient;
};

/**
 * Use this hook to get gradients colors for an array of values
 * @param data your values
 * @param aboveAvgColorRange Range of colors (min, max) for the values above average
 * @param bellowAvgColorRange Range of colors (min, max) for the values bellow the average
 * @param averageColor Color for the average value
 * @param average Average value
 *
 * @returns A function to get the color of a given value
 */
export default function useGradient(
  data: number[],
  aboveAvgColorRange: ColorRange,
  bellowAvgColorRange: ColorRange,
  averageColor: string,
  average = 0
) {
  const gradientConfig = useMemo(() => {
    const uniqueData = [...new Set(data)].sort();

    const [aboveAvgData, bellowAvgData] = partition(
      uniqueData.filter((val) => val !== 0),
      (val) => val > average
    );

    const aboveAvgGradient = generateGradient(
      aboveAvgColorRange.min,
      aboveAvgColorRange.max,
      aboveAvgData.length
    );
    const bellowAvgGradient = generateGradient(
      bellowAvgColorRange.min,
      bellowAvgColorRange.max,
      bellowAvgData.length
    );

    return { aboveAvgData, bellowAvgData, aboveAvgGradient, bellowAvgGradient };
  }, [data]);

  const getGradientColor = useCallback(
    (val: number) => {
      if (val === average) return averageColor;

      const {
        bellowAvgData,
        bellowAvgGradient,
        aboveAvgData,
        aboveAvgGradient,
      } = gradientConfig;

      let gradient;
      let index;
      if (val > average) {
        index = aboveAvgData.indexOf(val);
        gradient = aboveAvgGradient;
      } else {
        index = bellowAvgData.indexOf(val);
        gradient = bellowAvgGradient;
      }

      return gradient.getColor(index + 1);
    },
    [gradientConfig]
  );

  return getGradientColor;
}
