export default class TreeNodePrecision {
  ids: number[] = [];

  population = 0;

  conversions = 0;

  conversionRate = 0;

  percentTotalPopulation = 0;

  percentTotalConversion = 0;
}
