import React from 'react';
import {
  Button,
  Col,
  FormLabel,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  Card,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import distinctColors from 'distinct-colors';

import LiftChart from './LiftChart';
import PopulationConversionsChart from './PopulationConversionsChart';
import SummaryTable from './SummaryTable';
import UnivariateAnalysisVariableBucket from '../../../models/univariate/UnivariateAnalysisVariableBucket';
import UnivariateAnalysisVariableBucketWithShare from '../../../models/univariate/UnivariateAnalysisVariableBucketWithShare';
import replaceScientificNotation from '../../../utils/replace-scientific-notation';
import UnivariateAnalysis, {
  VariableOrder,
  VariableSort,
} from '../../../models/univariate/UnivariateAnalysis';
import UnivariateAnalysisVariable from '../../../models/univariate/UnivariateAnalysisVariable';
import calculateRelevanceScore from '../../../utils/calculate-relevance-score';

const filterBucketOnUnknown = (
  buckets: UnivariateAnalysisVariableBucket[],
  bucket: UnivariateAnalysisVariableBucket,
  showUnknown: boolean
): boolean => {
  return (
    buckets.length === 1 ||
    showUnknown ||
    !(bucket.name && bucket.name.toLowerCase().includes('unknown'))
  );
};
const getBucketsWithShares = (
  buckets: UnivariateAnalysisVariableBucket[],
  showUnknown: boolean,
  noScientificNotation: boolean
): UnivariateAnalysisVariableBucketWithShare[] => {
  const sumPopulation = buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .reduce(
      (sum: number, bucket: UnivariateAnalysisVariableBucket) =>
        Number(sum) + Number(bucket.population),
      0
    );
  const sumConversions = buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .reduce(
      (sum: number, bucket: UnivariateAnalysisVariableBucket) =>
        Number(sum) + Number(bucket.conversions),
      0
    );
  const totalConversionRate =
    sumPopulation > 0 ? (sumConversions / sumPopulation) * 100 : 0;
  return buckets
    .filter((bucket) => filterBucketOnUnknown(buckets, bucket, showUnknown))
    .map((bucket) => {
      const populationShare =
        sumPopulation > 0 ? (bucket.population / sumPopulation) * 100 : 0;
      const conversionsShare =
        sumConversions > 0 ? (bucket.conversions / sumConversions) * 100 : 0;
      const conversionRate =
        bucket.population > 0
          ? (bucket.conversions / bucket.population) * 100
          : 0;
      const lift =
        totalConversionRate > 0
          ? (conversionRate - totalConversionRate) / totalConversionRate
          : 0;
      const lift7 =
        sumConversions > 0 && sumPopulation > 0
          ? (sumConversions - bucket.conversions) /
              (sumPopulation - bucket.population) /
              (sumConversions / sumPopulation) -
            1
          : 0;
      const name = noScientificNotation
        ? replaceScientificNotation(bucket.name)
        : bucket.name;
      return new UnivariateAnalysisVariableBucketWithShare(
        bucket.population,
        populationShare,
        bucket.conversions,
        conversionsShare,
        bucket.rawName,
        name,
        conversionRate,
        lift,
        lift7
      );
    });
};

interface UnivariateAnalysisChartsProps {
  univariateAnalysis: UnivariateAnalysis;
  selectedVariable: string;
  selectVariable: (variableName: string) => void;
  customerMode?: boolean;
  sampleLinkTemplate?: (
    variableTechnicalName: string,
    variableReadableName: string,
    bucketRawName: string,
    bucketName: string
  ) => string;
  showUnknown: boolean;
}

interface UnivariateAnalysisChartsState {
  searchBar: string;
  sort: VariableSort;
  order: VariableOrder;
}

export default class UnivariateAnalysisCharts extends React.Component<
  UnivariateAnalysisChartsProps,
  UnivariateAnalysisChartsState
> {
  constructor(props: UnivariateAnalysisChartsProps) {
    super(props);
    this.selectVariable = this.selectVariable.bind(this);
    this.selectSort = this.selectSort.bind(this);
    this.selectOrder = this.selectOrder.bind(this);
    this.onSearchBarChange = this.onSearchBarChange.bind(this);
    this.onSearchBarClear = this.onSearchBarClear.bind(this);

    const sort = props.customerMode ? 'most_frequently_used' : 'alphabetical';
    this.state = {
      searchBar: '',
      sort,
      order: 'asc',
    };
  }

  selectVariable(variable: string) {
    this.props.selectVariable(variable);
  }

  selectSort(e: any) {
    const sort: VariableSort = e.target.value;
    const order = sort === 'alphabetical' ? 'asc' : 'desc';
    this.setState({
      sort,
      order,
    });
  }

  selectOrder(e: any) {
    this.setState({
      order: e.target.value,
    });
  }

  onSearchBarChange(e: any) {
    this.setState({ searchBar: e.target.value });
  }

  onSearchBarClear() {
    this.setState({ searchBar: '' });
  }

  cleanVariables(variables: UnivariateAnalysisVariable[]) {
    const { selectedVariable, customerMode, showUnknown } = this.props;
    const { sort, order } = this.state;

    return variables
      .map((v: UnivariateAnalysisVariable) => {
        let bestBucketScore = -100;
        const bucketsWithShares = getBucketsWithShares(
          v.buckets,
          showUnknown,
          customerMode
        );
        if (bucketsWithShares.length > 0) {
          const [conversionsSum, populationSum] = bucketsWithShares.reduce(
            (
              [conversionsIntermediateSum, populationIntermediateSum],
              { conversions, population }
            ) => [
              conversionsIntermediateSum + conversions,
              populationIntermediateSum + population,
            ],
            [0, 0]
          );

          const avgConversionRate = (100 * conversionsSum) / populationSum;
          bestBucketScore = bucketsWithShares
            .map((bucketWithShare: UnivariateAnalysisVariableBucketWithShare) =>
              calculateRelevanceScore(bucketWithShare, avgConversionRate)
            )
            .sort((a: number, b: number) => b - a)
            .shift();
        }
        return {
          id: v.id,
          variable: v.variableName,
          score: bestBucketScore,
          mostFrequentlyUsed: v.tags && v.tags.includes('Most Frequently Used'),
        };
      })
      .sort(
        (
          a: {
            id: string;
            variable: string;
            score: number;
            mostFrequentlyUsed: boolean;
          },
          b: {
            id: string;
            variable: string;
            score: number;
            mostFrequentlyUsed: boolean;
          }
        ) => {
          switch (sort) {
            case 'alphabetical':
              return order === 'desc'
                ? b.variable.localeCompare(a.variable)
                : a.variable.localeCompare(b.variable);
            case 'relevance':
              return order === 'desc' ? b.score - a.score : a.score - b.score;
            case 'most_frequently_used':
            default:
              if (b.mostFrequentlyUsed) {
                if (a.mostFrequentlyUsed) {
                  return a.variable.localeCompare(b.variable);
                }
                return 1;
              }
              return -1;
          }
        }
      )
      .map(
        ({
          id,
          variable,
          mostFrequentlyUsed,
        }: {
          id: string;
          variable: string;
          mostFrequentlyUsed: boolean;
        }) => {
          return (
            <ListGroupItem
              key={`univariate_props_${id}`}
              active={id === selectedVariable}
              onClick={() => this.selectVariable(id)}
            >
              {mostFrequentlyUsed && (
                <span
                  className="fa fa-fw fa-star"
                  style={{ marginRight: '5px' }}
                />
              )}
              {variable}
            </ListGroupItem>
          );
        }
      );
  }

  render() {
    const {
      univariateAnalysis,
      selectedVariable,
      customerMode,
      sampleLinkTemplate,
      showUnknown,
    } = this.props;
    const { variables } = univariateAnalysis;
    const filteredVariables = variables.filter(
      (v) => !customerMode || (v.tags && v.tags.includes('Customer Available'))
    );
    const { sort, order } = this.state;
    const { searchBar } = this.state;

    const cleanedVariables = searchBar
      ? this.cleanVariables(
          filteredVariables.filter((v) =>
            v.variableName.toLowerCase().includes(searchBar.toLowerCase())
          )
        )
      : this.cleanVariables(filteredVariables);

    let cols: JSX.Element[] = [<Col key="col_empty" sm={10}></Col>];

    if (selectedVariable) {
      const variable = filteredVariables.find((a) => a.id === selectedVariable);
      if (variable) {
        const bucketsWithShares = getBucketsWithShares(
          variable.buckets,
          showUnknown,
          customerMode
        );

        const { defaultSort }: { defaultSort: 'population' | 'name' } =
          bucketsWithShares.length > 0 && bucketsWithShares[0].name.match(/^\d/)
            ? { defaultSort: 'name' }
            : { defaultSort: 'population' };

        if (bucketsWithShares.length <= 20) {
          const colors: string[] = [
            '#1e77cc',
            '#dc3912',
            '#ff9900',
            '#109618',
            '#990099',
            '#0099c6',
            '#dd4477',
            '#66aa00',
            '#b82e2e',
            '#316395',
            '#994499',
            '#22aa99',
            '#aaaa11',
            '#6633cc',
            '#e67300',
            '#8b0707',
            '#651067',
            '#329262',
            '#5574a6',
            '#3b3eac',
            '#b77322',
            '#16d620',
            '#b91383',
            '#f4359e',
            '#9c5935',
            '#a9c413',
            '#2a778d',
            '#668d1c',
            '#bea413',
            '#0c5922',
            '#743411',
          ];
          const additionalColors: string[] = distinctColors({
            count: bucketsWithShares.length - colors.length,
          }).map((color) => color.hex());

          colors.push(...additionalColors);

          cols = [
            <Row key="row_1">
              <Col key="col_popConv" sm={6}>
                <Card className="h-100">
                  <Card.Body className="flex-column">
                    <Card.Title>
                      Impact of {variable.variableName} on conversion
                    </Card.Title>
                    <PopulationConversionsChart
                      bucketsWithShares={bucketsWithShares}
                      colors={colors}
                      defaultSort={defaultSort}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col key="col_lift" sm={6}>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>
                      Lift factor of {variable.variableName}
                    </Card.Title>
                    <p>
                      when lift &gt; 0, a lead with this trait is more likely to
                      convert
                      <br />
                      when lift &lt; 0, a lead with this trait is less likely to
                      convert
                    </p>
                    <LiftChart
                      bucketsWithShares={bucketsWithShares}
                      defaultSort={defaultSort}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>,
            <React.Fragment key="row_2">
              {!customerMode && (
                <Card className="mt-3">
                  <Card.Body>
                    <Card.Title>
                      Details{' '}
                      <span className="fst-italic fs-6 fw-light float-end">
                        Data from the training dataset
                      </span>
                    </Card.Title>
                    <div className="mt-3">
                      <SummaryTable
                        variable={variable}
                        bucketsWithShares={bucketsWithShares}
                        linkTemplate={sampleLinkTemplate}
                        hideLift7Factor={customerMode}
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}
            </React.Fragment>,
          ];
        } else {
          cols = [
            <Row key="row_1">
              <Col key="col_lift" sm={12}>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>
                      Lift factor of {variable.variableName}
                    </Card.Title>
                    <p>
                      when lift &gt; 0, a lead with this trait is more likely to
                      convert
                      <br />
                      when lift &lt; 0, a lead with this trait is less likely to
                      convert
                    </p>
                    <LiftChart
                      bucketsWithShares={bucketsWithShares}
                      defaultSort={defaultSort}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>,
            <Card key="row_2" className="mt-3">
              <Card.Body>
                <Card.Title>
                  Details{' '}
                  <span className="fst-italic fs-6 fw-light float-end">
                    Data from the training dataset
                  </span>
                </Card.Title>
                <div className="mt-3">
                  <SummaryTable
                    variable={variable}
                    bucketsWithShares={bucketsWithShares}
                    linkTemplate={sampleLinkTemplate}
                    hideLift7Factor={customerMode}
                  />
                </div>
              </Card.Body>
            </Card>,
          ];
        }
      }
    }

    return (
      <>
        <Row>
          <Col sm={3}>
            <Card>
              <Card.Body>
                <Card.Title>Computations</Card.Title>
                <Form onSubmit={(e) => e.preventDefault()} className="mb-2">
                  <FormGroup>
                    <InputGroup>
                      <FormControl
                        type="text"
                        value={searchBar}
                        onChange={this.onSearchBarChange}
                        placeholder="Search"
                      />
                      <Button onClick={this.onSearchBarClear}>
                        <i aria-hidden className="fas fa fa-times" />
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Form>
                {!customerMode && filteredVariables.length > 1 && (
                  <Form className="mb-3">
                    <FormGroup>
                      <FormLabel className="me-2">Sort by</FormLabel>
                      <Form.Select
                        className="d-inline w-auto me-1"
                        onChange={this.selectSort}
                        value={sort}
                      >
                        <option value="alphabetical" key="alphabetical">
                          Alphabetical
                        </option>
                        <option value="relevance" key="relevance">
                          Relevance
                        </option>
                      </Form.Select>
                      <Form.Select
                        className="d-inline w-auto"
                        onChange={this.selectOrder}
                        value={order}
                      >
                        <option value="asc">asc</option>
                        <option value="desc">desc</option>
                      </Form.Select>
                      <OverlayTrigger
                        overlay={
                          <Popover className="p-2">
                            <div>
                              <strong>Goal:</strong> identify computations that
                              best identify converters.
                            </div>
                            <div>
                              <strong>Formula:</strong>{' '}
                              <code>
                                |conversionRate / avgConversionRate - 1|
                              </code>
                            </div>
                            <div>
                              <strong>Filter population &ge; 100</strong>
                            </div>
                          </Popover>
                        }
                      >
                        <i
                          aria-hidden
                          className="fas fa-question-circle fa-lg ms-1 text-info"
                        />
                      </OverlayTrigger>
                    </FormGroup>
                  </Form>
                )}
                <ListGroup style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                  {cleanedVariables}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={9}>{cols}</Col>
        </Row>
      </>
    );
  }
}
