import { action, makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Store from '../../../store';
import { parseAggregationName } from './utils';

interface StepTwoComponentProps extends RouteComponentProps<any> {
  store?: Store;
  logicOptions: { display: string; value: string }[];
}

class StepTwoComponent extends React.Component<
  StepTwoComponentProps,
  Record<string, never>
> {
  constructor(props: StepTwoComponentProps) {
    super(props);

    this.handleAggregationLogicChange =
      this.handleAggregationLogicChange.bind(this);

    makeObservable(this, {
      handleAggregationLogicChange: action,
    });
  }

  handleAggregationLogicChange(e: any) {
    const { value } = e.target;
    const { customName, lifetime, type } =
      this.props.store.behavioralAggregationsPage.activeAggregation;

    if (lifetime && type && value) {
      // modifying lifeline automatically changes name -> '<type> - <logic_with_lifetime_insterted>'
      this.props.store.behavioralAggregationsPage.activeAggregation.name =
        parseAggregationName({
          lifetime,
          logic: value,
          custom: customName,
        });
    }
    this.props.store.behavioralAggregationsPage.activeAggregation.logic = value;
    this.props.store.behavioralAggregationsPage.stepConfiguration[2].validated =
      true;

    this.forceUpdate();
  }

  render() {
    const { logicOptions, store } = this.props;
    const { behavioralAggregationsPage, isArchitect, isSuperKudu } = store;
    const { activeAggregation } = behavioralAggregationsPage;

    if (!activeAggregation) return null;

    const { logic } = activeAggregation;

    const logicDisplayValue = logicOptions.find(
      (option) => option.value === logic
    );

    return (
      <Form.Group className="mb-3" controlId="formDefinition">
        <Form.Label className="fw-bold">Definition</Form.Label>
        <Form.Select
          name="aggregationLogic"
          value={logicDisplayValue?.value}
          onChange={this.handleAggregationLogicChange}
          disabled={
            !(isSuperKudu || isArchitect) ||
            activeAggregation.usedInLiveModel ||
            activeAggregation.isSynced
          }
        >
          {[{ display: '-', value: '-' }, ...logicOptions].map(
            (logicOption) => (
              <option
                key={`aggregation_logic_${logicOption.value}`}
                value={logicOption.value}
              >
                {logicOption.display}
              </option>
            )
          )}
        </Form.Select>
      </Form.Group>
    );
  }
}

export default inject('store')(observer(withRouter(StepTwoComponent)));
