/* eslint-disable max-classes-per-file */
import { makeObservable, observable } from 'mobx';

export class SampleLead {
  email: string;

  score: number;

  segment: string;

  signals: string;

  hasConverted: boolean;

  constructor(
    email: string,
    score: number,
    segment: string,
    signals: string,
    hasConverted: boolean
  ) {
    this.email = email;
    this.score = score;
    this.segment = segment;
    this.signals = signals;
    this.hasConverted = hasConverted;
  }
}

export class CustomerFitSampleLead extends SampleLead {
  employees: number;

  amountRaised: number;

  marketCap: number;

  isFortune500Company: boolean;

  country: string;

  predictiveTraffic: number;

  industry: string;

  hasEnterpriseTechnology: boolean;

  tree1Node: string;

  tree2Node: string;

  tree3Node: string;

  constructor(
    email: string,
    score: number,
    segment: string,
    signals: string,
    hasConverted: boolean,
    employees: number,
    amountRaised: number,
    marketCap: number,
    isFortune500Company: boolean,
    country: string,
    predictiveTraffic: number,
    industry: string,
    hasEnterpriseTechnology: boolean,
    tree1Node: string,
    tree2Node: string,
    tree3Node: string
  ) {
    super(email, score, segment, signals, hasConverted);
    this.employees = employees;
    this.amountRaised = amountRaised;
    this.marketCap = marketCap;
    this.isFortune500Company = isFortune500Company;
    this.country = country;
    this.predictiveTraffic = predictiveTraffic;
    this.industry = industry;
    this.hasEnterpriseTechnology = hasEnterpriseTechnology;
    this.tree1Node = tree1Node;
    this.tree2Node = tree2Node;
    this.tree3Node = tree3Node;

    makeObservable(this, {
      hasConverted: observable,
      employees: observable,
      amountRaised: observable,
      marketCap: observable,
      isFortune500Company: observable,
      country: observable,
      predictiveTraffic: observable,
      industry: observable,
      hasEnterpriseTechnology: observable,
      tree1Node: observable,
      tree2Node: observable,
      tree3Node: observable,
    });
  }
}

export class BehavioralSampleLead extends SampleLead {
  domain: string;

  constructor(
    email: string,
    score: number,
    segment: string,
    signals: string,
    hasConverted: boolean,
    domain: string
  ) {
    super(email, score, segment, signals, hasConverted);
    this.domain = domain;
    makeObservable(this, {
      domain: observable,
    });
  }
}
