/* eslint-disable max-classes-per-file */
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import React from 'react';
import { Badge, Card, Table } from 'react-bootstrap';
import { LeadToRevenue } from '../../../models/performance_check';
import { capitalizeEachWord } from '../../../utils';

type LeadToRevenueSegmentComponentProps = {
  leadToRevenue: LeadToRevenue;
  segment: string;
  badgeColor: string;
};

class LeadToRevenueSegmentComponent extends React.Component<
  LeadToRevenueSegmentComponentProps,
  Record<string, never>
> {
  render() {
    const { leadToRevenue, segment, badgeColor } = this.props;
    return (
      <tr>
        <td>
          <Badge bg={badgeColor}>{capitalizeEachWord(segment)}</Badge>
        </td>
        <td>
          {leadToRevenue[segment]
            ? numeral(leadToRevenue[segment].leads || 0).format('0,00')
            : 0}
        </td>
        <td>
          $
          {leadToRevenue[segment]
            ? numeral(leadToRevenue[segment].revenue || 0).format('0,00')
            : 0}
        </td>
      </tr>
    );
  }
}

type LeadRevenueProps = {
  leadToRevenue: LeadToRevenue;
};

export default inject('store')(
  observer(
    class LeadToRevenueComponent extends React.Component<
      LeadRevenueProps,
      Record<string, never>
    > {
      render() {
        const { leadToRevenue } = this.props;
        const veryGood = leadToRevenue['very good'] ?? {
          leads: 0,
          revenue: 0,
        };
        const good = leadToRevenue.good ?? {
          leads: 0,
          revenue: 0,
        };

        const qualifiedRevenue = (veryGood.revenue ?? 0) + (good.revenue ?? 0);
        const totalRevenue = Object.values(leadToRevenue).reduce(
          (sum, curr) => sum + (curr.revenue ?? 0),
          0
        );
        const percentageRevenue = Math.round(
          (qualifiedRevenue * 100) / totalRevenue
        );
        const totalLeads = Object.values(leadToRevenue).reduce(
          (sum, curr) => sum + curr.leads ?? 0,
          0
        );

        return (
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Leads and Revenue in Numbers</Card.Title>
              <p>
                <b>
                  ${numeral(qualifiedRevenue).format('0,00')} (
                  {percentageRevenue}%)
                </b>{' '}
                of your revenue was successfully predicted by MadKudu.
              </p>
              <Table>
                <thead>
                  <tr>
                    <th>Segment</th>
                    <th># of Leads</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  <LeadToRevenueSegmentComponent
                    leadToRevenue={leadToRevenue}
                    segment="very good"
                    badgeColor="primary"
                  />
                  <LeadToRevenueSegmentComponent
                    leadToRevenue={leadToRevenue}
                    segment="good"
                    badgeColor="info"
                  />
                  <LeadToRevenueSegmentComponent
                    leadToRevenue={leadToRevenue}
                    segment="medium"
                    badgeColor="warning"
                  />
                  <LeadToRevenueSegmentComponent
                    leadToRevenue={leadToRevenue}
                    segment="low"
                    badgeColor="danger"
                  />
                  <tr>
                    <td>
                      <b>Totals</b>
                    </td>
                    <td>{numeral(totalLeads).format('0,00')}</td>
                    <td>${numeral(totalRevenue).format('0,00')}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      }
    }
  )
);
